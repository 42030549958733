import React, { useEffect, useState, useRef } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

// Containers
import Employees from "./Employees";
import Faq from "./Faq";
import History from "./History";
import Contact from "./Contact";
import Practical from "./Practical";
import Vision from "./Vision";

// Components
import { Box } from "@material-ui/core";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
import ContentContainer from "../../components/containers/ContentContainer";
import AboutLink from "../../components/links/AboutLink";
import AboutContentContainer from "../../components/containers/about/AboutContentContainer";
import { useCallback } from "react";
import { overridings } from "../../themes/DarkTheme";

// Images
import VisionImg from "../../assets/headers/header-vision.jpg";
import HistoryImg from "../../assets/headers/header-history-small.jpg";
import EmployeesImg from "../../assets/headers/header-employees-small.jpg";
import PracticalImg from "../../assets/headers/header-practical-small.jpg";
import FaqImg from "../../assets/headers/header-faq-small.jpg";

const FlexContainer = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

const AboutNavContainer = styled(Box)`
  width: 18%;
  margin-right: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-right: 0;
  }
`;

function About(props) {
  const contentRef = useRef();

  const { url } = useRouteMatch();
  const [currentUrl, setCurrentUrl] = useState(`${url}/vision`);
  const [currentMenu, setCurrentMenu] = useState("vision");

  const getCurrentUrl = (currentUrl, currentMenu) => {
    setCurrentUrl(currentUrl);
    setCurrentMenu(currentMenu);
  };

  const changeSelectedMenu = (selectedUrl, selectedMenu) => {
    setCurrentUrl(selectedUrl);
    setCurrentMenu(selectedMenu);
  };

  const getRightHeaderImage = useCallback(() => {
    // Display mobile size images for mobile
    if (document.body.offsetWidth < overridings.breakpoints.values.md) {
      switch (currentMenu) {
        case "Vision":
          return VisionImg;
        case "Historie":
          return HistoryImg;
        case "Medarbejdere":
          return EmployeesImg;
        case "Praktisk":
          return PracticalImg;
        case "FAQ":
          return FaqImg;

        default:
          return VisionImg;
      }
    } else {
      switch (currentMenu) {
        case "Vision":
          return VisionImg;
        case "Historie":
          return HistoryImg;
        case "Medarbejdere":
          return EmployeesImg;
        case "Praktisk":
          return PracticalImg;
        case "FAQ":
          return FaqImg;

        default:
          return VisionImg;
      }
    }
  }, [currentMenu]);

  useEffect(() => {
    getRightHeaderImage();
  }, [currentMenu, getRightHeaderImage]);

  // ComponentDidMount
  useEffect(() => {
    // Take user to top of the page
    contentRef.current.scrollIntoView(true);
    // eslint-disable-next-line
  }, []);
  
  return (
    <div>
      {/* Make the image according to which tab is open */}
      <ReusableHeader bgPosition="10% 50%" imageUrl={getRightHeaderImage} />
      <span ref={contentRef}></span>
      <ContentContainer marginTop="2rem">
        <FlexContainer>
          <AboutNavContainer>
            <AboutLink
              changeSelectedMenu={changeSelectedMenu}
              to={`${url}/vision`}
              title="Vision"
              currentUrl={currentUrl}
            />

            <AboutLink
              changeSelectedMenu={changeSelectedMenu}
              to={`${url}/historie`}
              title="Historie"
              currentUrl={currentUrl}
            />

            <AboutLink
              changeSelectedMenu={changeSelectedMenu}
              to={`${url}/medarbejdere`}
              title="Medarbejdere"
              currentUrl={currentUrl}
            />

            <AboutLink
              changeSelectedMenu={changeSelectedMenu}
              to={`${url}/praktisk`}
              title="Praktisk"
              currentUrl={currentUrl}
            />

            <AboutLink
              changeSelectedMenu={changeSelectedMenu}
              to={`${url}/faq`}
              title="FAQ"
              currentUrl={currentUrl}
            />
          </AboutNavContainer>
          <AboutContentContainer>
            <Route path={`${url}/vision`}>
              <Vision getCurrentUrl={getCurrentUrl} />
            </Route>

            <Route path={`${url}/historie`}>
              <History getCurrentUrl={getCurrentUrl} />
            </Route>
            <Route path={`${url}/medarbejdere`}>
              <Employees getCurrentUrl={getCurrentUrl} />
            </Route>
            <Route path={`${url}/praktisk`}>
              <Practical getCurrentUrl={getCurrentUrl} />
            </Route>

            <Route path={`${url}/faq`}>
              <Faq getCurrentUrl={getCurrentUrl} />
            </Route>

            {/* Contact page is different and shouldn't be here. ??? */}
            <Route path={`${url}/kontakt`}>
              <Contact getCurrentUrl={getCurrentUrl} />
            </Route>
          </AboutContentContainer>
        </FlexContainer>
      </ContentContainer>
    </div>
  );
}

export default About;
