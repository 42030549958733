import React from "react";
import styled from "styled-components";
import RegularHeadline from "../../../components/headlines/RegularHeadline";
import { Typography } from "@material-ui/core";

const DescriptionText = styled(Typography)`
  margin-bottom: 1rem;
  width: 60%;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

function ExtraParentMeetingDescription(props) {
  return (
    <React.Fragment>
      <RegularHeadline title="Potentielle Følgevoksenmøder" />
      <DescriptionText variant="body2">
        I løbet af sæsonen opretter vi ekstra holdmøder, så snart mindst 5
        følgevoksne kan finde sammen om en fælles dato og mødetype. Herunder kan
        du afkrydse hvornår du kan komme.
      </DescriptionText>
      <DescriptionText variant="body2">
        7 dage før en given dato konstateres det, om der er følgevoksne nok til
        at oprette én af mødetyperne. Ved mere end 5 tilmeldte, bliver den
        mødetype med flest tilmeldte oprettet. NB! Ved stemmelighed er det den
        mødetype der først nåede sluttallet der oprettes.
      </DescriptionText>
      <DescriptionText variant="body2">
        Jo, flere tidspunkter du afkrydser, jo større mødemulighed skaber du for
        dig selv og andre :-)
      </DescriptionText>
    </React.Fragment>
  );
}

export default ExtraParentMeetingDescription;
