import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DialogComponent = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem !important;
  }

  position: relative;
`;

function WarningChildRemoval({
  close,
  setScrollView,
  onConfirm,
  childToRemove,
}) {
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");

  useEffect(() => {
    if (
      childToRemove &&
      childToRemove.childFirstname &&
      childToRemove.childSurname
    ) {
      setFirstname(childToRemove.childFirstname);
      setSurname(childToRemove.childSurname);
    }
  }, [childToRemove]);

  // ComponentDidMount
  useEffect(() => {
    if (setScrollView) {
      setScrollView();
    }
    // Come back: check if its okay not to include setScrollView in dependencies
    // eslint-disable-next-line
  }, []);
  console.log("¨Warning child removal", childToRemove);

  return (
    <DialogComponent
      onClose={close}
      open={true}
      aria-labelledby="error"
      aria-describedby="error-description"
      disableRestoreFocus
    >
      <DialogTitle id="error">Advarsel</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-description">
          Er du sikker på, at du vil fjerne {firstname} {surname}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box display="flex">
          <Button onClick={() => onConfirm()}>Ja</Button>
          <Button onClick={() => close()}>Annuler</Button>
        </Box>
      </DialogActions>
    </DialogComponent>
  );
}

export default WarningChildRemoval;
