import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const ButtonComponent = styled(Button)`
  border-radius: 30px;
  border: 2px solid
    ${(props) =>
      props.background
        ? props.background
        : props.theme.palette.primary.contastText};
  text-transform: none;
  min-width: ${(props) => (props.minwidth ? props.minwidth : "100%")};
  margin: ${(props) => props.margin && props.margin};

  padding: ${(props) =>
    props.paddingvertical ? props.paddingvertical : "6px"};

  background-color: ${(props) =>
    props.background ? props.background : "transparent"};
  color: ${(props) => props.buttoncolor && props.buttoncolor};
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: ${(props) => props.mobilewidth && props.mobilewidth};
  }
`;

const RegularButton = ({
  title,
  onButtonClick,
  background,
  color,
  name,
  paddingHorizontal,
  paddingVertical,
  size,
  mobileWidth,
  minWidth,
  margin,
  disabled,
}) => {
  return (
    <ButtonComponent
      variant="outlined"
      disabled={disabled}
      size={size}
      background={background}
      onClick={(event) => {
        onButtonClick(event);
      }}
      paddinghorizontal={paddingHorizontal}
      paddingvertical={paddingVertical}
      buttoncolor={color}
      name={name}
      mobilewidth={mobileWidth}
      minwidth={minWidth}
      margin={margin}
    >
      {title}
    </ButtonComponent>
  );
};

export default RegularButton;
