import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import moment from "moment"; // Luxon will replace moment, that is no longer being developed
import { DateTime } from "luxon";
import Loader from "../loader/Loader";
// import { nextMonth } from "./CalendarFunctions";
import EventInMobileCalendar from "./EventInMobileCalendar";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { getAndReturnData } from "../../containers/HelperFunctions";
import { Context } from "../../context/Context";

const CalendarContainerMobile = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: block;
    width: 85%;
    margin: 2rem auto 4rem auto;
  }
`;
const CalendarHeader = styled.div`
  position: relative;
  padding-top: 1rem;
`;
const CurrentMonth = styled.span`
  text-transform: uppercase;
  font-size: 0.9rem;
`;
const MonthContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 50px;
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 14px 0px;
`;
const Month = styled.span`
  margin: 3% 7.5%;
  text-transform: uppercase;
  font-size: 0.9rem;
`;
const NextEventsText = styled(Typography)`
  font-size: 1.1rem;
`;
const FilterIconContainer = styled.span`
  position: absolute;
  right: 0px;
  top: 0.5rem;
  fontsize: large;
`;
const CalendarContainer = styled.div``;
const EventsContainer = styled.div`
  margin: 2rem 0rem 1rem 0rem;
`;
const SeeMoreLink = styled(Typography)`
  font-size: 0.8rem;
  text-decoration: underline;
  display: inline;
`;
function MobileCalendar() {
  const context = useContext(Context);

  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [eventInformationFromApi, setEventInfofromApi] = useState([]);

  let firstDay = DateTime.now().toISO().substring(0,10);  
  let lastDay = DateTime.now().plus({months:1}).toISO().substring(0,10);  

  const sortEventsByDate = (event1, event2) => {
    if (
      moment(event1.startdate).format("YYYY-MM-DD") >
      moment(event2.startdate).format("YYYY-MM-DD")
    )
      return 1;
    if (
      moment(event1.startdate).format("YYYY-MM-DD") <
      moment(event2.startdate).format("YYYY-MM-DD")
    )
      return -1;
    if (
      moment(event1.startdate).format("YYYY-MM-DD") ===
      moment(event2.startdate).format("YYYY-MM-DD")
    )
      return 0;
  };
  const displayEvents = (events) =>
    events.map((event, key) => {
      if (key !== 0) {
        if (
          moment(event.startdate).format("MMMM") ===
          moment(events[key - 1].startdate).format("MMMM")
        ) {
          return <EventInMobileCalendar key={key} eventInfo={event} />;
        } else {
          return (
            <div key={key}>
              <MonthContainer>
                <Month>{moment(event.startdate).format("MMMM")}</Month>
              </MonthContainer>
              <EventInMobileCalendar eventInfo={event} />
            </div>
          );
        }
      } else {
        return <EventInMobileCalendar key={key} eventInfo={event} />;
      }
    });
  // Handle if there is an error with api and fetch
  const handleError = (err) => {
    console.log('getAndSaveCalendarData error:',err);
  };
  const getAndSaveCalendarData = async () => {
    firstDay= DateTime.now().toISO().substring(0,10);  
    lastDay = DateTime.now().plus({months:1}).toISO().substring(0,10);  
    const response = await getAndReturnData(
      `/api/v1/event/public/${firstDay}/${lastDay}`,
      handleError
    );
    let data = response.eventList;
    // console.log('Data', data)
    if (!data || data.length === 0) {
      setCalendarLoaded(true);
    } else {
      const allEvents = data.sort(sortEventsByDate);
      setEventInfofromApi(allEvents.slice(0, 5));
      setCalendarLoaded(true);
    }
  };
  useEffect(() => {
    // Start loader
    setCalendarLoaded(false);
    // Get events
    getAndSaveCalendarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CalendarContainerMobile style={{ display: (context.state.features.enableCalendar?'block':'none')}}>
      {calendarLoaded ? (
        <CalendarContainer>
          <CalendarHeader>
            <NextEventsText>Kommende aktiviteter</NextEventsText>
            <FilterIconContainer>
              <FilterListIcon fontSize={"large"} />
            </FilterIconContainer>
            {/*  Here we need to handle what happens if there is no data from api, 
              otherwise we get an error, as eventInformationFromApi[0] is undefined, since is an empty array.  */}
            {eventInformationFromApi.length > 0 ? (
              <CurrentMonth>
                {moment(eventInformationFromApi[0].startdate).format("MMMM")}
              </CurrentMonth>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </CalendarHeader>
          {/*  Here we need to handle what happens if there is no data from api, and eventInformationFromApi is an empty array.  */}
          {eventInformationFromApi.length > 0 ? (
            <React.Fragment>
              <EventsContainer>
                {displayEvents(eventInformationFromApi)}
              </EventsContainer>
              <SeeMoreLink>
                <Link
                  to={{
                    pathname: "/mobile-kalender-se-flere",
                    state: { data: eventInformationFromApi },
                  }}
                >
                  Se flere
                </Link>
              </SeeMoreLink>
            </React.Fragment>
          ) : (
            <Typography variant="body2">
              Der er ingen begivenheder, eller du er offline
            </Typography>
          )}
        </CalendarContainer>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </CalendarContainerMobile>
  );
}
export default withRouter(MobileCalendar);
