import React, { useState } from "react";
import styled from "styled-components";
import { withCookies } from "react-cookie";
import { putAndReturnResp } from "../../../context/helperFunctions";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

import InputLabel from "@material-ui/core/InputLabel";
import { Typography, Box, FormHelperText } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import RegularButton from "../../buttons/RegularButton/RegularButton";
import {
  validatePasswordValue,
  validatePasswordValueMatch,
} from "../../../containers/registration/information/RegisterInformationFunctions";

const DialogComponent = styled(Dialog)`
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
  }
  & .MuiPaper-root {
    box-shadow: inset 0 0 5px grey;
    width: 22rem;
    background-color: ${(props) => props.theme.palette.common.black};
  }
  & .MuiTypography-h6 {
    font-size: 1.1rem;
  }
  @media (max-width: 960px) {
    & .MuiBackdrop-root {
      overflow: visible;
    }
  }
`;
const DialogTitleComponent = styled(DialogTitle)`
  padding: 0px 24px 16px 24px;
`;
const CloseDialog = styled.button`
  font-size: 20px;
  width: 20%;
  padding-top: 16px;
  margin-left: 79%;
  color: ${(props) => props.theme.palette.common.white};
  border-style: none;
  background-color: transparent;
  cursor: pointer;
`;
const InputLabelComponent = styled(InputLabel)`
  color: ${(props) => props.theme.palette.common.white};
  margin-top: 0.8rem;
`;
const TextFieldComponent = styled(TextField)`
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
  width: 100%;
  & .MuiInputBase-root {
    padding: 5px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.palette.common.white};
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .MuiOutlinedInput-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px
      ${(props) => props.theme.palette.common.black} inset;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${(props) =>
        props.error
          ? props.theme.palette.error.main
          : props.theme.palette.common.white};
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.error.main};
  }
  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
    max-width: fit-content;
  }
  .MuiFormHelperText-contained {
    margin: 5px 0 0 0;
    max-width: fit-content;
  }
`;
const DialogActionsContainer = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 8px 10px 8px;
`;
const BoxContainer = styled(Box)`
  & .MuiFormHelperText-root {
    color: ${(props) =>
      props.successReq
        ? props.theme.palette.success.main
        : props.theme.palette.error.main};
    text-align: center;
    font-size: 1rem;
    margin: 1rem;
  }
`;
const SubmitButtonContainer = styled.div`
  width: 100%;
  padding: 10px 12px;
  display: ${(props) => (props.successReq ? "none" : "block")};
`;
const BackToLogin = styled(Typography)`
  text-decoration: underline;
  font-size: 1rem;
  margin: 0.5rem 0 0.5rem 20px;
`;
function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const { id } = props.match.params;
  const [passwordvalidateMessage, setpasswordValidateMessage] = useState("");
  const [repeatPasswordValidateMessage, setRepeatPasswordValidateMessage] =
    useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  const handleChange = (event) => {
    event.target.id === "password"
      ? setPassword(event.target.value)
      : setRepeatPassword(event.target.value);
  };

  const handleBlur = (event) => {
    if (event.target.id === "password") {
      if (password.length === 0) {
        setPasswordError(false);
      } else {
        if (validatePasswordValue(password)) {
          setPasswordError(false);
        } else {
          setPasswordError(true);
          setpasswordValidateMessage(
            `Kodeordet skal være på mindst 6 tegn og indeholde mindst et tal og både store og små bogstaver.`
          );
        }
      }
    } else {
      if (repeatPassword.length === 0) {
        setRepeatPasswordError(false);
      } else {
        if (validatePasswordValue(repeatPassword) === "") {
          setRepeatPasswordError(true);
        } else {
          setRepeatPasswordError(false);
        }
        setRepeatPasswordValidateMessage(
          validatePasswordValueMatch(password, repeatPassword)
        );
      }
    }
  };
  const handleFocus = (event) => {
    if (event.target.id === "password") {
      setpasswordValidateMessage("");
    } else {
      setRepeatPasswordValidateMessage("");
    }
  };
  const handleError = (message) => {
    setSubmitMessage(message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestBody = {
      password: password,
      uniqueId: id,
    };
    if (password.length === 0 && repeatPassword.length === 0) {
      setSuccessRequest(false);
      setpasswordValidateMessage("Du skal skrive dit kodeord.");
      setRepeatPasswordValidateMessage("Du skal indtaste det samme kodeord.");
      setPasswordError(true);
      setRepeatPasswordError(true);
      return;
    } else if (password.length === 0) {
      setSuccessRequest(false);
      setPasswordError(true);
      setpasswordValidateMessage("Du skal skrive dit kodeord.");
      return;
    } else if (repeatPassword.length === 0) {
      setSuccessRequest(false);
      setRepeatPasswordError(true);
      setRepeatPasswordValidateMessage("Du skal indtaste det samme kodeord.");
      return;
    } else {
      if (validatePasswordValueMatch(password, repeatPassword)) {
        // POST request to backend
        // setSubmitMessage("match");
        setRepeatPasswordError(false);
        const result = await putAndReturnResp(
          `/api/v1/user/password`,
          requestBody,
          handleError
        );
        if (result) {
          if (result.status === 200) {
            setSuccessRequest(true);
            setSubmitMessage("Adgangskoden er opdateret.");
          } else if (result.status === 404) {
            setSuccessRequest(false);
            setSubmitMessage("Linket duer ikke, bed om et nyt.");
          } else {
            setSuccessRequest(false);
            setSubmitMessage("Adgangskoden er ikke opdateret. Noget gik galt.");
          }
        } else {
        }
      } else {
        setSuccessRequest(false);
        setRepeatPasswordValidateMessage("Adgangskoderne er ikke identiske.");
        setRepeatPasswordError(true);
      }
    }
  };
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleClose = () => {
    setDialogOpen(false);
    props.history.push("/");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  const denyCopyPAste = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <DialogComponent
        fullpageview={props.fullPageView}
        open={dialogOpen}
        aria-labelledby="form-dialog-title"
        onSubmit={handleSubmit}
        // onClose={handleClose}
      >
        <CloseDialog onClick={handleClose}>
          <CloseIcon />
        </CloseDialog>
        <DialogTitleComponent id="form-dialog-title">
          Indtast et nyt kodeord
        </DialogTitleComponent>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <InputLabelComponent shrink htmlFor="password">
              Kodeord
            </InputLabelComponent>
            <TextFieldComponent
              margin="dense"
              id="password"
              fullWidth
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              required
              onBlur={handleBlur}
              // It should be uncommented when its done
              onCopy={denyCopyPAste}
              onPaste={denyCopyPAste}
              error={passwordError}
              helperText={passwordvalidateMessage}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      // Should delete it when finish test
                      // onClick={() => setShowPassword(!showPassword)}

                      //  not allowed user copy password
                      onMouseEnter={() => setShowPassword(true)}
                      onMouseLeave={() => setShowPassword(false)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <InputLabelComponent shrink htmlFor="password">
              Gentag kodeord
            </InputLabelComponent>
            <TextFieldComponent
              margin="dense"
              id="repeatpassword"
              fullWidth
              value={repeatPassword}
              type={showRepeatPassword ? "text" : "password"}
              onChange={handleChange}
              required
              onBlur={handleBlur}
              onCopy={denyCopyPAste}
              // It should be uncommented when its done
              onPaste={denyCopyPAste}
              onFocus={handleFocus}
              error={repeatPasswordError}
              helperText={repeatPasswordValidateMessage}
              onKeyPress={(event) => handleKeyPress(event)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseEnter={() => setShowRepeatPassword(true)}
                      onMouseLeave={() => setShowRepeatPassword(false)}
                    >
                      {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <BoxContainer maxWidth="fit-content" successReq={successRequest}>
            <FormHelperText>{submitMessage}</FormHelperText>
          </BoxContainer>
          <DialogActionsContainer>
            <SubmitButtonContainer successReq={successRequest}>
              <RegularButton title="Send" onButtonClick={handleSubmit} />
            </SubmitButtonContainer>
            <BackToLogin>
              <Link
                to={{
                  pathname: "/login",
                }}
              >
                Tilbage til Login
              </Link>
            </BackToLogin>
          </DialogActionsContainer>
        </form>
      </DialogComponent>
    </div>
  );
}

export default withRouter(withCookies(ResetPassword));
