import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../../context/Context";

const SliderContainer = styled.div`
  width: 50%;
  min-width: 350px;
  margin-bottom: 1.5rem;
`;

const SliderComponent = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 95%; /* Specific width is required for Firefox. */
  min-width: 330px;
  background: transparent; /* Otherwise white in Chrome */

  // For Webkit/blink
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.colors.purple};
    cursor: pointer;
    margin-top: -8px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  // For firefox
  &::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.colors.purple};
    cursor: pointer;
  }

  // For IE

  &::-ms-thumb {
    height: 1rem;
    width: 1rem;
    border-radius: 3px;
    background: ${(props) => props.theme.palette.colors.purple};
    cursor: pointer;
  }
  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &:::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 1.3px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 1.3px;
  }

  &:focus::-ms-fill-upper {
    background: ${(props) => props.theme.palette.colors.purple};
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.palette.colors.purple};
  }
`;

const LabelsContainer = styled.div`
  width: 100%;
  min-width: 350px;
  display: flex;
  justify-content: space-between;
  margin-left: -0.5rem;
  margin-bottom: 1rem;
`;

const Label = styled(Typography)`
  font-size: 1.1rem;
  width: 8.3%;
  text-align: center;
  color: ${(props) => {
    if (props.selectedvalue === props.number) {
      return `${props.theme.palette.primary.contrastText}`;
    } else {
      return "rgba(255, 255, 255, 0.5)";
    }
  }};

  cursor: pointer;
`;

const MainText = styled(Typography)`
  margin-bottom: 0.2rem;
`;
const PaleText = styled(Typography)`
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 1rem;
`;

function Slider({
  maxNumberOfRates,
  labels,
  setScrollView,
  priceSum,
  setError,
  setErrorMessage,
}) {
  const [value, setValue] = useState("1");
  const [rates, setRates] = useState([]);

  const context = useContext(Context);

  const handleLabelClick = (labelValue) => {
    if (priceSum < 1 || isNaN(priceSum)) {
      setScrollView();
      return setTimeout(() => {
        // Come back: Danish text
        setErrorMessage("You need to select a forløb first.");
        setError(true);
      }, 500);
    }
    setValue(labelValue);
    context.saveRatesData({ rates, numberOfRates: labelValue });
  };

  const onChange = (event) => {
    if (priceSum < 1 || isNaN(priceSum)) {
      setScrollView();
      return setTimeout(() => {
        // Come back: Danish text
        setErrorMessage("You need to select a forløb first.");
        setError(true);
      }, 500);
    }
    setValue(Number(event.target.value));
    const numberOfRatesInNumber = Number(event.target.value);
    context.saveRatesData({ rates, numberOfRates: numberOfRatesInNumber });
  };

  // Check if there is already data in context about rates
  useEffect(() => {
    const ratesData = context.state.ratesData;
    if (ratesData) {
      if (ratesData.numberOfRates) {
        setRates(ratesData.rates);
        setValue(ratesData.numberOfRates);
      }
    }
  }, [context.state.ratesData]);

  return (
    <div>
      <MainText variant="body2">
        Hvor mange rater vil du dele betalingen op i?
      </MainText>
      <PaleText variant="body2">
        Brug slideren eller klik på de måneder du vil betale i
      </PaleText>
      <SliderContainer>
        <LabelsContainer>
          {labels.map((label) => (
            <Label
              variant="body2"
              key={label.value}
              onClick={() => handleLabelClick(label.value)}
              selectedvalue={Number(value)}
              number={label.value}
            >
              {label.value}
            </Label>
          ))}
        </LabelsContainer>
        <SliderComponent
          type="range"
          min="1"
          max={maxNumberOfRates}
          value={value}
          onChange={onChange}
        />
      </SliderContainer>
    </div>
  );
}

export default Slider;
