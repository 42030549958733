import React from "react";
import styled from "styled-components";
import RegularButton from "../../buttons/RegularButton/RegularButton";
import { Typography } from "@material-ui/core";

const SlideContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 3%;
position: relative;
width:100%;
height:450px;
@media (max-width: 960px) {
  max-height: 700px;
  height: 700px;   
 `;
const SlideInfoContainer = styled.div`
display: flex; 
height: 75%;
@media (max-width: 960px) {
  // flex-direction: column;
  display: block;

`;

const ImageContainer = styled.div`
max-width: 45%;
width:45%;

@media (max-width: 960px) {
  max-width: 75%;
  width: 75%;
  margin:auto;
@media (max-width: 710px) {
  max-width: 100%;
  width: 100%;
  };
`;
const SlideImg = styled.img`
  max-width: 100%;
  width: 100%;
  // height: 100%;
  //  @media (max-width: 960px) {
  //   max-width:100%;
  //   width:100%;
`;
const TextContainer = styled.div`
display: flex;
flex-direction: column; 
justify-content: start;
margin-left:5%;
width:55%;
@media (max-width: 960px) {
  display: block;
  margin:0px;
  max-height: 350px;
  height: 350px; 
  width: 100%;    
`;
const SlideTitle = styled(Typography)`
font-size:1.5rem;
text-transform: capitalize; 
@media (max-width: 960px) {
  margin-top:25px;
`;
const SlideText = styled(Typography)`
width: 100%;
@media (max-width: 960px) {
  width: 100%
`;

const RegularButtonContainer = styled.div`
  margin-top: 5%;
  position: absolute;
  bottom: 10%;
  width: 29%;
  @media (max-width: 960px) {
    position: static;
    width:100%;
  `;

function SchoolQualitySlide(props) {
  return (
    <SlideContent>
      <SlideInfoContainer>
        <ImageContainer>
          <SlideImg
            src={props.slideInfo.image}
            alt={`${props.slideInfo.title} slide image`}
          />
        </ImageContainer>
        <TextContainer>
          <SlideTitle>{props.slideInfo.title}</SlideTitle>
          <SlideText>{props.slideInfo.description}</SlideText>
          <RegularButtonContainer>
            <RegularButton title="Tilmeld dig og dit barn" />
          </RegularButtonContainer>
        </TextContainer>
      </SlideInfoContainer>
    </SlideContent>
  );
}

export default SchoolQualitySlide;
