import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import { getAndReturnResp } from '../../HelperFunctions';

import {
    getParticipantId,
    isBirthdateValid
} from '../GlobalRegistrationFunctions';
import {
    getChildFullname,
    getChildFullnameStorage,
    recoverComment,
    recoverPriorities,
    saveMeetings
} from './ChooseCourseFunctions';
import {
    getAllChildrenInReg,
    getCoursesData,
    getLatestChildInRegFlow,
    returnAuthenticated
} from '../../../context/getData';
import { Box, Typography } from '@material-ui/core';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';

import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import RegularHeadline from '../../../components/headlines/RegularHeadline';

import CourseBar from '../../../components/bars/course-bar/CourseBar';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import Loader from '../../../components/loader/Loader';
import CommentSection from './CommentSection';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';

const DescriptionText = styled(Typography)`
    margin-bottom: 1.2rem;
`;

const DescriptionContainer = styled.div`
    margin-bottom: 2.5rem;
`;

const CourseNameContainer = styled.div`
    width: 85%;
    margin: 3rem auto 1rem auto;
    padding-top: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
`;

const RegistrationButtonContainer = styled.div`
    width: 85%;
    margin: 2rem auto;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
`;

function ChooseCourse(props) {
    const [courses, setCourses] = useState([]);
    // const [introMeetings, setIntroMeetings] = useState([]);
    // const [parentMeetings, setParentMeetings] = useState([]);
    // const [extraParentMeetings, setExtraParentMeetings] = useState([]);
    const [comment, setComment] = useState('');
    const [priorities, setPriorities] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [missingCourses, setMissingCourses] = useState(false);

    const coursesRef = useRef();
    const contentRef = useRef();
    const context = useContext(Context);

    const setScrollView = () => {
        coursesRef.current.scrollIntoView();
    };

    const getPriority = (priorityObject) => {
        const prioritiesCopy = [...priorities];
        // Check course id to see if it has already been selected a priority to
        if (
            priorities.find(
                (priority) => priority.courseId === priorityObject.courseId
            )
        ) {
            // If type is 'not selected', remove it from the array
            if (priorityObject.type === 'not selected') {
                const filteredCopy = prioritiesCopy.filter(
                    (p) => p.courseId !== priorityObject.courseId
                );
                setPriorities(filteredCopy);
            } else {
                // Remove already existing object
                const filteredCopy = prioritiesCopy.filter(
                    (p) => p.courseId !== priorityObject.courseId
                );

                // Add new object instead
                filteredCopy.push(priorityObject);
                setPriorities(filteredCopy);
            }
        } else {
            // Save new course's priority selection
            // Save only if a type is selected
            if (priorityObject.type !== 'not selected') {
                prioritiesCopy.push(priorityObject);
                setPriorities(prioritiesCopy);
            }
        }
    };

    const onSubmit = () => {
        // Validation: check if user has chosen priority for at least one course
        if (priorities.length < 1) {
            setScrollView();
            setTimeout(() => {
                setError(true);
            }, 500);
        } else {
            const childInRegFlow = getLatestChildInRegFlow(context);
            childInRegFlow.priorities = priorities;
            childInRegFlow.comment = comment;

            context.saveChildInRegFlow(childInRegFlow);

            // Save birthdate to indicate that these saved courses are for this specific birthdate
            const coursesData = {
                childFirstname: childInRegFlow.childFirstname,
                childSurname: childInRegFlow.childSurname,
                birthdate: childInRegFlow.birthdate,
                courses: courses
            };
            context.saveCoursesData(coursesData);
            props.history.push('/tilmelding/vaelg-forloeb');
        }
    };

    // If the server discovers that the child is already registered, but the user is not authenticated, we need to prompt them to log in.
    const handleAlreadyRegisteredError = () => {
        context.saveChildAlreadyRegistered(true);
        props.history.push('/tilmelding/foedselsdato');
    };

    // Fetches and saves courses and meetings into local storage
    const fetchAndSaveCourses = useCallback(async () => {
        const birthdate = getLatestChildInRegFlow(context).birthdate;
        const firstname = getLatestChildInRegFlow(context).childFirstname;
        const surname = getLatestChildInRegFlow(context).childSurname;
        const childId = getLatestChildInRegFlow(context).id;
        let url;
        // Check if the given child has an id. If it has, it means that the parent wants to
        // sign up the child to a new course
        if (childId) {
            url = `/api/v1/product/types/${birthdate}/${firstname}/${surname}/${childId}`;
        } else {
            // If it is a new child (so there's no id), we need to check, if there's a child like this in the
            // system already. If there is, we need to ask the parent to log in, and select from the dropdown list
            url = `/api/v1/product/types/${birthdate}/${firstname}/${surname}`;
        }

        try {
            const response = await getAndReturnResp(url);
            if (response.status === 400) {
                // Child is already registered, but parent is not logged in.
                handleAlreadyRegisteredError();
                setLoading(false);
                setMissingCourses(true);
            } else if (response.status === 200) {
                const data = await response.json();
                console.log('show data', data);

                if (data) {
                    if (!data.productTypes || data.productTypes.length < 1) {
                        setLoading(false);
                        setMissingCourses(true);
                    }
                    // Come back: For now, since all courses are coming from api, it is set manually to display only one. Remove/fix this when a specific course is coming!
                    const receivedProductType = [data.productTypes[0]];
                    // Save meetings - will be used in choose meetings page
                    saveMeetings(receivedProductType, context);
                    setCourses(receivedProductType);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setMissingCourses(true);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setMissingCourses(true);
        }

        // eslint-disable-next-line
    }, []);

    const checkParticipantId = useCallback(() => {
        const participantId = getParticipantId(context);
        if (participantId && !returnAuthenticated()) {
            props.history.push('/tilmelding/foedselsdato');
        }
    }, [context, props]);

    // If authentication changes, check if we have a participantId saved. If there is, take user back to birthdate.
    // useEffect(() => {
    //   const participantId = getParticipantId(context);
    //   if (participantId && !returnAuthenticated()) {
    //     props.history.push("/tilmelding/foedselsdato");
    //   }
    // }, [context.state.currentUser, context, props]);

    // Need to check if birthdate and name has been changed, if so,
    // we need to check in db again if there is a child with that name and birthdate
    const hasContextChanged = useCallback((coursesData) => {
        const childInRegFlow = getLatestChildInRegFlow(context);
        const nameForCourseData = getChildFullname(
            coursesData.childFirstname,
            coursesData.childSurname
        );
        const nameFromStorage = getChildFullnameStorage(childInRegFlow);
        const birthdateForCourseData = coursesData.birthdate;

        if (childInRegFlow) {
            if (childInRegFlow.birthdate) {
                if (
                    nameForCourseData === nameFromStorage &&
                    birthdateForCourseData === childInRegFlow.birthdate
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        }
        // Come back: check if we need context in dependencies
        // eslint-disable-next-line
    }, []);

    const loadCoursesData = useCallback(() => {
        //  If there is a participant id in storage but the user is not logged in, take them back to Birthdate page,
        // because they are not allowed to reenroll a participant if they are not signed in. Birthday page will handle their case.
        const participantId = getParticipantId(context);
        if (participantId && !returnAuthenticated()) {
            props.history.push('/tilmelding/foedselsdato');
        } else {
            // If courses data is in local storage, load from there, else get data from server
            const coursesData = getCoursesData();
            if (coursesData && coursesData.courses) {
                if (coursesData.courses.length > 0) {
                    // Check if birthdate or name has been changed
                    if (hasContextChanged(coursesData)) {
                        // If changed, fetch again with new data
                        fetchAndSaveCourses();
                    } else {
                        // Otherwise Save it in state and load that
                        setCourses(coursesData.courses);
                        recoverPriorities(setPriorities, context);
                        setLoading(false);
                    }
                } else {
                    fetchAndSaveCourses();
                }
            } else {
                fetchAndSaveCourses();
            }
        }
        // eslint-disable-next-line
    }, [fetchAndSaveCourses, hasContextChanged]);

    //ComponentDidMount
    useEffect(() => {
        // Check if birthdate and name is known. If not, return user to birthdate page
        if (isBirthdateValid()) {
            loadCoursesData();

            recoverComment(setComment, context);
        } else {
            // If childInRegFlow is empty, or birthdate or course are not selected,
            // we should check if there are other children already in allChildrenInRegFlow.
            // If there are, we should redirect user to checkout page.
            if (getAllChildrenInReg() && getAllChildrenInReg().length > 0) {
                props.history.push('/tilmelding/kasse');
            } else {
                props.history.push('/tilmelding/foedselsdag');
            }
        }

        // Scroll to content
        contentRef.current.scrollIntoView();

        checkParticipantId();
        // eslint-disable-next-line
    }, [loadCoursesData]);

    return (
        <div>
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <NavHeaderDesktop />
            {/* Ref is used for scrollview position */}
            <span ref={contentRef}></span>
            <RegistrationContentContainer
                marginBottom="0"
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                <ProgressBar currentStage={'Vælg hold'} />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/foedselsdato"
                        destination="Fødselsdato"
                    />
                </Box>
                <RegularHeadline
                    component="h1"
                    title="Vælg hold"
                    marginbottom="1.5rem"
                />
                <DescriptionContainer>
                    <DescriptionText variant="body2">
                        Du kan vælge mellem første og anden prioritet:
                    </DescriptionText>
                    <DescriptionText variant="body2">
                        Første prioritet: Vi godkender uden videre tilmeldingen,
                        hvis der er plads på holdet, og du har deltaget i et
                        intromøde samt et forældremøde for Musikalsk Legestue
                        II.
                    </DescriptionText>
                    <DescriptionText variant="body2">
                        Anden prioritet: Vi godkender ikke tilmeldingen uden at
                        kontakte dig først, for at sikre os, at du gerne vil gå
                        på holdet. Du kan have lige så mange 1. og 2.
                        prioriteter som du vil.
                    </DescriptionText>
                    <DescriptionText variant="body2">
                        Vi vil forsøge at få dit barn på et af de hold, du giver
                        højest prioritet.
                    </DescriptionText>
                    <DescriptionText variant="body2">
                        Du kan altid ændre i dine prioriteringer på Min Side.
                    </DescriptionText>
                    <DescriptionText variant="body2">
                        Hvis dit barn starter på et hold, som ikke er din
                        højeste prioritet, vil du automatisk blive sat på
                        venteliste til hold med højere prioritet.
                    </DescriptionText>
                </DescriptionContainer>
            </RegistrationContentContainer>
            <span ref={coursesRef}></span>
            {!loading ? (
                <React.Fragment>
                    {/* Display error message if there are no courses coming from DB */}
                    {missingCourses && (
                        <RegistrationContentContainer>
                            <DescriptionText variant="body2">
                                There are no courses found. Please try again, or
                                contact us.
                            </DescriptionText>
                        </RegistrationContentContainer>
                    )}

                    {/* Or display courses  */}

                    <React.Fragment>
                        {courses.map((course) => (
                            <React.Fragment key={course.id}>
                                <CourseNameContainer>
                                    <RegularHeadline
                                        component="h2"
                                        title={course.name}
                                        marginbottom="1.5rem"
                                    />
                                </CourseNameContainer>
                                {course.courses.map((c) => (
                                    <CourseBar
                                        data={c}
                                        key={c.id}
                                        getPriority={getPriority}
                                        selectedPriorities={priorities}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                </React.Fragment>
            ) : (
                <RegistrationContentContainer>
                    <Loader />
                </RegistrationContentContainer>
            )}

            <CommentSection comment={comment} setComment={setComment} />

            <RegistrationButtonContainer>
                <RegistrationButton onButtonClick={onSubmit} />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/foedselsdato"
                        destination="Fødselsdato"
                    />
                </Box>
            </RegistrationButtonContainer>

            {error && (
                <ErrorDialog
                    errorMessage="Du har ikke valgt prioritet."
                    close={() => setError(false)}
                    setScrollView={setScrollView}
                />
            )}
        </div>
    );
}

export default withRouter(ChooseCourse);
