import React from "react";
import { Link } from "react-router-dom";

import OpenLayers from "../../components/openlayers";
import RoomIcon from '@material-ui/icons/Room';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


// Check GDPR compliance
// https://www.cookiebot.com/da/privatlivspolitik-gdpr

function Contact(props) {
    return (
        <div className="page">
            <h1>Kontakt</h1>
            <div style={{ width: '100%'}}>
              <OpenLayers />
            </div>
            <div style={{ marginTop: '20px'}}>
                <RoomIcon style={{ float: 'left', marginRight: '10px'}} />
                <div style={{height: '60px'}}>
                    Bülowsvej 10<br/>
                    1870 Frederiksberg
                </div>
                <CallIcon style={{ float: 'left', marginRight: '10px'}}/>
                <div style={{height: '40px'}}>
                    30 14 01 23
                </div>
                <MailOutlineIcon style={{ float: 'left', marginRight: '10px'}}/>
                <div style={{float: 'left', height: '40px'}}>
                    <Link to="mailto:kontakt@denlillemusikskole.dk">kontakt@denlillemusikskole.dk</Link>
                </div>
            </div>
            <div style={{ clear: 'both', paddingTop: '20px'}}>
                <h1>Privatlivspolitik</h1>
                <p>
                    Persondataforordningen (GDPR) og EU ePrivacy direktivet (ePR) beskriver din ret til kontrol 
                    over hvordan dine private data anvendes. 
                    Når du bruger dette site vil du blive bedt om at tage stilling til hvilke
                    informationer vi må gemme om dig. 
                    Du skal desuden som minimum give din accept af at 
                    vi må benytte de cookies, der er nødvendige for at levere vores service online. 
                </p>
                <p>
                    Hvis du ønsker at få dine informationer anonymiseret, så kontakt venligst kontoret på mail, 
                    for at vi har en skriftlig dokumentation for at du har bedt om at blive glemt.
                </p>
            </div>
        </div>
    );
}

export default Contact;
