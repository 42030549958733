import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";

// import { Typography } from "@material-ui/core";
import AboutContentContainer from "../../components/containers/about/AboutContentContainer";
import AboutHeadline from "../../components/headlines/AboutHeadline";
import AboutImageContainer from "../../components/containers/about/AboutImageContainer";

// CMS feature
import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";

const HistoryContainer = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;
/*
const Paragraph = styled(Typography)`
  margin-bottom: 1rem;
`;
*/

const HistoryImage = styled.img`
  width: 13rem;
  margin-left: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin: 2rem auto;
  }
`;

function History({ getCurrentUrl }) {
  const { url } = useRouteMatch();

  useEffect(() => {
    getCurrentUrl(url, "Historie");
  }, [getCurrentUrl, url]);

  const [messageList, setMessageList] = useState([]);

  return (
    <HistoryContainer display="flex">
      <MessageList type="html" name="History" state="final" change={setMessageList}/>

      <AboutContentContainer>
        <AboutHeadline title={getMessage(messageList,"History",'title','Historie')} ></AboutHeadline>
        <MessagePlaceholder list={messageList} name="History" value=""/>
      </AboutContentContainer>
      <AboutImageContainer>
        <HistoryImage src={getMessage(messageList,"History",'text','/images/about/history-image.svg')} alt="vision" />
      </AboutImageContainer>
    </HistoryContainer>
  );
}

export default History;
