import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";

const Container = styled(Box)`
  display: flex;
  width: 80%;
  max-width: 50rem;
  margin-top: 2rem;

  @media (max-width: 1010px) {
    width: 85%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
    width: 100%;
  }
`;

const CustomTextField = styled.textarea`
  width: 100%;
  background: transparent;
  color: white;
  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.palette.error.main
        : props.theme.palette.primary.contrastText};
  padding: 1rem;
  font-family: ${(props) => props.theme.typography.fontFamily};

  // Is this needed? Or shall I just leave the focus as it is currently - blue?
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.palette.colors.purple};
  }
`;

const TextareaContainer = styled(Box)`
  width: 70%;
  margin-left: 3rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
    margin-left: 0;
  }
`;

const TitleContainer = styled(Box)`
  width: 30%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const Title = styled(Typography)``;

const ErrorMessage = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
`;

function ProfileTextArea({ saveValue, rows, stateObj, removeErrorOnChange }) {
  const [value, setValue] = useState();

  const handleTextChange = (event) => {
    removeErrorOnChange(stateObj.label);
    setValue(event.target.value);
  };

  //Send data on blur
  // If we sent/changed data on onChange, the whole parent component would rerender on change, which leads to performance issues
  const handleBlur = () => {
    const valueObj = {
      value,
      label: stateObj.label,
    };
    if (valueObj) {
      saveValue(valueObj);
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    setValue(stateObj.value);
  }, [stateObj.value]);
  return (
    <Container>
      <TitleContainer>
        <Title variant="body2">{stateObj.danishLabel}</Title>
      </TitleContainer>
      <TextareaContainer>
        <CustomTextField
          onBlur={() => handleBlur()}
          placeholder={stateObj.placeholder}
          rows={rows}
          value={value}
          onChange={(e) => {
            handleTextChange(e);
          }}
          error={stateObj.textareaError}
        />
        {stateObj.textareaError && (
          <ErrorMessage variant="caption">{stateObj.errorMessage}</ErrorMessage>
        )}
      </TextareaContainer>
    </Container>
  );
}

export default ProfileTextArea;
