import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { postAndReturnResp } from "../../context/helperFunctions";
import Loader from "../../components/loader/Loader";
import ErrorDialog from "../../components/dialogs/error-dialog/ErrorDialog";
import SuccessDialog from "../../components/dialogs/success-dialog/SuccessDialog";
import { Box } from "@material-ui/core";
import DialogsBackground from "../DialogsBackground";

function SignupPhoneVerification(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleError = (message) => {
    return message;
  };

  const connectParentToChildren = async () => {
    const params = new URL(document.location).searchParams;
    const userId = params.get("userId");
    const selectedChildrenIdsString = params.get("selectedChildrenIds"); // '15 16'
    const selectedChildrenIdsStringArr = selectedChildrenIdsString.split(" ");
    const selectedChildrenIdsNumberArr = selectedChildrenIdsStringArr.map(
      (id) => {
        return Number(id);
      }
    );

    const response = await postAndReturnResp(
      `/api/v1/user/${userId}/connect-to-parent`,
      { selectedChildrenIds: selectedChildrenIdsNumberArr },
      handleError
    );

    if (!response || !response.status) {
      setIsLoading(false);
      setError(true);
      // Come back: danish text
      return setErrorMessage(
        "Potential connection problems. Check your internet."
      );
    }

    if (response.status === 404) {
      setIsLoading(false);
      setError(true);

      // Come back: Danish text
      setErrorMessage("User is not found. Try to get a new link");
    } else if (response.status === 200 || response.status === 202) {
      setIsLoading(false);
      setSuccess(true);
      // Come back: Danish text
      setSuccessMessage("User is now connected with the given children.");
    }
  };

  // ComponentDidMount
  useEffect(() => {
    setIsLoading(true);
    connectParentToChildren();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <Box ml="auto" mr="auto" width="fit-content">
          <Loader />
        </Box>
      ) : (
        <>
          <DialogsBackground />
          {error && (
            <ErrorDialog
              dialogTitle="Vigtigt"
              close={() => {
                setError(false);
                props.history.push("/");
              }}
              errorMessage={errorMessage}
            />
          )}
          {success && (
            <SuccessDialog
              // title="Succes"
              close={() => {
                setSuccess(false);
                props.history.push("/");
              }}
              successMessage={successMessage}
            />
          )}
        </>
      )}
    </>
  );
}
export default withRouter(withCookies(SignupPhoneVerification));
