import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

const ImageContainer = styled(Box)`
  width: 30%;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin: 0 auto;
    width: 100%;
  }
`;

function AboutImageContainer({ children }) {
  return <ImageContainer>{children}</ImageContainer>;
}

export default AboutImageContainer;
