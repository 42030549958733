import { Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const LinkContainer = styled.div`
  margin-bottom: 1.8rem;
  & a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-bottom: 0;
  }
`;

const LinkContainerActive = styled.div`
  margin-bottom: 1.8rem;
  & a {
    text-decoration: underline;
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-bottom: 0;
  }
`;

function AboutLink({ title, to, currentUrl, changeSelectedMenu }) {
  return (
    <React.Fragment>
      {to === currentUrl ? (
        <LinkContainerActive onClick={() => changeSelectedMenu(to, title)}>
          <RouterLink to={to}>
            <Typography variant="body1">{title}</Typography>
          </RouterLink>
        </LinkContainerActive>
      ) : (
        <LinkContainer onClick={() => changeSelectedMenu(to, title)}>
          <RouterLink to={to}>
            <Typography variant="body1">{title}</Typography>
          </RouterLink>
        </LinkContainer>
      )}
    </React.Fragment>
  );
}

export default AboutLink;
