import React, {
    Suspense,
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef
} from 'react';
import {
    Redirect,
    Route,
    Switch,
    withRouter,
    useLocation
} from 'react-router-dom';
import { CookiesProvider, withCookies } from 'react-cookie';
// import { IntlProvider } from "react-intl";
import { getUser, fetchWithTimeout } from './util';

import { getUserData, getFeatures } from './context/getData';
import { Context } from './context/Context';
import moment from 'moment';
import 'moment/locale/da';
import { withContext } from './components/containers/ContextContainer';

import ErrorBoundary from './components/error-boundary';
import Privacy from './components/privacy';

// Routes
import AuthenticatedRoute from './components/authenticated-route/index';
import Home from './containers/Home';
import ForgotPasswordPage from './containers/login-pages/ForgotPasswordPage';

import MyPage from './containers/my-page/MyPage.jsx';
import EditProfile from './containers/my-page/edit-profile/EditProfile';

import EconomyOverview from './containers/my-page/EconomyOverview';
import MeetingsOverview from './containers/my-page/MeetingsOverview';
import Employee from './containers/about/Employees';

import Course from './containers/my-course/MyCourse.jsx';
import About from './containers/about/About';
import Educations from './containers/education/Educations';
import Education from './containers/education/Education';
import Instrument from './containers/education/Instrument';

import BirthDate from './containers/registration/birthdate/BirthDate';
import ChooseCourse from './containers/registration/choose-course/ChooseCourse';
import ChooseProcess from './containers/registration/choose-process/ChooseProcess';
import ChooseMeetings from './containers/registration/choose-meetings/ChooseMeetings';
import Summary from './containers/registration/summary/Summary';

import Checkout from './containers/registration/checkout/Checkout';
import Footer from './components/footer/Footer';
import AllPosts from './containers/my-course/AllPosts';
import AllPictures from './containers/my-course/AllPictures';

import CalendarMoreEvents from './components/global-calendar/CalendarMoreEvents';
import MobileCalendarMoreEvents from './components/global-calendar-mobile/MobileCalendarMoreEvents';
import NotFound from './containers/NotFound';

// Hardcoded Data was moved to data seeders
// import { educationData } from "./data/EducationData";

import manifestTemplate from './manifestTemplate.json';
import ChooseIntroMeeting from './containers/registration/choose-intro-meeting/ChooseIntroMeeting';
import RegistrationType from './containers/registration/registration-type/RegistrationType';
import InformationIntromeeting from './containers/registration/information-intromeeting/InformationIntromeeting';
import SummaryIntromeeting from './containers/registration/summary-intrommeting/SummaryIntromeeting';
import LoginPage from './containers/login-pages/LoginPage';
import ResetPasswordPage from './containers/login-pages/ResetPasswordPage';

import MessageList from './components/message-list';
import MessageMetaData from './components/message-list/MessageMetaData';

// Language translations
// import { IntlProvider } from 'react-intl-hooks';
import { IntlProvider } from 'react-intl';
import locales from './locales';
import RegisterInformation from './containers/registration/information/RegisterInformation';
import SessionExpired from './containers/session-expired/SessionExpired';
import ConnectExistingParentPage from './containers/connect-parent/ConnectExistingParentPage';
import CookiePage from './containers/cookie-page/CookiePage';
import PrivacyDialog from './components/dialogs/privacy-dialog/PrivacyDialog';
import styled from 'styled-components';
// import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

const Warning = styled(Box)`
    padding-right: 65px;
    font-size: 150%;
    @media only screen and (max-device-width: 480px) {
        font-size: 80%;
    }
    width: 100%;
    height: 40px;
    margin-top: 16;
    color: #ffffff;
    border: 2px solid #ff0000;
    background-color: #dd0000;
    display: flex;
    justify-content: center;
`;

// Lazy load pages behind login, like editing messages
const Message = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/Message')
);

const Record = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/Record')
);

const Document = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/Document')
);

const Event = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/Event')
);

// Lazy load admin pages, because normal users dont need them at all.
// See https://reactjs.org/docs/code-splitting.html
const Dashboard = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/Dashboard')
);
const OrderDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/OrderDataGrid')
);
const UserDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/UserDataGrid')
);
const MessageDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/MessageDataGrid')
);
const NoteDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/NoteDataGrid')
);
const TopicDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/TopicDataGrid')
);

const CalendarDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/CalendarDataGrid'
    )
);

const ExceptionDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/ExceptionDataGrid'
    )
);

const QueryDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/QueryDataGrid')
);
const PaymentTransactionDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/PaymentTransactionDataGrid'
    )
);
const LogDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/LogDataGrid')
);
const CustomerDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/CustomerDataGrid'
    )
);
const ProductDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/ProductDataGrid')
);
const UserGroupDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/UserGroupDataGrid'
    )
);
const ClassDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/ClassDataGrid')
);
const EventDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/EventDataGrid')
);

const EventPlannerDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/EventPlannerDataGrid'
    )
);

const EventParticipantDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/EventParticipantDataGrid'
    )
);

const CourseDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/CourseDataGrid')
);
const RecordDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/RecordDataGrid')
);
const DocumentDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/DocumentDataGrid'
    )
);
const SequelizeManager = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/SequelizeManager'
    )
);

// QuickPay test form
const QuickPay = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './components/quickpay')
);
const QuickPayCancel = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/QuickPayCancel')
);
const QuickPayContinue = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/QuickPayContinue')
);

/*
const createManifest = (domain, log) => {
    // make a cloned copy of javascript manifest object is done fastest with parse and stringify, then update properties on cloned object
    const manifest = JSON.parse(JSON.stringify(manifestTemplate));
    manifest.start_url = "https://" + domain + "/";

    // make blob with manifest json and write dynamic URL to link with manifest-placeholder
    const manifestData = JSON.stringify(manifest, null, 2);
    const blob = new Blob([manifestData], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    const manifestLink = document.querySelector("#manifest-placeholder");
    if (manifestLink) manifestLink.setAttribute("href", manifestURL);
    if (log) {
      console.log('Created manifest.json for domain '+domain);
      console.log('Link: ', manifestURL);
      console.log(manifestData);
    }
    return manifest;
};
*/

// https://developer.mozilla.org/en-US/docs/Web/Manifest/scope
// https://developer.chrome.com/docs/extensions/mv2/manifest/name/
const createManifest = (domain, log) => {
    // make a cloned copy of javascript manifest object is done fastest with parse and stringify, then update properties on cloned object
    const manifest = JSON.parse(JSON.stringify(manifestTemplate));
    if (manifest.name.length > 45)
        console.log('Watning manifest name is more than 45 characters');
    if (manifest.short_name.length > 12)
        console.log('Watning manifest short_name is more than 12 characters');
    // Add current domain to start_url
    if (manifest.start_url === '/') manifest.start_url = 'https://' + domain;
    else if (manifest.start_url.startsWith('/'))
        manifest.start_url = 'https://' + domain + manifest.start_url;
    // Add current domain to scope
    if (manifest.scope === '/') manifest.scope = 'https://' + domain;
    if (manifest.scope.startsWith('/'))
        manifest.scope = 'https://' + domain + manifest.scope;
    manifest.icons.forEach((icon) => {
        if (icon.src.startsWith('/')) icon.src = 'https://' + domain + icon.src;
    });

    // make blob with manifest json and write dynamic URL to link with manifest-placeholder
    const manifestData = JSON.stringify(manifest, null, 2);
    const blob = new Blob([manifestData], { type: 'application/json' });
    // Jest jsdom environment does not yet support createObjectURL
    if (!window.URL.createObjectURL)
        window.URL.createObjectURL = function () {};
    const manifestURL = URL.createObjectURL(blob);
    const manifestLink = document.querySelector('#manifest-placeholder');
    if (manifestLink) manifestLink.setAttribute('href', manifestURL);
    if (log) {
        console.log('Created manifest.json for domain ' + domain);
        console.log('Link: ', manifestURL);
        console.log(manifestData);
    }
    return manifest;
};

createManifest(document.location.hostname);

// Create React function component
function App(props) {
    // const { location } = props;
    const location = useLocation(); // react-router-dom as location
    const prevLocationRef = useRef(location);

    // eslint-disable-next-line
    const [onLine, setOnLine] = useState(navigator.onLine);
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    // eslint-disable-next-line
    const [messageList, setMessageList] = useState([]);
    // eslint-disable-next-line
    const [educationMessageList, setEducationMessageList] = useState(undefined); // Array of messages
    const [educationData, setEducationData] = useState(undefined); // Data object

    const [page, setPage] = useState(null);
    // eslint-disable-next-line
    // const [previousLocation, setPreviousLocation] = useState(props.location);
    //const [pathName, setPathName] = useState(props.path);
    // eslint-disable-next-line
    const [systemUpdateText, setSystemUpdateText] = useState(null);

    // Date library locale
    moment.locale('da');
    // const path = "/";
    // Settings for modal display on own route
    const locale = 'da-DK';
    const messages = locales[locale];
    const isModal =
        location.state &&
        location.state.modal &&
        prevLocationRef.current !== location;
    const user = getUser();

    // eslint-disable-next-line
    const context = useContext(Context);

    // Internal variables
    let resizeTimer;
    let scrollTimer;
    // eslint-disable-next-line
    let scrollTop = 0;
    // eslint-disable-next-line
    let scrollLeft = 0;

    // https://chromium.googlesource.com/chromium/src.git/+/65d0e0f4d84abab407f942e81b3937821075ec0d/chrome/browser/banners/app_banner_manager.cc
    // https://whatwebcando.today/installation.html
    // https://pwa.rocks/

    const setEducationDataFromMessageList = (list) => {
        setEducationMessageList(list);
        if (list && list.length > 0) {
            let data = list
                .filter((message) => message.url !== '/undervisning')
                .map((message) => {
                    return {
                        title: message.title ? message.title : '',
                        id: message.url
                            ? message.url.substring(
                                  message.url.lastIndexOf('/') + 1
                              )
                            : '',
                        imageUrl: message.text ? message.text : '',
                        description: message.html ? message.html : '',
                        color: 'orange',
                        colorRGB: '250, 152, 43,',
                        url: message.url ? message.url : '',
                        name: message.name ? message.name : ''
                    };
                });

            setEducationData(data);
        }
    };

    // This is only called in PRODUCTION build, not in development
    const handleBeforeInstallPrompt = (event) => {
        // Stash the event for later use
        window.lastbeforeinstallpromptevent = event;
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        if (event.preventDefault) event.preventDefault();
        return false;
    };

    // WebKit on iOS is used for both Safari and Chrome on iPhone.
    // WebKit will unload app, when any action causes the app to lose
    // focus, even if the screen saver kicks in. We need to store state
    // to be able to restore state, when app is reloaded again.

    const handleBeforeUnload = (event) => {
        // let key = document.location.hostname + ":data";
        // let data = getLocalStorageJson(key, {});
        // data.scrollTop = scrollTop;
        // data.scrollLeft = scrollLeft;
        // data.location = window.location.href;
        //Save location history to cookies if mobile device
        // Come back: removed it for now, since it is not implemented properly.
        // if (document.body.offsetWidth < 960) {
        //   context.saveLocation(props.location.pathname);
        // }
        // setLocalStorageJson(key, data);
    };

    const handleScroll = (event) => {
        if (scrollTimer) clearTimeout(scrollTimer);
        // Store last scroll event only to avoid many updates while user drags scrollbar
        let newTimer = setTimeout(function () {
            var html = document.documentElement;
            scrollTop = html.scrollTop;
            scrollLeft = html.scrollLeft;
        }, 2000);
        scrollTimer = newTimer;
    };

    // Delay event execution 1 seconds, because event can fire many times
    // while user is dragging window to be resized. Only last event will
    // be executed when timeout expires, before being cleared
    // If manifest.display === 'standalone' resize is called every time
    // the browser bottom navigation bar automatically hides itself in Safari
    // If manifest.display === 'fullscreen' this is not a problem
    // just dont call self.forceUpdate() or Safari on iOS will refresh on scroll

    const handleResize = (event) => {
        if (resizeTimer) clearTimeout(resizeTimer);
        // Store last resize event only to avoid many updates while user drags window
        let newTimer = setTimeout(function () {
            var html = document.documentElement;
            scrollTop = html.scrollTop;
            scrollLeft = html.scrollLeft;
        }, 1000);
        resizeTimer = newTimer;
        // setResizeTimer(newTimer);
    };

    const updateOnlineIndicator = (event) => {
        setOnLine(navigator.onLine);
    };

    const updateOfflineIndicator = (event) => {
        setOnLine(navigator.onLine);
    };

    // History location is saved in Cookies if onUnload event is fired - if page is reloaded.
    // When application is mounted, check if there is any location history saved in Cookies, and load that path.
    // Otherwise just load the page with the current url.
    // This part is to be checked and more work is needed, when we have more routes.
    const checkForLocationHistory = () => {
        // On mobile devices
        if (document.body.offsetWidth < 960) {
            if (props.cookies.cookies.locationHistory) {
                props.history.push(props.cookies.cookies.locationHistory);
                // window.location.reload();
                document.cookie = 'locationHistory=';
            }
        }
    };

    // /file/system-update return 200 if some text is found and 204 no content otherwise
    const getSystemUpdateText = useCallback(async () => {
        let key = document.location.hostname + ':system-update';
        let res = await fetchWithTimeout('/api/v1/file/system-update');
        if (res.status === 200) {
            let body = await res.text();
            if (typeof Storage !== 'undefined')
                localStorage.setItem(key, 'true');
            return body;
        }
        if (typeof Storage !== 'undefined') localStorage.setItem(key, 'false');
        return null;
        // eslint-disable-next-line
    }, []);

    const fetchData = async () => {
        let text = await getSystemUpdateText();
        setSystemUpdateText(text);
    };

    /*
  const componentDidUpdate = (prevProps, prevState) => {
    let { location } = props;

    // Copied from Bulowsvej10 project
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
    // Copied from Bulowsvej10 project
    if (props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    // Copied from Bulowsvej10 project
    if (systemUpdateText !== prevState.systemUpdateText) {
      fetchData(getSystemUpdateText);
    }
  }
  */

    const setPageContent = (list) => {
        setMessageList(list);
        let message = list.length > 0 ? list[0] : null;
        // Check if any properties has changed not just url
        let newPageHash = JSON.stringify(message ? message : {});
        let oldPageHash = JSON.stringify(page ? page : {});
        if (newPageHash !== oldPageHash) {
            setPage(message);
        }
    };

    // componentDidMount
    useEffect(() => {
        window.addEventListener(
            'beforeinstallprompt',
            handleBeforeInstallPrompt
        );
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('online', updateOnlineIndicator);
        window.addEventListener('offline', updateOfflineIndicator);
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Check if system update mode for maintenance is active
        fetchData();

        // Check if user is authenticated
        getUserData(context);

        // Check features user is allowed to use
        getFeatures(context);

        // Check location history on mobile device
        if (document.body.offsetWidth < 960) {
            checkForLocationHistory();
        }

        // Location setting
        if (!(location.state && location.state.modal)) {
            prevLocationRef.current = location;
        }

        return function cleanup() {
            window.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt
            );
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('online', updateOnlineIndicator);
            window.removeEventListener('offline', updateOfflineIndicator);
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
            window.lastbeforeinstallpromptevent = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.location.pathname !== prevLocationRef.current.pathname) {
            window.scrollTo(0, 0);
        }
        prevLocationRef.current = location;
    }, [location, props.location.pathname]);

    return (
        <IntlProvider locale={locale} messages={messages} defaultLocale="da-DK">
            <ErrorBoundary>
                <CookiesProvider>
                    {/* <Router basename={path}> */}
                    <Suspense fallback={<div>Loading...</div>}>
                        {/* <Redirect from='/:url*(/+)' to={props.location.pathname.slice(0, -1)} /> */}

                        <div className="content" id="outer-container">
                            {systemUpdateText && (
                                <Warning>
                                    {systemUpdateText != null
                                        ? systemUpdateText
                                        : ''}
                                </Warning>
                            )}

                            {!onLine && (
                                <Warning>
                                    Du er offline. Tjek venligst din
                                    internetforbindelse.
                                </Warning>
                            )}

                            <MessageList
                                type="html"
                                name="Education%"
                                state="final"
                                change={setEducationDataFromMessageList}
                                path={location.pathname}
                            />

                            {/* Load Message MetaData for SEO and SoMe based on url implies type html */}
                            {location.pathname &&
                                !('' + location.pathname).startsWith(
                                    '/admin'
                                ) && (
                                    <MessageList
                                        type="url"
                                        name={'' + location.pathname}
                                        state="final"
                                        change={setPageContent}
                                    />
                                )}
                            {page && <MessageMetaData message={page} />}

                            <Switch
                                location={
                                    isModal ? prevLocationRef.current : location
                                }
                            >
                                <Route exact path="/">
                                    {educationData && (
                                        <Home data={educationData} />
                                    )}
                                </Route>

                                <Route path="/om-skolen">
                                    <About />
                                </Route>

                                <Route exact path="/login">
                                    <LoginPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>

                                <Route path="/glemt-dit-kodeord">
                                    <ForgotPasswordPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>
                                <Route exact path="/nulstil-kodeord/:id">
                                    <ResetPasswordPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>
                                {/* See more events in desktop calendar */}

                                <Route path="/kalender-se-flere">
                                    <CalendarMoreEvents />
                                </Route>
                                {/* See more events in mobile calendar */}
                                <Route path="/mobile-kalender-se-flere">
                                    <MobileCalendarMoreEvents />
                                </Route>

                                {/* Admin pages */}
                                <AuthenticatedRoute
                                    path="/admin/*"
                                    user={user}
                                    role="partner,superuser,admin"
                                >
                                    <Route path="/admin/messages">
                                        <MessageDataGrid />
                                    </Route>

                                    <Route path="/admin/notes">
                                        <NoteDataGrid />
                                    </Route>

                                    <Route path="/admin/topics">
                                        <TopicDataGrid />
                                    </Route>

                                    <Route path="/admin/calendar">
                                        <CalendarDataGrid />
                                    </Route>

                                    <Route path="/admin/exception">
                                        <ExceptionDataGrid />
                                    </Route>

                                    <Route path="/admin/queries">
                                        <QueryDataGrid />
                                    </Route>

                                    <Route path="/admin/users">
                                        <UserDataGrid />
                                    </Route>

                                    <Route path="/admin/payments">
                                        <PaymentTransactionDataGrid />
                                    </Route>

                                    <Route path="/admin/orders">
                                        <OrderDataGrid />
                                    </Route>

                                    <Route path="/admin/logs">
                                        <LogDataGrid />
                                    </Route>

                                    <Route path="/admin/seqman">
                                        <SequelizeManager />
                                    </Route>

                                    <Route path="/admin/customers">
                                        <CustomerDataGrid />
                                    </Route>

                                    <Route path="/admin/products">
                                        <ProductDataGrid />
                                    </Route>

                                    <Route path="/admin/usergroups">
                                        <UserGroupDataGrid />
                                    </Route>

                                    <Route path="/admin/classes">
                                        <ClassDataGrid />
                                    </Route>

                                    <Route path="/admin/events">
                                        <EventDataGrid />
                                    </Route>

                                    <Route path="/admin/event-planner">
                                        <EventPlannerDataGrid />
                                    </Route>

                                    <Route path="/admin/event-participant">
                                        <EventParticipantDataGrid />
                                    </Route>

                                    <Route path="/admin/courses">
                                        <CourseDataGrid />
                                    </Route>

                                    <Route path="/admin/records">
                                        <RecordDataGrid />
                                    </Route>

                                    <Route path="/admin/documents">
                                        <DocumentDataGrid />
                                    </Route>

                                    <Route path="/admin/dashboard">
                                        <Dashboard />
                                    </Route>
                                </AuthenticatedRoute>

                                {/* QuickPay test page routes. Add .env.development.local with test page settings. */}
                                <AuthenticatedRoute
                                    path="/quickpay/*"
                                    user={user}
                                    role="superuser,admin"
                                >
                                    <Route path="/quickpay/test">
                                        <QuickPay />
                                    </Route>
                                    <Route path="/quickpay/cancel">
                                        <QuickPayCancel />
                                    </Route>
                                    <Route path="/quickpay/continue">
                                        <QuickPayContinue />
                                    </Route>
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    path="/event/:eventId/edit"
                                    user={user}
                                    role="partner,superuser,admin"
                                >
                                    <Event />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    path="/record/:recordId/edit"
                                    user={user}
                                    role="partner,superuser,admin"
                                >
                                    <Record />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    path="/document/:documentId/edit"
                                    user={user}
                                    role="partner,superuser,admin"
                                >
                                    <Document />
                                </AuthenticatedRoute>

                                <Route path="/sessionen-udloebet">
                                    <SessionExpired />
                                </Route>

                                {/* Min side sites */}

                                <AuthenticatedRoute exact path="/min-side">
                                    <MyPage />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/message/:messageId/edit"
                                    user={user}
                                    role="superuser,admin"
                                >
                                    <Message />
                                </AuthenticatedRoute>

                                {/* Example of how to use nested routing - can be deleted later */}
                                {/* <Route path="/products">
                  <Products />
                </Route> */}

                                <AuthenticatedRoute
                                    exact
                                    path="/hold/:courseId"
                                >
                                    <Course />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/hold/:courseId/alle-opslag"
                                >
                                    <AllPosts />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/hold/:courseId/alle-billeder"
                                >
                                    <AllPictures />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/rediger-profil"
                                >
                                    <EditProfile />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/okonomioverblik"
                                >
                                    <EconomyOverview />
                                </AuthenticatedRoute>

                                <AuthenticatedRoute exact path="/modeoverblik">
                                    <MeetingsOverview />
                                </AuthenticatedRoute>

                                {/* Show educations when educationData is loaded from messages */}
                                <Route exact path="/undervisning">
                                    {educationData && (
                                        <Educations
                                            data={educationData}
                                            headline="Undervisning"
                                        />
                                    )}
                                </Route>

                                <Route
                                    exact
                                    path={`/undervisning/:educationId`}
                                >
                                    <Education />
                                </Route>

                                <Route
                                    exact
                                    path="/undervisning/instrumenter/:instrumentId"
                                >
                                    <Instrument />
                                </Route>

                                <Route path="undervisning/instrumenter/:instrumentId/:employee">
                                    <Employee />
                                </Route>

                                {/* Registration */}

                                <Route path="/tilmelding/tilmeldingstype">
                                    <RegistrationType />
                                </Route>

                                <Route path="/tilmelding/foedselsdato">
                                    <BirthDate />
                                </Route>

                                <Route path="/tilmelding/vaelg-hold">
                                    <ChooseCourse />
                                </Route>
                                <Route path="/tilmelding/vaelg-forloeb">
                                    <ChooseProcess />
                                </Route>
                                <Route path="/tilmelding/vaelg-moede">
                                    <ChooseMeetings />
                                </Route>

                                <Route path="/tilmelding/oplysninger">
                                    <RegisterInformation />
                                </Route>

                                <Route path="/tilmelding/kasse">
                                    <Checkout />
                                </Route>
                                <Route path="/tilmelding/kvittering">
                                    <Summary />
                                </Route>

                                {/* This is a simple signup, without the child registration or meeting registration flow */}
                                {/* This is for a user, who is being invited through a link */}
                                <Route path="/signup">
                                    <RegisterInformation />
                                </Route>

                                <Route path="/glemt-dit-kodeord">
                                    <ForgotPasswordPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>

                                {/* If you change the route of the following, you also need to change it in auth.controller - postRequestConnection, and in aut.action.js createUrlWithChildrenIds */}
                                <Route path="/tilfoej-foraeldre">
                                    <ConnectExistingParentPage />
                                </Route>

                                <Route path="/tilmelding/vaelg-intromoede">
                                    <ChooseIntroMeeting />
                                </Route>

                                <Route path="/tilmelding/intromoede-oplysninger">
                                    <InformationIntromeeting />
                                </Route>

                                {/* <Route path="/tilmelding/intromoede-kasse">
                  <CheckoutIntromeeting />
                </Route> */}

                                <Route path="/tilmelding/intromoede-opsummering">
                                    <SummaryIntromeeting />
                                </Route>

                                <Route path="/cookiepolitik">
                                    <CookiePage />
                                </Route>

                                <Redirect
                                    from="/tilmelding"
                                    to="/tilmelding/tilmeldingstype"
                                />

                                {/* Fall back page */}
                                <Route>
                                    <NotFound />
                                </Route>
                            </Switch>
                        </div>

                        {isModal ? (
                            // If modal is opened then it has its own route,
                            //while we are still able to see the page where modal was opened from.
                            <>
                                <Route exact path="/login">
                                    <LoginPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>

                                <Route path="/glemt-dit-kodeord">
                                    <ForgotPasswordPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>

                                <Route path="/nulstil-kodeord/:id">
                                    <ResetPasswordPage
                                        isModal={isModal}
                                        prevLocation={prevLocationRef.current}
                                    />
                                </Route>
                            </>
                        ) : null}
                        {/* List of URL prefixes to not show footer */}
                        {[
                            '/admin',
                            '/message',
                            '/mobile-kalender-se-flere',
                            '/record',
                            '/document',
                            '/event'
                        ].reduce((value, path) => {
                            return (
                                value &&
                                !props.location.pathname.startsWith(path)
                            );
                        }, true) && <Footer />}
                        <Privacy />
                    </Suspense>
                    <PrivacyDialog />
                    {/* </Router> */}
                </CookiesProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
}

// export default  withRouter(withCookies(withStyles(styles, {withTheme: true})(App)));
export default withRouter(withContext(withCookies(App)));
