import React from "react";
import styled from "styled-components";
import HeaderComponentContainer from "../../containers/header/HeaderComponentContainer";
import NavHeaderMobile from "../nav-header/NavHeaderMobile";
import NavHeaderDesktop from "../nav-header/NavHeaderDesktop";
import HomeHeaderBackgroundMobile from "./HomeHeaderBackgroundMobile";
import HeaderOverlay from "../HeaderOverlay";
import HeaderImg from "../../../assets/headers/header-home-small.jpg";

const Mobile = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

const Desktop = styled.div`
  display: block;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
`;

function HomeHeaderComponent(props) {
  return (
    <React.Fragment>
      <Mobile>
        <NavHeaderMobile />
        <HomeHeaderBackgroundMobile height="60vh" imageUrl={HeaderImg} />
      </Mobile>
      <Desktop>
        <HeaderComponentContainer
          height="60vh"
          imageUrl="/images/headers/header-home-medium.jpg"
        >
          <NavHeaderDesktop />
          <HeaderOverlay />
        </HeaderComponentContainer>
      </Desktop>
    </React.Fragment>
  );
}

export default HomeHeaderComponent;
