import React from "react";
import { useState, useContext } from "react";
import styled from "styled-components";

import { Box, Typography } from "@material-ui/core";
import StaircaseImage from "../staircase-image/StaircaseImage";
import StaircaseImageFlipped from "../staircase-image/StaircaseImageFlipped";

import parseHTML from 'html-react-parser';
import { Context } from "../../context/Context";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@material-ui/core/IconButton';

const QABoxComponent = styled(Box)`
  width: ${(props) => props.width};;
  border-bottom: 1px solid
    ${(props) => props.theme.palette.primary.contrastText};
  margin-bottom: 1.7rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const StaircaseContainer = styled(Box)`
  position: relative;
  & img {
    margin-right: 1.5rem;
    position: relative;
    top: 0.5rem;
  }
`;

const Question = styled(Typography)`
  margin-bottom: 1.7rem;
  width: ${(props) => props.width};
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const QuestionContainer = styled(Box)`
cursor: pointer;
`

// const Answer = styled(Typography)`
//   margin-bottom: 1.2rem;
//   width: ${(props) => props.width};

//   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//     width: 100%;
//   }
// `;

function QABox({ messageId, title, html, width }) {
  const [isOpen, setIsOpen] = useState(false);

  const context = useContext(Context);
  const user = context.state.currentUser;
  const isSuperuser = (['superuser','admin'].indexOf(user.role)>=0);

  const toggleQABox = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = (messageId) => {
    window.open('/message/'+messageId+'/edit','_blank');
  }

  return (
    <QABoxComponent width={width}>
      <QuestionContainer display="flex">
        { isSuperuser && 
            <IconButton size="small" style={{ width: 30, height: 30, background: '#FF0000', margin: 4 }} component="span" onClick={(event) => handleEdit(messageId)}>
              <EditIcon/>
            </IconButton>
        }

        <StaircaseContainer onClick={() => toggleQABox()}>
          {isOpen ? (
            <StaircaseImage
              src="/images/staircase/staircase-white.png"
              alt="staircase-logo"
              width="1.5"
            />
          ) : (
            <StaircaseImageFlipped
              src="/images/staircase/staircase-white.png"
              alt="staircase-logo"
              width="1.5"
            />
          )}
        </StaircaseContainer>
        <Box>
          <Question onClick={() => toggleQABox()} variant="body2" width={width}>
            {title}
          </Question>
        </Box>
      </QuestionContainer>

      {isOpen && <div>
        {parseHTML(html)}
      </div>}
    </QABoxComponent>
  );
}

export default QABox;
