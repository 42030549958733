import React, { useEffect, useState, useContext } from "react";
import { 
  // Link, 
  useParams, /* useRouteMatch,*/ withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  checkLocationStatePath,
  checkLocationStateTitle,
//  handleErrors,
} from "../HelperFunctions";

// Components
import { Box, Typography } from "@material-ui/core";
import ReturnLinkReusable from "../../components/return-link/ReturnLinkReusable";
// import LinkButton from "../../components/buttons/LinkButton/LinkButton";
import StaircaseImage from "../../components/staircase-image/StaircaseImage";
// import MailImage from "../../components/mail-image/MailImage";

// CMS functionality
import parseHTML from 'html-react-parser';
import { Context } from "../../context/Context";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@material-ui/core/IconButton';
import { getMessageList } from "../../context/getData";


const EmployeeImage = styled.img`
  width: 100%;
`;

const NameText = styled(Typography)`
  color: ${(props) => props.color};
  margin-bottom: 1rem;
  display: inline-block;
`;

const ImageContainer = styled(Box)`
  width: 40%;
  margin-right: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
    margin-bottom: 2rem;
    margin-right: 0;
  }
`;

const ContentContainer = styled(Box)`
  width: 60%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const InfoTitle = styled(Typography)`
  display: inline-block;
  margin-right: 2rem;
`;

const InfoText = styled(Typography)`
  display: inline-block;
`;

const DescriptionContainer = styled(Box)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

/*
const Description = styled(Typography)`
  margin-bottom: 1rem;
`;

const CvButtonContainer = styled.div`
  width: 70%;
  & a {
    text-decoration: none;
  }

  & button {
    color: ${(props) => props.color};
  }
`;
*/

const StaircaseImageContainer = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

/*
const MailImageContainer = styled.div`
  display: inline-block;
  margin-left: 1.5rem;
`;
*/

const EmployeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

function Employee(props) {
  const context = useContext(Context);
  const user = context.state.currentUser;
  const isSuperuser = (['superuser','admin'].indexOf(user.role)>=0);

  const { employeeName } = useParams();
  // const { url } = useRouteMatch();
  const [employee, setEmployee] = useState([]);

  const handleEdit = (messageId) => {
    window.open('/message/'+messageId+'/edit','_blank');
  }

  useEffect(() => {
    async function fetchData() {    
      let name = 'Employee '+employeeName; 
      const json = await getMessageList('html',name,'final', (err) => {
        console.log('ERROR: getMessageList:',err);
      });
      if (json && json.messageList && json.messageList.length>0) {
        let message = json.messageList[0];
        // console.log('Employee:',JSON.stringify(message,null,2));
        let name = message.name.substring('Employee '.length); 
        let urlName = name.toLowerCase().replaceAll('_',' ');
        let mailToIndex = message.html.indexOf('href="mailto:')+'href="mailto:'.length;
        let mailToEnd = message.html.indexOf('"',mailToIndex);
        let email = '';
        if (mailToIndex>0 && mailToEnd>0) {
          email = message.html.substring(mailToIndex,mailToEnd);
        }
        let data = {
          messageId: message.id,
          name: (name?name:''),
          urlName: (urlName?urlName:''),
          instrument: (message.title?message.title:''),
          id: 1,
          imageUrl: (message.text?message.text:''),
          color: "#DDDDDD",
          colorName: "green",
          email: (email?email:''),
          html: (message.html?message.html:'')
        }
        setEmployee(data);
      };  
    }
    fetchData();

    /* Get employee info form message system instead    
    fetch(`/api/v1/employee/${employeeName}`)
      .then(handleErrors)
      .then((resp) => resp.json())
      .then((data) => {
        setEmployee(data);
      })
      .catch((error) => {
        console.log(error);
      });
    */  
  }, [employeeName]);

  return (
    <div>
      <ReturnLinkReusable
        destination={checkLocationStateTitle(props)}
        url={checkLocationStatePath(props)}
      />

      <EmployeeContainer>
        { isSuperuser && 
            <IconButton size="small" style={{ width: 30, height: 30, background: '#FF0000', margin: 4 }} component="span" onClick={(event) => handleEdit(employee.messageId)}>
              <EditIcon/>
            </IconButton>
        }

        <ImageContainer>
          <EmployeeImage src={employee.imageUrl} alt={employee.name} />
        </ImageContainer>

        <ContentContainer>
          <StaircaseImageContainer>
            <StaircaseImage
              src={`/images/staircase/staircase-${employee.colorName}.png`}
              alt="staircase-logo"
              width="1.3"
            />
          </StaircaseImageContainer>          
          {/* Removed attribute color={employee.color} in NameText causing errors */}
          <NameText variant="h5" component="h2">
            {employee.name}{" "}
          </NameText>
          <Box>
            <InfoTitle variant="body2">Rolle:</InfoTitle>
            <InfoText gutterBottom variant="body2">
              {employee.instrument && 
                <span>
                  {employee.instrument.substring(0,1).toUpperCase()+employee.instrument.substring(1)}
                </span>
              }
            </InfoText>
          </Box>
          {/*
          <Box>
            <InfoTitle gutterBottom variant="body2">
              Website:
            </InfoTitle>
            <InfoText gutterBottom variant="body2"></InfoText>
          </Box>
          */}

          <DescriptionContainer>
            { employee.html && 
              <div>
                { parseHTML(employee.html) }
              </div>
            }
          </DescriptionContainer>
          {/*
          <CvButtonContainer color={employee.color}>
            <Link to={`${url}/cv`}>
              <LinkButton title="Vis mit CV" />
            </Link>
          </CvButtonContainer>
          */}
        </ContentContainer>
      </EmployeeContainer>
    </div>
  );
}

export default withRouter(Employee);
