import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../App.css";
import { Context } from "../context/Context";
import ContentContainer from "../components/containers/ContentContainer";
import SchoolQualityCarousel from "../components/carousel/school-quality-carousel/SchoolQualityCarousel";
import SchoolQualityCarouselMobile from "../components/carousel/school-quality-carousel/SchoolQualityCarouselMobile";
import ParentsWordsCarousel from "../components/carousel/parents-words-carousel/ParentsWordsCarousel";
import ParentsWordsCarouselMobile from "../components/carousel/parents-words-carousel/ParentsWordsCarouselMobile";
import EducationGrid from "../components/education-grid/EducationGrid";
import ColorBackground from "../components/containers/ColorBackground";
import Calendar from "../components/global-calendar/CalendarPage";
import HomeHeaderComponent from "../components/headers/home-header/HomeHeaderComponent";
import AdminRichTextEditor from "../components/rich-text-editor/AdminRichTextEditor";
import TextFromAdminEditor from "../components/rich-text-editor/TextFromAdminEditor";
import { returnUserId } from "../context/getData";

// education data parsed from App
// import { educationData } from "../data/EducationData";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      screenWidth: null,
      educationData: props.data
    };
    this.topRef = React.createRef();
  }

  static contextType = Context;

  handleSignout = async () => {
    this.context.onLogout();
    //Redirect user to home page
    this.props.location.reload();
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // Scroll to top of page
    this.topRef.current.scrollIntoView();
  }

  resize() {
    let currentWidth = window.innerWidth <= 960;
    if (currentWidth !== this.state.screenWidth) {
      this.setState({ screenWidth: currentWidth });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  render() {
    // Come back: here we should get set the admin user ids
    const isAdmin = () => {
      return returnUserId() === 1 ? true : false;
    };
    return (
      <div ref={this.topRef}>
        {/* CMS like features are now implemented using Message of type HTML
        <ContentContainer>
          {isAdmin() ? <AdminRichTextEditor /> : <TextFromAdminEditor />}
          <SchoolQualityCarousel />
          <SchoolQualityCarouselMobile />
        </ContentContainer>
        */}
        <ColorBackground>
          <HomeHeaderComponent />
          <Calendar />
          <ContentContainer>
            <EducationGrid
              data={this.state.educationData}
              url={"/undervisning"}
              returnPath="/"
              returnTitle="Hjemmesiden"
            />
          </ContentContainer>
        </ColorBackground>

        <ContentContainer>
          <ParentsWordsCarousel />
          <ParentsWordsCarouselMobile />
        </ContentContainer>
      </div>
    );
  }
}

export default withRouter(Home);
