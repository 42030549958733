import React from "react";
import styled from "styled-components";
import NavHeaderDesktop from "../components/headers/nav-header/NavHeaderDesktop";
import NavHeaderMobile from "../components/headers/nav-header/NavHeaderMobile";
import NavHeaderMobileContainer from "../components/headers/nav-header/NavHeaderMobileContainer";
import ContentContainer from "./ContentContainer";

const BackgroundBody = styled.div`
  min-height: 45vh;
`;

function DialogsBackground() {
  return (
    <>
      <NavHeaderDesktop />
      <NavHeaderMobileContainer>
        <NavHeaderMobile />
      </NavHeaderMobileContainer>
      <ContentContainer>
        <BackgroundBody />{" "}
      </ContentContainer>
    </>
  );
}

export default DialogsBackground;
