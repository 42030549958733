import React from "react";
import { Link } from "react-router-dom";

function NotFound(props) {
  return (
    <div>
      <h1>Page was not found.</h1>

      <Link to="/">Go back to main page</Link>
    </div>
  );
}

export default NotFound;
