import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import "moment/locale/da";
import GlobalEventDetails from "./GlobalEventDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Fade from "@material-ui/core/Fade";

const { overridings } = require("../../themes/DarkTheme");
const handleColorType = (props) => {
  switch (props) {
    case "concert":
      return `${overridings.palette.colors.purple}`;
    case "workday":
      return `${overridings.palette.colors.green}`;
    case "meeting":
      return `${overridings.palette.colors.yellow}`;
    default:
      return `${overridings.palette.colors.blue}`;
  }
};
const EventContainer = styled.div`
  height: 3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
const EventStartTime = styled.span`
  font-size: 0.8rem;
`;
const displayOpacity = keyframes`
  {
    0%{
        opacity:0%
      }
      50%{
        opacity:50%   
      }
    100%{
    opacity:100%
  }
}
`;
const DayDateContainerMobile = styled.div`
  width: 6%;
  @media (max-width: 700px) {
    width: 8%;
  }
  @media (max-width: 500px) {
    width: 10%;
  }
  @media (max-width: 500px) {
    width: 12%;
  }
`;
const DayDate = styled.div`
  font-size: 0.8rem;
  height: 1rem;
  background-color: ${(props) =>
    props.isTodayDate && props.theme.palette.common.white};
  color: ${(props) => props.isTodayDate && props.theme.palette.common.black};
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  margin-right: 0.9rem;
  width: 60%;
  text-align: ${(props) => props.isTodayDate && "center"};
`;
const EventDetails = styled.div`
  border-left: 3px solid ${(props) => handleColorType(props.color)};
  padding-left: 10px;
  font-size: 0.8rem;
`;
const DayShortName = styled.span`
  text-transform: uppercase;
`;
const ExpandMoreIconContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  animation: ${displayOpacity} 0.5s linear forwards;
`;
const ExpandLessIconContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  animation: ${displayOpacity} 0.5s linear forwards;
`;
const ExpandEvent = styled.div`
  margin-top: 20px;
  margin-left: 6%;
  @media (max-width: 700px) {
    margin-left: 8%;
  }
  @media (max-width: 500px) {
    margin-left: 10%;
  }
  @media (max-width: 500px) {
    margin-left: 12%;
  }
`;
function EventInMobileCalendar(props) {
  const [isOpenEventDetails, setIsOpenEventDetails] = useState(false);
  const isTodayDate =
    moment(props.eventInfo.startdate).format("YYYY-MM-DD") ===
    moment().format("YYYY-MM-DD")
      ? true
      : false;
  return (
    <>
      <EventContainer
        onClick={() => {
          setIsOpenEventDetails(!isOpenEventDetails);
        }}
      >
        <DayDateContainerMobile>
          <DayShortName>
            {moment(props.eventInfo.startdate).locale("da").format("ddd")}
          </DayShortName>
          <br />
          <DayDate isTodayDate={isTodayDate}>
            {moment(props.eventInfo.startdate).format("D")}
          </DayDate>
        </DayDateContainerMobile>
        <EventDetails color={props.eventInfo.type}>
          {props.eventInfo.title}
          <br />
          <EventStartTime>
            kl.{props.eventInfo.starttime.slice(0, 2)}.
            {props.eventInfo.starttime.slice(3, 5)}
          </EventStartTime>
          {isOpenEventDetails ? (
            <ExpandLessIconContainer>
              {" "}
              <ExpandLessIcon />
            </ExpandLessIconContainer>
          ) : (
            <ExpandMoreIconContainer>
              <ExpandMoreIcon />
            </ExpandMoreIconContainer>
          )}
        </EventDetails>
      </EventContainer>
      {isOpenEventDetails ? (
        <Fade in={isOpenEventDetails} timeout={500}>
          <div>
            <ExpandEvent>
              <GlobalEventDetails
                eventDetails={props.eventInfo}
              ></GlobalEventDetails>
            </ExpandEvent>
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
    </>
  );
}
export default EventInMobileCalendar;
