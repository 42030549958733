import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUserFirstnameHooks } from '../../../context/getData';
import { Context } from '../../../context/Context';
// import { getFeatures } from "../../../util.js";

import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HeaderLogo from '../../../assets/HeaderLogo.svg';
import NavContainerDesktop from '../../containers/header/NavContainerDesktop';
import ProfileDialog from '../../dialogs/profile-dialog/ProfileDialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const HeaderContainer = styled(Box)`
    width: 85%;
    padding-top: 2rem;
    margin: 0 auto;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const Logo = styled.img`
    width: 6rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 6rem;
        padding-top: 2rem;
    }
`;

const LoginContainer = styled(Box)`
    & a {
        text-decoration: none;
    }
`;

const HeaderUserFirstname = styled(Typography)`
    cursor: pointer;
`;

const DesktopContainer = styled.div`
    display: block;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

function NavHeaderDesktop({ props }) {
    const history = useHistory();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userFirstname, setUserFirstname] = useState('');
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);

    const context = useContext(Context);

    const toggleProfileDialog = () => {
        if (profileDialogOpen) {
            setProfileDialogOpen(false);
        } else {
            setProfileDialogOpen(true);
        }
    };

    const closeProfileDialog = () => {
        setProfileDialogOpen(false);
    };

    const createLoginLink = () => {
        const currentPath = history.location.pathname;
        // if (currentPath === '/tilmelding/vaelg-moede') {
        return (
            <Link
                className="frontpage-job"
                to={{
                    pathname: '/login',
                    state: {
                        modal: true,
                        from: {
                            path: `${currentPath}`
                        }
                    }
                }}
            >
                Login
            </Link>
        );
    };

    useEffect(() => {
        getUserFirstnameHooks(setIsAuthenticated, setUserFirstname);
    }, [context.state.currentUser]);

    return (
        <DesktopContainer>
            <HeaderContainer display="flex" justifyContent="space-between">
                <Box>
                    <Link to="/">
                        <Logo src={HeaderLogo} alt="Logo" />
                    </Link>
                </Box>

                <Box position="relative">
                    <LoginContainer
                        textAlign="right"
                        style={{
                            display: context.state.features.enableLogin
                                ? 'block'
                                : 'none'
                        }}
                    >
                        {isAuthenticated ? (
                            <div>
                                <HeaderUserFirstname
                                    variatn="subtitle1"
                                    onClick={() => toggleProfileDialog()}
                                >
                                    Logget ind som: {userFirstname}
                                </HeaderUserFirstname>
                                {profileDialogOpen && (
                                    <ClickAwayListener
                                        onClickAway={closeProfileDialog}
                                    >
                                        <ProfileDialog
                                            userFirstname={userFirstname}
                                            closeProfileDialog={
                                                closeProfileDialog
                                            }
                                        />
                                    </ClickAwayListener>
                                )}
                            </div>
                        ) : (
                            <>{createLoginLink()}</>
                            // <Link
                            //     className="frontpage-job"
                            //     to={{
                            //         pathname: '/login',
                            //         state: { modal: true }
                            //     }}
                            // >
                            //     Login
                            // </Link>
                        )}
                    </LoginContainer>
                    <NavContainerDesktop />
                </Box>
            </HeaderContainer>
        </DesktopContainer>
    );
}

export default NavHeaderDesktop;
