import React from "react";
import styled from "styled-components";
import CalendarHeadline from "./CalendarHeadline";

const CalendarHeadlinesContainerComp = styled.div`
  width: 100%;
  display: flex;
  padding: 5px;
  text-transform: uppercase;
  @media (max-width: 960px) {
    display: none;
  }
`;
const days = [
  "Mandag",
  "Tirsdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lørdag",
  "Søndag",
];

function CalendarHeadlinesContainer(props) {
  return (
    <CalendarHeadlinesContainerComp>
      {days.map((day) => (
        <CalendarHeadline key={day}>{day}</CalendarHeadline>
      ))}
    </CalendarHeadlinesContainerComp>
  );
}

export default CalendarHeadlinesContainer;
