import { Box, FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import SelectComponent from "../select/SelectComponent";
import { days, months, years } from "./BirthdateData";

// const SelectComponent = styled(Select)`
//   margin: 0 1rem 0 0;
//   height: 2.8rem;
//   border-radius: 0;
//   border: 1px solid white;
//   & div {
//     width: 2.5rem;
//   }

//   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//       margin-bottom: 1rem;
//   }

// `;

const BirthdatePickerContainer = styled(Box)`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

const SelectComponentMonth = styled(Select)`
  margin: 0 1rem 0 0;
  height: 2.8rem;
  border-radius: 0;
  border: 1px solid white;
  & div {
    width: 5rem;
  }
`;

function BirthdatePicker({ day, month, year, setDay, setMonth, setYear }) {
  return (
    <BirthdatePickerContainer>
      <FormControl variant="outlined">
        <SelectComponent
          onChange={(e) => setDay(e.target.value)}
          name="day"
          value={day}
        >
          {days.map((day) => (
            <MenuItem key={day.value} value={day.value}>
              {day.title}
            </MenuItem>
          ))}
        </SelectComponent>
      </FormControl>

      <FormControl variant="outlined">
        <SelectComponentMonth
          onChange={(e) => setMonth(e.target.value)}
          name="month"
          value={month}
        >
          {months.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.title}
            </MenuItem>
          ))}
        </SelectComponentMonth>
      </FormControl>

      <FormControl variant="outlined">
        <SelectComponent
          onChange={(e) => setYear(e.target.value)}
          name="year"
          value={year}
        >
          {years.map((year) => (
            <MenuItem key={year.value} value={year.value}>
              {year.title}
            </MenuItem>
          ))}
        </SelectComponent>
      </FormControl>
    </BirthdatePickerContainer>
  );
}

export default BirthdatePicker;
