import { getLatestChildInRegFlow } from '../../../context/getData';
import {
    capitalize,
    postAndReturnResponseWithAuth
} from '../../../context/helperFunctions';
import {
    postAndReturnResp,
    postAndReturnData
} from '../../../context/helperFunctions';

export const validateName = (
    value,
    label,
    setFirstname,
    setSurname,
    firstname,
    surname,
    isNullAllowed
) => {
    console.log('validating name', value, label);
    // If the field is not required, we just save that
    const capitalizedValue = capitalize(value);
    if (value === '' && isNullAllowed) {
        if (label === 'Barnets fornavn') {
            return setFirstname({ ...firstname, value });
        } else if (label === 'Barnets efternavn') {
            return setSurname({ ...surname, value: capitalizedValue });
        }
    }
    const regex = /^[A-Za-z æøåÆØÅ.,-]+$/;

    // What should be the max length of a name?
    if (regex.test(capitalizedValue) && capitalizedValue.length < 500) {
        switch (label) {
            case 'Barnets fornavn':
                setFirstname({ ...firstname, value: capitalizedValue });
                break;
            case 'Barnets efternavn':
                setSurname({ ...surname, value: capitalizedValue });
                break;
            case 'Fornavn':
                setFirstname({ ...firstname, value: capitalizedValue });
                break;
            case 'Dit fornavn':
                setFirstname({ ...firstname, value: capitalizedValue });
                break;
            case 'Efternavn':
                setSurname({ ...surname, value: capitalizedValue });
                break;
            case 'Dit efternavn':
                setSurname({ ...surname, value: capitalizedValue });
                break;
            default:
                break;
        }
    } else {
        switch (label) {
            case 'Barnets fornavn':
                setFirstname({
                    ...firstname,
                    error: true,
                    errorMessage: `${label} mangler.`,
                    value: capitalizedValue
                });
                break;
            case 'Barnets efternavn':
                setFirstname({
                    ...firstname,
                    error: true,
                    errorMessage: `${label} mangler.`,
                    value: capitalizedValue
                });
                break;
            case 'Dit fornavn':
                setFirstname({
                    ...firstname,
                    error: true,
                    errorMessage: `${label} mangler.`,
                    value: capitalizedValue
                });
                break;
            case 'Dit efternavn':
                setSurname({
                    ...surname,
                    error: true,
                    errorMessage: `${label} mangler.`,
                    value: capitalizedValue
                });
                break;
            default:
                break;
        }
    }
};

export const validateEmail = (value, setEmailObj, emailObj) => {
    // var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(value)) {
        setEmailObj({ ...emailObj, value });
    } else {
        setEmailObj({
            ...emailObj,
            error: true,
            errorMessage: `Din mailadresse mangler eller er ukorrekt.`,
            value
        });
    }
};

export const validateActivityOrWebpage = (
    value,
    label,
    setActivityNameObj,
    setWebpageLinkObj,
    setOrganizationObj,
    activityNameObj,
    webpageLinkObj,
    organizationObj,
    onSubmit
) => {
    const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-/:()!?'";]+$/;
    const capitalizedValue = capitalize(value);
    if (
        regex.test(capitalizedValue) &&
        capitalizedValue.length < 64 &&
        capitalizedValue.length > 0
    ) {
        if (label === 'Navn på din aktivitet') {
            setActivityNameObj({ ...activityNameObj, value: capitalizedValue });
            const newState = {
                ...activityNameObj,
                value: capitalizedValue
            };
            onSubmit('activityNameObj', newState);
        } else if (label === 'Hjemmeside') {
            setWebpageLinkObj({ ...webpageLinkObj, value: value });
            const newState = {
                ...webpageLinkObj,
                value: value
            };
            onSubmit('webpageLinkObj', newState);
        } else if (label === 'Organisation') {
            setOrganizationObj({ ...organizationObj, value: capitalizedValue });
            const newState = {
                ...organizationObj,
                value: capitalizedValue
            };
            onSubmit('organizationObj', newState);
        }
    } else if (value === '' || value === undefined) {
        if (label === 'Navn på din aktivitet') {
            setActivityNameObj({
                ...activityNameObj,
                error: true,
                errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                value: capitalizedValue
            });
        } else if (label === 'Hjemmeside') {
            setWebpageLinkObj({ ...webpageLinkObj, value: value });
        } else if (label === 'Organisation') {
            setOrganizationObj({ ...organizationObj, value: capitalizedValue });
        }
    } else {
        if (label === 'Navn på din aktivitet') {
            setActivityNameObj({
                ...activityNameObj,
                error: true,
                errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                value: capitalizedValue
            });
        } else if (label === 'Hjemmeside') {
            setWebpageLinkObj({
                ...webpageLinkObj,
                error: true,
                errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                value: value
            });
        } else if (label === 'Organisation') {
            setOrganizationObj({
                ...organizationObj,
                error: true,
                errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                value: value
            });
        }
    }
};

export const validateActivityDescription = (
    value,
    label,
    setActivityDescriptionObj,
    activityDescriptionObj
) => {
    if (value && value !== '' && value.length > 0) {
        // const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-/:()!?'";_\n\r]+$/
        if (value.length < 5000) {
            setActivityDescriptionObj({ ...activityDescriptionObj, value });
        } else {
            setActivityDescriptionObj({
                ...activityDescriptionObj,
                error: true,
                errorMessage: `${label} længere end 2500 karakter.`
            });
        }
    } else {
        setActivityDescriptionObj({ ...activityDescriptionObj, value: '' });
    }
};

export const validateStringAddress = (
    value,
    label,
    setStreetObj,
    setHousenumberObj,
    setCityObj,
    streetObj,
    housenumberObj,
    cityObj
) => {
    const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-]+$/;
    const regexCity = /^[A-Za-z æøåüÆØÅÜ.,-]+$/;
    const capitalizedValue = capitalize(value);
    if (label === 'Husnummer' || label === 'Gade') {
        if (regex.test(capitalizedValue) && capitalizedValue.length < 500) {
            switch (label) {
                case 'Gade':
                    setStreetObj({ ...streetObj, value: capitalizedValue });
                    break;
                case 'Husnummer':
                    setHousenumberObj({
                        ...housenumberObj,
                        value: capitalizedValue
                    });
                    break;

                default:
                    break;
            }
        } else {
            switch (label) {
                case 'Gade':
                    setStreetObj({
                        ...streetObj,
                        error: true,
                        errorMessage: `Dit gadenavn mangler eller er ukorrekt.`,
                        value: capitalizedValue
                    });
                    break;
                case 'Husnummer':
                    setHousenumberObj({
                        ...housenumberObj,
                        error: true,
                        errorMessage: `Dit husnummer mangler eller er ukorrekt.`,
                        value: capitalizedValue
                    });
                    break;

                default:
                    break;
            }
        }
    } else {
        if (regexCity.test(capitalizedValue) && capitalizedValue.length < 500) {
            setCityObj({ ...cityObj, value: capitalizedValue });
        } else {
            setCityObj({
                ...cityObj,
                error: true,
                errorMessage: `Dit bynavn mangler eller er ukorrekt.`,
                value: capitalizedValue
            });
        }
    }
};

export const validatePostcode = (value, setPostcodeObj, postcodeObj) => {
    const regex = /^[0-9]+$/;
    if (regex.test(value) && value.length === 4) {
        setPostcodeObj({ ...postcodeObj, value });
    } else {
        setPostcodeObj({
            ...postcodeObj,
            error: true,
            errorMessage: `Dit postnummer mangler eller er ukorrekt (4 cifre).`,
            value
        });
    }
};

export const validatePhone = (
    value,
    label,
    setPhoneObj,
    setActivityPhoneObj,
    phoneObj,
    activityPhoneObj,
    onSubmit
) => {
    const regex = /^[0-9+ ]{8,}$/;
    if (regex.test(value) && value) {
        if (label === 'Mobil') {
            setPhoneObj({ ...phoneObj, value });
        } else if (label === 'Kontaktnummer') {
            setActivityPhoneObj({ ...activityPhoneObj, value });
            const newState = {
                ...activityPhoneObj,
                value
            };
            onSubmit('activityPhoneObj', newState);
        }
    } else if (
        (value === '' || value === undefined) &&
        label === 'Kontaktnummer'
    ) {
        setActivityPhoneObj({ ...activityPhoneObj, value });
    } else {
        if (label === 'Mobil') {
            setPhoneObj({
                ...phoneObj,
                error: true,
                errorMessage: `Dit mobilnummer mangler (8 cifre).`,
                value
            });
        } else if (label === 'Kontaktnummer') {
            setActivityPhoneObj({
                ...activityPhoneObj,
                error: true,
                errorMessage: `Dit nummer mangler (8 cifre).`,
                value
            });
        }
    }
};

export const validatePassword = (value, setPasswordObj, passwordObj) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
    if (value.length < 6) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage:
                'Kodeordet skal være på mindst 6 tegn og indeholde mindst et tal og både store og små bogstaver.',
            value
        });
    } else if (value.length > 250) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage: 'Kodeordet kan være max 250 bogstaver.',
            value
        });
    } else if (!regex.test(value)) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage:
                'Kodeordet skal være på mindst 6 tegn og indeholde mindst et tal og både store og små bogstaver.',
            value
        });
    } else {
        setPasswordObj({ ...passwordObj, value });
    }
};

// Validate password without passing password object
export const validatePasswordValue = (value) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
    if (value.length < 6 || value.length > 250 || !regex.test(value)) {
        return false;
    } else {
        return true;
    }
};

export const validatePasswordMatch = (
    password,
    repeatPassword,
    setRepeatPasswordObj,
    repeatPasswordObj
) => {
    if (password !== repeatPassword) {
        setRepeatPasswordObj({
            ...repeatPasswordObj,
            error: true,
            errorMessage: 'Kodeordene matcher ikke.',
            value: repeatPassword
        });
    } else {
        setRepeatPasswordObj({ ...repeatPasswordObj, value: repeatPassword });
    }
};
// Validate match password without passing password object and repeat password object
export const validatePasswordValueMatch = (password, repeatPassword) => {
    if (password !== repeatPassword) {
        return false;
    } else {
        return true;
    }
};
// Returns true, if any state has an object
export const hasErrors = (states) => {
    return states.find((state) => state.error);
};

export const returnStateObj = (statesArr, label) => {
    return statesArr.find((state) => state.label === label);
};

export const returnSetStateObj = (setStatesArr, label) => {
    const setStateObj = setStatesArr.find(
        (setState) => setState.label === label
    );
    if (setStateObj) {
        return setStateObj.function;
    }
};

export const throwError = (label, stateObj, setStateObj) => {
    switch (label) {
        case 'childFirstname':
            setStateObj({
                ...stateObj,
                errorMessage: 'Barnets fornavn skal udfyldes.',
                error: true
            });
            break;
        case 'childSurname':
            setStateObj({
                ...stateObj,
                errorMessage: 'Barnets efternavn skal udfyldes.',
                error: true
            });
            break;
        case 'firstname':
            setStateObj({
                ...stateObj,
                errorMessage: 'Fornavn skal udfyldes.',
                error: true
            });
            break;

        case 'surname':
            setStateObj({
                ...stateObj,
                errorMessage: 'Efternavn skal udfyldes.',
                error: true
            });
            break;

        case 'email':
            setStateObj({
                ...stateObj,
                errorMessage: 'Din mailadresse mangler eller er ukorrekt.',
                error: true
            });
            break;

        case 'phone':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit mobilnummer mangler (8 cifre).',
                error: true
            });
            break;

        case 'password':
            setStateObj({
                ...stateObj,
                errorMessage:
                    'Kodeordet skal være på mindst 8 tegn og indeholde mindst et tal og både store og små bogstaver.',
                error: true
            });
            break;
        case 'repeatPassword':
            setStateObj({
                ...stateObj,
                errorMessage:
                    'Kodeordet skal være på mindst 8 tegn og indeholde mindst et tal og både store og små bogstaver.',
                error: true
            });
            break;

        case 'street':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit gadenavn mangler eller er ukorrekt.',
                error: true
            });
            break;
        case 'housenumber':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit husnummer mangler eller er ukorrekt.',
                error: true
            });
            break;

        case 'postcode':
            setStateObj({
                ...stateObj,
                errorMessage: 'Postnummer mangler eller er ukorrekt (4 cifre).',
                error: true
            });
            break;
        case 'city':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit bynavn mangler eller er ukorrekt.',
                error: true
            });
            break;
        default:
            break;
    }
};

const saveInformationDataAndRegister = async (
    props,
    context,
    statesArr,
    setError,
    setErrorMessage,
    setIsLoading,
    isOnlySignup,
    selectedChildrenIds
) => {
    let informationData;
    if (!isOnlySignup) {
        const childInRegFlow = getLatestChildInRegFlow(context);
        childInRegFlow.childFirstname = returnValue(
            statesArr,
            'childFirstname'
        );
        childInRegFlow.childSurname = returnValue(statesArr, 'childSurname');
        context.saveChildInRegFlow(childInRegFlow);

        // if (isAuthenticated) {
        // Save child firstname and surname

        // This should be implemented later, when it is possible to sign up multiple children with the same flow
        // Save child in allChildrenInReg data storage too. It is needed in order to be able to add more children.
        // Check if there is a child object in childToModified in storage. If there is, it means that user is modifying a selected child.
        // Find the same child object in allChildrenInReg, and remove it, since we have the new, modified version in childInRegFlow, that we need to save
        // instead.
        // const allChildrenInReg = getAllChildrenInReg() ? getAllChildrenInReg() : [];
        // const childToModify = getChildToModify();
        // if (childToModify && Object.keys(childToModify).length > 0) {
        //   const matchingChild = allChildrenInReg.find(
        //     (child) =>
        //       child.childFirstname === childToModify.childFirstname &&
        //       child.childSurname === childToModify.childSurname &&
        //       child.birthdate === childToModify.birthdate
        //   );
        //   const filteredAllChildren = allChildrenInReg.filter(
        //     (child) => child !== matchingChild
        //   );
        //   filteredAllChildren.push(childInRegFlow);
        //   context.saveAllChildrenInReg(filteredAllChildren);
        //   context.saveChildInRegFlow({});
        // } else {
        //   allChildrenInReg.push(childInRegFlow);
        //   context.saveAllChildrenInReg(allChildrenInReg);
        //   context.saveChildInRegFlow({});
        // }

        // props.history.push("/tilmelding/kasse");
        // } else {
        //For now we are only saving one child in regflow, because in the first phase, only one child can be signed up in the flow
        context.saveAllChildrenInReg([childInRegFlow]);
        informationData = {
            childFirstname:
                returnValue(statesArr, 'childFirstname') &&
                returnValue(statesArr, 'childFirstname'),
            childSurname:
                returnValue(statesArr, 'childSurname') &&
                returnValue(statesArr, 'childSurname'),
            firstname: returnValue(statesArr, 'firstname'),
            surname: returnValue(statesArr, 'surname'),
            street: returnValue(statesArr, 'street'),
            housenumber: returnValue(statesArr, 'housenumber'),
            city: returnValue(statesArr, 'city'),
            postcode: returnValue(statesArr, 'postcode'),
            email: returnValue(statesArr, 'email'),
            phone: returnValue(statesArr, 'phone'),
            password: returnValue(statesArr, 'password')
        };

        // Send request to /user/register endpoint, server should create a temporary user and return an ID, which is used for sending the order.
        registerUser(
            informationData,
            context,
            props,
            setError,
            setErrorMessage,
            setIsLoading,
            isOnlySignup,
            childInRegFlow
        );
        // }
    } else {
        // If it is only signup...
        informationData = {
            firstname: returnValue(statesArr, 'firstname'),
            surname: returnValue(statesArr, 'surname'),
            street: returnValue(statesArr, 'street'),
            housenumber: returnValue(statesArr, 'housenumber'),
            city: returnValue(statesArr, 'city'),
            postcode: returnValue(statesArr, 'postcode'),
            email: returnValue(statesArr, 'email'),
            phone: returnValue(statesArr, 'phone'),
            password: returnValue(statesArr, 'password'),
            selectedChildrenIds: selectedChildrenIds && selectedChildrenIds
        };
        registerUser(
            informationData,
            context,
            props,
            setError,
            setErrorMessage,
            setIsLoading,
            isOnlySignup
        );
    }
};

const registerUser = async (
    requestObj,
    context,
    props,
    setError,
    setErrorMessage,
    setIsLoading,
    isOnlySignup,
    childInRegFlow
) => {
    let response;

    response = await postAndReturnResp('/api/v1/user/signup', requestObj);

    if (response && response.status === 200) {
        // Log user in
        const data = await postAndReturnData(
            '/api/v1/user/login',
            { email: requestObj.email, password: requestObj.password },
            setError
        );

        if (
            data &&
            data.user &&
            data.user.id &&
            data.user.firstname &&
            data.user.email &&
            data.userToken
        ) {
            context.saveUser({
                userFirstname: data.user.firstname,
                userEmail: data.user.email,
                userId: data.user.id,
                emailVerifiedAt: data.user.emailVerifiedAt,
                phoneVerifiedAt: data.user.phoneVerifiedAt,
                subscriber: data.user.subscriber,
                token: data.userToken,
                tokenExpires: data.expires,
                lastDayOfSubscription: data.user.lastDayOfSubscription,
                subscriptionDueDate: data.user.subscriptionDueDate
            });

            // Send out verify email and sms
            postAndReturnResponseWithAuth(
                `/api/v1/user/${data.user.id}/send-verification-email?isWelcomeEmail=true`,
                {},
                data.userToken,
                setError
            );
            postAndReturnResponseWithAuth(
                `/api/v1/user/${data.user.id}/send-verification-sms`,
                {},
                data.userToken,
                setError
            );

            // Save in local storage, that this is a new user, so the modal about having to verify email and phone must be shown to them
            // context.saveNewUser(true);
            // context.showFullProgressBar(true);
            //Empty context registration information
            context.saveRegistrationData({});
            // Save child in regflow - needed to save it for allChildreninRegFlow, because the next page is configured to be able to take in more children

            // If it is an only signup, user should be directed to My page
            if (isOnlySignup) {
                context.saveOnlySignup(true);
                props.history.push({
                    pathname: '/min-side'
                });

                // Check if there is an id in the url,
                // If there is, it means that the user is signing up through an invitation
                //
            } else {
                props.history.push('/tilmelding/kasse');
            }
        } else {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen eller kontakt os.');
        }
    } else if (response && response.status === 500) {
        const responseObj = await response.json();

        if (responseObj && responseObj.message) {
            // Check error message
            if (responseObj.message === 'email must be unique') {
                setError(true);
                // Come back! We should get a custom error modal here to prompt people to log in?
                setErrorMessage(
                    'Denne email er allerede registreret. Log ind.'
                );
            } else {
                setError(true);
                setErrorMessage('Noget gik galt. Prøv igen eller kontakt os.');
            }
        } else {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen eller kontakt os.');
        }
    } else {
        setError(true);
        setErrorMessage('Noget gik galt. Prøv igen eller kontakt os.');
    }
    setIsLoading(false);
};

export const setScrollView = (ref) => {
    ref.current.scrollIntoView();
};

export const returnValue = (statesArr, label) => {
    const state = statesArr.find((state) => state.label === label);
    if (state && state.value) {
        return state.value;
    }
};

export const findUnfilledInputOrRegister = async (
    statesArr,
    setStatesArr,
    context,
    props,
    isAuthenticated,
    childNameRef,
    parentNameRef,
    emailRef,
    phoneRef,
    passwordRef,
    addressRef,
    setError,
    setErrorMessage,
    setIsLoading,
    isOnlySignup,
    selectedChildrenIds
) => {
    if (statesArr.some((state) => state.value === '')) {
        const notFilledState = statesArr.filter((state) => state.value === '');
        // Throw error in these States
        notFilledState.forEach((state) =>
            throwError(
                state.label,
                returnStateObj(statesArr, state.label),
                returnSetStateObj(setStatesArr, state.label)
            )
        );

        // Set scroll view to the first element that has error
        const firstErrorLabel = notFilledState[0].label;

        if (
            firstErrorLabel === 'childFirstname' ||
            firstErrorLabel === 'childSurname'
        ) {
            setScrollView(childNameRef);
        } else if (
            firstErrorLabel === 'firstname' ||
            firstErrorLabel === 'surname'
        ) {
            setScrollView(parentNameRef);
        } else if (firstErrorLabel === 'email') {
            setScrollView(emailRef);
        } else if (firstErrorLabel === 'phone') {
            setScrollView(phoneRef);
        } else if (
            firstErrorLabel === 'street' ||
            firstErrorLabel === 'housenumber' ||
            firstErrorLabel === 'postcode' ||
            firstErrorLabel === 'city'
        ) {
            setScrollView(addressRef);
        } else if (
            firstErrorLabel === 'password' ||
            firstErrorLabel === 'repeatPassword'
        ) {
            setScrollView(passwordRef);
        }
        setIsLoading(false);
    } else {
        if (!isOnlySignup) {
            const regData = {};
            regData.information = {
                childFirstname: returnValue(statesArr, 'childFirstname'),
                childSurname: returnValue(statesArr, 'childSurname'),
                firstname: returnValue(statesArr, 'firstname'),
                surname: returnValue(statesArr, 'surname'),
                email: returnValue(statesArr, 'email'),
                phone: returnValue(statesArr, 'phone'),
                street: returnValue(statesArr, 'street'),
                housenumber: returnValue(statesArr, 'housenumber'),
                city: returnValue(statesArr, 'city'),
                postcode: returnValue(statesArr, 'postcode'),
                password: returnValue(statesArr, 'password')
            };

            // Save information to storage, so if the request fails, user don't need to retype everything
            const regDataWithoutPassword = {
                childFirstname: returnValue(statesArr, 'childFirstname'),
                childSurname: returnValue(statesArr, 'childSurname'),
                firstname: returnValue(statesArr, 'firstname'),
                surname: returnValue(statesArr, 'surname'),
                email: returnValue(statesArr, 'email'),
                phone: returnValue(statesArr, 'phone'),
                street: returnValue(statesArr, 'street'),
                housenumber: returnValue(statesArr, 'housenumber'),
                city: returnValue(statesArr, 'city'),
                postcode: returnValue(statesArr, 'postcode')
            };
            // Save information to local storage
            context.saveRegistrationData(regDataWithoutPassword);
        }
        saveInformationDataAndRegister(
            props,
            context,
            statesArr,
            setError,
            setErrorMessage,
            setIsLoading,
            isOnlySignup,
            selectedChildrenIds
        );
    }
};

// Check if there is user information saved in storage
export const storageHasInformation = (regInformation) => {
    if (
        regInformation.childFirstname &&
        regInformation.childSurname &&
        regInformation.firstname &&
        regInformation.surname &&
        regInformation.street &&
        regInformation.housenumber &&
        regInformation.postcode &&
        regInformation.city &&
        regInformation.email &&
        regInformation.phone
    ) {
        return true;
    } else {
        return false;
    }
};
