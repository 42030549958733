import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@material-ui/core';
import { useState } from 'react';
import ErrorDialog from '../error-dialog/ErrorDialog';
import TextInput from '../../text-input/TextInput';
import {
    displaySmallError,
    validateEmail
} from '../add-parent-dialog/AddParentDialogFunctions';
import Loader from '../../loader/Loader';
import { postAndReturnResp } from '../../../containers/HelperFunctions';
import CheckboxComponent from '../../checkbox/Checkbox';
import TermsOfPurchaseDialog from '../terms-of-purchase-dialog/TermsOfPurchaseDialog';
import RadioCheckboxComponent from '../../radio-checkbox/RadioCheckbox';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }

    position: relative;
`;

const BigSuccessTextComp = styled(Typography)`
    color: ${(props) => props.theme.palette.success.main};
`;

const CheckboxText = styled(Typography)`
    margin-top: 0.8rem;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const BigErrorTextComp = styled(Typography)`
    color: ${(props) => props.theme.palette.error.main};
    display: inline;
`;

const CloseIcon = styled(Typography)`
    font-size: 2.8rem;
    position: absolute;
    right: 1.5rem;
    top: 0.4rem;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.contrastText};
`;

const LinkStyle = styled(Typography)`
    text-decoration: underline;
    margin-top: 0.8rem;
    margin-left: 0.3rem;
    cursor: pointer;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

function ProcessDialog({ close, setScrollView }) {
    const [accepted, setAccepted] = useState(false);
    const [email, setEmail] = useState('');
    const [inputError, setInputError] = useState(false);
    const [smallErrorText, setSmallErrorText] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [bigSuccessText, setBigSuccessText] = useState('');
    const [bigErrorText, setBigErrorText] = useState('');
    const [checkboxError, setCheckboxError] = useState('false');
    const [termsOfPurchaseOpen, setTermsOfPurchaseOpen] = useState(false);

    const handleCheckBoxChange = () => {
        if (checkboxError) {
            setCheckboxError('false');
        }
        setAccepted(!accepted);
    };

    // ComponentDidMount
    useEffect(() => {
        if (setScrollView) {
            setScrollView();
        }
        // Come back: check if its okay not to include setScrollView in dependencies
        // eslint-disable-next-line
    }, []);

    const handleError = () => {
        setError(true);
        setErrorMessage('Something went wrong. Please try again.');
    };

    const removeErrorOnChange = () => {
        setInputError(false);
        setSmallErrorText('');
    };

    const handleInputChange = (valueObject) => {
        validateEmail(
            valueObject.value,
            setEmail,
            setInputError,
            setSmallErrorText
        );
    };

    const handleSubmit = async () => {
        if (!accepted) {
            setCheckboxError('true');
        } else {
            setIsLoading(true);
            if (email && email !== '') {
                // Come back: implement endpoint when available
                const response = await postAndReturnResp(
                    '/api/v1/process/email',
                    handleError
                );
                if (response && response.status && response.status === 200) {
                    setBigSuccessText(
                        'Din email er gemt. Vi kontakter dig, så snart de nye forløbstyper er klar.'
                    );
                    setIsLoading(false);
                } else {
                    setBigErrorText(
                        'Noget gik galt. Prøv igen, eller kontakt os.'
                    );
                    setIsLoading(false);
                }
            } else {
                displaySmallError(
                    setInputError,
                    setSmallErrorText,
                    'Email is missing or invalid.',
                    setIsLoading
                );
            }
        }
    };

    return (
        <DialogComponent
            onClose={close}
            open={true}
            aria-labelledby="forløb-info"
            aria-describedby="forløb-info-beskrivelse"
            disableRestoreFocus
        >
            <CloseIcon onClick={() => close()}>&#10799;</CloseIcon>
            <DialogTitle id="forløb-info">OBS</DialogTitle>
            <DialogContent>
                <DialogContentText id="forløb-info-beskrivelse">
                    Mollit non tempor elit incididunt aliquip excepteur commodo
                    aliquip aliquip qui mollit laborum. Deserunt elit non non
                    aliquip. Aliqua elit tempor do anim duis ea laboris fugiat
                    magna. Esse duis duis esse laborum ut adipisicing irure
                    ullamco anim.
                </DialogContentText>
                <TextInput
                    label="Email"
                    onInputChange={handleInputChange}
                    smallErrorText={smallErrorText}
                    inputError={inputError}
                    id="email"
                    type="text"
                    valueFromParent={email}
                    handleEnterPress={handleInputChange}
                    removeErrorOnChange={removeErrorOnChange}
                />

                <Box display="flex">
                    <Box display="flex" position="relative">
                        <RadioCheckboxComponent
                            onCheckboxChange={handleCheckBoxChange}
                            position="static"
                            error={checkboxError}
                        />

                        <CheckboxText
                            variant="body2"
                            onClick={() => handleCheckBoxChange()}
                            error={checkboxError}
                        >
                            Jeg accepterer
                        </CheckboxText>
                    </Box>
                    <LinkStyle
                        variant="body2"
                        onClick={() => setTermsOfPurchaseOpen(true)}
                        error={checkboxError}
                    >
                        betingelserne.
                    </LinkStyle>
                </Box>

                {bigSuccessText !== '' && (
                    <BigSuccessTextComp variant="subtitle1">
                        {bigSuccessText}
                    </BigSuccessTextComp>
                )}
                {bigErrorText !== '' && (
                    <Box mt="1rem">
                        <BigErrorTextComp variant="subtitle1">
                            {bigErrorText}
                        </BigErrorTextComp>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {isLoading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        {bigSuccessText === '' && bigErrorText === '' ? (
                            <Button onClick={() => handleSubmit()}>Send</Button>
                        ) : (
                            <Button onClick={() => close()}>Luk</Button>
                        )}
                    </React.Fragment>
                )}
            </DialogActions>
            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => setError(false)}
                />
            )}
            {termsOfPurchaseOpen && (
                <TermsOfPurchaseDialog
                    close={() => setTermsOfPurchaseOpen(false)}
                />
            )}
        </DialogComponent>
    );
}

export default ProcessDialog;
