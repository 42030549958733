import React, { useState } from "react";
import { useParams, useRouteMatch, withRouter } from "react-router-dom";
// import { instrumentData } from "../../data/InstrumentData";
// import { educationData } from "../../data/EducationData";
import Educations from "./Educations";
import EducationComponent from "../../components/education-component/EducationComponent";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
import ContentContainer from "../../components/containers/ContentContainer";
import MusicalPlayground from "./MusicalPlayground";
import BabyMusic from "./BabyMusic";
import { getDestination, getReturnUrl } from "./EducationFunctions";
import { useEffect } from "react";
import { useRef } from "react";
import MessageList from "../../components/message-list";

function Education(props) {
  const { educationId } = useParams();
  // const data = educationData.find((education) => education.id === educationId);
  const { url } = useRouteMatch();

  const [educationData, setEducationData] = useState(undefined); // Data object
  const [instrumentData, setInstrumentData] = useState(undefined); // Data object
  // eslint-disable-next-line
  const [educationMessageList, setEducationMessageList] = useState(undefined); // Array of messages
  // eslint-disable-next-line
  const [instrumentMessageList, setInstrumentMessageList] = useState(undefined); // Array of messages

  const contentRef = useRef();

  const getRightImageUrl = () => {
    // This should be implemented once I get the right size images
    // if (document.body.offsetWidth < 960) {
    //   switch (educationId) {
    //     case "hoereleg":
    //       return "/images/headers/header-hoereleg.jpg";
    //     case "sammenspil":
    //       return "/images/headers/header-sammenspil.jpg";
    //     default:
    //       return "/imag˘es/headers/header-vision.jpg";
    //   }
    // }
    switch (educationId) {
      case "hoereleg":
        return "/images/headers/header-hoereleg.jpg";
      case "sammenspil":
        return "/images/headers/header-sammenspil.jpg";
      default:
        return "/images/headers/header-vision.jpg";
    }
  };

  // ComponentDidMount
  useEffect(() => {
    if (educationId !== "instrumenter") {
      contentRef.current.scrollIntoView();
    }
    // eslint-disable-next-line
  }, []);

  const setInstrumentDataFromMessageList = (list) => {
    // console.log('Instrument message list',list);
    setInstrumentMessageList(list);
    if (list && list.length>0) {
      let data = list.map(message => {
        let path  = (message.url?message.url.split('/'):[undefined]);
        let instrumentId = path[path.length-1];
        // console.log('instrumentId',instrumentId)
        return ({
          title: message.title,
          id: instrumentId,
          imageUrl: message.text,
          description: message.html,
          color: "orange",
          colorRGB: "250, 152, 43,",
          url: message.url
        });
      });       
      setInstrumentData(data);
    }
  }

  const setEducationDataFromMessageList = (list) => {
    // console.log('Education: setEducationDataFromMessageList:',list);    
    setEducationMessageList(list);

    if (list && list.length>0) {
      let data = list
      .filter(message => {
        if (!message.url) console.log('ERROR: Message id '+message.id+' is missing url');
        let path  = (message.url?message.url.split('/'):[undefined]);
        let instrumentId = path[path.length-1];
        // console.log('educationId',educationId,'=== instrumentId',instrumentId,instrumentId === educationId);
        return instrumentId === educationId;
      })
      .map(message => {        
        return ({
          title: message.title,
          id: educationId,
          imageUrl: message.text,
          description: message.html,
          color: "orange",
          colorRGB: "250, 152, 43,",
          url: message.url
        });
      });       
      if (data.length>0) setEducationData(data[0]);
    }
  }

  const getRightPage = () => {
    if (educationId === "instrumenter") {
      return (
        <div>
          <MessageList type="html" name="Instrument%" state="final" change={setInstrumentDataFromMessageList}/>
          { instrumentData && 
            <Educations
              data={instrumentData}
              headline="Instrument"
              url="/undervisning/instrumenter"
            />
          }
        </div>
      );
    } else if (educationId === "musikalsk-legestue") {
      return (
        <div>
          {/* Todo: once you have the mobile version image, integrate it here */}
          <ReusableHeader imageUrl="/images/headers/header-musical-playground.jpg" />
          <div ref={contentRef}></div>
          <ContentContainer>
            <MusicalPlayground url="/undervisning" />
          </ContentContainer>
        </div>
      );
    } else if (educationId === "babymusik") {
      return (
        <div>
          {/* Todo: once you have the mobile version image, integrate it here */}
          <ReusableHeader imageUrl="/images/headers/header-babymusic.jpg" />
          <div ref={contentRef}></div>
          <ContentContainer>
            <BabyMusic />
          </ContentContainer>
        </div>
      );
    } 
    else {
      /* Display all educations other than instrumental, musical playground or babymusic */
      return (
        <div>
          <MessageList type="html" name="Education%" state="final" change={setEducationDataFromMessageList}/>

          <ReusableHeader imageUrl={getRightImageUrl()} />
          
          <div ref={contentRef}></div>
          <ContentContainer>
            { educationData && 
              <EducationComponent
                data={educationData}
                url={`${url}`}
                previousUrl={getReturnUrl(props)}
                destination={getDestination(props)}
              />
            }
          </ContentContainer>
        </div>
      );
    }
  };

  return getRightPage();
}

export default withRouter(Education);
