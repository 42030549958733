import React from "react";
import styled from "styled-components";

const ExplainerImageComponent = styled.img`
width: 100%;`;

function ExplainerImage({ src, alt }) {
  return <ExplainerImageComponent src={src} alt={alt} />;
}

export default ExplainerImage;
