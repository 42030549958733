import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import TermsOfPurchase from "../../terms-of-purchase/TermsOfPurchase";

const DialogComponent = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem !important;
  }

  position: relative;
`;

const CloseIcon = styled(Typography)`
  font-size: 2.8rem;
  position: absolute;
  right: 1.5rem;
  top: 0.4rem;
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

function TermsOfPurchaseDialog({ close, setScrollView }) {
  // ComponentDidMount
  useEffect(() => {
    if (setScrollView) {
      setScrollView();
    }
    // Come back: check if its okay not to include setScrollView in dependencies
    // eslint-disable-next-line
  }, []);

  return (
    <DialogComponent
      onClose={close}
      open={true}
      aria-labelledby="købsvilkår"
      aria-describedby="købsvilkår-beskrivelse"
      disableRestoreFocus
    >
      <CloseIcon onClick={() => close()}>&#10799;</CloseIcon>
      {/* Come back: danish text */}
      <DialogTitle id="købsvilkår">Købsvilkår</DialogTitle>
      <DialogContent>
        {/* Come back: get right text in terms of purchase here */}
        <TermsOfPurchase />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()}>Ok</Button>
      </DialogActions>
    </DialogComponent>
  );
}

export default TermsOfPurchaseDialog;
