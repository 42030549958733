import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getLatestSimpleRegData } from '../../../context/getData';
import { Context } from '../../../context/Context';

import { capitalize } from '../../HelperFunctions';
import { Box, Typography } from '@material-ui/core';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';

import RegularHeadline from '../../../components/headlines/RegularHeadline';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';

const DescriptionText = styled(Typography)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    }
`;

const DescriptionTextBold = styled(Typography)`
    font-family: 'Benton Sans Bold';
    margin-right: 0.3rem;

    &.bigger-margin {
        margin-bottom: 1rem;
    }
`;

// Come back: danish text
function SummaryIntromeeting(props) {
    const [meetingName, setMeetingName] = useState('');
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingDate, setMeetingDate] = useState('');
    const [isUserNew, setIsUserNew] = useState(false);

    const contentRef = useRef();

    const context = useContext(Context);

    // Check if it is an old user (-we have no user information in simpleRegData), or a newly created user.
    // We will display text according to that - if an old user booked an intromeeting, we tell them that the booking is successfull.
    //  If it's a new user, we tell that the registration was successfull.
    const checkIfUserNew = (simpleRegData) => {
        if (simpleRegData && simpleRegData.information) {
            setIsUserNew(true);
        }
    };
    // ComponentDidMount
    useEffect(() => {
        // Check if userinfo is saved in localstorage
        const simpleRegData = getLatestSimpleRegData(context);
        checkIfUserNew(simpleRegData);

        // Check if there are any meetings selected
        if (
            simpleRegData.selectedIntroMeeting &&
            Object.keys(simpleRegData.selectedIntroMeeting).length > 0
        ) {
            setMeetingName(simpleRegData.selectedIntroMeeting.title);
            setMeetingTime(simpleRegData.selectedIntroMeeting.time);
            setMeetingDate(simpleRegData.selectedIntroMeeting.date);
            // Cleare reigstration data from storage
            context.saveSimpleRegistrationData({});
        } else {
            props.history.push('/tilmelding/tilmeldingstype');
        }
        contentRef.current.scrollIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <span ref={contentRef}></span>
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <NavHeaderDesktop />
            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                {isUserNew ? (
                    <ProgressBar
                        currentStage={'Opsummering'}
                        type="intromeeting"
                    />
                ) : (
                    <ProgressBar
                        currentStage={'Opsummering'}
                        type="authIntromeeting"
                    />
                )}

                <Box width="fit-content">
                    <ReturnLinkReusable url="/" destination="Forsiden" />
                </Box>

                <RegularHeadline component="h1" title="Opsummering" />

                <Box mb="2rem">
                    {isUserNew ? (
                        <DescriptionTextBold variant="subtitle1">
                            Du er nu oprettet som ny bruger. Your username is
                            your email, and password is your phone number, which
                            you can change when you log in.
                        </DescriptionTextBold>
                    ) : (
                        <DescriptionTextBold variant="subtitle1">
                            Din tilmelding er modtaget.
                        </DescriptionTextBold>
                    )}
                </Box>

                <Box mb="1rem">
                    <Typography variant="subtitle1">
                        Du tilmeldte dig følgende møde:
                    </Typography>
                </Box>
                <DescriptionText variant="body2">{meetingName}</DescriptionText>

                <DescriptionText variant="body2">
                    {capitalize(meetingTime)}, den {meetingDate}
                </DescriptionText>
            </RegistrationContentContainer>
        </div>
    );
}

export default withRouter(SummaryIntromeeting);
