import React from "react";
import ReturnLinkReusable from "../../components/return-link/ReturnLinkReusable";
import { useParams, withRouter } from "react-router-dom";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
import ContentContainer from "../../components/containers/ContentContainer";

function AllPictures(props) {
  const { courseId } = useParams();

  return (
    <div>
      <ReusableHeader />
      <ContentContainer>
        <ReturnLinkReusable url={`/hold/${courseId}`} destination="Mit hold" />
        <h1>Alle billeder</h1>
      </ContentContainer>
    </div>
  );
}

export default withRouter(AllPictures);
