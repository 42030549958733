
import React from "react";
import styled from "styled-components";

const ContentContainerComponent = styled.div`
  width: 85%;
  margin: 4rem auto;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
  }
`;

function EducationContentContainer({ children }) {
  return <ContentContainerComponent>{children}</ContentContainerComponent>;
}

export default EducationContentContainer;