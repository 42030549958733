import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const OuterContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  height: 25rem;

  overflow: scroll;
  overflow-x: hidden;

  & :last-child {
    margin-bottom: 2rem;
  }
`;

// const Underlined = styled(Typography)`
//   width: 60%;
//   text-decoration: underline;
//   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//     width: 90%;
//   }
// `;
// const TextBold = styled(Typography)`
//   width: 60%;
//   font-family: "Benton Sans Med";
//   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//     width: 90%;
//   }
// `;

const GradientOverlay = styled.div`
  height: 6rem;
  width: 100%;
  background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
  position: absolute;
  bottom: 0;
`;

const Text = styled(Typography)`
  padding: 0.3rem 0rem;
  width: 75%;
  font-size: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 90%;
    font-size: 0.8rem;
  }
`;
const TextTitle = styled(Typography)`
  font-size: 1.2rem;
  padding: 0.8rem 0rem 0.1rem 0rem;
  color: ${(props) => props.theme.palette.colors.orange};
  width: 80%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 90%;
    font-size: 1rem;
  }
`;

const SubTitle = styled(Typography)`
  font-size: 0.85rem;
  padding: 0.3rem 0rem 0.1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    font-size: 1rem;
  }
`;
const LinkText = styled.a`
  text-decoration: underline;
`;
const List = styled.ul`
  @media (max-width: 350px) {
    padding: 0px 19px;
  }
`;
const ListItem = styled.li`
  width: 70%;
  font-size: 0.85rem;
  padding: 0.5rem 0rem;
  margin-bottom: 0rem !important;
  @media (max-width: 350px) {
    width: 100%;
  }
`;

function PersonalDataRules(props) {
  return (
    <OuterContainer>
      <Container>
        <TextTitle variant="subtitle1" gutterBottom>
          Dataansvarlig og kontaktoplysninger
        </TextTitle>
        <SubTitle gutterBottom>
          Dette websted{" "}
          <LinkText
            target="_blank"
            rel="noopener noreferrer"
            href="bulowsvej10.dk"
          >
            bulowsvej10.dk
          </LinkText>{" "}
          ejes og drives af:
        </SubTitle>
        <Text gutterBottom>
          Den Ny Musikskole ApS <br /> Bülowsvej 10
          <br /> 1870 Frederiksberg
          <br /> CVR.nr.: 33382898
          <br /> Telefon: 30 14 01 23 <br />
          <LinkText href="mailto:kontakt@bulowsvej10.dk">
            E-mail: kontakt@bulowsvej10.dk
          </LinkText>
        </Text>
        <Text gutterBottom>
          På{" "}
          <LinkText
            target="_blank"
            rel="noopener noreferrer"
            href="bulowsvej10.dk"
          >
            bulowsvej10.dk
          </LinkText>{" "}
          kan du købe et abonnement, som giver adgang til alle ledige lokaler
          24/7 i hele abonnementsperioden.
        </Text>
        <Text gutterBottom>
          Nærværende persondatapolitik fastlægger klare retningslinjer for Den
          Ny Musikskole ApS/bulowsvej10.dk’s måde at behandle dine
          personoplysninger på. I det følgende redegøres for, hvordan vi
          anvender de personlige oplysninger, som du efterlader og/eller
          afgiver, når du køber hos os, når du besøger vores hjemmeside, samt
          når du anvender de forskellige tjenester på via vores hjemmeside.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Indsamling af persondata
        </TextTitle>
        <Text gutterBottom>
          Den Ny Musikskole ApS indsamler dine persondata i forbindelse med køb
          på vores hjemmeside. Oprettelse af abonnement og/eller lokalebooking
          eller når du browser på vores hjemmeside (herunder når du accepterer
          cookies eller accepterer autoudfyldning (hvor systemet gemmer
          oplysninger til hurtig udfyldning, af navn, adresse og andre
          persondata f.eks. i forbindelse med dine ordrer).
        </Text>
        <Text gutterBottom>
          Vi indsamler følgende personoplysninger: Fornavn, efternavn, adresse,
          mailadresse, mobilnummer samt kodeord til login.
        </Text>
        <Text gutterBottom>
          De personoplysninger, som vi indsamler, kan f.eks. omfatte dit navn,
          din e-mailadresse, dit telefonnummer og lignende
          identifikationsoplysninger, samt oplysninger om din navigation på
          hjemmesiden.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Formål med og anvendelse af persondata og andet data
        </TextTitle>
        <Text gutterBottom>
          Den Ny Musikskole ApS behandler dine persondata ud fra det
          lovgrundlag, som findes i Databeskyttelsesforordningens artikel 6,
          stk. 1.
        </Text>
        <Text gutterBottom>
          Det betyder, at vi kun indsamler, anvender og behandler dine data,
          hvis du giver os samtykke til det og/eller det er nødvendigt i
          forbindelse med vores service til dig, f.eks. for at kunne gennemføre
          dit køb (herunder ordreopbevaring, ordrekvittering og
          ordrebekræftelse) og ved udsendelse af vigtige og relevante
          informationer om huset og lokalerne, som vil vedrøre dig, som bruger
          (systemmails, fejlmeddelelser, servicemails).
        </Text>
        <SubTitle gutterBottom>De data vi indsamler, anvender vi til:</SubTitle>
        <List>
          <ListItem>
            At gennemføre dit køb, når du handler på vores hjemmeside, herunder
            ordreopbevaring, ordrekvittering og ordrebekræftelse
          </ListItem>
          <ListItem>
            At udsende vigtige informationsmails angående huset og lokalerne.
          </ListItem>
        </List>
        <Text gutterBottom>
          Mht. informationsmails, så udsendes de kun, når det gælder vigtige og
          relevante informationer om huset og lokalerne, som vil vedrøre dig,
          som bruger.
        </Text>
        <br />
        <TextTitle variant="subtitle1" gutterBottom>
          Videregivelse af dine persondata til andre dataansvarlige
        </TextTitle>
        <Text gutterBottom>
          Vi videregiver ikke information til tredjepart om din navigation eller
          brug af vores hjemmeside.
        </Text>
        <Text gutterBottom>
          I henhold til lovgivningen kan der dog forekomme tilfælde, hvor det er
          nødvendigt at videregive oplysninger til politiet eller offentlige
          myndigheder.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Videregivelse af dine persondata til eksterne samarbejdspartnere
          (databehandlere).
        </TextTitle>
        <Text gutterBottom>
          Vi samarbejder rent teknisk med en række virksomheder, som opbevarer
          og behandler data. Virksomhederne behandler udelukkende oplysninger på
          vores vegne og må ikke bruge dem til egne formål.
        </Text>
        <Text gutterBottom>
          I forbindelse med køb, registrerer og videregiver vi de
          personoplysninger, du har opgivet, og som er nødvendige for at vi kan
          gennemføre købet. Det drejer sig om NETS, Quickpay til drift af
          betalingsmodulet.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Overførsel af persondata til tredjelande
        </TextTitle>
        <Text gutterBottom>
          Vi samarbejder kun med databehandlere i EU eller i lande, der kan give
          dine oplysninger en tilstrækkelig beskyttelse.
        </Text>
        <Text gutterBottom>
          Den Ny Musikskole er tilstede online på diverse tredjeparts sociale
          netværk og platforme. Når du interagerer på og med vores
          virksomhedsprofiler på det enkelte netværk eller platform, gælder de
          regler, vilkår og betingelser, som den enkelte udbyder har opstillet.
        </Text>
        <Text gutterBottom>
          Vi henviser desuden til Datatilsynets hjemmeside, for mere indgående
          læsning om reglerne for indsamling og overførsel af data til
          tredjepart.
        </Text>
        <br />
        <TextTitle variant="subtitle1" gutterBottom>
          Sletning af personoplysninger
        </TextTitle>
        <Text gutterBottom>
          Vi registrerer de personoplysninger, som er nødvendige for at du kan
          oprette et abonnement og/eller leje lokaler. Personoplysningerne
          registreres hos Den Ny Musikskole/bulowsvej10.dk og opbevares i fem
          år, hvorefter oplysningerne slettes.
        </Text>
        <Text gutterBottom>
          Vi behandler dine personoplysninger fortroligt og du kan altid logge
          ind og se dine oplysninger eller lukke din konto, så alle data
          anonymiseres i det omfang lovgivningen tillader det.
        </Text>
        <Text gutterBottom>
          Vi er jævnfør regnskabsloven forpligtet til at gemme
          regnskabsinformationer om betalinger i fem år.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Cookies
        </TextTitle>
        <Text gutterBottom>
          Vi anvender cookies p vores hjemmeside. Du kan læse om vores
          Cookiepolitik{" "}
          <LinkText
            target="_blank"
            rel="noopener noreferrer"
            href="https://bulowsvej10.dk/"
          >
            her
          </LinkText>
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Sikkerhed
        </TextTitle>
        <Text gutterBottom>
          Vi har implementeret sikkerhedsforanstaltninger til sikring af, at
          vores interne procedurer lever op til vores høje sikkerhedspolitiske
          standarder. Vi bestræber os således på bedst muligt på at beskytte
          kvaliteten og integriteten af dine personlige oplysninger.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Online tilstedeværelse på sociale medier
        </TextTitle>
        <Text gutterBottom>
          Vi bruger online tilstedeværelse på tredjeparts sociale netværk og
          platforme, eksempelvis Facebook, LinkedIn og Instagram, for at kunne
          kommunikere med aktive kunder, interesserede og brugere, for at
          informere om og markedsføre vores tjenester og produkter. Ved at gå
          ind på de pågældende netværk og platforme gælder
          forretningsbetingelserne og retningslinjerne for databehandling for
          den pågældende udbyder.
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Dine rettigheder
        </TextTitle>
        <Text gutterBottom>
          Vi følger Datatilsynets vejledning ang ende sikkerhed og h ndtering af
          dine rettigheder({" "}
          <LinkText
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.datatilsynet.dk/"
          >
            datatilsynet.dk
          </LinkText>
          )
        </Text>
        <Text gutterBottom>
          Du kan altid få indsigt i de persondata, vi har indsamlet om dig. Du
          har ret til at få slettet eller ændret de eksisterende data.
        </Text>
        <Text gutterBottom>
          Du kan altid tilbagekalde det samtykke, du har afgivet. Hvis du ønsker
          at tilbagekalde dit samtykke, skal du kontakte os på{" "}
          <LinkText
            target="_blank"
            rel="noopener noreferrer"
            href="kontakt@bulowsvej10.dk"
          >
            kontakt@bulowsvej10.dk
          </LinkText>
          ).
        </Text>
        <Text gutterBottom>
          Hvis du vælger at trække dit samtykke tilbage, sker det først med
          virkning fra dette tidspunkt (ikke med tilbagevirkende kraft).
        </Text>
        <TextTitle variant="body2" gutterBottom>
          Klageadgang
        </TextTitle>
        <Text gutterBottom>Du har mulighed for at klage til:</Text>
        <Text gutterBottom>
          Den Ny Musikskole ApS <br /> Bülowsvej 10
          <br /> 1870 Frederiksberg
          <br /> CVR.nr.: 33382898
          <br /> Telefon: 30 14 01 23 <br />
          <LinkText href="mailto:kontakt@bulowsvej10.dk">
            E-mail: kontakt@bulowsvej10.dk
          </LinkText>
        </Text>
        <Text>
          Du kan også kontakte Datatilsynet, Borgergade 28, 5., 1300 København
          K.
        </Text>
        <br />
        <Text gutterBottom>
          <i>Persondatapolitik er senest opdateret den 06.07.2022</i>
        </Text>
        <br />
        <br /> <br /> <br />
        <br />
      </Container>
      <GradientOverlay />
    </OuterContainer>
  );
}

export default PersonalDataRules;
