// MessagePlaceholder looks for changes to messageList.length to trigger refresh

import React, { useState, useEffect, useContext } from 'react';
import parseHTML from 'html-react-parser';
import { Context } from '../../context/Context';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';

// Pass callback to parent context setMessageList
const MessagePlaceholder = (props) => {
    let { list, name, type, state, property, value } = props;

    if (!list) throw new Error('MessagePlaceholder is missing list property');
    if (!name) throw new Error('MessagePlaceholder is missing name property');
    type = type ? type.toLowercase() : 'html';
    state = state ? state.toLowercase() : 'final';
    property = property ? property : 'html';
    value = value ? value : ''; // Default value

    const context = useContext(Context);
    const user = context.state.currentUser;
    const isSuperuser = ['superuser', 'admin'].indexOf(user.role) >= 0;
    const [html, setHTML] = useState('');
    const [message, setMessage] = useState(null);

    const handleEdit = (event) => {
        if (message) {
            window.open('/message/' + message.id + '/edit', '_blank');
        }
    };

    // ComponentDidMount
    useEffect(() => {
        // eslint-disable-next-line
        let messageList = list.filter(
            (message) =>
                message.name === name &&
                message.type === type &&
                message.state === state
        );
        // console.log('List',list);
        // console.log('name='+name+', type='+type+', state='+state);
        // console.log('Message',messageList);
        if (messageList.length > 0) setMessage(messageList[0]);
        let contentList = messageList.map((message) =>
            message[property] ? parseHTML(message[property]) : value
        );
        setHTML(contentList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length]); // Watch for changes of list length, as arrays are a reference

    return (
        <>
            {isSuperuser && (
                <IconButton
                    size="small"
                    style={{
                        width: 28,
                        height: 28,
                        background: '#FF0000',
                        margin: 4
                    }}
                    component="span"
                    onClick={handleEdit}
                >
                    <EditIcon />
                </IconButton>
            )}
            <Typography variant="body2">{html}</Typography>
        </>
    );
};

export default MessagePlaceholder;
