import React, { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { overridings } from '../../../themes/DarkTheme';
import styled from 'styled-components';
import { removeWhiteSpace } from '../../../context/helperFunctions';
// import { Link } from "react-router-dom";
import {
    findUnfilledInputOrRegister,
    hasErrors,
    validateEmail,
    validateName,
    validatePassword,
    validatePasswordMatch,
    validatePhone,
    validatePostcode,
    validateStringAddress,
    returnValue
} from './RegisterInformationFunctions';
import { Context } from '../../../context/Context';
import {
    getLatestRegData,
    getAuthentication,
    returnAuthenticated,
    getLatestChildInRegFlow,
    getChildInRegFlow
} from '../../../context/getData';
// import { motion } from "framer-motion";

import { Box, Typography } from '@material-ui/core';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';

import RegularHeadline from '../../../components/headlines/RegularHeadline';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import InformationTextInput from '../../../components/text-input/InformationTextInput';

import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import Loader from '../../../components/loader/Loader';
import RegularButton from '../../../components/buttons/RegularButton/RegularButton';
import CheckboxComponent from '../../../components/checkbox/Checkbox';
import TermsOfPurchaseDialog from '../../../components/dialogs/terms-of-purchase-dialog/TermsOfPurchaseDialog';
import {
    isBirthdateValid,
    isCourseSelected,
    isMeetingSelected,
    isProcessSelected
} from '../GlobalRegistrationFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
/*
const LinkComponent = styled.span`
  text-decoration: underline;
`;
*/
const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 50rem;

    @media (max-width: 1010px) {
        width: 85%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        width: 100%;
    }
`;

const ButtonContainer = styled(Box)`
    cursor: pointer;
`;
const RegularButtonContainer = styled.div`
    width: fit-content;
    margin-top: 1rem;
`;

const CheckboxText = styled(Typography)`
    margin-top: 0.8rem;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const DescriptionTextLink = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

function RegisterInformation(props) {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [checkboxErrorRules, setCheckboxErrorRules] = useState('false');
    const [acceptedRules, setAcceptedRules] = useState(false);
    // If it is only signup (user is coming from Login dialog - Sign up button), then the process bar is not visible, and user should be redirected to My page.
    const [isOnlySignup, setIsOnlySignup] = useState(false);
    const [termsAndConditionsDialog, setTermsAndConditionsDialog] = useState(
        false
    );
    // eslint-disable-next-line
    const [houseRulesDialog, setHouseRulesDialog] = useState(false);
    // eslint-disable-next-line
    const [personalDataDialog, setPersonalDataDialog] = useState(false);
    // eslint-disable-next-line
    const [cookieRulesDialog, setCookieRulesDialog] = useState(false);

    const currentPath = history.location.pathname;

    const [childFirstnameObj, setChildFirstnameObj] = useState({
        label: 'childFirstname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Barnets fornavn',
        placeholder: 'Barnets fornavn'
    });
    const [childSurnameObj, setChildSurnameObj] = useState({
        label: 'childSurname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Barnets efternavn',
        placeholder: 'Barnets efternavn'
    });

    const [firstnameObj, setFirstnameObj] = useState({
        label: 'firstname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Dit fornavn',
        placeholder: 'Dit fornavn'
    });
    const [surnameObj, setSurnameObj] = useState({
        label: 'surname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Dit efternavn',
        placeholder: 'Dit efternavn'
    });
    const [streetObj, setStreetObj] = useState({
        label: 'street',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gade',
        placeholder: 'Gade'
    });
    const [housenumberObj, setHousenumberObj] = useState({
        label: 'housenumber',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Husnummer',
        placeholder: 'Husnummer'
    });
    const [postcodeObj, setPostcodeObj] = useState({
        label: 'postcode',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Postnummer',
        placeholder: 'Postnummer'
    });
    const [cityObj, setCityObj] = useState({
        label: 'city',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Bynavn',
        placeholder: 'Bynavn'
    });
    const [emailObj, setEmailObj] = useState({
        label: 'email',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mailadresse',
        placeholder: 'E-Mail'
    });
    const [phoneObj, setPhoneObj] = useState({
        label: 'phone',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mobil',
        placeholder: 'Mobilnummer'
    });
    const [passwordObj, setPasswordObj] = useState({
        label: 'password',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Kodeord',
        placeholder: 'Kodeord'
    });
    const [repeatPasswordObj, setRepeatPasswordObj] = useState({
        label: 'repeatPassword',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gentag kodeord',
        placeholder: 'Gentag Kodeordet'
    });

    // Refs
    const contentRef = useRef();
    const childNameRef = useRef();
    const parentNameRef = useRef();
    const addressRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const passwordRef = useRef();
    const termsRef = useRef();

    const context = useContext(Context);

    const handleVisibility = () => {
        if (!passwordVisible) {
            setPasswordVisible(true);
            setPasswordInputType('text');
        } else {
            setPasswordVisible(false);
            setPasswordInputType('password');
        }
    };

    const saveInputValue = (valueObject) => {
        const valueNoWhiteSpace = removeWhiteSpace(valueObject.value);
        if (
            valueObject.label === 'Barnets fornavn' ||
            valueObject.label === 'Barnets efternavn'
        ) {
            validateName(
                valueNoWhiteSpace,
                valueObject.label,
                setChildFirstnameObj,
                setChildSurnameObj,
                childFirstnameObj,
                childSurnameObj
            );
        } else if (
            valueObject.label === 'Dit fornavn' ||
            valueObject.label === 'Dit efternavn'
        ) {
            validateName(
                valueNoWhiteSpace,
                valueObject.label,
                setFirstnameObj,
                setSurnameObj,
                firstnameObj,
                surnameObj
            );
        } else if (
            valueObject.label === 'Gade' ||
            valueObject.label === 'Bynavn' ||
            valueObject.label === 'Husnummer'
        ) {
            validateStringAddress(
                valueNoWhiteSpace,
                valueObject.label,
                setStreetObj,
                setHousenumberObj,
                setCityObj,
                streetObj,
                housenumberObj,
                cityObj
            );
        } else if (valueObject.label === 'Postnummer') {
            validatePostcode(valueNoWhiteSpace, setPostcodeObj, postcodeObj);
        } else if (valueObject.label === 'Mobil') {
            validatePhone(
                valueNoWhiteSpace,
                valueObject.label,
                setPhoneObj,
                null,
                phoneObj,
                null
            );
        } else if (valueObject.label === 'Kodeord') {
            validatePassword(valueObject.value, setPasswordObj, passwordObj);
        } else if (valueObject.label === 'Gentag kodeord') {
            validatePasswordMatch(
                passwordObj.value,
                valueObject.value,
                setRepeatPasswordObj,
                repeatPasswordObj
            );
        } else if (valueObject.label === 'Mailadresse') {
            validateEmail(valueNoWhiteSpace, setEmailObj, emailObj);
        }
    };

    const returnSelectedChildrenIds = () => {
        const params = new URL(document.location).searchParams;
        const selectedChildrenIdsString = params.get('selectedChildrenIds'); // '15 16'
        if (selectedChildrenIdsString) {
            const selectedChildrenIdsStringArr = selectedChildrenIdsString.split(
                ' '
            );
            const selectedChildrenIdsNumberArr = selectedChildrenIdsStringArr.map(
                (id) => {
                    return Number(id);
                }
            );
            return selectedChildrenIdsNumberArr;
        }
    };

    const onSubmit = () => {
        // Get user id from url
        const selectedChildrenIds = returnSelectedChildrenIds();

        // If it is a signup, we need to check all the fields, and register the user
        if (!isAuthenticated) {
            if (!acceptedRules) {
                setCheckboxErrorRules('true');
                setError(true);
                setErrorMessage(
                    'Du mangler at acceptere persondata- og cookiepolitikken.'
                );
                termsRef.current.scrollIntoView();
                return setError(true);
            }

            setIsLoading(true);
            const states = [
                childFirstnameObj,
                childSurnameObj,
                firstnameObj,
                surnameObj,
                streetObj,
                cityObj,
                housenumberObj,
                postcodeObj,
                phoneObj,
                emailObj,
                passwordObj,
                repeatPasswordObj
            ];

            const statesWithoutChildName = [
                firstnameObj,
                surnameObj,
                streetObj,
                cityObj,
                housenumberObj,
                postcodeObj,
                phoneObj,
                emailObj,
                passwordObj,
                repeatPasswordObj
            ];

            const setStates = [
                { label: 'childFirstname', function: setChildFirstnameObj },
                { label: 'childSurname', function: setChildSurnameObj },
                { label: 'firstname', function: setFirstnameObj },
                { label: 'surname', function: setSurnameObj },
                { label: 'street', function: setStreetObj },
                { label: 'city', function: setCityObj },
                { label: 'housenumber', function: setHousenumberObj },
                { label: 'postcode', function: setPostcodeObj },
                { label: 'phone', function: setPhoneObj },
                { label: 'email', function: setEmailObj },
                { label: 'password', function: setPasswordObj },
                { label: 'repeatPassword', function: setRepeatPasswordObj }
            ];

            const setStatesWithoutChildName = [
                { label: 'firstname', function: setFirstnameObj },
                { label: 'surname', function: setSurnameObj },
                { label: 'street', function: setStreetObj },
                { label: 'city', function: setCityObj },
                { label: 'housenumber', function: setHousenumberObj },
                { label: 'postcode', function: setPostcodeObj },
                { label: 'phone', function: setPhoneObj },
                { label: 'email', function: setEmailObj },
                { label: 'password', function: setPasswordObj },
                { label: 'repeatPassword', function: setRepeatPasswordObj }
            ];

            // Check for errors
            if (!hasErrors(states)) {
                // If it is onlySignup, then the name of the kid should not be required
                if (isOnlySignup) {
                    findUnfilledInputOrRegister(
                        statesWithoutChildName,
                        setStatesWithoutChildName,
                        context,
                        props,
                        isAuthenticated,
                        childNameRef,
                        parentNameRef,
                        emailRef,
                        phoneRef,
                        passwordRef,
                        addressRef,
                        setError,
                        setErrorMessage,
                        setIsLoading,
                        isOnlySignup,
                        selectedChildrenIds
                    );
                } else {
                    findUnfilledInputOrRegister(
                        states,
                        setStates,
                        context,
                        props,
                        isAuthenticated,
                        childNameRef,
                        parentNameRef,
                        emailRef,
                        phoneRef,
                        passwordRef,
                        addressRef,
                        setError,
                        setErrorMessage,
                        setIsLoading,
                        isOnlySignup
                    );
                }
            } else {
                // Trow error in dialog
                setError(true);
                setErrorMessage('Du skal udfylde alle felter.');
                setIsLoading(false);
            }
        } else {
            // If the user is signed in, yet it is only signup, then we have an error, since they are
            // not supposed to be signed in
            if (isOnlySignup) {
                return context.onLogout();
            }

            // If the user is logged in, we only need to check if the child's names are filled and valid
            if (!hasErrors([childFirstnameObj, childSurnameObj])) {
                // Resave child's name, because it might have been changed
                const childInRegFlow = getChildInRegFlow();
                context.saveChildInRegFlow({
                    ...childInRegFlow,
                    childFirstname: childFirstnameObj.value,
                    childSurname: childSurnameObj.value
                });
                context.saveAllChildrenInReg([
                    {
                        ...childInRegFlow,
                        childFirstname: childFirstnameObj.value,
                        childSurname: childSurnameObj.value
                    }
                ]);
                props.history.push('/tilmelding/kasse');
            } else {
                // Trow error in dialog
                setError(true);
                setErrorMessage('Du skal udfylde alle felter.');
                setIsLoading(false);
            }
        }
    };

    const removeErrorOnChange = (label) => {
        const states = [
            childFirstnameObj,
            childSurnameObj,
            firstnameObj,
            surnameObj,
            streetObj,
            cityObj,
            housenumberObj,
            postcodeObj,
            phoneObj,
            emailObj,
            passwordObj,
            repeatPasswordObj
        ];
        const changedField = states.find(
            (state) => state.danishLabel === label
        );

        changedField.error = false;
        changedField.errorMessage = '';
    };

    // If there is anything in storage, fill the inputs
    const recoverInformation = (
        regInformation,
        childInRegFlow,
        isItOnlySignup
    ) => {
        console.log('recovering info', regInformation);
        console.log('recovering child', childInRegFlow);
        if (regInformation && Object.keys(regInformation).length !== 0) {
            if (regInformation.firstname) {
                setFirstnameObj({
                    ...firstnameObj,
                    value: regInformation.firstname
                });
            }
            if (regInformation.surname) {
                setSurnameObj({ ...surnameObj, value: regInformation.surname });
            }
            if (regInformation.city) {
                setCityObj({ ...cityObj, value: regInformation.city });
            }
            if (regInformation.street) {
                setStreetObj({ ...streetObj, value: regInformation.street });
            }
            if (regInformation.postcode) {
                setPostcodeObj({
                    ...postcodeObj,
                    value: regInformation.postcode
                });
            }
            if (regInformation.housenumber) {
                setHousenumberObj({
                    ...housenumberObj,
                    value: regInformation.housenumber
                });
            }
            if (regInformation.email) {
                setEmailObj({ ...emailObj, value: regInformation.email });
            }
            if (regInformation.phone) {
                setPhoneObj({ ...phoneObj, value: regInformation.phone });
            }
        }

        if (childInRegFlow && !isItOnlySignup) {
            if (childInRegFlow.childFirstname) {
                setChildFirstnameObj({
                    ...childFirstnameObj,
                    value: childInRegFlow.childFirstname
                });
            }
            if (childInRegFlow.childSurname) {
                setChildSurnameObj({
                    ...childSurnameObj,
                    value: childInRegFlow.childSurname
                });
            }
        }
    };

    const onBackToCalendarClick = () => {
        const states = [
            firstnameObj,
            surnameObj,
            streetObj,
            cityObj,
            housenumberObj,
            postcodeObj,
            phoneObj,
            emailObj,
            passwordObj,
            repeatPasswordObj
            // activityNameObj,
            // activityDescriptionObj,
            // webpageLinkObj,
        ];
        // Save info to local storage, except for password. So if user does not complete the signup and returns, we can restore the given values.
        const regDataWithoutPassword = {
            firstname: returnValue(states, 'firstname'),
            surname: returnValue(states, 'surname'),
            email: returnValue(states, 'email'),
            phone: returnValue(states, 'phone'),
            street: returnValue(states, 'street'),
            housenumber: returnValue(states, 'housenumber'),
            city: returnValue(states, 'city'),
            postcode: returnValue(states, 'postcode')
            // activityName: returnValue(states, "activityName"),
            // activityDescription: returnValue(states, "activityDescription"),
            // webpageLink: returnValue(states, "webpageLink"),
            // isProfilePublic: acceptedPublicProfile,
        };
        // Save information to local storage
        context.saveRegistrationData(regDataWithoutPassword);

        // // In case of adding subscription, we should redirect the user to /abonnement page.
        // // This will get a bit more complex, when we implement the calendar, because we will need to check, if the user is coming from calendar (rates??) or subscription page
        // props.history.push("/abonnement");
    };

    const handleCheckBoxChangeRules = () => {
        if (checkboxErrorRules) {
            setCheckboxErrorRules('false');
        }
        setAcceptedRules(!acceptedRules);
    };

    const checkOnlySignup = () => {
        if (props.location.state && props.location.state.isOnlySignup) {
            setIsOnlySignup(true);
            return true;
        } else if (props.location.pathname.includes('signup')) {
            // If a logged in user has been clicked on the invitation link, we should log them out
            if (returnAuthenticated()) {
                return context.onLogout();
            }
            setIsOnlySignup(true);
            return true;
        }
    };

    // Get authentication
    useEffect(() => {
        getAuthentication(setIsAuthenticated);
    }, [context.state.currentUser]);

    //ComponentDidMount
    // Load data if user has already filled meetings
    useEffect(() => {
        // Check if the user is coming from login dialog (so they clicked on Signup button) - they won't be directed to the kasse page.
        checkOnlySignup();
        const childInRegFlow = getLatestChildInRegFlow(context);
        // console.log('child in regflow', childInRegFlow);
        console.log('in register', childInRegFlow);

        //If user is authenticated, they should only see the two input fields about the child's names.
        if (returnAuthenticated()) {
            if (childInRegFlow.childSurname && childInRegFlow.childFirstname) {
                // Recover the name of the child
                recoverInformation(null, childInRegFlow);
            }
        } else {
            // If the user has already been here, and filled some of the fields, we should recover that
            const regData = getLatestRegData(context);
            recoverInformation(regData, childInRegFlow, checkOnlySignup());

            // Recover the name of the kid as well, which is in the registration flow right now
            // The user has already given the kid's name in the first step of the flow, and we would like to use that here by filling
            // the first two input fields.

            if (
                isBirthdateValid() &&
                isCourseSelected() &&
                isProcessSelected() &&
                isMeetingSelected() &&
                childInRegFlow &&
                childInRegFlow.childSurname &&
                childInRegFlow.childFirstname &&
                Object.keys(childInRegFlow).length > 0
            ) {
                //Check if there is new user info saved in localstorage, if yes, apply.
                recoverInformation(regData, childInRegFlow);
            } else {
                // If there are already children in allChildrenInReg, then take the user to checkout page,
                //  where they can add or modify the current children they have in cart.
                // if (getAllChildrenInReg() && getAllChildrenInReg().length > 0) {
                //   props.history.push("/tilmelding/kasse");
                // } else {
                //   props.history.push("/tilmelding/foedselsdag");
                // }
            }

            // Scroll view to top of page where content starts
            contentRef.current.scrollIntoView();
        }

        // eslint-disable-next-line
    }, [props.history]);

    return (
        // <motion.div
        //   initial={{ opacity: 0 }}
        //   animate={{ opacity: 1 }}
        //   exit={{ opacity: 0 }}
        //   transition={{ duration: 0.7 }}
        // >
        <div>
            <div>
                <NavHeaderDesktop />
                <NavHeaderMobileContainer>
                    <NavHeaderMobile />
                </NavHeaderMobileContainer>

                <span ref={contentRef}></span>
                <RegistrationContentContainer
                    marginTop="2.5rem"
                    mobilePaddingTop="1rem"
                >
                    {isOnlySignup ? (
                        <></>
                    ) : (
                        <ProgressBar currentStage={'Oplysninger'} />
                    )}
                    <Box width="fit-content">
                        <ReturnLinkReusable
                            url="/tilmelding/vaelg-moede"
                            destination="Møder"
                        />
                    </Box>
                    {/* {!isOnlySignup && <ProgressBar currentStage={"Oplysninger"} />} */}
                    {!isAuthenticated ? (
                        <>
                            <RegularHeadline
                                component="h1"
                                title="Opret brugerprofil"
                                marginbottom="1.5rem"
                            />
                            <DescriptionText variant="body2">
                                For at oprette din profil skal vi bruge et par
                                oplysninger. <br />
                                Du kan tilføje yderligere oplysninger eller
                                rette i dem inde på ‘Min Side’
                            </DescriptionText>
                            <DescriptionTextLink
                                onClick={() =>
                                    props.history.push({
                                        pathname: '/login',
                                        state: {
                                            modal: true,
                                            from: {
                                                path: `${currentPath}`
                                            }
                                        }
                                    })
                                }
                                variant="body2"
                            >
                                Har du allerede en profil? Log ind her.
                            </DescriptionTextLink>
                        </>
                    ) : (
                        <>
                            {/* Come back: translation needed */}
                            <RegularHeadline
                                component="h1"
                                title="Edit child's name"
                                marginbottom="1.5rem"
                            />
                            <DescriptionText variant="body2">
                                Consectetur ut fugiat aliqua est exercitation
                                adipisicing dolor id.
                            </DescriptionText>
                        </>
                    )}

                    <React.Fragment>
                        {isAuthenticated ? (
                            <NameContainer ref={childNameRef}>
                                <InformationTextInput
                                    stateObj={childFirstnameObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                />
                                <InformationTextInput
                                    stateObj={childSurnameObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                />
                            </NameContainer>
                        ) : (
                            <>
                                {isOnlySignup ? null : (
                                    <NameContainer ref={childNameRef}>
                                        <InformationTextInput
                                            stateObj={childFirstnameObj}
                                            onInputChange={saveInputValue}
                                            removeErrorOnChange={
                                                removeErrorOnChange
                                            }
                                        />
                                        <InformationTextInput
                                            stateObj={childSurnameObj}
                                            onInputChange={saveInputValue}
                                            removeErrorOnChange={
                                                removeErrorOnChange
                                            }
                                        />
                                    </NameContainer>
                                )}

                                <NameContainer ref={parentNameRef}>
                                    <InformationTextInput
                                        stateObj={firstnameObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                    <InformationTextInput
                                        stateObj={surnameObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>
                                {/* Address */}
                                <NameContainer ref={addressRef}>
                                    <InformationTextInput
                                        stateObj={streetObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />

                                    <InformationTextInput
                                        stateObj={housenumberObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>
                                <NameContainer>
                                    <InformationTextInput
                                        stateObj={postcodeObj}
                                        onInputChange={saveInputValue}
                                        type="number"
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />

                                    <InformationTextInput
                                        stateObj={cityObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>
                                <NameContainer ref={emailRef}>
                                    <InformationTextInput
                                        stateObj={emailObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                        type="email"
                                    />

                                    <span ref={phoneRef}></span>

                                    <InformationTextInput
                                        stateObj={phoneObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                        type="number"
                                    />
                                </NameContainer>
                                <NameContainer ref={passwordRef}>
                                    <InformationTextInput
                                        stateObj={passwordObj}
                                        onInputChange={saveInputValue}
                                        type={passwordInputType}
                                        handleVisibility={handleVisibility}
                                        visible={passwordVisible}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />

                                    <InformationTextInput
                                        stateObj={repeatPasswordObj}
                                        onInputChange={saveInputValue}
                                        type={passwordInputType}
                                        handleVisibility={handleVisibility}
                                        visible={passwordVisible}
                                        customClass="last-text-input"
                                        // handleEnterPress={() => onSubmit()}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>
                            </>
                        )}
                    </React.Fragment>
                    <Box mt="2rem">
                        {/* <DescriptionText>Some text about public profile</DescriptionText> */}
                    </Box>
                    {/* <Box display="flex" position="relative">
            <CheckboxComponent
              checked={acceptedPublicProfile}
              onCheckboxChange={handleCheckboxChangePublicProfile}
              position="static"
              error={checkboxErrorPublicProfile}
            />

            <CheckboxText
              variant="body2"
              onClick={() => handleCheckboxChangePublicProfile()}
              error={checkboxErrorPublicProfile}
            >
              Gør min profil offentlig.
            </CheckboxText>
          </Box> */}
                    <span ref={termsRef}></span>
                    {/* <Box mt="2.5rem">
            <DescriptionText variant="body2">
              Ved at klikke her, accepterer du GDPR-reglerne.
            </DescriptionText>
          </Box> */}

                    {!isAuthenticated && (
                        <>
                            <Box display="flex" position="relative">
                                <CheckboxComponent
                                    onCheckboxChange={handleCheckBoxChangeRules}
                                    position="static"
                                    error={checkboxErrorRules}
                                    linksInText="true"
                                />

                                <CheckboxText
                                    variant="body2"
                                    error={checkboxErrorRules}
                                >
                                    Jeg erklærer at have læst og forstået{' '}
                                    <UnderlinedText
                                        onClick={() =>
                                            setPersonalDataDialog(true)
                                        }
                                    >
                                        persondatapolitikken
                                    </UnderlinedText>{' '}
                                    og{' '}
                                    <UnderlinedText
                                        onClick={() =>
                                            setCookieRulesDialog(true)
                                        }
                                    >
                                        cookiepolitikken
                                    </UnderlinedText>{' '}
                                    for oprettelse af profil på Bülowsvej 10.
                                </CheckboxText>
                            </Box>
                        </>
                    )}
                    {isLoading ? (
                        <Box mt="1rem" mb="1rem">
                            <Loader />
                        </Box>
                    ) : (
                        <RegistrationButton
                            title={
                                isAuthenticated
                                    ? 'Videre'
                                    : 'Opret brugerprofil'
                            }
                            onButtonClick={() => onSubmit()}
                            minWidth="12rem"
                            width="12rem"
                        />
                    )}

                    <ButtonContainer
                        width="fit-content"
                        onClick={() => onBackToCalendarClick()}
                    ></ButtonContainer>
                </RegistrationContentContainer>
            </div>
            {/* <MyCartIcon /> */}
            {/* Come back: error text to danish */}
            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => setError(false)}
                />
            )}
            {termsAndConditionsDialog && (
                <TermsOfPurchaseDialog
                    close={() => setTermsAndConditionsDialog(false)}
                />
            )}
            {/* {houseRulesDialog && (
        <HouseRulesDialog close={() => setHouseRulesDialog(false)} />
      )}
      {personalDataDialog && (
        <PersonalDataDialog close={() => setPersonalDataDialog(false)} />
      )}
      {cookieRulesDialog && (
        <CookieDialog close={() => setCookieRulesDialog(false)} />
      )} */}
        </div>
    );
}

export default withRouter(RegisterInformation);
