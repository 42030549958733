import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
    height: 3.4rem;
  }
`;
function NavHeaderMobileContainer({ children }) {
  return <Container>{children}</Container>;
}

export default NavHeaderMobileContainer;
