import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const Headline = styled(Typography)`
  margin-bottom: 2.5rem;
`;

function AboutHeadline({ title }) {
  return <Headline variant="h5">{title}</Headline>;
}

export default AboutHeadline;
