import React from "react";
import { nextTwoWeeks, nextWeek } from "../CalendarFunctions";
import styled from "styled-components";

const ArrowContainer = styled.div`
  font-size: 1.8rem;
  padding: ${(props) =>
    props.calendar === "global-calendar" ? "17.5rem 0rem" : "17rem 0rem"};
  cursor: pointer;
  position: absolute;
  right: 3%;
`;
function RightArrow(props) {
  return (
    <div>
      {props.calendar === "global-calendar" ? (
        <ArrowContainer
          calendar={props.calendar}
          onClick={() => {
            props.onClick(nextTwoWeeks(props.dateObj));
          }}
        >
          &#62;
        </ArrowContainer>
      ) : (
        <ArrowContainer
          onClick={() => {
            props.onClick(nextWeek(props.dateObj));
          }}
        >
          &#62;
        </ArrowContainer>
      )}
    </div>
  );
}
export default RightArrow;
