import moment from 'moment';
import { getLocalStorageJson } from '../util';
import { getAndReturnDataWithAuth } from './helperFunctions';

let localStorageKey = document.location.hostname + ':state-history';

// Come back: perhaps find a better name for this function
// Changes userobject in context - which triggers Effects
// export const getUserFirstname = (context) => {
//   // Check in local storage if user is authenticated
//   const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
//   const userObj = dataFromLocalStorage.currentUser;
//   // eslint-disable-next-line
//   if (userObj) {
//     if (
//       Object.keys(userObj).length > 0 &&
//       userObj.userFirstname &&
//       userObj.userId
//     ) {
//       // Update context too
//       context.onLogin({
//         userId: userObj.userId,
//         participants: userObj.participants,
//         userFirstname: userObj.userFirstname,
//         role: userObj.role,
//         token: userObj.token,
//       });
//     } else {
//       // Remove userobject from context if there is any
//       context.onLogin({
//         userId: "",
//         participants: [],
//         userFirstname: "",
//         role: "",
//         token: "",
//       });
//       // self.setState({ isAuthenticated: false });
//     }
//   } else {
//     // Remove userobject from context if there is any
//     context.onLogin({
//       userId: "",
//       participants: [],
//       userFirstname: "",
//       role: "",
//       token: "",
//     });
//     // self.setState({ isAuthenticated: false });
//   }
// };

// Get list of app features this user is allowed to use
export const getFeatures = (context) => {
    // Check in local storage for user features
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const featuresObj = dataFromLocalStorage.features;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('debug') === 'true')
        console.log(
            'Features',
            featuresObj ? JSON.stringify(featuresObj, null, 2) : 'undefined'
        );
    // TODO Check user role priviledges to enable some features after login
    // eslint-disable-next-line
    context.saveFeatures(featuresObj);
};

// Changes userObject in context - which triggers Effects
export const getUserData = (context) => {
    // Check in local storage if user is authenticated
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;

    // eslint-disable-next-line
    if (userObj) {
        if (
            Object.keys(userObj).length > 0 &&
            userObj.userFirstname &&
            userObj.userEmail &&
            userObj.userId
        ) {
            // Update context too
            context.saveUser({
                userFirstname: userObj.userFirstname,
                userEmail: userObj.userEmail,
                userId: userObj.userId,
                emailVerifiedAt: userObj.emailVerifiedAt,
                phoneVerifiedAt: userObj.phoneVerifiedAt,
                subscriber: userObj.subscriber,
                role: userObj.role,
                token: userObj.token,
                tokenExpires: userObj.tokenExpires,
                lastDayOfSubscription: userObj.lastDayOfSubscription,
                subscriptionDueDate: userObj.subscriptionDueDate
            });
        } else {
            // Remove userObject from context if there is any
            context.saveUser({ userFirstname: '', userId: '', userEmail: '' });
        }
    } else {
        // Remove userObject from context if there is any
        context.saveUser({ userFirstname: '', userId: '', userEmail: '' });
    }
};

export const getUserId = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.userFirstname) {
            return userObj.userId;
        } else {
            return '';
        }
    } else {
        return '';
    }
};

export const returnAuthenticated = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (!userObj.userFirstname || userObj.userFirstname === '') {
            return false;
        } else {
            return true;
        }
    }
};

export const getOrderInfo = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.orderInfo;
};

export const getUserFirstnameHooks = (setIsAuthenticated, setUserFirstname) => {
    // Check in local storage if user is authenticated
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.userFirstname) {
            setIsAuthenticated(true);
            if (setUserFirstname) {
                setUserFirstname(userObj.userFirstname);
            }
        } else {
            setIsAuthenticated(false);
        }
    } else {
        setIsAuthenticated(false);
    }
};

export const getAuthentication = (setIsAuthenticated) => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.userFirstname && userObj.userFirstname !== '') {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }
};

export const returnUserId = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    if (
        dataFromLocalStorage.currentUser &&
        dataFromLocalStorage.currentUser.userId
    ) {
        return dataFromLocalStorage.currentUser.userId;
    }
};

export const getChildInRegFlow = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.childInRegFlow;
};

export const getRegInformation = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.regInformation;
};

// Only intro meeting registration data
export const getSimpleRegistrationData = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.simpleRegistrationData;
};

export const getCoursesData = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.coursesData;
};

export const getMeetingsData = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('debug') === 'true')
        console.log(
            'getMeetingsData:',
            dataFromLocalStorage.meetingsData
                ? JSON.stringify(dataFromLocalStorage.meetingsData, null, 2)
                : undefined
        );
    return dataFromLocalStorage.meetingsData;
};

export const getSelectedIntroMeeting = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.selectedIntroMeeting;
};

export const getAllChildrenInReg = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.allChildrenInReg;
};

export const getRatesData = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.ratesData;
};

export const getChildToModify = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.childToModify;
};

export const getAllWorkdays = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.allWorkdays;
};

export const getChildAlreadyRegistered = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.childAlreadyRegistered;
};

// Checks if data is expired in local storage
const isExpired = (dataFromStorage) => {
    if (dataFromStorage.expiry) {
        const now = new Date();
        if (now.getTime() > dataFromStorage.expiry) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

// Remove data from storage if expired
export const removeExpiredData = (dataFromStorage, context) => {
    if (dataFromStorage) {
        if (isExpired(dataFromStorage)) {
            console.log('Data in storage is expired!');
            context.saveChildInRegFlow({});
            context.saveSimpleRegistrationData({});
            context.saveCoursesData({});
            context.saveMeetingsData({});
            context.saveRegInformation({});
            context.saveAllChildrenInReg([]);
            context.saveAllWorkdays([]);
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

// export const removeExpiredChildInRegFlow = (dataFromStorage, context) => {
//   if (dataFromStorage) {
//     if (isExpired(dataFromStorage)) {
//       console.log("Data in storage is expired!");
//       context.childInRegFlow(undefined);
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }

// If registration data expired, clear it, then get it again
export const getLatestChildInRegFlow = (context) => {
    const childInRegFlow = getChildInRegFlow();
    if (childInRegFlow) {
        if (removeExpiredData(childInRegFlow, context)) {
            return getChildInRegFlow();
        } else {
            return childInRegFlow;
        }
    } else {
        return undefined;
    }
};

export const getLatestRegInformation = (context) => {
    const regInformation = getRegInformation();
    if (regInformation) {
        if (removeExpiredData(regInformation, context)) {
            return getRegInformation();
        } else {
            return regInformation;
        }
    } else {
        return undefined;
    }
};

export const getLatestSimpleRegData = (context) => {
    const simpleRegData = getSimpleRegistrationData();
    if (simpleRegData) {
        if (removeExpiredData(simpleRegData, context)) {
            return getSimpleRegistrationData();
        } else {
            return simpleRegData;
        }
    } else {
        return {};
    }
};

// export const getLatestChildInRegFlow = (context) => {
//   const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
//   const childInRegFlow = dataFromLocalStorage.childInRegFlow
//   if (removeExpiredChildInRegFlow(childInRegFlow, context)) {
//     return getRegistrationData();
//   } else {
//     return childInRegFlow;
//   }
// }

// Only intro meeting registration data
export const getRegistrationData = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.registrationData;
};

export const getListOfParticipants = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    if (
        dataFromLocalStorage &&
        dataFromLocalStorage.currentUser &&
        dataFromLocalStorage.currentUser.participants
    ) {
        return dataFromLocalStorage.currentUser.participants;
    } else {
        return [];
    }
};

export const getLatestRegData = (context) => {
    const regData = getRegistrationData();
    console.log(regData);
    if (regData) {
        if (removeExpiredData(regData, context)) {
            console.log('here');
            return getRegistrationData();
        } else {
            console.log('here2');
            return regData;
        }
    } else {
        console.log('here3');
        return {};
    }
};

export const hasPrevPageDestination = (props) => {
    if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.from
    ) {
        return true;
    }
};

export const getPrevPageDestination = (props) => {
    if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.from &&
        props.location.state.from.title
    ) {
        return props.location.state.from.title;
    }
};

export const getPrevPageUrl = (props) => {
    if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.from &&
        props.location.state.from.path &&
        props.location.state.from.path !== ''
    ) {
        return props.location.state.from.path;
    }
};

export const getToken = (isFromSessionExpired) => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.token && userObj.tokenExpires) {
            if (isTokenValid()) {
                return userObj.token;
            } else {
                console.log('should redirect to session page');
                window.location.href = '/sessionen-udloebet';
            }
        }
    }
};

export const getTokenExpires = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.tokenExpires) {
            return userObj.tokenExpires;
        }
    }
};

export const isTokenValid = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    const token = userObj.token;
    const tokenExpires = userObj.tokenExpires;
    const now = moment();
    if (token && tokenExpires) {
        if (moment(now).isBefore(moment(tokenExpires))) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getCurrentUserInfo = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.currentUser;
};

export const getIsSubscriberUserFromDb = async (userId, handleError) => {
    const token = getToken();
    const isSubscriberFromDb = await getAndReturnDataWithAuth(
        `api/v1/user/${userId}/is-subscriber`,
        token,
        handleError
    );

    return isSubscriberFromDb;
};

// Get message list from database based on type and name filter
// If messageType=url is specified then this implies type html
// where url is used instead of name to find message
export const getMessageList = async (
    messageType,
    messageName,
    messageState,
    handleError,
    pathName
) => {
    const token = getToken(true);

    if (!messageType) messageType = 'html';
    if (!messageState) messageState = 'final';

    const res = await getAndReturnDataWithAuth(
        `/api/v1/message/list/${encodeURIComponent(
            messageType
        )}/${encodeURIComponent(messageName)}/${encodeURIComponent(
            messageState
        )}`,
        token,
        handleError
    );
    return res;
};

export const getCookie = (name) => {
    const cookieName = name + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return '';
};
