// Old version
// export function getLocalStorageJson( name, json ) {
//     let startTime = Date.now();
//     // Local storage provides 10MB were cookies are limited to 4093 bytes
//     if (typeof(Storage) === 'undefined') {
//         throw new Error('HTML5 local storage API not found, cant save any changes!');
//     }
//     // getItem return null if not found and then we return default json from input patameter
//     let data = localStorage.getItem(name);
//     if (data) {
//         data = JSON.parse(data);
//         let time = Date.now()-startTime;
//         if (time > 50) console.log('Get local storage json',name,'in',time,'ms');
//         // If server settings are newer than client settings use them, this will
//         // normally only happen first time, otherwise client is king, but if
//         // something went very wrong we might choose to refresh from server
//         if (json.timestamp > data.timestamp) {
//             // console.log('Default json timestamp is never',name,json.timestamp,'>',data.timestamp)
//             return json;
//         }
//         // console.log('Local storage json is never',name);
//         return data;
//     }
//     return json;
// }

export function getLocalStorageJson(name, json) {
    let startTime = Date.now();
    // Local storage provides 10MB were cookies are limited to 4093 bytes
    if (typeof Storage === 'undefined') {
        throw new Error(
            'HTML5 local storage API not found, cant save any changes!'
        );
    }
    // getItem return null if not found and then we return default json from input patameter
    let data = localStorage.getItem(name);
    if (data) {
        data = JSON.parse(data);
        let time = Date.now() - startTime;
        if (time > 50)
            if (json.timestamp > data.timestamp) {
                // console.log("Get local storage json", name, "in", time, "ms");
                // If server settings are newer than client settings use them, this will
                // normally only happen first time, otherwise client is king, but if
                // something went very wrong we might choose to refresh from server
                // console.log('Default json timestamp is never',name,json.timestamp,'>',data.timestamp)
                return json;
            }
        // console.log('Local storage json is never',name);
        return data;
    }
    return json;
}

export function setLocalStorageJson(name, json) {
    let startTime = Date.now();
    // Local storage provides 10MB were cookies are limited to 4093 bytes
    if (typeof Storage === 'undefined') {
        throw new Error(
            'HTML5 local storage API not found, cant save any changes!'
        );
    }

    json.timestamp = new Date().toJSON();
    let data = JSON.stringify(json);
    localStorage.setItem(name, data);
    // console.log(data);
    let time = Date.now() - startTime;
    if (time > 50)
        console.log('Set local storage json', name, 'in', time, 'ms');
}

// Helper functions
let localStorageKey = document.location.hostname + ':state-history';
export const getUser = () => {
    const data = getLocalStorageJson(localStorageKey, {});
    if (data) return data.currentUser;
    return undefined;
};

export const getFeatures = () => {
    const data = getLocalStorageJson(localStorageKey, {});
    if (data) return data.features;
    return undefined;
};

export const htmlToPlainText = (htmlString) => {
    const element = document.createElement('div');
    element.innerHTML = htmlString;
    return element.textContent || element.innerText;
};

export const areObjectsEqual = (obj1, obj2) => {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check if all properties and their values are equal
    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    // Objects are equal
    return true;
};

export const getSession = () => {
    const data = getLocalStorageJson(localStorageKey, {});
    if (data)
        return {
            token: data.currentUser.token,
            expires: data.currentUser.tokenExpires
        };
    return undefined;
};

export const hasValidSession = () => {
    const data = getLocalStorageJson(localStorageKey, {});
    if (data && data.session) {
        const now = new Date().getTime();
        if (
            data.currentUser.token &&
            new Date(data.currentUser.tokenExpires).getTime() > now
        )
            return true;
    }
    return false;
};

// Output can be: json, text, log or undefined returning response as is
export async function fetchWithTimeout(resource, options, output) {
    let log = output === 'log';
    // Make default options is not specied in options function argument
    if (!options) options = {};
    if (!options.mode) options.mode = 'cors';
    if (!options.headers) options.headers = {};
    if (!options.headers['Content-Type'])
        options.headers['Content-Type'] = 'application/json';
    // let contentType = options.headers['Content-Type'];
    // Upload files uses multipart/form-data, where the browser autos generate boundary in content-type
    // "Content-Type": "multipart/form-data; charset=utf-8;boundary=------WebKitFormBoundaryF0RBZBSar7PzOJpN",
    if (options.headers['Content-Type'].startsWith('multipart/form-data')) {
        console.log(
            'Deleted header Content-Type to allow browser to auto detect content type and add boundary for multipart/form-data'
        );
        delete options.headers['Content-Type'];
    }
    const { timeout = 10000, method = 'GET' } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const fetchOptions = {
        ...options,
        signal: controller.signal
    };
    if (log)
        console.log(
            'Fetch ' + method + ' ' + resource + ' with timeout ' + timeout
        );
    if (log) console.log('Options', JSON.stringify(fetchOptions, null, 2));
    const response = await fetch(resource, fetchOptions)
        .then(async (res) => {
            if (log) console.log(res);
            if (output === 'json') return await res.json();
            if (output === 'text') return await res.text();
            return res; // return raw read stream
        })
        .catch((err) => {
            console.log('FetchWithTimeoutError', err.message, ' on ', resource);
            return err;
        });
    clearTimeout(id);
    return response;
}

export const getLocationNameFromRoomId = (roomId) => {
    switch (Number(roomId)) {
        case 1:
            return 1;
        case 9:
            return 2;
        case 5:
            return 3;
        case 2:
            return 4;
        case 12:
            return 5;
        case 14:
            return 6;
        case 13:
            return 7;
        case 10:
            return 8;

        default:
            return 1;
    }
};
