import React from "react";
import ContentContainer from "../../components/containers/ContentContainer";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
import ReturnLinkReusable from "../../components/return-link/ReturnLinkReusable";

function MeetingsOverview(props) {
  return (
    <div>
      <ReusableHeader />
      <ContentContainer>
        <ReturnLinkReusable url="/min-side" destination="Min side" />
        <h2>Møde overblik</h2>
      </ContentContainer>
    </div>
  );
}

export default MeetingsOverview;
