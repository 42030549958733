import moment from 'moment';

export const getColorFromTheme = (color, props) => {
    if (color === 'blue') {
        return props.theme.palette.colors.blue;
    } else if (color === 'green') {
        return props.theme.palette.colors.green;
    } else if (color === 'orange') {
        return props.theme.palette.colors.orange;
    } else if (color === 'yellow') {
        return props.theme.palette.colors.yellow;
    } else if (color === 'purple') {
        return props.theme.palette.colors.purple;
    } else {
        return props.theme.palette.colors.green;
    }
};

// Removes white space from string
export const removeWhiteSpace = (value) => {
    if (value) {
        return value.replace(/^\s+/, '').replace(/\s+$/, '');
    }
};

export const capitalize = (str) => {
    if (str && str !== '') {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return '';
};

export const fetchWithTimeout = async (resource, options) => {
    try {
        const { timeout = 8000 } = options;

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });

        clearTimeout(id);

        if (response.status === 400) {
            console.log(await response.json());
        }

        return response;
    } catch (err) {
        console.log('error:', err);
    }
};

export const postAndReturnResp = async (resource, requestBody, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        if (handleError) {
            handleError();
        }
    }
};

export const postAndReturnRespWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: requestBody && JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        if (handleError) {
            handleError();
        }
    }
};

export const getAndReturnData = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',

            timeout: 8000
        });
        const data = await response.json();
        return data;
    } catch (error) {
        // Do something with error
        // console.log("Error loading resource " + resource, error);
        if (handleError) {
            handleError('');
        }
    }
};
export const getAndReturnResponse = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',

            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log('Error loading resource ' + resource, error);
        if (handleError) {
            handleError(error);
        }
    }
};

export const getAndReturnDataWithAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    // console.log("show res", resource);
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        const data = await response.json();
        return data;
    } catch (err) {
        // Log errors not related to WebPack proxy errors in development
        if (!err.message.startsWith('Error occurred while trying to proxy:')) {
            console.log(err);
        }
        if (handleError) handleError(err);
    }
};

export const getAndReturnDataAndResponse = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',

            timeout: 8000
        });
        const data = await response.json();
        const result = { data: data, response: response };
        return result;
    } catch (error) {
        // Do something with error
        handleError(error);
    }
};

export const getAndReturnDataAndResponseWithAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        const data = await response.json();
        const result = { data: data, response: response };
        return result;
    } catch (error) {
        // Do something with error
        handleError(error);
    }
};

export const postAndReturnData = async (resource, requestBody, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });

        const data = await response.json();
        return data;
    } catch (error) {
        // Do something with error
        console.log(error);
        // handleError();
    }
};

export const postAndReturnDataWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });

        const data = await response.json();
        return data;
    } catch (error) {
        // Do something with error
        console.log(error);
    }
};

export const postAndReturnResponseWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });

        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        if (handleError) {
            handleError();
        }
    }
};

export const postAndReturnDataAndResponse = async (
    resource,
    requestBody,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        // Here I changed from const data = await response.json(), because it gave an error.
        const data = response;
        const result = { data: data, response: response };
        return result;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError('Server Error: something went wrong');
    }
};

export const postTokenAuthorization = async (
    resource,
    requestBody,
    headerAuth,
    setError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        console.log(error);
        // if (setError && setErrorMessage) {
        //   handleRegisterErrors(error, setError, setErrorMessage);
        // }
    }
};

export const patchAndReturnResp = async (
    resource,
    requestBody,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PATCH',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        // Do something with error
        console.log(error);
        // handleError();
    }
};

export const patchAndReturnData = async (
    resource,
    requestBody,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PATCH',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        if (response.status === 200) {
            return response.json();
        }
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnResp = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'text/plain'
            },
            // mode: "cors",
            // cache: "no-cache",
            // credentials: "same-origin",
            timeout: 8000
        });
        if (response !== undefined && response.status === 200) {
            return response;
        }
    } catch (error) {
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnRespWithAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        // if (response.status === 200) {
        //   return response;
        // }
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnRespWithNewData = async (resource, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            timeout: 8000
        });

        const data = await response.json();
        const result = { data: data, response: response };
        return result;
        // if (response.status === 200) {
        //   return response;
        // }
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const deleteAndReturnRespWithNewDataAuth = async (
    resource,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });

        const data = await response.json();
        const result = { data: data, response: response };
        return result;
        // if (response.status === 200) {
        //   return response;
        // }
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const putAndReturnResp = async (resource, requestBody, handleError) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const putTokenAuthorization = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

export const putAndReturnRespWithAuth = async (
    resource,
    requestBody,
    headerAuth,
    handleError
) => {
    try {
        const response = await fetchWithTimeout(resource, {
            method: 'PUT',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${headerAuth}`
            },
            timeout: 8000
        });
        return response;
    } catch (error) {
        // Do something with error
        console.log(error);
        handleError();
    }
};

// If email is valid it returns true
export const validateEmail = (email) => {
    // eslint-disable-next-line
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email) && email.length < 250) {
        return true;
    } else {
        return false;
    }
};

export const isSignupForNextSeason = () => {
    const now = moment(Date.now());
    const currentMonth = moment(now).format('MMM');
    const currentYear = moment(now).format('YYYY');
    // Is user in the first semester?
    const isUserInFirstSemester = (currentMonth) => {
        switch (currentMonth) {
            case 'jan':
                return false;
            case 'feb':
                return false;
            case 'mar':
                return false;
            case 'apr':
                return false;
            case 'naj':
                return false;
            case 'jun':
                return false;
            case 'jul':
                return true;
            case 'aug':
                return true;
            case 'sep':
                return true;
            case 'okt':
                return true;
            case 'nov':
                return true;
            case 'dec':
                return true;
            default:
                return false;
        }
    };

    // Come back: We should keep the semester where signup is available in the env file.
    // For now its just hardcoded
    // const currentSemester = process.env.DLM_SIGNUP_OPEN_FOR_THIS_SEMESTER;
    const currentSeason = '2022/2023';
    const firstSemesterYear = currentSeason.slice(0, 4);
    // const secondSemesterYear = currentSeason.slice(5, 9);
    const isInFirstSemester = isUserInFirstSemester(currentMonth);

    if (isInFirstSemester) {
        if (firstSemesterYear === currentYear) {
            return true;
        }
    }
};
