import React from "react";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import RegularButton from "../buttons/RegularButton/RegularButton";
import concertImg from "../../assets/global-events-images/event-concert-desktop.jpg";
import meetingImg from "../../assets/global-events-images/event-meeting-desktop.jpg";
import workdayImg from "../../assets/global-events-images/event-workday-desktop.jpg";
// import workdayImg from "../../assets/global-events-images/event-workday-desktop.jpg";

const { overridings } = require("../../themes/DarkTheme");
const handleColorType = (props) => {
  switch (props) {
    case "concert":
      return `${overridings.palette.colors.purple}`;
    case "workday":
      return `${overridings.palette.colors.green}`;
    case "meeting":
      return `${overridings.palette.colors.yellow}`;
    default:
      return `${overridings.palette.colors.blue}`;
  }
};
const EventDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10%;
  margin-top: 3%;
`
const EventImageContainer = styled.div`
  width:40%;
  max-width:40%;
`
const EventImg = styled.img`
  max-width: 100%;
  width: 100%;
`
const EventInformationContainer = styled.div`
  width:40%;
  max-width:40%;
  display: flex;
  flex-direction:column;
  justify-content: center;
`
const EventTitle = styled(Typography)`
  font-size: 1.5rem;
  margin: 0px 0px 15px 0px;
  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
`
const CloseEventDetails = styled.div`
  cursor: pointer;
`
const EventTime = styled(Typography)`
  margin: 0px;
  @media (max-width: 1200px) {
      font-size:0.75rem;
  }
`
const EventDetailsHorizontalLine = styled.hr`
  height: 2px;
  background-color: ${props => props.color};
  border: none;
  width: 7rem;
  margin: 4px 0px;
`
const EventContent = styled(Typography)`
  padding: 10px 0px;
`
const EventLDetailsLinks = styled.div``
const RegularButtonContainer = styled.div`
  width: 40%;
  display: inline-block;
  margin: 1rem 1rem 0 0;
  font-size: 0.9rem;
  @media (max-width: 1200px) {
    font-size:0.8rem;
  }
`
const AddToCalendar = styled.a`
  width: 40%;
  margin: 1rem 1rem 0px 0px;
  padding: 3px 15px;
  text-decoration-line: underline;
  cursor: pointer;
  color:  ${props => props.color};
  font-size: 0.9rem;
  @media (max-width: 1200px) {
    font-size:0.8rem;
    margin: 0px;
  }
`

function GlobalEventDetails(props) {
  function SwitchCase(props) {
    switch (props.value) {
      case 'concert':
        return <EventImg src={concertImg} alt={`${props.eventTitle} event image`} />;
      case 'workday':
        return <EventImg src={workdayImg} alt={`${props.eventTitle} event image`} />;
      case 'meeting':
        return <EventImg src={meetingImg} alt={`${props.eventTitle} event image`} />;
      default:
        return <EventImg src={workdayImg} alt={`${props.eventTitle} event image`} />;
    }
  }
  // Føj til kaledar
  const downloadICal = (eventDetails) => {
    const eventInfo = {
      title: eventDetails.title,
      location: 'Den Lille Musikskole, Bülowsvej 10, Frederiksberg, 1870,Danmark',
      description: `Room number: ${eventDetails.roomId}`,
      start: [
        moment(eventDetails.startdate).year(),
        moment(eventDetails.startdate).add({ M: 1 }).month(),
        moment(eventDetails.startdate).date(),
        moment(eventDetails.starttime, "HH:mm:ss").hour(),
        moment(eventDetails.starttime, "HH:mm:ss").minute(),
        moment(eventDetails.starttime, "HH:mm:ss").second()
      ]
      ,
      end: [
        moment(eventDetails.startdate).year(),
        moment(eventDetails.startdate).add({ M: 1 }).month(),
        moment(eventDetails.startdate).date(),
        moment(eventDetails.enddate, "HH:mm:ss").hour(),
        moment(eventDetails.enddate, "HH:mm:ss").minute(),
        moment(eventDetails.enddate, "HH:mm:ss").second()
      ]
    }
    // Save event - create iCalendar file, send event in body
    fetch("/api/v1/calendar_download", {
      method: "POST",
      body: JSON.stringify(eventInfo),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //After event is saved we should get status 200 back if saving was successfull.
      // Then we want to donwload the event file
    }).then((resp) => {
      if (resp.status === 200) {
        // Make another request: GET request to /calendar_download
        // This cannot be done by fetch, as the window won't be prompted to download the file
        window.location = "/api/v1/calendar_download";
      } else {
        console.log("error");
      }
    });
  };

  const handleRegularButtonClick = () => {
    props.history.push('/login');
  }
  const eventTime = `${moment(props.eventDetails.startdate).format('dddd [d. ] Do MMMM YYYY')}`;
  return (
    <EventDetailsContainer>
      <EventImageContainer>
        <SwitchCase value={props.eventDetails.type} eventTitle={props.eventDetails.title} />
      </EventImageContainer>
      <EventInformationContainer>
        <EventTitle>{props.eventDetails.title}</EventTitle>
        <EventTime>{eventTime.charAt(0).toUpperCase() + eventTime.slice(1)}</EventTime>
        <EventTime>
        kl. {(props.eventDetails.starttime).slice(0, 2)}.{(props.eventDetails.starttime).slice(3, 5)} - {(props.eventDetails.endtime).slice(0, 2)}.{(props.eventDetails.endtime).slice(3, 5)}
         </EventTime>
        <EventDetailsHorizontalLine color={handleColorType(props.eventDetails.type)} />
        <EventContent>{props.eventDetails.content}Consectetur eiusmod laborum tempor consectetur reprehenderit cillum sint velit. Culpa ut dolore occaecat deserunt. Et velit commodo tempor Lorem pariatur nulla adipisicing adipisicing amet pariatur ut dolor. Culpa aliqua magna eu cillum qui excepteur excepteur deserunt.</EventContent>
        <EventLDetailsLinks>
          <RegularButtonContainer>
            <RegularButton color={() => handleColorType(props.eventDetails.type)} title="Deltag" size="small" onButtonClick={handleRegularButtonClick} >Deltag</RegularButton>
          </RegularButtonContainer>
          <AddToCalendar color={handleColorType(props.eventDetails.type)} onClick={() => downloadICal(props.eventDetails)}>+ Tilføj til kalender</AddToCalendar>
        </EventLDetailsLinks>
      </EventInformationContainer>
      <CloseEventDetails onClick={() => props.closeOpenEvent()}>
        <CloseIcon style={{ fontSize: '20px' }} />
      </CloseEventDetails>
    </EventDetailsContainer>
  )

}
export default withRouter(GlobalEventDetails);