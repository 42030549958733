import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useRef
} from 'react';
import { Context } from '../../../context/Context';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import {
    getAllChildrenInReg,
    getAuthentication,
    getChildInRegFlow,
    getLatestChildInRegFlow,
    getMeetingsData,
    getSelectedIntroMeeting
} from '../../../context/getData';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';

import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import MeetingBar from '../../../components/bars/meeting-bar/MeetingBar';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';

import IntroMeetingDescription from './IntroMeetingDescription';
import ExtraParentMeetingDescription from './ExtraParentMeetingDescription';
import ParentMeetingDescription from './ParentMeetingDescription';
import {
    isBirthdateValid,
    isCourseSelected,
    isProcessSelected
} from '../GlobalRegistrationFunctions';
import {
    getDateString,
    getDayName,
    getTimeString,
    recoverMeetings,
    recoverSelectElement
} from './ChooseMeetingsFunctions';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import moment from 'moment';

const options = [
    { value: 'not selected', title: 'Vælg' },
    { value: 'now', title: 'Vælg nu' },
    { value: 'later', title: 'Vælg senere' }
];

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const SelectComponent = styled(Select)`
    margin: 1rem 1rem 0 0;
    height: 2.8rem;
    border-radius: 0;
    border: ${(props) =>
        props.error
            ? props.theme.palette.error.main
            : `1px solid ${props.theme.palette.primary.contrastText}`};

    & div {
        width: 100%;
        min-width: 10rem;
    }

    & .MuiSelect-select {
        background-color: ${(props) =>
            props.option !== 'not selected' &&
            props.theme.palette.colors.purple};
        padding-bottom: 0.8rem;
        padding-top: 0.7rem;
    }

    & .MuiSelect-select:focus {
        padding-bottom: 0.8rem;
        padding-top: 0.7rem;
    }
`;

function ChooseMeetings(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [option, setOption] = useState('not selected');
    const [optionSelected, setOptionSelected] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [allIntroMeetings, setAllIntroMeetings] = useState([]);
    // eslint-disable-next-line
    const [allParentMeetings, setAllParentMeetings] = useState([]);
    // eslint-disable-next-line
    const [allExtraParentMeetings, setAllExtraParentMeetings] = useState([]);
    const [selectedIntroMeeting, setSelectedIntroMeeting] = useState({});
    const [selectedParentMeetings, setSelectedParentMeetings] = useState([]);
    const [
        selectedExtraParentMeetings,
        setSelectedExtraParentMeetings
    ] = useState([]);
    const [selectError, setSelectError] = useState(false);

    const topRef = useRef();
    const parentMeetingRef = useRef();
    const introMeetingRef = useRef();

    const context = useContext(Context);

    const handleOptionChange = (e) => {
        setSelectError(false);
        setOption(e.target.value);
        if (e.target.value === 'now') {
            setOptionSelected(true);
        } else {
            setOptionSelected(false);
            // Remove selected parent meeting data
            setSelectedParentMeetings([]);
            setSelectedExtraParentMeetings([]);
        }
    };

    const handleIntroMeetingSelect = (meetingObject) => {
        // In case user is only allowed to select one
        setSelectedIntroMeeting(meetingObject);
    };

    const handleParentMeetingSelect = (meetingObject, type) => {
        // If it is a fastlagt parent meeting, they should only be able to select one meeting
        if (type !== 'parentMeeting') {
            const selectedParentMeetingsCopy = [...selectedParentMeetings];
            // If user is able to choose more than one meeting in case of extra parent meeting

            if (
                selectedParentMeetings.find(
                    (meeting) => meeting.id === meetingObject.id
                )
            ) {
                const filteredMeetings = selectedParentMeetingsCopy.filter(
                    (meeting) => meetingObject.id !== meeting.id
                );
                setSelectedParentMeetings(filteredMeetings);
            } else {
                selectedParentMeetingsCopy.push(meetingObject);
                setSelectedParentMeetings(selectedParentMeetingsCopy);
            }
        } else {
            setSelectedParentMeetings([meetingObject]);
        }
    };

    // Add or remove clicked extra parent meeting to state
    const handleExtraParentMeetingSelect = (meetingObject) => {
        const selectedExtraParentMeetingsCopy = [
            ...selectedExtraParentMeetings
        ];

        if (
            selectedExtraParentMeetings.find(
                (meeting) => meeting.id === meetingObject.id
            )
        ) {
            const filteredMeetings = selectedExtraParentMeetingsCopy.filter(
                (meeting) => meetingObject.id !== meeting.id
            );
            setSelectedExtraParentMeetings(filteredMeetings);
        } else {
            selectedExtraParentMeetingsCopy.push(meetingObject);
            setSelectedExtraParentMeetings(selectedExtraParentMeetingsCopy);
        }
    };

    const setScrollView = () => {
        // If there is error in parent meeting
        if (
            errorMessage ===
            'Vælg, om du vil vælge forældremøde nu eller senere'
        ) {
            parentMeetingRef.current.scrollIntoView();
        } else {
            introMeetingRef.current.scrollIntoView();
        }
    };

    const saveAndSubmit = () => {
        const selectedMeetingsData = {
            selectedParentMeetings,
            selectedExtraParentMeetings: selectedExtraParentMeetings
        };
        const childInRegFlow = getChildInRegFlow();
        // Only parent and extra parent meetings, because intro meetings are saved outside childInRegflow
        childInRegFlow.selectedMeetings = selectedMeetingsData;
        childInRegFlow.selectParentMeetingValue = option;
        context.saveChildInRegFlow(childInRegFlow);

        context.saveSelectedIntroMeeting(selectedIntroMeeting);
        if (isAuthenticated) {
            props.history.push('/tilmelding/kasse');
        } else {
            props.history.push('/tilmelding/oplysninger');
        }
    };

    const onSubmit = () => {
        // Check for errors and missing selects
        // If user is authenticated, they don't need to add intro meeting (-clarify this), so we check for the select element for parent meeting.
        if (isAuthenticated) {
            if (option === 'not selected') {
                // Come back: danish text
                setError(true);
                setErrorMessage(
                    'Vælg, om du vil vælge forældremøde nu eller senere.'
                );
                setSelectError(true);
                // Come back: if you change this message, you'll also need to change it at setScrollView function!
            } else {
                saveAndSubmit();
            }
        } else {
            if (
                allIntroMeetings.length !== 0 &&
                Object.entries(selectedIntroMeeting).length < 1
            ) {
                setError(true);
                setErrorMessage('Du har ikke valgt introduktionsmøde.');
                // Maybe set scroll position for the height of intro meeting select?
            } else {
                saveAndSubmit();
            }
        }
    };

    // Get meetings from local storage to list them
    const getMeetings = useCallback(() => {
        const meetingsData = getMeetingsData();

        if (meetingsData) {
            if (Object.keys(meetingsData).length) {
                const parentMeetings = meetingsData.parentMeetings;
                const introMeetings = meetingsData.introMeetings;
                const extraParentMeetings = meetingsData.extraParentMeetings;

                // Sort meetings according to date
                introMeetings.sort((a, b) => {
                    const dateA = moment(a.startdate);
                    const dateB = moment(b.startdate);
                    return dateA.diff(dateB);
                });

                parentMeetings.sort((a, b) => {
                    const dateA = moment(a.startdate);
                    const dateB = moment(b.startdate);
                    return dateA.diff(dateB);
                });
                setAllParentMeetings(parentMeetings);
                setAllIntroMeetings(introMeetings);
                setAllExtraParentMeetings(extraParentMeetings);
            }
        }

        // Scroll to content
        topRef.current.scrollIntoView();
    }, []);

    // Check if user has chosen options in the previous registration steps
    // Load data if user has already filled meetings
    // ComponentDidMount
    useEffect(() => {
        if (isBirthdateValid() && isCourseSelected() && isProcessSelected()) {
            //Check if there is chosen meeting data saved in localstorage, if yes, apply.
            const childInRegFlow = getLatestChildInRegFlow(context);
            const selectedIntroMeetingFromStorage = getSelectedIntroMeeting();
            if (childInRegFlow) {
                recoverMeetings(
                    childInRegFlow,
                    selectedIntroMeetingFromStorage,
                    setSelectedIntroMeeting,
                    setSelectedParentMeetings,
                    setSelectedExtraParentMeetings
                );
                recoverSelectElement(
                    childInRegFlow,
                    setOption,
                    setOptionSelected
                );
                getMeetings();
            }
        } else {
            // If childInRegFlow is empty, or birthdate or course are not selected,
            // we should check if there are other children already in allChildrenInRegFlow.
            // If there are, we should redirect user to checkout page.
            if (getAllChildrenInReg() && getAllChildrenInReg().length > 0) {
                props.history.push('/tilmelding/kasse');
            } else {
                props.history.push('/tilmelding/foedselsdag');
            }
        }
        // eslint-disable-next-line
    }, [props.history, getMeetings]);

    // Get authentication
    useEffect(() => {
        getAuthentication(setIsAuthenticated);
    }, [context.state.currentUser]);

    // come back: danish text
    return (
        <div>
            <NavHeaderDesktop />
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <span ref={topRef}></span>
            <RegistrationContentContainer
                marginBottom="2rem"
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                <ProgressBar currentStage={'Møder'} />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/vaelg-forloeb"
                        destination="Forløb"
                    />
                </Box>
            </RegistrationContentContainer>
            {/* {!isAuthenticated && ( */}
            <div>
                <span ref={introMeetingRef}></span>
                <RegistrationContentContainer
                    marginTop="1rem"
                    marginBottom="1.5rem"
                >
                    <IntroMeetingDescription
                        isAuthenticated={isAuthenticated}
                    />
                </RegistrationContentContainer>
                {/* Come back:  what to do if no meetings are, and the page doesn't let you continue because you must choose something!! */}
                {/* Display meetings if there are any */}
                {allIntroMeetings.length === 0 ? (
                    <RegistrationContentContainer>
                        <DescriptionText variant="body2">
                            Der er ingen intromøder planlagt lige nu, henvend
                            dig venligt til kontoret.
                        </DescriptionText>
                    </RegistrationContentContainer>
                ) : (
                    <React.Fragment>
                        {allIntroMeetings.length > 0 ? (
                            <React.Fragment>
                                {allIntroMeetings // Show first two intro meetings
                                    .filter((meeting, index) => index < 2)
                                    .map((meeting) => (
                                        <MeetingBar
                                            meetingData={meeting}
                                            key={meeting.id}
                                            id={meeting.id}
                                            title={meeting.title}
                                            date={`${getDayName(
                                                meeting.startdate
                                            )} d. ${getDateString(
                                                meeting.startdate
                                            )}`}
                                            time={getTimeString(
                                                meeting.startdate,
                                                meeting.enddate
                                            )}
                                            onSelect={handleIntroMeetingSelect}
                                            selectedMeeting={
                                                selectedIntroMeeting
                                            }
                                        />
                                    ))}
                            </React.Fragment>
                        ) : (
                            <RegistrationContentContainer>
                                <DescriptionText variant="body2">
                                    Der er ingen introduktionsmøde lige nu.
                                </DescriptionText>
                            </RegistrationContentContainer>
                        )}
                        {/* For testing if only one meeting is coming from db */}
                        {/* <MeetingBar
                id="1"
                title="My meeting"
                date="2021-01-07"
                time="2021-02-22 12:22"
                onSelect={handleIntroMeetingSelect}
                selectedMeeting={selectedIntroMeeting}
              />
              <MeetingBar
                id="2"
                title="My meeting"
                date="2021-01-07"
                time="2021-02-22 12:22"
                onSelect={handleIntroMeetingSelect}
                selectedMeeting={selectedIntroMeeting}
              />
              <MeetingBar
                id="3"
                title="My meeting"
                date="2021-01-07"
                time="2021-02-22 12:22"
                onSelect={handleIntroMeetingSelect}
                selectedMeeting={selectedIntroMeeting}
              /> */}
                    </React.Fragment>
                )}
            </div>
            {/* )} */}
            <RegistrationContentContainer>
                <span ref={parentMeetingRef}></span>
                <ParentMeetingDescription />

                <FormControl variant="outlined" error={selectError}>
                    <SelectComponent
                        onChange={(e) => handleOptionChange(e)}
                        name="option"
                        value={option}
                        option={option}
                        error={selectError}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.title}
                            </MenuItem>
                        ))}
                    </SelectComponent>
                </FormControl>
            </RegistrationContentContainer>
            {/* Display parent and extraparent meetings if user wants to select now. */}
            {optionSelected && (
                <React.Fragment>
                    {/* Display meetings if there are any */}

                    <React.Fragment>
                        {allParentMeetings.length > 0 ? (
                            <React.Fragment>
                                {allParentMeetings.map((meeting) => (
                                    <MeetingBar
                                        key={meeting.id}
                                        id={meeting.id}
                                        title="Fastlagt Følgevoksenmøde"
                                        date={getDateString(meeting.startdate)}
                                        time={getTimeString(
                                            meeting.startdate,
                                            meeting.enddate
                                        )}
                                        // extraTextGreen="Afholdes uanset hvad"
                                        onSelect={handleParentMeetingSelect}
                                        height="12rem"
                                        selectedMeetings={
                                            selectedParentMeetings
                                        }
                                        type="parentMeeting"
                                    />
                                ))}
                            </React.Fragment>
                        ) : (
                            <RegistrationContentContainer>
                                <DescriptionText variant="body2">
                                    Der er ingen oplagt forældremøde lige nu.
                                </DescriptionText>
                            </RegistrationContentContainer>
                        )}
                    </React.Fragment>

                    <RegistrationContentContainer>
                        <ExtraParentMeetingDescription />
                    </RegistrationContentContainer>

                    <React.Fragment>
                        {allExtraParentMeetings.length > 0 ? (
                            <React.Fragment>
                                {allExtraParentMeetings.map((meeting) => (
                                    <MeetingBar
                                        key={meeting.id}
                                        id={meeting.id}
                                        title="Potentielt følgevoksenmøde"
                                        date={getDateString(meeting.startdate)}
                                        time={getTimeString(
                                            meeting.startdate,
                                            meeting.enddate
                                        )}
                                        onSelect={
                                            handleExtraParentMeetingSelect
                                        }
                                        numberOfSignups={
                                            meeting.numberOfSignedUp
                                        }
                                        height="12rem"
                                        selectedMeetings={
                                            selectedExtraParentMeetings
                                        }
                                        type="extraParentMeeting"
                                    />
                                ))}
                            </React.Fragment>
                        ) : (
                            <RegistrationContentContainer>
                                <DescriptionText variant="body2">
                                    Der er ingen ekstra forældremøde lige nu.
                                </DescriptionText>
                            </RegistrationContentContainer>
                        )}

                        {/* <MeetingBar
              id="2"
              title="My meeting"
              date="2021-01-07"
              time="2021-02-22 12:22"
              selectedMeeting={selectedIntroMeeting}
              onSelect={handleExtraParentMeetingSelect}
              numberOfSignups={12}
              height="12rem"
              selectedMeetings={selectedExtraParentMeetings}
              type="extraParentMeeting"
            />
            <MeetingBar
              id="3"
              title="My meeting"
              date="2021-01-07"
              time="2021-02-22 12:22"
              selectedMeeting={selectedIntroMeeting}
              onSelect={handleExtraParentMeetingSelect}
              numberOfSignups={12}
              height="12rem"
              selectedMeetings={selectedExtraParentMeetings}
              type="extraParentMeeting"
            />
            <MeetingBar
              id="4"
              title="My meeting"
              date="2021-01-07"
              time="2021-02-22 12:22"
              selectedMeeting={selectedIntroMeeting}
              onSelect={handleExtraParentMeetingSelect}
              numberOfSignups={12}
              height="12rem"
              selectedMeetings={selectedExtraParentMeetings}
              type="extraParentMeeting"
            /> */}
                    </React.Fragment>
                    {/* )} */}
                </React.Fragment>
            )}
            <RegistrationContentContainer>
                <RegistrationButton onButtonClick={onSubmit} />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/vaelg-forloeb"
                        destination="Forløb"
                    />
                </Box>
            </RegistrationContentContainer>
            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => {
                        setError(false);
                    }}
                    setScrollView={setScrollView}
                />
            )}
        </div>
    );
}

export default withRouter(ChooseMeetings);
