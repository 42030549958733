import React from "react";
import styled from "styled-components";

const AudioComp = styled.audio`
  width: ${(props) => props.width};
  color: ${props => props.theme.palette.error.main};
  margin-top: 1rem;
`;

function AudioComponent({ width, src, type }) {
  return (
    <AudioComp controls width={width}>
      {/* <source src="horse.ogg" type="audio/ogg" /> */}
      <source src={src} type={type} />
      Your browser does not support the audio element.
    </AudioComp>
  );
}

export default AudioComponent;
