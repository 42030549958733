import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const ExplainerTextComponent = styled(Typography)`
  margin-bottom: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    & ul {
      padding-left: 1.5rem;
      margin-bottom: 3rem;
    }

    
  }
`;

function ExplainerText({ children }) {
  return (
    <ExplainerTextComponent variant="body2">{children}</ExplainerTextComponent>
  );
}

export default ExplainerText;
