import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavHeadline from "../../headlines/NavHeadline";

const NavContainerComponent = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  width: max-content;
  align-content: flex-end;
  
`;

function NavContainerDesktop(props) {
  return (
    <NavContainerComponent >
      <Link to="/tilmelding/tilmeldinstype">
        <NavHeadline title="Tilmelding" />
      </Link>
      <Link to="/undervisning">
        <NavHeadline title="Om undervisning" />
      </Link>
      <Link to="/om-skolen/vision">
        <NavHeadline title="Om skolen" />
      </Link>
    </NavContainerComponent>
  );
}

export default NavContainerDesktop;
