import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const ContentContainerComponent = styled(Box)`
  width: 85%;
  margin: ${(props) => {
    if (props.margintop) {
      return `${props.margintop} auto 4rem auto`;
    } else {
      return "4rem auto";
    }
  }};
`;

function ContentContainer({ children, marginTop }) {
  return (
    <ContentContainerComponent margintop={marginTop}>
      {children}
    </ContentContainerComponent>
  );
}

export default ContentContainer;
