import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RegistrationContentContainer from "../../../components/containers/registration/RegistrationContentContainer";
import RegularHeadline from "../../../components/headlines/RegularHeadline";

const CommentSectionContainer = styled(Box)`
  display: flext;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

const DescriptionText = styled(Typography)`
  margin-bottom: 1.2rem;
`;

const TextFieldContainer = styled.div`
  width: 45%;
  max-width: 35rem;
  height: 10rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const CustomTextField = styled.textarea`
  width: 100%;

  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 1rem;
  font-family: ${(props) => props.theme.typography.fontFamily};

  // Is this needed? Or shall I just leave the focus as it is currently - blue?
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.palette.colors.purple};
  }
`;

const CommentSection = React.memo(({ comment, setComment }) => {
  const [value, setValue] = useState();

  const handleCommentChange = (event) => {
    setValue(event.target.value);
  };

  //Send comment data on blur
  // If we sent/changed data on onChange, the whole parent component would rerender on change, which leads to performance issues
  const handleBlur = () => {
    setComment(value);
  };

  useEffect(() => {
    setValue(comment);
  }, [comment]);

  return (
    <RegistrationContentContainer>
      <CommentSectionContainer>
        <Box>
          <RegularHeadline title="Tilmeldingskommentarer" component="h3" />
          <DescriptionText variant="body2">
            Fugiat do cupidatat fugiat tempor irure.
          </DescriptionText>
        </Box>

        <TextFieldContainer>
          <CustomTextField
            onBlur={() => handleBlur()}
            placeholder="Kære..."
            rows="10"
            value={value}
            onChange={(e) => {
              handleCommentChange(e);
            }}
          />
        </TextFieldContainer>
      </CommentSectionContainer>
    </RegistrationContentContainer>
  );
});

export default CommentSection;
