import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Typography,
    Box,
    MenuItem,
    Select,
    FormControl
} from '@material-ui/core';

const CourseBarContainer = styled(Box)`
    width: 100%;
    background-color: ${(props) => props.theme.palette.secondary.main};
`;

const CourseBarTextContainer = styled.div`
    margin-bottom: 1rem;
`;

const SelectComponent = styled(Select)`
    margin: 0 1rem 0 0;
    height: 2.8rem;
    border-radius: 0;
    border: 1px solid white;
    & div {
        width: 100%;
        min-width: 10rem;
    }
    & .MuiSelect-select:focus {
        padding-bottom: 0.8rem;
        padding-top: 0.7rem;
    }
`;

const SelectContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: static;
        top: 0;
        left: 0;
        transform: none;
    }
`;

const CourseBarContentContainer = styled.div`
    width: 85%;
    margin: 0 auto 1rem auto;
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const CourseBarTextContainerOuter = styled(Box)`
    width: 70%;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const SelectContainerOuter = styled(Box)`
    width: 30%;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
        padding-bottom: 2rem;
    }
`;

// Value should be checked according to db
const priorities = [
    { value: 'not selected', title: 'Vælg prioritet' },
    { value: 'first priority', title: 'Første prioritet' },
    { value: 'second priority', title: 'Anden prioritet' }
];

// This should be clarified
// In Administrations håndbog it says that max number of students in a class is 14.
// However there are certain cases where there can be more children, and where there should be less.
// So either the status of course (hold) is changed when it reached the desired number of students manually
// Or we create a new column/property for courses, where the max number of students are specified.

function CourseBar({ data, getPriority, selectedPriorities }) {
    const [courseData, setCourseData] = useState([]);
    const [courseDataUpdated, setCourseDataUpdated] = useState(false);
    const [priority, setPriority] = useState('not selected');

    // Get price per lesson for basic registration

    const getBasicPrice = (data) => {
        let result;
        if (!data.options) console.log('Missing options', data);
        const basicOption = data.options.find(
            (d) => d.name === 'Basistilmelding'
        );
        // Come back: Need to clarify what is an existing customer, employee, etc.
        // For now we'll just use new customer in all cases until it's cleared.
        const newCustomerPriceOption = basicOption.priceOptions.find(
            (option) => option.name === 'new_customer'
        );
        if (newCustomerPriceOption) {
            result = newCustomerPriceOption.priceOfLesson;
        } else {
            console.log('There is an error with the course price!');
            return 0;
        }

        // let basicOption, basicPriceOption, result;
        // if (!data.options) {
        //   console.log("Missing options", data);
        //   return
        // }
        // else {
        //   basicOption = data.options.find((d) => d.name === "Basistilmelding");
        // }
        // if (!basicOption.priceOptions) {
        //   console.log("Missing price options", basicOption);
        //   return;
        // }
        // else {
        //   console.log(basicOption.priceOptions)
        //   basicPriceOption = basicOption.priceOptions.find(
        //     (d) => d.name === "discount-none"
        //   );
        //   if (basicPriceOption)
        //   result= basicPriceOption.priceOfLesson;
        // }
        return result;
    };

    // Get the number of available space for class, or the number of students on waitinglist
    const getAvailableSpace = (data) => {
        // Check if status is open
        if (data.status === 'open') {
            // Check if there are at least 6 people
            if (data.registered < 6) {
                return `${data.registered} tilmeldte, Holdet oprettes ved ${data.min_students} tilmeldte`;
            } else {
                const freeSpaces = data.max_students - data.registered;
                return `${freeSpaces} ledige pladser`;
            }
        } else {
            return `Ingen ledige pladser, ${data.waitinglist} på venteliste`;
        }
    };

    const handlePriorityChange = (e) => {
        setPriority(e.target.value);
        getPriority({ courseId: data.id, course: data, type: e.target.value });
    };

    useEffect(() => {
        setCourseData(data);
        setCourseDataUpdated(true);
    }, [data]);

    useEffect(() => {
        // If priorities course id matches with data id, then make the priority
        if (selectedPriorities && data) {
            const matchingCourse = selectedPriorities.filter(
                (prio) => prio.courseId === data.id
            );
            if (matchingCourse.length > 0) {
                setPriority(matchingCourse[0].type);
            }
        }
    }, [data, selectedPriorities]);

    return (
        <CourseBarContainer>
            {data && (
                <CourseBarContentContainer>
                    {courseDataUpdated && (
                        <CourseBarTextContainerOuter>
                            <CourseBarTextContainer>
                                <Typography variant="body2">
                                    {courseData.day} kl. {courseData.time}
                                </Typography>
                                <Typography variant="body2">
                                    Lærer: {courseData.teacher}
                                </Typography>
                            </CourseBarTextContainer>
                            <CourseBarContainer>
                                <Typography variant="body2">
                                    Pris: fra DKK {getBasicPrice(courseData)}{' '}
                                    per lektion
                                </Typography>
                            </CourseBarContainer>

                            <CourseBarTextContainer>
                                <Typography variant="body2">
                                    {getAvailableSpace(courseData)}
                                </Typography>
                            </CourseBarTextContainer>
                        </CourseBarTextContainerOuter>
                    )}

                    <SelectContainerOuter>
                        <SelectContainer>
                            <FormControl variant="outlined">
                                <SelectComponent
                                    onChange={(e) => handlePriorityChange(e)}
                                    name="priority"
                                    value={priority}
                                >
                                    {priorities.map((priority) => (
                                        <MenuItem
                                            key={priority.value}
                                            value={priority.value}
                                        >
                                            {priority.title}
                                        </MenuItem>
                                    ))}
                                </SelectComponent>
                            </FormControl>
                        </SelectContainer>
                    </SelectContainerOuter>
                </CourseBarContentContainer>
            )}
        </CourseBarContainer>
    );
}

export default CourseBar;
