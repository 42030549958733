import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { getColorFromTheme } from "../../containers/HelperFunctions";
import { Box, Typography } from '@material-ui/core';

import RegularLink from '../links/RegularLink';
import PersonIcon from '@material-ui/icons/Person';

// this should be included in this container
/* color: ${(props) => getColorFromTheme(props.color, props)}; */
const ChildInfoContainer = styled(Box)`
    margin-right: 2rem;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 50%;
        margin-right: 0;
    }
`;

const CourseInfoContainer = styled(Box)`
    position: relative;
    top: 4px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-left: 1rem;
        width: 50%;
    }
`;

// This should be inlcuded in this container:
/* color: ${(props) => getColorFromTheme(props.color, props)}; */
const ChildnameContainer = styled(Box)`
    margin-bottom: 1rem;

    p {
        position: relative;
        top: 4px;
    }
`;

const ParticipantComp = styled(Box)`
    margin-bottom: 2rem;
    /* display: flex; */
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    }
`;
const RenewCardLink = styled(Typography)`
    margin-bottom: 1rem;
    text-decoration: underline;
`;
function ParticipantComponent({ participant, color, handlePaymentForm }) {
    return (
        <ParticipantComp>
            <ChildInfoContainer color={color}>
                <Box display="flex" alignItems="flex-start">
                    <PersonIcon />
                    <Box ml="0.5rem">
                        <ChildnameContainer color={color}>
                            <Typography variant="h6">
                                {participant.firstname} {participant.surname} (D
                                {participant.id})
                            </Typography>
                            {/* <RenewCardLink onClick={e=> handlePaymentForm(participant.id)}>Renew Blocked Card</RenewCardLink> */}
                            {/* <Typography variant="body2" gutterBottom>
                ({participant.nickname})
              </Typography> */}
                        </ChildnameContainer>
                        {/* <Typography variant="body2">{participant.age} år</Typography> */}
                    </Box>
                </Box>
            </ChildInfoContainer>{' '}
            <CourseInfoContainer>
                {participant.courses.length > 0 &&
                    participant.courses.map((course) => (
                        <Box mb="2rem" key={course.id}>
                            <Typography variant="body2">
                                {course.name}
                            </Typography>

                            <Typography variant="body2">
                                {course.day}e kl. {course.start}
                            </Typography>

                            <Link
                                to={{
                                    pathname: `/hold/${course.id}`
                                }}
                            >
                                <RegularLink
                                    title="Se holdets side"
                                    customColor={color}
                                />
                            </Link>
                        </Box>
                    ))}
                {/* {participant.instruments.length > 0 &&
          participant.instruments.map((instrument) => (
            <React.Fragment key={instrument.processId}>
              <Typography variant="body2">{instrument.name}</Typography>

              <Typography variant="body2">
                {instrument.day}e kl. {instrument.starttime}
              </Typography>
            </React.Fragment>
          ))} */}
            </CourseInfoContainer>
        </ParticipantComp>
    );
}

export default ParticipantComponent;
