import React from "react";
import styled from "styled-components";
import RegularHeadline from "../../../components/headlines/RegularHeadline";
import { Box, Typography } from "@material-ui/core";
import Questionmark from "../../../components/Questionmark/Questionmark";

const DescriptionText = styled(Typography)`
  margin-bottom: 1rem;
  width: 60%;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

function ParentMeetingDescription(props) {
  return (
    <React.Fragment>
      <Box display="flex">
        <RegularHeadline
          component="h1"
          title="Vælg følgevoksenmøder"
          marginbottom="1.5rem"
        />
        <Questionmark width="1rem" explainerText="Explainer text here" />
      </Box>

      <DescriptionText variant="body2">
        På intromødet får du en generel introduktion til Den Lille Musikskole,
        men vi kan slet ikke nå at gennemgå den relevante information for de
        enkelte hold. Det gør vi på holdmøderne.
      </DescriptionText>
    </React.Fragment>
  );
}

export default ParentMeetingDescription;
