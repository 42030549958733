import moment from "moment";
import "moment/locale/da";

// Checks if month is changing in the current week
// const hasFirstDayOfMonth = (dates) => {
//   for (let i = 1; i < dates.length; i++) {
//     if (dates[i].month !== dates[i - 1].month) {
//       return true;
//     }
//   }
// };

// Sort the days of an array according to date
const sortDays = (days) => {
  const sortedDays = days.sort(
    (a, b) => new Date(a.dateTimeValue) - new Date(b.dateTimeValue)
  );
  return sortedDays;
};

export const buildCalendar = (dateObject) => {
  //Change language to danish, and set starting day to Monday
  moment.updateLocale("da", {
    week: {
      dow: 1, // First day of week is Monday
      doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
    },
  });
  const weekNames = moment.weekdays(true);

  // Get start and end day
  let startDay, endDay;
  const today = moment(new Date()).format("YYYY-MM-DD");
  startDay = moment(dateObject).startOf("week");
  endDay = moment(dateObject).endOf("week");

  // Mobile device needs different start and end date than desktop
  if (document.body.offsetWidth < 960) {
    startDay = moment(dateObject);
    endDay = moment(dateObject).add(7, "days");
  } else {
    startDay = moment(dateObject).startOf("week");
    endDay = moment(dateObject).endOf("week");
  }
  const day = moment(startDay).subtract(1, "day");

  // Dates of the present week are pushed into weekDates array
  let weekDates = [];
  while (day.isBefore(endDay, "day")) {
    weekDates.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, "day").clone())
    );
  }

  // Display date
  const daysOfWeekDates = weekDates[0].map((moment) =>
    moment.format("YYYY-MM-DD")
  );
  // Same dates as in the line above, but in different formats for different views
  const displayDates = weekDates[0].map((moment) => moment.format("D/M"));
  const displayDatesShort = weekDates[0].map((moment) => moment.format("D"));
  const month = weekDates[0].map((moment) => moment.format("MMMM"));

  //Create an array of objects with name, date and isToday, etc properties
  const dates = [];
  // const  day
  for (let i = 0; i < weekNames.length; i++) {
    const newObject = {
      name: weekNames[i],
      nameShort: weekNames[i].slice(0, 3),
      date: daysOfWeekDates[i],
      dateTimeValue: moment(daysOfWeekDates[i] + " 00:01"),
      displayDate: displayDates[i],
      displayDateShort: displayDatesShort[i],
      isToday: false,
      month: month[i].toUpperCase(),
    };
    dates.push(newObject);
  }

  //Check if any of the days in the selected week is the date of today
  for (let i = 0; i < dates.length; i++) {
    if (dates[i].date === today) {
      dates[i].isToday = true;
    }
  }
  return sortDays(dates);
};

// Povides current week number
export const getWeekNumber = (dateObject) => {
  return moment(dateObject).week();
};

export const getStartDate = (dateObject) => {
  return moment(dateObject).startOf("week");
};

export const getEndDate = (dateObject) => {
  return moment(dateObject).endOf("week");
};

export const getEndDateMobile = (dateObject) => {
  const endDate = moment(dateObject).endOf("week");
  return endDate.add(7, "day");
};

export const prevWeek = (dateObject) => {
  return moment(dateObject).subtract(1, "week");
};
export const prevTwoWeeks = (dateObject) => {
  return moment(dateObject).subtract(2, "week");
};
export const nextWeek = (dateObject) => {
  return moment(dateObject).add(1, "week");
};
export const nextTwoWeeks = (dateObject) => {
  return moment(dateObject).add(2, "week");
};
