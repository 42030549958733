import { getLatestSimpleRegData } from '../../../context/getData';
import { postAndReturnResp } from '../../HelperFunctions';
import {
    returnSetStateObj,
    returnStateObj,
    returnValue,
    setScrollView,
    throwError
} from '../information/RegisterInformationFunctions';

export const findUnfilledInputOrRegister = async (
    statesArr,
    setStatesArr,
    context,
    props,
    parentNameRef,
    emailRef,
    phoneRef,
    passwordRef,
    handleError,
    birthdate
) => {
    console.log('finding states', statesArr);
    if (
        statesArr.some(
            (state) =>
                state.value === '' &&
                state.label !== 'childFirstname' &&
                state.label !== 'childSurname'
        )
    ) {
        const notFilledState = statesArr.filter(
            (state) => state.value === '' || !state.value
        );

        console.log('show notfilled', notFilledState);

        // Throw error in these States
        notFilledState.forEach((state) => {
            console.log('show state', state);
            if (state.label === 'childFirstname') {
                console.log('IT IS FIRSTNAME');
            }
            // If the child firstname or surname is not filled out, it is okay
            if (
                state.label === 'childFirstname' ||
                state.label === 'childSurname'
            ) {
                console.log('we shouldnshow error', state.label);
            } else {
                throwError(
                    state.label,
                    returnStateObj(statesArr, state.label),
                    returnSetStateObj(setStatesArr, state.label)
                );
            }
        });

        // Set scroll view to the first element that has error
        const firstErrorLabel = notFilledState[0].label;

        if (firstErrorLabel === 'firstname' || firstErrorLabel === 'surname') {
            setScrollView(parentNameRef);
        } else if (firstErrorLabel === 'email') {
            setScrollView(emailRef);
        } else if (firstErrorLabel === 'phone') {
            setScrollView(phoneRef);
        } else if (
            firstErrorLabel === 'password' ||
            firstErrorLabel === 'repeatPassword'
        ) {
            setScrollView(passwordRef);
        }
    } else {
        // Save info to local storage, except for password. So if user does not complete the signup and returns, we can restore the given values.
        const simpleRegData = getLatestSimpleRegData()
            ? getLatestSimpleRegData()
            : {};

        simpleRegData.information = {
            firstname: returnValue(statesArr, 'firstname'),
            surname: returnValue(statesArr, 'surname'),
            email: returnValue(statesArr, 'email'),
            phone: returnValue(statesArr, 'phone'),
            password: returnValue(statesArr, 'password'),
            childFirstname: returnValue(statesArr, 'childFirstname'),
            childSurname: returnValue(statesArr, 'childSurname'),
            birthdate: birthdate
        };
        // Save information to local storage
        context.saveSimpleRegistrationData(simpleRegData);
        registerAndSendOrder(props, context, simpleRegData, handleError);
    }
};

// Logs user in, and sends order if there are any
// const loginUserAndSendOrder = async (
//     props,
//     context,
//     requestBody,
//     email,
//     simpleRegData,
//     handleError
// ) => {
//     const loginRespData = await postAndReturnData(
//         '/api/v1/user/login',
//         requestBody,
//         handleError
//     );
//     if (
//         loginRespData &&
//         loginRespData.user &&
//         loginRespData.user._id &&
//         loginRespData.responseObject &&
//         loginRespData.responseObject.participants &&
//         loginRespData.responseObject.firstname
//     ) {
//         context.onLogin({
//             username: email,
//             userId: loginRespData.user._id,
//             participants: loginRespData.responseObject.participants,
//             userFirstname: loginRespData.responseObject.firstname
//         });
//         sendOrder(
//             context,
//             loginRespData.user.id,
//             props,
//             simpleRegData,
//             handleError
//         );
//     } else {
//         handleError('Noget gik galt. Prøv igen.');
//     }
// };

const sendOrder = async (
    context,
    userId,
    props,
    simpleRegData,
    handleError
) => {
    console.log('show userid', userId);
    //Check if user is selected a meeting
    console.log('show regdata', simpleRegData);
    if (
        simpleRegData &&
        simpleRegData.selectedIntroMeeting &&
        simpleRegData.selectedIntroMeeting.id
    ) {
        console.log('in the if');
        const meetingId = simpleRegData.selectedIntroMeeting.id;
        const requestBody = {
            introMeetings: [meetingId],
            parentMeetings: [],
            isNewUser: true
        };

        console.log('show request body', requestBody);

        const response = await postAndReturnResp(
            `/api/v1/user/${userId}/order/events/intro-meeting`,
            requestBody,
            handleError
        );

        console.log('show response', response);
        if (response && response.status === 200) {
            props.history.push('/tilmelding/intromoede-opsummering');
        }
    } else {
        props.history.push('tilmelding/intromoede-opsummering');
    }
};

export const registerAndSendOrder = async (
    props,
    context,
    simpleRegData,
    handleError
) => {
    const informationObj = simpleRegData.information;
    const requestBody = {
        firstname: informationObj.firstname,
        surname: informationObj.surname,
        email: informationObj.email,
        phone: informationObj.phone,
        password: informationObj.password,
        childFirstname:
            informationObj.childFirstname && informationObj.childFirstname,
        childSurname:
            informationObj.childSurname && informationObj.childSurname,
        birthdate: informationObj.birthdate && informationObj.birthdate,
        isSimpleRegistration: true
    };
    // Save information in context
    console.log('show requestbody signup', requestBody);
    // Register user
    const response = await postAndReturnResp(
        '/api/v1/user/signup',
        requestBody,
        handleError
    );

    if (response && response.status === 200) {
        const responseObj = await response.json();
        console.log('show responseObj', responseObj);
        const userId = responseObj.id;

        sendOrder(context, userId, props, simpleRegData, handleError);
    } else if (response && response.status === 500) {
        const responseObj = await response.json();

        if (responseObj && responseObj.message) {
            // Check error message
            if (responseObj.message === 'email must be unique') {
                handleError('Denne email er allerede registreret. Log ind.');
            } else {
                handleError('Noget gik galt. Prøv igen.');
            }
        } else {
            handleError('Noget gik galt. Prøv igen.');
        }
    } else {
        handleError('Noget gik galt. Prøv igen1.');
    }
};
