import React from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from '@material-ui/core';
import StaircaseImageFlipped from '../staircase-image/StaircaseImageFlipped';
import StaircaseImage from '../staircase-image/StaircaseImage';
import styled from 'styled-components';

const CustomCardItem = styled.div`
    border-radius: 0;
    border-bottom: 2px solid
        ${(props) => props.theme.palette.primary.contastText};
    margin-bottom: 1.5rem;

    // Give a colourful overlay on hover
    &:hover .education-image-overlay {
        display: block;
    }

    &:hover .custom-card-content {
        color: rgba(${(props) => props.color} 1);
    }

    &:hover {
        border-bottom: 2px solid rgba(${(props) => props.color} 1);
    }

    // Flip the staircase image on hover
    &:hover .education-card-staircase-image-flipped-container {
        display: none;
    }
    &:hover .education-card-staircase-image-container {
        display: block;
    }
`;

const CardMediaOverlay = styled.div`
  display: none;
  width: 100%;
  height: 15rem;
  position: absolute;
  top: 0;
  background-image: linear-gradient(
    rgba(${(props) => props.color} 0.4),
    rgba(${(props) => props.color} 0.4)
  ),
  url(${(props) => props.imageurl})!important;

}
`;

const SeeMoreLink = styled(Typography)`
    text-decoration: underline;
`;

const CustomCardMedia = styled(CardMedia)`
    height: 15rem;
`;

const StaircaseImageFlippedContainer = styled.div`
    margin-right: 1rem;
    & img {
        position: relative;
        top: 3px;
    }
`;

const StaircaseImageContainer = styled.div`
    margin-right: 1rem;
    display: none;
    & img {
        position: relative;
        top: 3px;
    }
`;

function EducationCard({
    title,
    imageUrl,
    color,
    colorName,
    url,
    id,
    returnPath,
    returnTitle,
    externalUrl
}) {
    return (
        <>
            {externalUrl ? (
                <a href={externalUrl} target="_blank" rel="noopener noreferrer">
                    <CustomCardItem overlaycolor={color} color={color}>
                        <CardActionArea disableRipple>
                            <CustomCardMedia
                                imageurl={imageUrl}
                                title={title}
                                image={imageUrl}
                            />
                            <CardMediaOverlay
                                className="education-image-overlay"
                                color={color}
                            />
                            <CardContent className="custom-card-content">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box display="flex">
                                        <StaircaseImageFlippedContainer className="education-card-staircase-image-flipped-container">
                                            <StaircaseImageFlipped
                                                src="/images/staircase/staircase-white.png"
                                                alt="staircase-logo"
                                                width="1"
                                            />
                                        </StaircaseImageFlippedContainer>
                                        <StaircaseImageContainer className="education-card-staircase-image-container">
                                            {colorName ? (
                                                <StaircaseImage
                                                    src={`/images/staircase/staircase-${colorName}.png`}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            ) : (
                                                <StaircaseImage
                                                    src={`/images/staircase/staircase-white.png`}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            )}
                                        </StaircaseImageContainer>
                                        <Typography variant="body2">
                                            {title}
                                        </Typography>
                                    </Box>
                                    <SeeMoreLink variant="body2">
                                        Se mere
                                    </SeeMoreLink>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </CustomCardItem>
                </a>
            ) : (
                <Link
                    to={{
                        pathname: `${url}`,
                        state: {
                            from: {
                                path: returnPath,
                                title: returnTitle
                            }
                        }
                    }}
                >
                    <CustomCardItem overlaycolor={color} color={color}>
                        <CardActionArea disableRipple>
                            <CustomCardMedia
                                imageurl={imageUrl}
                                title={title}
                                image={imageUrl}
                            />
                            <CardMediaOverlay
                                className="education-image-overlay"
                                color={color}
                            />
                            <CardContent className="custom-card-content">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box display="flex">
                                        <StaircaseImageFlippedContainer className="education-card-staircase-image-flipped-container">
                                            <StaircaseImageFlipped
                                                src="/images/staircase/staircase-white.png"
                                                alt="staircase-logo"
                                                width="1"
                                            />
                                        </StaircaseImageFlippedContainer>
                                        <StaircaseImageContainer className="education-card-staircase-image-container">
                                            {colorName ? (
                                                <StaircaseImage
                                                    src={`/images/staircase/staircase-${colorName}.png`}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            ) : (
                                                <StaircaseImage
                                                    src={`/images/staircase/staircase-white.png`}
                                                    alt="staircase-logo"
                                                    width="1"
                                                />
                                            )}
                                        </StaircaseImageContainer>
                                        <Typography variant="body2">
                                            {title}
                                        </Typography>
                                    </Box>
                                    <SeeMoreLink variant="body2">
                                        Se mere
                                    </SeeMoreLink>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </CustomCardItem>
                </Link>
            )}
        </>
    );
}

export default EducationCard;
