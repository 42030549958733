import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import NavHeaderDesktop from "../headers/nav-header/NavHeaderDesktop.js";
import Week from "./Week.jsx";
import CalendarHeadlinesContainer from "./calendar-headlines/CalendarHeadlinesContainer";
import moment from "moment"; // Luxon will replace moment, that is no longer being developed
import { DateTime } from "luxon";
import RightArrow from "./arrow/RightArrow.jsx";
import LeftArrow from "./arrow/LeftArrow.jsx";
import RegularHeadline from "../headlines/RegularHeadline";
import GlobalEventDetails from "./GlobalEventDetails";
import { buildCalendar, getWeekNumber } from "./CalendarFunctions";
import Loader from "../loader/Loader";
import { withRouter } from "react-router-dom";
import ReturnLinkReusable from "../return-link/ReturnLinkReusable";
import Fade from "@material-ui/core/Fade";
import { getAndReturnData } from "../../containers/HelperFunctions";

const CalendarWithArrowsContainer = styled.div`
  display: flex;
  width: 85%;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
`;
const CalendarContainer = styled.div`
  display: flex;
  margin-top: 6%;
  flex-direction: column;
  width: 100%;
`;
const MoreEventsContainerDesktop = styled.div`
  min-height: 45rem;
`;
const LoaderContainer = styled.div`
  padding: 18%;
  /* min-height: 40rem; */
`;
function Calendar(props) {
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const initialDate = (props.location.state) ? props.location.state.weekInfo.dates[0].date : moment();
  console.log(initialDate,moment(),props.location.state,'åå')
  const [dateObject, setDateObject] = useState(initialDate);
  const [eventInfofromApi, setEventInfofromApi] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const weekInformation = {
      dates: buildCalendar(dateObject),
      weekNumber: getWeekNumber(dateObject),
    };
  let firstDayInCalendar = weekInformation.dates[0].date;
  let lastDayInCalendar = weekInformation.dates[6].date;
  // Handle click for next or past two weeks
  const handleArrowClick = (week) => {
    setDateObject(week);
  };
  // Handle if there is an error with api and fetch
  const handleError = (err) => {
    console.log(err);
  };

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });
  useEffect(() => {
    // Start loader
    setCalendarLoaded(false);
    // Get events
    const getAndSaveCalendarData = async () => {
      // TODO: Fix warning on firstDayInCalendar and lastDayInCalendar 
      // Assignments to the 'firstDayInCalendar' variable from inside React Hook useEffect 
      // will be lost after each render. To preserve the value over time, store it in a useRef Hook and keep 
      // the mutable value in the '.current' property. Otherwise, you can move this variable directly inside 
      // useEffect  react-hooks/exhaustive-deps

      firstDayInCalendar= DateTime.now().toISO().substring(0,10);  
      lastDayInCalendar = DateTime.now().plus({months:1}).toISO().substring(0,10);  
      const response = await getAndReturnData(
        `/api/v1/event/public/${firstDayInCalendar}/${lastDayInCalendar}`,
        handleError
      );
      let data=[]
      console.log('RESPONSE MORE EVENTS', response);
      if(response && response.eventList){
      data = response.eventList;
      if (!data || data.length === 0) {
        setCalendarLoaded(true);
      } else {
        setEventInfofromApi(data);
        setCalendarLoaded(true);
      }
    }else {
      setEventInfofromApi(data);
      setCalendarLoaded(true);
    }
    };
    getAndSaveCalendarData();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateObject]);

  // Handle Expand Calendar to show event details
  const handleOpenEventDetails = (eventDetails) => {
    if (isOpenEvent) {
      // If the current event is the same old one then close the eventDetails else open it
      if (selectedEvent === eventDetails) {
        setIsOpenEvent(false);
        setSelectedEvent({});
      } else {
        setSelectedEvent(eventDetails);
      }
    } else {
      setIsOpenEvent(true);
      setSelectedEvent(eventDetails);
    }
  };

  // Handle (X) close event details
  const closeOpenEvent = () => {
    setIsOpenEvent(false);
    setSelectedEvent({});
  };
  return (
    <MoreEventsContainerDesktop>
      <NavHeaderDesktop />
      {calendarLoaded ? (
        <CalendarWithArrowsContainer>
          {/* {eventInfofromApi.length > 0 ? ( */}
            <>
              <LeftArrow
                onClick={handleArrowClick}
                dateObj={dateObject}
                calendar={"more-events-calendar"}
              />
              <CalendarContainer>
                <ReturnLinkReusable url="/" destination="forsiden" />
                <RegularHeadline
                  component="h2"
                  title={`Hvad sker der mellem ${moment(
                    firstDayInCalendar
                  ).format("Do MMMM YYYY")} - ${moment(
                    lastDayInCalendar
                  ).format("Do MMMM YYYY")}`}
                />
                <CalendarHeadlinesContainer />
                <Week
                  weekInfo={weekInformation}
                  openEventDetails={handleOpenEventDetails}
                  selectedEventInCalendar={selectedEvent}
                  globalEvents={eventInfofromApi}
                  weekNumber={weekInformation.weekNumber}
                  calendar={"more-events-calendar"}
                />
                {isOpenEvent ? (
                  <Fade in={isOpenEvent} timeout={500}>
                    <div>
                      <GlobalEventDetails
                        eventDetails={selectedEvent}
                        closeOpenEvent={closeOpenEvent}
                      ></GlobalEventDetails>
                    </div>
                  </Fade>
                ) : (
                  <div></div>
                )}
              </CalendarContainer>
              <RightArrow
                onClick={handleArrowClick}
                dateObj={dateObject}
                calendar={"more-events-calendar"}
              />
            </>
          {/* ) : (
            <TypographyContainer>
              <Typography variant="body2">
                Der er ingen begivenheder, eller du er offline
              </Typography>
            </TypographyContainer>
          )} */}
        </CalendarWithArrowsContainer>
      ) : (
        <LoaderContainer>
          <Loader  minHeight={'40rem'} />
        </LoaderContainer>
      )}
    </MoreEventsContainerDesktop>
  );
}
export default withRouter(Calendar);
