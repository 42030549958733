import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const ExplainerImageContainerComponent = styled(Box)`
  width: 45%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

function ExplainerImageContainer({ children }) {
  return (
    <ExplainerImageContainerComponent>
      {children}
    </ExplainerImageContainerComponent>
  );
}

export default ExplainerImageContainer;
