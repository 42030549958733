import React from "react";
import ReturnLinkReusable from "../../components/return-link/ReturnLinkReusable";
import { useParams } from "react-router-dom";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
import ContentContainer from "../../components/containers/ContentContainer";

function AllPosts(props) {
  const { courseId } = useParams();
  return (
    <div>
      <ReusableHeader />
      <ContentContainer>
        <ReturnLinkReusable url={`/hold/${courseId}`} destination="Mit hold" />
        Se alle opslag her
      </ContentContainer>
    </div>
  );
}

export default AllPosts;
