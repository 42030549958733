import React from "react";
import styled from "styled-components";

const ArrowContainer = styled.div`
  font-size: 1.8rem;
  padding: 13.8rem 0rem;
  cursor: pointer;
  position: absolute;
  right: 7.5%;
  @media (max-width: 960px) {
    position: static;
    padding: 0px;
  }
`;

function RightArrow(props) {
  const goToNextSlide = () => {
    // console.log('currentSlide', props.currentSlide, 'slidesNumber,', props.slidesNumber)
    props.currentSlide === props.slidesNumber - 1
      ? props.onClick(0)
      : props.onClick(props.currentSlide + 1);
  };
  return <ArrowContainer onClick={goToNextSlide}>&#62;</ArrowContainer>;
}

export default RightArrow;