import React from "react";
import styled from "styled-components";

const ColorBackgroundComponent = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  width: 100%;
  padding-top:  "2rem";
  padding-bottom: 2rem;
`;

function ColorBackground({ children}) {
  return (
    <ColorBackgroundComponent >
      {children}
    </ColorBackgroundComponent>
  );
}

export default ColorBackground;
