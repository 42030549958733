import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../loader/Loader';
import { getLocationNameFromRoomId } from '../../../util';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }

    position: relative;
`;

const DialogActionsCustom = styled(DialogActions)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }

    & span {
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            font-size: 0.875rem;
            margin-left: 0;
        }
    }

    & button {
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            margin-left: 0 !important;
        }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

function DeleteDialog({
    close,
    removeBookedEvent,
    eventId,
    isLoading,
    startdate,
    starttime,
    endtime,
    eventType
}) {
    const handleYesClick = (eventId) => {
        removeBookedEvent(eventId);
    };

    return (
        <>
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="confirm-event-deletion"
                aria-describedby="confirm-event-deletion-description"
                disableRestoreFocus
            >
                {/* <DialogTitle id="confirm-event-deletion">Vigtigt</DialogTitle> */}
                <DialogContent>
                    {/* Here we also need to list the rules for cancellation. Text request is sent to Liuwina */}

                    <>
                        <DialogContentText id="onfirm-event-deletion-description">
                            Du er nu ved at annullere følgende mødetilmelding:
                            <br />
                            <br />
                            {eventType}
                            <br />
                            {moment(startdate).format('DD.MM.YYYY')} kl.{' '}
                            {moment(startdate + ' ' + starttime).format(
                                'HH:mm'
                            )}{' '}
                            til{' '}
                            {moment(startdate + ' ' + endtime).format('HH:mm')}.
                        </DialogContentText>

                        <DialogContentText>
                            Er du sikker på, at du vil annullere din
                            mødetilmelding?
                        </DialogContentText>
                    </>
                </DialogContent>

                {isLoading ? (
                    <Box width="fit-content" ml="auto" mr="auto">
                        <Loader />
                    </Box>
                ) : (
                    <DialogActionsCustom>
                        <Button onClick={() => close()}>Nej</Button>

                        <Button onClick={() => handleYesClick(eventId)}>
                            Ja
                        </Button>
                    </DialogActionsCustom>
                )}
            </DialogComponent>
        </>
    );
}

export default DeleteDialog;
