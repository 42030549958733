import {
    Box,
    FormControl,
    FormHelperText,
    TextField,
    Typography
} from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import RegularButton from '../../../../components/buttons/RegularButton/RegularButton';
import RegularHeadline from '../../../../components/headlines/RegularHeadline';
import { overridings } from '../../../../themes/DarkTheme';
import RateCalculator from '../../../../components/rate-calculator/RateCalculator';
import Slider from '../../../../components/slider/Slider';
import { Context } from '../../../../context/Context';
import { useEffect } from 'react';
import {
    getChildInRegFlow,
    getLatestChildInRegFlow
} from '../../../../context/getData';
import RadioCheckboxComponent from '../../../../components/radio-checkbox/RadioCheckbox';
const purple = overridings.palette.colors.purple;

const initialLabels = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
    { value: 12 }
];

const PaymentRatesContainer = styled(Box)`
    // background: pink;
    justify-content: space-between;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        // display: block;
    }
`;

const PaymentRatesDescriptionContainer = styled(Box)`
    width: 45%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
`;

const FirstRateText = styled(Typography)`
    margin-top: 0.5rem;
`;

const FirstRateField = styled(TextField)`
    margin-bottom: ${(props) => (props.error ? '0rem' : '1.5rem')};
    margin-top: 1rem;
`;

const FirstRateContainer = styled.div`
  width: 30%;
  min-width: 10rem;
  & .MuiInputLabel-outlined {
    color: ${(props) =>
        props.error ? 'red' : props.theme.palette.primary.contrastText}} 
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
        props.error
            ? 'red'
            : 'rgba(255, 255, 255, 0.23) !important'}; // Custom color should be dinamic?
  }
`;

const YourPriceContainer = styled(Box)`
    margin-top: 4rem;
    // position: absolute;
    // bottom: 0;
`;

const Price = styled(Typography)`
    font-family: 'Benton Sans Bold';
`;

const SliderCalculatorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

function RatesSection({ setError, setErrorMessage, priceSum, setScrollView }) {
    const [isInputOpen, setIsInputOpen] = useState(false);
    const [unconfirmedFirstRate, setUnconfirmedFirstRate] = useState('');
    const [customFirstRate, setCustomFirstRate] = useState('0');
    const [smallError, setSmallError] = useState(false);
    const [smallErrorText, setSmallErrorText] = useState('');
    const [firstRateSubmitted, setFirstRateSubmitted] = useState(false);
    const [ratePrice, setRatePrice] = useState('');
    const [maxNumberOfRates, setMaxNumberOfRates] = useState(12);
    const [labels, setLabels] = useState(initialLabels);

    const changeNumberOfRatesInParent = (newMaxNumberOfRates) => {
        setMaxNumberOfRates(newMaxNumberOfRates);

        // Need to also change the labels according to the newMaxNumberOfRates
        const newLabels = [];
        for (let i = 0; i < newMaxNumberOfRates; i++) {
            if (i <= newMaxNumberOfRates) {
                newLabels.push(labels[i]);
            }
        }
        setLabels(newLabels);
    };

    const context = useContext(Context);

    const handleCheckBoxChange = (event) => {
        setIsInputOpen(event.target.checked);
        if (!event.target.checked) {
            setFirstRateSubmitted(false);
            setCustomFirstRate('0');
        }
    };

    const handleInputChange = (event) => {
        setSmallError(false);
        setSmallErrorText('');
        setFirstRateSubmitted(false);
        const inputNumber = Number(event.target.value);
        if (inputNumber >= 0 && inputNumber <= priceSum) {
            setUnconfirmedFirstRate(inputNumber);
        } else if (inputNumber > priceSum) {
            setSmallError(true);
            setSmallErrorText('First rate cannot be higher than samlet pris'); // Come back:
        } else if (priceSum === 0 || !priceSum || isNaN(priceSum)) {
            setSmallError(true);
            setSmallErrorText('You must select a process first.'); //Come back: Danish text? The text here is in connection to removeError, so if you change it, change that too!
        } else {
            setSmallError(true);
            setSmallErrorText('Insert a valid number!'); //Come back: Danish text?
        }
    };

    const handleFirstRateSubmit = () => {
        if (Number(customFirstRate) >= 0 && !smallError) {
            setFirstRateSubmitted(true);
            setCustomFirstRate(unconfirmedFirstRate.toString());
        }
    };

    // If user tries to enter a first rate, but have not selected a process,
    // An error sign will appear. If the error sign is 'You must select a process first',
    // then if user clicks away (onBlur) - to select a process, this error is removed.
    const removeError = () => {
        if (smallErrorText === 'You must select a process first.') {
            setSmallErrorText('');
            setSmallError(false);
            // Same with when first rate is higher. If user unBlurs, remove error message.
        } else if (
            smallErrorText === 'First rate cannot be higher than samlet pris'
        ) {
            setSmallErrorText('');
            setSmallError(false);
        }
    };

    // Set input value to customFirstRate if already exists
    const getInputValue = () => {
        if (Number(unconfirmedFirstRate) > 0) {
            return unconfirmedFirstRate;
        } else {
            return '';
        }
    };

    // Save custom first rate to context
    useEffect(() => {
        context.saveCustomFirstRate(customFirstRate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFirstRate]);

    // If there is custom first rate saved in localstorage, restore it here and context
    const recoverCustomFirstRate = useCallback(() => {
        if (getLatestChildInRegFlow(context)) {
            if (getChildInRegFlow().customFirstRateNumber > 0) {
                setCustomFirstRate(getChildInRegFlow().customFirstRate);
                setIsInputOpen(true);
            }
        }
        // eslint-disable-next-line
    }, []);

    const changeRatePrice = useCallback(
        (rates) => {
            const selectedRates = rates.filter((rate) => rate.selected);
            if (selectedRates.length <= 0) {
                // If only one month is selected, return the sum
                setRatePrice(priceSum.toString());
            } else {
                // Price per rate is the second rate's price - since user is able to add custom first rate, and the last rate could also differ from the rest.
                if (selectedRates.length > 1) {
                    setRatePrice(selectedRates[1].price.toString());
                } else {
                    setRatePrice(selectedRates[0].price.toString());
                }
            }
        },
        [priceSum]
    );

    useEffect(() => {
        recoverCustomFirstRate();
    }, [recoverCustomFirstRate]);

    // componentDidMount
    useEffect(() => {
        // Check if customFirstRate is saved in storage. If yes, recover the value
        const childInRegFlow = getChildInRegFlow();
        if (
            childInRegFlow &&
            childInRegFlow.customFirstRate &&
            childInRegFlow.customFirstRate > 0
        ) {
            setCustomFirstRate(childInRegFlow.customFirstRate);
            setIsInputOpen(true);
            setUnconfirmedFirstRate(childInRegFlow.customFirstRate);
        }
    }, []);

    return (
        <PaymentRatesContainer>
            <PaymentRatesDescriptionContainer>
                <RegularHeadline
                    title="Vælg betalingsrater"
                    marginbottom="1.5rem"
                />
                <DescriptionText variant="body2">
                    Du kan vælge, om du vil betale det fulde beløb nu, eller om
                    du vil dele betalingen op i rater. Den samlede pris er den
                    samme, uanset om du vælger straks- eller ratebetaling.
                </DescriptionText>
                <Box mb="2rem">
                    {isNaN(priceSum) ? (
                        <React.Fragment>
                            <Typography variant="body2" mb="0">
                                Samlet pris
                            </Typography>
                            <Price variant="h5">Vælg et forløb</Price>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography variant="body2" mb="0">
                                Samlet pris
                            </Typography>
                            <Price variant="h5">DKK {priceSum}</Price>
                        </React.Fragment>
                    )}
                </Box>
            </PaymentRatesDescriptionContainer>
            <SliderCalculatorContainer>
                <Box>
                    <Slider
                        labels={labels}
                        maxNumberOfRates={maxNumberOfRates}
                        priceSum={priceSum}
                        setScrollView={setScrollView}
                        setError={setError}
                        setErrorMessage={setErrorMessage}
                    />
                    <Box display="flex" position="relative">
                        <RadioCheckboxComponent
                            onCheckboxChange={handleCheckBoxChange}
                            position="static"
                            checked={isInputOpen}
                        />
                        <FirstRateText variant="body2">
                            Jeg vil gerne betale en højere første rate, så
                            prisen på de resterende rater bliver lavere
                        </FirstRateText>
                    </Box>
                    {isInputOpen && (
                        <FirstRateContainer error={smallError}>
                            <FormControl error={smallError}>
                                <FirstRateField
                                    error={smallError}
                                    variant="outlined"
                                    label="Første rate"
                                    type="number"
                                    onChange={(event) =>
                                        handleInputChange(event)
                                    }
                                    min="0"
                                    value={getInputValue()}
                                    onBlur={removeError}
                                />
                                <Box mb="1rem">
                                    <FormHelperText>
                                        {smallErrorText}
                                    </FormHelperText>
                                </Box>

                                <RegularButton
                                    title="Anvend"
                                    size="small"
                                    onButtonClick={() =>
                                        handleFirstRateSubmit()
                                    }
                                    background={purple}
                                />
                            </FormControl>
                        </FirstRateContainer>
                    )}
                    <YourPriceContainer>
                        <Typography variant="body2" mb="0">
                            Pris per rate
                        </Typography>
                        {ratePrice === 0 || isNaN(ratePrice) ? (
                            <Price variant="h5">DKK {0}</Price>
                        ) : (
                            <Price variant="h5">DKK {ratePrice}</Price>
                        )}
                    </YourPriceContainer>
                </Box>

                <RateCalculator
                    priceSum={priceSum}
                    setError={setError}
                    setErrorMessage={setErrorMessage}
                    customFirstRate={customFirstRate}
                    firstRateSubmitted={firstRateSubmitted}
                    changeRatePrice={changeRatePrice}
                    setMaxNumberOfRates={changeNumberOfRatesInParent}
                    setScrollView={setScrollView}
                />
            </SliderCalculatorContainer>
        </PaymentRatesContainer>
    );
}

export default RatesSection;
