import React from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import RegularButton from "../buttons/RegularButton/RegularButton";
import concertImg from "../../assets/global-events-images/event-concert-mobile.jpg";
import meetingImg from "../../assets/global-events-images/event-meeting-mobile.jpg";
import workdayImg from "../../assets/global-events-images/event-workday-mobile.jpg";

const { overridings } = require("../../themes/DarkTheme");
const handleColorType = (props) => {
  switch (props) {
    case "concert":
      return `${overridings.palette.colors.purple}`;
    case "workday":
      return `${overridings.palette.colors.green}`;
    case "meeting":
      return `${overridings.palette.colors.yellow}`;
    default:
      return `${overridings.palette.colors.blue}`;
  }
};
const displayOpacity = keyframes`
  {
    0%{
        opacity:0%
      }
      50%{
        opacity:50%   
      }
    100%{
    opacity:100%
  }
}
`;
const EventDetailsContainer = styled.div`
  display: flex;
  animation: ${displayOpacity} 1s ease forwards;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 10px;
  margin: 0rem;
`;
const EventImageContainer = styled.div``;
const EventImg = styled.img`
  max-width: 75%;
  width: 75%;
  margin: 0;
  animation: ${displayOpacity} 1s linear forwards;
  @media (max-width: 710px) {
    max-width: 100%;
    width: 100%;
  }
`;
const EventInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`;
const EventContent = styled(Typography)`
  margin: 6px 0px;
  font-size: 0.8rem;
  max-width: 75%;
  width: 75%;
  @media (max-width: 710px) {
    max-width: 100%;
    width: 100%;
  }
`;
const EventLDetailsLinks = styled.div`
  display: flex;
  flex-direction: column;
`;
const RegularButtonContainer = styled.div`
  display: inline-block;
  margin: 1rem 2rem 0 0;
  max-width: 75%;
  width: 75%;
  @media (max-width: 710px) {
    max-width: 100%;
    width: 100%;
  }
`;
const AddToCalendar = styled.a`
  width: 40%;
  margin: 1rem 1rem 0px 0px;
  padding: 3px 0px;
  text-decoration-line: underline;
  width: 100%;
  color: ${(props) => handleColorType(props.color)};
`;
function GlobalEventDetails(props) {
  function SwitchCase(props) {
    switch (props.value) {
      case "concert":
        return (
          <EventImg src={concertImg} alt={`${props.eventTitle} event image`} />
        );
      case "workday":
        return (
          <EventImg src={workdayImg} alt={`${props.eventTitle} event image`} />
        );
      case "meeting":
        return (
          <EventImg src={meetingImg} alt={`${props.eventTitle} event image`} />
        );
      default:
        return <EventImg src={meetingImg} alt={`${props.eventTitle} event image`} />;
    }
  }

  // Føj til kaledar
  const downloadICal = (eventDetails) => {
    const eventInfo = {
      title: eventDetails.title,
      location:
        "Den Lille Musikskole, Bülowsvej 10, Frederiksberg, 1870,Danmark",
      description: `Room number: ${eventDetails.roomId}`,
      start: [
        moment(eventDetails.date).year(),
        moment(eventDetails.date).add({ M: 1 }).month(),
        moment(eventDetails.date).date(),
        moment(eventDetails.start, "HH:mm:ss").hour(),
        moment(eventDetails.start, "HH:mm:ss").minute(),
        moment(eventDetails.start, "HH:mm:ss").second(),
      ],
      end: [
        moment(eventDetails.date).year(),
        moment(eventDetails.date).add({ M: 1 }).month(),
        moment(eventDetails.date).date(),
        moment(eventDetails.end, "HH:mm:ss").hour(),
        moment(eventDetails.end, "HH:mm:ss").minute(),
        moment(eventDetails.end, "HH:mm:ss").second(),
      ],
    };
    // Save event - create iCalendar file, send event in body
    fetch("/api/v1/calendar_download", {
      method: "POST",
      body: JSON.stringify(eventInfo),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //After event is saved we should get status 200 back if saving was successfull.
      // Then we want to donwload the event file
    }).then((resp) => {
      if (resp.status === 200) {
        // Make another request: GET request to /calendar_download
        // This cannot be done by fetch, as the window won't be prompted to download the file
        window.location = "/api/v1/calendar_download";
      } else {
        console.log("error");
      }
    });
  };
  const handleRegularButtonClick = () => {
    props.history.push("/login");
  };
  return (
    <EventDetailsContainer>
      <EventImageContainer>
        <SwitchCase
          value={props.eventDetails.type}
          eventTitle={props.eventDetails.title}
        />
      </EventImageContainer>
      <EventInformationContainer>
        <EventContent>{props.eventDetails.content}</EventContent>
        <EventLDetailsLinks>
          <RegularButtonContainer>
            <RegularButton
              color={handleColorType(props.eventDetails.type)}
              title="Deltag"
              size="small"
              onButtonClick={handleRegularButtonClick}
            >
              Deltag
            </RegularButton>
          </RegularButtonContainer>
        </EventLDetailsLinks>
        <AddToCalendar
          color={props.eventDetails.type}
          onClick={() => downloadICal(props.eventDetails)}
        >
          + Tilføj til kalender
        </AddToCalendar>
      </EventInformationContainer>
    </EventDetailsContainer>
  );
}
export default withRouter(GlobalEventDetails);
