import React from 'react';
import ContentContainer from '../../components/containers/ContentContainer';
import ReusableHeader from '../../components/headers/reusable-header/ReusableHeader';
import ReturnLinkReusable from '../../components/return-link/ReturnLinkReusable';
import ChangePaymentEmail from '../../components/change-payment-email/ChangePaymentEmail';
import { getAndReturnResp } from '../HelperFunctions';

function EconomyOverview(props) {
    const handleTestCall = async () => {
        const resp = await getAndReturnResp('/api/v1/test', () =>
            console.log('err')
        );
    };
    return (
        <div>
            <ReusableHeader />
            <ContentContainer>
                <ReturnLinkReusable url="/min-side" destination="Min side" />
                <h1>Economy overview</h1>
                {/* This is now here for test purposes, 
                    but should be moved to the page, which shows the coming payments */}
                <ChangePaymentEmail />

                <button onClick={() => handleTestCall()}>click</button>
            </ContentContainer>
        </div>
    );
}

export default EconomyOverview;
