import React, { useEffect, useRef, useState
//  , useContext
} from "react";
import { useParams } from "react-router-dom";
import ContentContainer from "../../components/containers/ContentContainer";
import EducationComponent from "../../components/education-component/EducationComponent";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
// import { instrumentData } from "../../data/InstrumentData";

//import { Context } from "../../context/Context";
import MessageList from "../../components/message-list";
// import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
// import { getMessage } from "../../components/message-list/MessageListHelper";

function Instrument() {
  const { instrumentId } = useParams();
  const contentRef = useRef();

  //const context = useContext(Context);
  // eslint-disable-next-line
  const [messageList, setMessageList] = useState(undefined); // Array of messages
  const [selectedInstrument, setSelectedInstrument] = useState(undefined); // Data object

  /*
  const selectedInstrument = instrumentData.find(
    (instrument) => instrument.id === instrumentId
  );
  */

  const getRightHeaderImage = () => {
    // Once I get right size images, we should check if the browser size is mobile or desktop
    // And render image according to that
    switch (instrumentId) {
      case "violin":
        return "/images/headers/header-violin.jpg";
      case "cello":
        return "/images/headers/header-cello.jpg";
      case "klaver":
        return "/images/headers/header-piano.jpg";
      case "guitar":
        return "/images/headers/header-guitar.jpg";
      case "harp":
        return "/images/headers/header-harp.jpg";
      case "klarinet":
        return "/images/headers/header-clarinet.jpg";
      default:
        return "/images/headers/header-violin.jpg";
    }
  };

  // ComponentDidMount
  useEffect(() => {
    contentRef.current.scrollIntoView();    
  }, []);

  const setInstrumentData = (list) => {
    setMessageList(list);
    // console.log(list);

    if (list && list.length>0) {
      let data = list.find(message => (message.url && message.url.indexOf(instrumentId)>=0));
      if (data) {
        setSelectedInstrument({
          title: data.title,
          id: instrumentId,
          imageUrl: data.text,
          description: data.html,
          color: "orange",
          colorRGB: "250, 152, 43,"
        });        
      };
    }
  }

  return (
    <React.Fragment>
      <MessageList type="html" name="Instrument%" state="final" change={setInstrumentData}/>

      <ReusableHeader imageUrl={getRightHeaderImage()} />
      <div ref={contentRef}></div>
      <ContentContainer>
        { selectedInstrument && 
          <EducationComponent
            data={selectedInstrument}
            url={`/undervisning/instrumenter/${instrumentId}`}
            previousUrl={`/undervisning/instrumenter`}
          />
        }
      </ContentContainer>
    </React.Fragment>
  );
}

export default Instrument;
