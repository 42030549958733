import React from "react";
import styled from "styled-components";

const StaircaseImageFlippedComponent = styled.img`
  width: ${(props) => props.width}rem;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

function StaircaseImageFlipped({ src, alt, width }) {
  return <StaircaseImageFlippedComponent src={src} alt={alt} width={width} />;
}

export default StaircaseImageFlipped;
