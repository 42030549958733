import React from "react";
import styled from "styled-components";
import { Select } from "@material-ui/core";

const SelectComp = styled(Select)`
  margin: 0 1rem 0 0;
  height: 2.8rem;
  border-radius: 0;
  border: 1px solid white;
  width: ${(props) => props.width};
  & div {
    width: 2.5rem;
  }

  & .MuiSelect-root {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-bottom: 1rem;
  }
`;

function SelectComponent({ children, onChange, name, value, id, width }) {
  return (
    <SelectComp
      onChange={(e) => onChange(e)}
      name={name}
      value={value}
      id={id}
      width={width}
    >
      {children}
    </SelectComp>
  );
}

export default SelectComponent;
