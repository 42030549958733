import createDataContext from './createDataContext';
import { getLocalStorageJson, setLocalStorageJson } from '../util';
let key = document.location.hostname + ':state-history';
const initialState = {
    currentUser: {},
    features: {
        enableLogin: true, // Show login
        enableOrder: true, // Show order class, event and webshop items together
        enableOrderEvent: true, // Show order event
        enablePayment: false, // Show only free events like intro meeting
        enableCalendar: false, // Frontpage calendar must be implemented for mobile
        enableStories: true, // Frontpage parents words carousel
        enablePosts: true, // Frontpage posts
        test: false
    }
};

const userReducer = (state, action) => {
    switch (action.type) {
        case 'SAVE_FEATURES': // Control which features are displayed
            return { ...state, features: action.payload };
        case 'SAVE_USER':
            return { ...state, currentUser: action.payload };
        case 'LOGOUT':
            return { ...state, currentUser: '', childInRegFlow: '' };
        case 'SAVE_LOCATION':
            return { ...state, location: action.payload };
        case 'SAVE_CHILD_REGISTRATION_DATA':
            return { ...state, childInRegFlow: action.payload };
        case 'SAVE_REGISTRATION_DATA':
            return { ...state, registrationData: action.payload };
        case 'SAVE_SIMPLE_REGISTRATION_DATA':
            return { ...state, simpleRegistrationData: action.payload };
        case 'SAVE_REG_INFORMATION':
            return { ...state, regInformation: action.payload };
        case 'SAVE_MEETINGS_DATA':
            return { ...state, meetingsData: action.payload };
        case 'SAVE_COURSES_DATA':
            return { ...state, coursesData: action.payload };
        case 'SAVE_RATES_DATA':
            return { ...state, ratesData: action.payload };
        case 'SAVE_CUSTOM_FIRST_RATE':
            return { ...state, customFirstRate: action.payload };
        case 'SAVE_GENERAL_EVENT_DETAILS_DATA':
            return { ...state, eventDetailsData: action.payload };
        case 'SAVE_IS_OPEN_EVENT_DETAILS':
            return { ...state, isOpenEventDetails: action.payload };
        case 'SAVE_CHILD_TO_MODIFY':
            return { ...state, childToModify: action.payload };
        case 'SAVE_SELECTED_INTRO_MEETING':
            return { ...state, selectedIntroMeeting: action.payload };
        case 'SAVE_ALL_WORKDAYS':
            return { ...state, allWorkdays: action.payload };
        case 'CHILD_ALREADY_REGISTERED':
            return { ...state, childAlreadyRegistered: action.payload };
        case 'SAVE_ONLY_SIGNUP':
            return { ...state, isOnlySignup: action.payload };
        case 'SAVE_IS_OPEN_PRIVACY':
            return { ...state, isOpenPrivacy: action.payload };
        default:
            return state;
    }
};

const saveFeatures = (dispatch) => {
    return async (featuresObject) => {
        const features = featuresObject
            ? featuresObject
            : initialState.features;
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            features
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_FEATURES', payload: featuresObject });
    };
};

const saveUser = (dispatch) => {
    return async (userObject) => {
        // Set username to local storage
        const currentUser = {
            userFirstname: userObject.userFirstname,
            userEmail: userObject.userEmail,
            userId: userObject.userId,
            emailVerifiedAt: userObject.emailVerifiedAt,
            phoneVerifiedAt: userObject.phoneVerifiedAt,
            subscriber: userObject.subscriber,
            role: userObject.role,
            theme: userObject.theme,
            token: userObject.token,
            tokenExpires: userObject.tokenExpires,
            lastDayOfSubscription: userObject.lastDayOfSubscription,
            subscriptionDueDate: userObject.subscriptionDueDate
        };

        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            currentUser
        };

        setLocalStorageJson(key, updatedLocalStorageData);

        dispatch({ type: 'SAVE_USER', payload: userObject });
    };
};

const onLogout = (dispatch) => {
    return async () => {
        console.log('Logging out');
        // Clear local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            currentUser: { currentUser: {} },
            childInRegFlow: {}
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        //Remove cookie
        document.cookie = 'currentUser=';
        document.cookie = `token=`;
        //Refresh page
        window.location.reload();
        // document.cookie = `locationHistory=`;
        dispatch({ type: 'LOGOUT' });
    };
};
// should this be in a different context?
const saveLocation = (dispatch) => {
    // return async (locationHistory) => {
    //   console.log("Saving location history");
    //   // Clear previous location cookie
    //   document.cookie = "locationHistory=";
    //   //Set location to cookies
    //   document.cookie = `locationHistory=${locationHistory}`;
    //   // Save location to context
    //   dispatch({ type: "SAVE_LOCATION", payload: locationHistory });
    // };
};
const saveChildInRegFlow = (dispatch) => {
    return async (childInRegFlow) => {
        // If childInRegFlow is an empty array, then we do not need expiry dated
        if (Object.keys(childInRegFlow).length !== 0) {
            const now = new Date();
            // 3 days in miliseconds
            const threeDays = 259200000;
            // Set expiry time
            childInRegFlow.expiry = now.getTime() + threeDays;
        }

        // Set registration data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            childInRegFlow
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_CHILD_REGISTRATION_DATA',
            payload: childInRegFlow
        });
    };
};

const saveRegistrationData = (dispatch) => {
    return async (registrationData) => {
        const now = new Date();
        // 3 days in miliseconds
        const threeDays = 259200000;
        // Set expiry time
        registrationData.expiry = now.getTime() + threeDays;
        // Set registration data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            registrationData
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        // Clear previous registrationData cookie
        // Save username to context state - Is this even needed?
        dispatch({ type: 'SAVE_REGISTRATION_DATA', payload: registrationData });
    };
};

// When user fills the tilmelding/oplysninger page, we want to send his/her data in storage as regInformation
const saveRegInformation = (dispatch) => {
    return async (regInformation) => {
        const now = new Date();

        if (Object.keys(regInformation).length !== 0) {
            // 3 days in miliseconds
            const threeDays = 259200000;
            regInformation.expiry = now.getTime() + threeDays;
        }

        // Set regInformation data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            regInformation
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_REG_INFORMATION',
            payload: regInformation
        });
    };
};

// Saving only intro meeting registration data
const saveSimpleRegistrationData = (dispatch) => {
    return async (simpleRegistrationData) => {
        const now = new Date();
        // 3 days in miliseconds
        const threeDays = 259200000;
        // Set expiry time
        simpleRegistrationData.expiry = now.getTime() + threeDays;
        // Set registration data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            simpleRegistrationData
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        // Clear previous registrationData cookie
        document.cookie = 'simpleRegistrationData=';
        document.cookie = `simpleRegistrationData=${JSON.stringify(
            simpleRegistrationData
        )}`;
        dispatch({
            type: 'SAVE_SIMPLE_REGISTRATION_DATA',
            payload: simpleRegistrationData
        });
    };
};

const saveCoursesData = (dispatch) => {
    return async (coursesData) => {
        // Set courses to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            coursesData
        };
        setLocalStorageJson(key, updatedLocalStorageData);

        //Clear previous coursesData cookie, add new
        // document.cookie = "coursesData=";
        // document.cookie = `coursesData=${JSON.stringify(coursesData)}`;
        dispatch({ type: 'SAVE_COURSES_DATA', payload: coursesData });
    };
};

const saveRatesData = (dispatch) => {
    return async (ratesData) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            ratesData
        };

        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_RATES_DATA', payload: ratesData });
    };
};

const saveCustomFirstRate = (dispatch) => {
    return async (customFirstRate) => {
        dispatch({ type: 'SAVE_CUSTOM_FIRST_RATE', payload: customFirstRate });
    };
};
// Saves meetings to local storage/cookies
const saveMeetingsData = (dispatch) => {
    return async (meetingsData) => {
        // Set meetings data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            meetingsData
        };

        setLocalStorageJson(key, updatedLocalStorageData);
        //Clear previous meetingsData cookie, add new
        document.cookie = 'meetingsData=';
        document.cookie = `meetingsData=${JSON.stringify(meetingsData)}`;
        dispatch({ type: 'SAVE_MEETINGS_DATA', payload: meetingsData });
    };
};
//Save event data
const saveGeneralEventDetailsData = (dispatch) => {
    return async (eventDetailsData) => {
        dispatch({
            type: 'SAVE_GENERAL_EVENT_DETAILS_DATA',
            payload: eventDetailsData
        });
    };
};
//Save event data
const saveIsOpenEventDetails = (dispatch) => {
    return async (isOpenEventDetails) => {
        dispatch({
            type: 'SAVE_IS_OPEN_EVENT_DETAILS',
            payload: isOpenEventDetails
        });
    };
};

// What is the difference between saveChildInRegFlow and this?
// chilsInRegFlow is used to load the child that is currently being loaded in the registration flow
// - e.g. user would like to add more children before payment, they can go back and edit the selected children.
// allChildrenInReg is to contain all the children data.
const saveAllChildrenInReg = (dispatch) => {
    return async (allChildrenInReg) => {
        // Set regInformation data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            allChildrenInReg
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_ALL_CHILDREN_IN_REG',
            payload: allChildrenInReg
        });
    };
};

const saveChildToModify = (dispatch) => {
    return async (childToModify) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            childToModify
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_CHILD_TO_MODIFY',
            payload: childToModify
        });
    };
};

const saveSelectedIntroMeeting = (dispatch) => {
    return async (selectedIntroMeeting) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            selectedIntroMeeting
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_SELECTED_INTRO_MEETING',
            payload: selectedIntroMeeting
        });
    };
};

const saveAllWorkdays = (dispatch) => {
    return async (allWorkdays) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            allWorkdays
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_ALL_WORKDAYS',
            payload: allWorkdays
        });
    };
};

// Ensuring communication between ChooseCourse and Birthdate pages. If child is already registered, user is going to be redirected to birthdate page
// where a modal will be displaying that explains the redirect - the user must log in to reenroll an existing child.
const saveChildAlreadyRegistered = (dispatch) => {
    return async (childAlreadyRegistered) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            childAlreadyRegistered
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_CHILD_ALREADY_REGISTERED',
            payload: childAlreadyRegistered
        });
    };
};

const saveOnlySignup = (dispatch) => {
    return async (onlySignup) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            onlySignup
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_ONLY_SIGNUP', payload: onlySignup });
    };
};

const saveIsOpenPrivacy = (dispatch) => {
    return async (isOpenPrivacy) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isOpenPrivacy
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_IS_OPEN_PRIVACY', payload: isOpenPrivacy });
    };
};

export const { Context, Provider } = createDataContext(
    userReducer,
    {
        saveFeatures,
        saveUser,
        onLogout,
        saveLocation,
        saveRegistrationData,
        saveSimpleRegistrationData,
        saveMeetingsData,
        saveCoursesData,
        saveRatesData,
        saveCustomFirstRate,
        saveGeneralEventDetailsData,
        saveIsOpenEventDetails,
        saveChildInRegFlow,
        saveRegInformation,
        saveAllChildrenInReg,
        saveChildToModify,
        saveSelectedIntroMeeting,
        saveAllWorkdays,
        saveChildAlreadyRegistered,
        saveOnlySignup,
        saveIsOpenPrivacy
    },
    initialState
);
