import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Headline = styled(Typography)`
    margin-bottom: ${(props) =>
        props.marginbottom ? props.marginbottom : '3rem'};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: ${(props) =>
            props.mobilemarginbottom && props.mobilemarginbottom};
    }
`;

function RegularHeadline({
    title,
    component,
    marginbottom,
    mobileMarginBottom
}) {
    return (
        <Headline
            variant="h5"
            component={component}
            marginbottom={marginbottom}
            mobilemarginbottom={mobileMarginBottom}
        >
            {title}
        </Headline>
    );
}

export default RegularHeadline;
