import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../context/Context";

import { Box, Typography } from "@material-ui/core";
import ReturnLinkReusable from "../../../components/return-link/ReturnLinkReusable";
import NavHeaderMobile from "../../../components/headers/nav-header/NavHeaderMobile";
import NavHeaderDesktop from "../../../components/headers/nav-header/NavHeaderDesktop";
import ProgressBar from "../../../components/progress-bars/ProgressBar";
import RegistrationContentContainer from "../../../components/containers/registration/RegistrationContentContainer";
import RegularHeadline from "../../../components/headlines/RegularHeadline";
import { getOrderFromStorage } from "./SummaryFunctions";
import {
  getCourseName,
  getFirstPriorities,
  getProcessType,
  getSecondPriorities,
} from "../checkout/CheckoutFunctions";
import NavHeaderMobileContainer from "../../../components/containers/header/NavHeaderMobileContainer";

const DescriptionTextBold = styled(Typography)`
  font-family: "Benton Sans Bold";
  margin-right: 0.3rem;

  &.bigger-margin {
    margin-bottom: 1rem;
  }
`;

const DescriptionText = styled(Typography)`
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
  }
`;

const DescriptionContainer = styled(Box)`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
    margin-bottom: 1rem;
  }
`;

function Summary(props) {
  const [allChildrendData, setAllChildrenData] = useState([]);

  const context = useContext(Context);

  // Get data from local storage
  // ComponentDidMount
  useEffect(() => {
    // Check if user has previous registration steps filled
    getOrderFromStorage(setAllChildrenData, props);

    // Clear registration, meetings and courses data from local storage
    context.saveChildInRegFlow({});
    context.saveMeetingsData({});
    context.saveCoursesData({});
    context.saveRegInformation({});
    context.saveSelectedIntroMeeting({});
    context.saveAllChildrenInReg([]);
    context.saveAllWorkdays([]);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavHeaderMobileContainer>
        <NavHeaderMobile />
      </NavHeaderMobileContainer>

      <NavHeaderDesktop />
      <RegistrationContentContainer marginTop="2.5rem" mobilePaddingTop="1rem">
        <ProgressBar currentStage={"Opsummering"} />
        <Box width="fit-content">
          <ReturnLinkReusable url="/" destination="Forsiden" />
        </Box>

        <RegularHeadline component="h1" title="Opsummering" />
        <Box mb="2rem">
          <DescriptionTextBold variant="subtitle1">
            The payment has been successfull.
          </DescriptionTextBold>
        </Box>
        <Box mb="2rem">
          <Box mb="1rem">
            <Typography variant="subtitle1">
              You have purchased the following:
            </Typography>
          </Box>
          {allChildrendData.length > 0 ? (
            <React.Fragment>
              {allChildrendData.map((child) => (
                <React.Fragment>
                  <DescriptionContainer>
                    <DescriptionTextBold variant="body2">
                      Vare:
                    </DescriptionTextBold>
                    <DescriptionText variant="body2">
                      Musikundervisning for {child.childFirstname}{" "}
                      {child.childSurname}
                    </DescriptionText>
                  </DescriptionContainer>

                  <DescriptionContainer>
                    <DescriptionTextBold variant="body2">
                      Kursustype:
                    </DescriptionTextBold>
                    <DescriptionText variant="body2">
                      {getCourseName(child)}
                    </DescriptionText>
                  </DescriptionContainer>

                  <DescriptionContainer mb="1rem">
                    <DescriptionTextBold variant="body2">
                      Tilmeldingstype:
                    </DescriptionTextBold>
                    <DescriptionText variant="body2">
                      {getProcessType(child)}
                    </DescriptionText>
                  </DescriptionContainer>
                  <Box mb="1rem">
                    <DescriptionTextBold variant="body2">
                      Første prioritet:
                    </DescriptionTextBold>
                    {getFirstPriorities(child, child.process.price) &&
                    getFirstPriorities(child, child.process.price).length >
                      0 ? (
                      <React.Fragment>
                        {getFirstPriorities(child, child.process.price).map(
                          (prio) => (
                            <Box mb="1rem" key={prio.id}>
                              <DescriptionText variant="body2">
                                {prio.day} kl. {prio.time} på {prio.location}{" "}
                                med {prio.teacher}
                              </DescriptionText>
                              <DescriptionText variant="body2">
                                Fuld pris: DKK {prio.priceSum}
                              </DescriptionText>
                            </Box>
                          )
                        )}
                      </React.Fragment>
                    ) : (
                      <Box mb="2rem">
                        <DescriptionText>Ikke vælgt</DescriptionText>
                      </Box>
                    )}
                  </Box>

                  <Box>
                    <DescriptionTextBold variant="body2">
                      Anden prioritet:
                    </DescriptionTextBold>
                    {getSecondPriorities(child, child.process.price) &&
                    getSecondPriorities(child, child.process.price).length >
                      0 ? (
                      <React.Fragment>
                        {getSecondPriorities(child, child.process.price).map(
                          (prio) => (
                            <Box mb="1rem" key={prio.id}>
                              <DescriptionText variant="body2">
                                {prio.day} kl. {prio.time} på {prio.place} med{" "}
                                {prio.teacher}
                              </DescriptionText>
                              <DescriptionText variant="body2">
                                Fuld pris: DKK {prio.priceSum}
                              </DescriptionText>
                            </Box>
                          )
                        )}
                      </React.Fragment>
                    ) : (
                      <Box mb="2rem">
                        <DescriptionText>Ikke vælgt</DescriptionText>
                      </Box>
                    )}
                  </Box>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : (
            <DescriptionText variant="body2">No children</DescriptionText>
          )}

          {/* <HorizontalLine /> */}

          {/* For now products are not available */}
          {/* <DescriptionTextBold variant="body2" customclassname="bigger-margin">
            Tilkøb:
          </DescriptionTextBold>
          {products.map((product) => (
            <DescriptionText key={product.productName} variant="body2">
              {product.productName} 150,-
            </DescriptionText>
          ))} */}
        </Box>
        <Box width="fit-content">
          <ReturnLinkReusable url="/" destination="Forsiden" />
        </Box>
      </RegistrationContentContainer>
    </div>
  );
}

export default withRouter(Summary);
