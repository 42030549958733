import React, { useEffect, useContext, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import { withCookies } from 'react-cookie';
import { getCookie } from '../../../context/getData';

const domain = document.location.hostname; // For example: dev.denlillemusikskole.dk, test.denlillemusikskole.dk
const cookiePrivacyLevel = domain + ':privacy-level';
const CustomDialogContent = styled(DialogContent)`
    overflow: hidden;
`;

const CustomDialogActions = styled(DialogActions)`
    margin-right: 2rem;
`;

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
        box-shadow: inset 0 0 5px black;
    }
    position: relative;
    @media (max-width: 350px) {
        & .MuiPaper-root {
            padding: 0.2rem !important;
        }
        & .MuiDialogContentText-root {
            font-size: 0.9rem;
        }
        & .MuiTypography-h6 {
            font-size: 1.2rem;
        }
    }
`;
const ButtonContainer = styled.div`
    min-width: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
        color: #593c77;
    }
    @media (max-width: 350px) {
        width: 4rem;
    }
`;

function PrivacyDialog(props) {
    const context = useContext(Context);
    const [open, setOpen] = useState(false);
    const [level, setLevel] = useState(4);

    const setCookie = (name, value) => {
        const { cookies } = props;
        // Make cookie available to any path /
        cookies.set(name, value, { path: '/' });
    };

    const handleAccept = () => {
        setCookie(cookiePrivacyLevel, level);
        setOpen(false);
    };
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const showPrivacy = () => {
        context.saveIsOpenPrivacy(true);
        setOpen(false);
    };

    // componentDidMount
    useEffect(() => {
        let value = 0;
        try {
            value = parseInt(getCookie(cookiePrivacyLevel));
        } catch {}
        if (value > 0) {
            setOpen(false);
            console.log('Accepted privacy level', value);
            setLevel(value);
        } else if (value === 0) {
            setOpen(true);
        }
    }, []);

    return (
        <DialogComponent
            open={open}
            onClose={handleClose}
            aria-labelledby="privacy"
            aria-describedby="privacy"
            disableRestoreFocus
            disableEscapeKeyDown
        >
            <DialogTitle id="privacy">Velkommen til Leonardo</DialogTitle>
            <CustomDialogContent>
                <DialogContentText id="privacy">
                    Vi bruger cookies på vores hjemmeside for at give dig den
                    mest relevante oplevelse ved at huske dine præferencer og
                    gentagne besøg. Ved at klikke på "Tillad" accepterer du
                    brugen af ALLE cookies.
                </DialogContentText>
            </CustomDialogContent>
            <CustomDialogActions>
                <ButtonContainer
                    id="privacy-dialog-button"
                    onClick={() => handleAccept()}
                >
                    Tillad
                </ButtonContainer>
                <ButtonContainer onClick={() => showPrivacy()}>
                    Vælg indstillinger
                </ButtonContainer>
            </CustomDialogActions>
        </DialogComponent>
    );
}

export default withCookies(PrivacyDialog);
