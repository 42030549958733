import React, { useEffect, useState } from 'react';
import {
    hasErrors,
    validateEmail
} from '../../containers/registration/information/RegisterInformationFunctions';
import InformationTextInput from '../text-input/InformationTextInput';
import {
    getAndReturnData,
    postAndReturnResp,
    putAndReturnResp,
    removeWhiteSpace
} from '../../context/helperFunctions';
import RegistrationButton from '../buttons/RegistrationButton/RegistrationButton';
import ErrorDialog from '../dialogs/error-dialog/ErrorDialog';
import Loader from '../loader/Loader';
import { getUserId } from '../../context/getData';
import SuccessDialog from '../dialogs/success-dialog/SuccessDialog';
import { getUser } from '../../util';

function ChangePaymentEmail(props) {
    const [emailObj, setEmailObj] = useState({
        label: 'email',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Betalingsmail',
        placeholder: 'Betalingsmail'
    });
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleError = (errorMessage) => {
        setIsLoading(false);
        setError(true);
        setErrorMessage(errorMessage);
    };

    const onSubmit = async () => {
        if (!emailObj.error) {
            setIsLoading(true);
            const response = await putAndReturnResp(
                `/api/v1/user/${getUserId()}/payment-email`,
                { paymentEmail: emailObj.value },
                handleError
            );
            if (response) {
                if (response.status === 200) {
                    setSuccess(true);
                    setIsLoading(false);
                } else {
                    setError(true);
                    setErrorMessage('Kunne ikke gemme emailen. Prøv igen. ');
                    setIsLoading(false);
                }
            }
        }
    };

    const saveInputValue = (valueObject) => {
        const valueNoWhiteSpace = removeWhiteSpace(valueObject.value);
        validateEmail(valueNoWhiteSpace, setEmailObj, emailObj);
    };

    const removeErrorOnChange = () => {
        emailObj.error = false;
        emailObj.errorMessage = '';
    };

    const getAndSetPaymentEmailFromDb = async () => {
        const data = await getAndReturnData(
            `/api/v1/user/${getUserId()}/payment-email`
        );
        if (data && data.paymentEmail) {
            setEmailObj({ ...emailObj, value: data.paymentEmail });
        }
    };

    // componentDidMount
    useEffect(() => {
        getAndSetPaymentEmailFromDb();
    }, []);

    return (
        <div>
            <InformationTextInput
                stateObj={emailObj}
                onInputChange={saveInputValue}
                removeErrorOnChange={removeErrorOnChange}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <RegistrationButton
                    marginTop={'1rem'}
                    onButtonClick={onSubmit}
                    title="Gem"
                />
            )}

            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => setError(false)}
                />
            )}

            {success && (
                <SuccessDialog
                    title="Succes"
                    successMessage={'Betalingsmailen er gemt.'}
                    close={() => setSuccess(false)}
                />
            )}
        </div>
    );
}

export default ChangePaymentEmail;
