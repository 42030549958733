import React from 'react';
import styled from 'styled-components';

const ArrowContainer = styled.div`
font-size: 1.8rem;
padding: 13.8rem 0rem;
cursor: pointer;
position: absolute;
left: 7.5%;
@media (max-width: 960px) {
position: static;
padding: 0px;
}
`

function LeftArrow(props) {
    const goToPrevSlide = () => {
        props.currentSlide === 0 ? props.onClick(props.slidesNumber - 1) : props.onClick(props.currentSlide - 1);
    }

    return (
        <ArrowContainer onClick={goToPrevSlide}>
            &#60;
        </ArrowContainer>
    );
}

export default LeftArrow;