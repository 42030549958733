import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withCookies } from "react-cookie";
import {
  // postAndReturnResponse,
  postAndReturnDataAndResponse,
  validateEmail,
  removeWhiteSpace,
} from "../../../context/helperFunctions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { Typography, Box, FormHelperText } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import { returnAuthenticated } from "../../../context/getData";
import Loader from "../../loader/Loader";
import RegularButton from "../../buttons/RegularButton/RegularButton";

const DialogComponent = styled(Dialog)`
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
  }

  & .MuiPaper-root {
    box-shadow: inset 0 0 5px grey;
    width: 22rem;
    background-color: ${(props) => props.theme.palette.common.black};
    // display: ${(props) => (props.open ? "block" : "none")}
  }
  & .MuiTypography-h6 {
    font-size: 1.1rem;
  }
  @media (max-width: 960px) {
    & .MuiBackdrop-root {
      overflow: visible;
    }
  }
`;
const InputLabelComponent = styled(InputLabel)`
  color: ${(props) => props.theme.palette.common.white};
  margin-top: 0.8rem;
`;
const TextFieldComponent = styled(TextField)`
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
  width: 100%;
  & .MuiInputBase-root {
    padding: 5px;
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 0px;
    // -webkit-box-shadow: 0 0 0 100px transparent inset;
    -webkit-box-shadow: 0 0 0 100px
      ${(props) => props.theme.palette.common.black} inset;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.palette.common.white};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${(props) =>
        props.error
          ? props.theme.palette.error.main
          : props.theme.palette.common.white};
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.error.main};
  }
  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
    max-width: fit-content;
  }
  .MuiFormHelperText-contained {
    margin: 5px 0 0 0;
  }
`;
const DialogActionsContainer = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 8px 10px 8px;
`;
const BoxContainer = styled(Box)`
  & .MuiFormHelperText-root {
    color: ${(props) =>
      props.successReq
        ? props.theme.palette.success.main
        : props.theme.palette.error.main};
    text-align: center;
    font-size: 0.9rem;
    margin: 0rem 1rem;
  }
`;
const SubmitButtonContainer = styled.div`
  width: 100%;
  padding: 10px 12px;
`;
const BackToLogin = styled(Typography)`
  text-decoration: underline;
  font-size: 1rem;
  margin: 0.5rem 0 0.5rem 20px;
`;
function ForgotPassword(props) {
  const [userName, setUserName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(true);
  // const [showPassword, setShowPassword] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [emailValidateMessage, setEmailValidateMessage] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [prevLocation, setPrevLocation] = useState("/");

  const handleChange = (event) => {
    setUserName(event.target.value);
  };
  const handleBlur = (event) => {
    if (!validateEmail(removeWhiteSpace(userName)) && userName.length > 0) {
      setUserNameError(true);
      setEmailValidateMessage("Invalid email");
    } else {
      setEmailValidateMessage("");
      setUserNameError(false);
    }
  };

  const handleError = (message) => {
    setSubmitMessage(message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const requestBody = {
      email: removeWhiteSpace(userName),
    };
    if (userName.length === 0) {
      setSuccessRequest(false);
      setSubmitMessage("Du skal skrive din email.");
      setUserNameError(true);
    } else if (!validateEmail(removeWhiteSpace(userName))) {
      setSuccessRequest(false);
      setUserNameError(true);
      setSubmitMessage("Ugyldig emailadresse");
    } else {
      // POST request to backend
      const result = await postAndReturnDataAndResponse(
        "/api/v1/user/password",
        requestBody,
        handleError
      );

      if (result) {
        if (result.response.status === 200) {
          setSuccessRequest(true);
          setUserNameError(false);
          setSubmitMessage("Vi har nu sendt en mail til dig.");
        } else if (result.response.status === 404) {
          setSuccessRequest(false);
          handleError("Denne email har vi ikke registretet.");
        } else {
          setSuccessRequest(false);
          handleError("Der er sket en fejl.");
        }
      } else {
        setSuccessRequest(false);
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
    if (!prevLocation) {
      props.history.push("/");
    }
    props.history.push(prevLocation);
  };

  //ComponentDidMount
  useEffect(() => {
    // Check in local storage if user is authenticated

    //Redirects user if already logged in.
    if (returnAuthenticated()) {
      props.history.goBack();
    }
    if (
      props.prevLocation.pathname !== "/glemt-dit-kodeord" &&
      props.prevLocation.pathname !== "/login"
    ) {
      setPrevLocation(props.prevLocation.pathname);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {/* If isModalView false, then the background should not be visible, since the modal's own route was opened - so there is no page in the background. */}
      <DialogComponent
        fullpageview={props.fullPageView}
        open={dialogOpen}
        aria-labelledby="form-dialog-title"
        onSubmit={handleSubmit}
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
          Udfyld din emailadresse for at nulstille dit kodeord
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <InputLabelComponent shrink htmlFor="username">
              Email
            </InputLabelComponent>
            <TextFieldComponent
              type="email"
              // InputProps={{ disableUnderline: true }}
              margin="dense"
              id="username"
              // onFocus={handleFocus}
              fullWidth
              value={userName}
              onChange={handleChange}
              variant="outlined"
              // onFocus={handleFocus}
              onBlur={handleBlur}
              error={userNameError}
              helperText={emailValidateMessage}
              required
            />
          </DialogContent>

          <BoxContainer maxWidth="fit-content" successReq={successRequest}>
            <FormHelperText>{submitMessage}</FormHelperText>
          </BoxContainer>

          <DialogActionsContainer>
            {isLoading ? (
              <Box ml="auto" mr="auto">
                <Loader />
              </Box>
            ) : (
              <SubmitButtonContainer>
                <RegularButton title="Send" onButtonClick={handleSubmit} />
              </SubmitButtonContainer>
            )}

            <BackToLogin>
              <Link
                to={{
                  pathname: "/login",
                  // state: { data: eventInformationFromApi },
                }}
              >
                Tilbage til Login
              </Link>
            </BackToLogin>
          </DialogActionsContainer>
        </form>
      </DialogComponent>
    </div>
  );
}

export default withRouter(withCookies(ForgotPassword));
