import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const MonthBoxNotSelected = styled.div`
  width: 7rem;
  height: 5rem;
  background-color: ${(props) =>
    props.isDisabled ? "rgba(71, 71, 71, .8)" : "transparent"};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 6rem;
    height: 5rem;
  }
`;

const MonthBoxSelected = styled.div`
  width: 7rem;
  height: 5rem;
  background-color: ${(props) => props.theme.palette.colors.purple};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 6rem;
    height: 5rem;
  }
`;

const MonthName = styled(Typography)`
  text-align: center;
  padding-top: 1rem;
  font-size: 0.8rem;
  color: ${(props) =>
    props.isDisabled
      ? "rgba(179, 179, 179, .8)"
      : props.theme.palette.primary.contrastText};
`;

const Price = styled(Typography)`
  text-align: center;
  padding-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${(props) =>
    props.isDisabled
      ? "rgba(179, 179, 179, .8)"
      : props.theme.palette.primary.contrastText};
`;

function MonthBox({
  monthName,
  price,
  selected,
  handleMonthSelection,
  isDisabled,
}) {
  const [isSelected, setIsSelected] = useState(false);

  const onMonthClick = () => {
    setIsSelected(!isSelected);
    handleMonthSelection(monthName);
  };

  // Check if month is selected
  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <React.Fragment>
      {!selected ? (
        <MonthBoxNotSelected
          isDisabled={isDisabled}
          selected={selected}
          onClick={() => onMonthClick()}
        >
          <MonthName isDisabled={isDisabled}>{monthName}</MonthName>
          <Price isDisabled={isDisabled}>DKK {price}</Price>
        </MonthBoxNotSelected>
      ) : (
        <MonthBoxSelected selected={selected} onClick={() => onMonthClick()}>
          <MonthName>{monthName}</MonthName>
          {isNaN(price) ? <Price>DKK 0</Price> : <Price>DKK {price}</Price>}
        </MonthBoxSelected>
      )}
    </React.Fragment>
  );
}

export default MonthBox;
