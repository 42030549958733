import React from "react";
import ContentContainer from "../../components/containers/ContentContainer";

function Participants({ data }) {
  return (
    <div>
      <ContentContainer>
        <h1>Deltagere</h1>
        <ul>
          {data.map((participant) => (
            <li key={participant.name}>{participant.name}</li>
          ))}
        </ul>
      </ContentContainer>
    </div>
  );
}

export default Participants;
