import React from "react";
import { withRouter } from "react-router-dom";
import DesktopCalendar from "./Calendar";
import MobileCalendar from "../global-calendar-mobile/MobileCalendar";
import { Box } from "@material-ui/core";

function CalendarPage(props) {
  // let json = getLocalStorageJson(localStorageKey);

  return (
    <>
      {document.body.offsetWidth < 960 ? (
        <Box width="90%" ml="auto" mr="auto">
          <MobileCalendar /> 
        </Box>
      ) : (
         <DesktopCalendar />
      )}
    </>
  );
}

export default withRouter(CalendarPage);
