import React from "react";
import styled from "styled-components";

const MailImageComponent = styled.img`
  height: ${(props) => props.height}rem;
`;

function MailImage({ src, height, alt }) {
  return <MailImageComponent src={src} height={height} alt={alt} />;
}

export default MailImage;
