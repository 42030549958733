import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import VisibilityIconComponent from "../visibility-icon/VisibilityIconComponent";

const InputLabelComponent = styled(InputLabel)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  margin-bottom: 0.5rem;
  font-size: 0.875rem;

  &.MuiInputLabel-formControl {
    position: static;
    transform: none;
  }
`;

const TextFieldComponent = styled(TextField)`
  width: 20rem;

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.primary.contrastText};
    border-radius: 0;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.primary.light};
  }

  .MuiOutlinedInput-input {
    padding-top: 0.6rem;
    padding-bottom: 0.7rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }

  // The followings helps so the textfields are not to far from each other.
  @media (min-width: 1050px) {
    width: 21rem;
  }

  @media (min-width: 1120px) {
    width: 22rem;
  }

  @media (min-width: 1120px) {
    width: 23rem;
  }
`;

const CustomFormControl = styled(FormControl)`
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const TextInputContainer = styled.div`
  // margin-right: 2rem;
  margin-bottom: ${(props) => (props.mb ? props.mb : "1rem")};
  padding-top: 1rem;
  position: relative;
  width: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const VisibilityContainer = styled.div`
  position: absolute;
  top: 3rem;
  right: 0.5rem;
`;

function InformationTextInput({
  stateObj,
  onInputChange,
  type,
  visible,
  handleVisibility,
  customClass,
  marginBottom,
  handleEnterPress,
  removeErrorOnChange,
  required,
  onSubmit,
}) {
  const [value, setValue] = useState("");
  // Next is needed for onBlur
  const [unsavedValue, setUnsavedValue] = useState(value);

  const handleInputChange = (e) => {
    removeErrorOnChange(stateObj.danishLabel);
    // Remove error message
    setUnsavedValue(e.target.value);
  };

  // Send value to parent on onBlur
  const handleInputChangeOnBlur = () => {
    setValue(unsavedValue);
    const valueObject = {
      label: stateObj.danishLabel,
      value: unsavedValue,
    };
    onInputChange(valueObject, onSubmit);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleEnterPress();
    }
  };

  useEffect(() => {
    if (stateObj.value === null) {
      setValue("");
      setUnsavedValue("");
    } else {
      setValue(stateObj.value);
      setUnsavedValue(stateObj.value);
    }
  }, [stateObj]);

  return (
    <CustomFormControl error={stateObj.error} className={customClass}>
      <TextInputContainer mb={marginBottom}>
        <Box display="flex" justifyContent="space-between">
          <InputLabelComponent htmlFor={stateObj.danishLabel}>
            {stateObj.danishLabel}
          </InputLabelComponent>
          {required && (
            <InputLabelComponent htmlFor={stateObj.danishLabel}>
              *
            </InputLabelComponent>
          )}
        </Box>

        <TextFieldComponent
          id={stateObj.danishLabel}
          variant="outlined"
          onChange={(event) => handleInputChange(event)}
          value={unsavedValue}
          aria-describedby={stateObj.danishLabel}
          type={type}
          onBlur={handleInputChangeOnBlur}
          onKeyPress={(e) => handleKeyPress(e)}
          placeholder={stateObj.placeholder}
        />
        {(stateObj.danishLabel === "Kodeord" ||
          stateObj.danishLabel === "Gentag kodeord") && (
          <VisibilityContainer>
            <VisibilityIconComponent
              visible={!visible}
              handleVisibility={handleVisibility}
            />
          </VisibilityContainer>
        )}
        <Box maxWidth="fit-content">
          <FormHelperText>{stateObj.errorMessage}</FormHelperText>
        </Box>
      </TextInputContainer>
    </CustomFormControl>
  );
}

export default InformationTextInput;
