import moment from 'moment';
import { getToken } from '../../context/getData';
import { getAndReturnDataWithAuth } from '../../context/helperFunctions';
import { getAndReturnData } from '../HelperFunctions';

export const getUserInfo = async (
    userId,
    setUserFirstname,
    setUserSurname,
    handleError,
    setIsLoading
) => {
    const token = getToken();
    const userData = await getAndReturnDataWithAuth(
        `api/v1/user/${userId}`,
        token,
        handleError
    );
    console.log('show userData', userData);

    if (userData && userData.firstname && userData.surname) {
        setUserFirstname(userData.firstname);
        setUserSurname(userData.surname);
        setIsLoading(false);
    } else {
        handleError();
        setIsLoading(false);
    }
};

export const getParticipants = async (
    userId,
    setFamilies,
    handleError,
    setIsLoading
) => {
    const familiesData = await getAndReturnDataWithAuth(
        `/api/v1/user/${userId}/participant`,
        getToken(),
        handleError
    );

    console.log('show familes', familiesData);

    if (familiesData && familiesData.families) {
        setFamilies(familiesData.families);
        setIsLoading(false);
    } else {
        handleError();
        setIsLoading(false);
    }
};

export const getCompanions = async (
    userId,
    setCompanions,
    handleError,
    setIsLoading
) => {
    const companionsData = await getAndReturnData(
        `/api/v1/user/${userId}/companions`,
        handleError
    );
    if (companionsData) {
        setCompanions(companionsData);
        setIsLoading(false);
    } else {
        handleError();
        setIsLoading(false);
    }
};

export const getColor = (courses) => {
    if (courses.length > 0) {
        if (courses[0].name.includes('Musikalsk Legestue')) {
            return 'yellow';
        } else if (courses[0].name.includes('Høreleg')) {
            return 'green';
        } else if (courses[0].name.includes('Babymusik')) {
            return 'blue';
        } else {
            return 'purple';
        }
    } else {
        return 'green';
    }
};

export const isCurrentUser = (id, userId) => {
    if (id === userId) {
        return true;
    } else {
        return false;
    }
};

export const getMeetingsOfUser = async (
    userId,
    setUpcomingMeetings,
    setPastMeetings,
    handleError,
    setPromptIntroMeeting
) => {
    console.log('get upcoming meetings of user');
    const meetingsData = await getAndReturnData(
        `/api/v1/user/${userId}/events`,
        handleError
    );

    console.log(meetingsData);

    if (
        meetingsData &&
        meetingsData.message &&
        meetingsData.message === 'Successful get user events' &&
        meetingsData.userEvents
    ) {
        const today = moment();
        const upcomingMeetings = meetingsData.userEvents.filter((meeting) =>
            moment(meeting['Event.startdate']).isSameOrAfter(today)
        );
        const pastMeetings = meetingsData.userEvents.filter((meeting) =>
            moment(meeting['Event.startdate']).isSameOrBefore(today)
        );
        setUpcomingMeetings(upcomingMeetings);
        setPastMeetings(pastMeetings);

        // Go through the pas meetings, and check if the user has been to intro meeting
        if (
            pastMeetings.some((meeting) => {
                return meeting['EventParticipants.status'] === 'joined';
            }) &&
            upcomingMeetings.some((meeting) => {
                return meeting['Event.title'] === 'Introduktionsmøde';
            })
        ) {
            console.log('got in');
            setPromptIntroMeeting(false);
        }
    }
};
