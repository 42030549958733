import React from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Loader from "../../loader/Loader";
import { useState } from "react";
import { deleteAndReturnRespWithAuth } from "../../../context/helperFunctions";
import { getToken, getUserId } from "../../../context/getData";
import RegularButton from "../../buttons/RegularButton/RegularButton";

const DialogComponent = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem !important;
  }
`;

const ButtonContainer = styled.div`
  width: 12rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const CloseIcon = styled(Typography)`
  font-size: 2.8rem;
  position: absolute;
  right: 1rem;
  top: 0rem;
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

function DeleteProfileDialog({ close, message, subscriber, userId, context }) {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [errorType, setErrorType] = useState();

  const handleErrors = () => {
    setError(true);
  };

  // Come back, what happens here?
  const handleDeleteProfile = async () => {
    setIsLoading(true);
    const token = getToken();

    // If user is subscriber, we show error to let the user cancel subscription first.
    // if (subscriber) {
    //   const cancelSubResponse = await deleteAndReturnRespWithAuth(
    //     `/api/v1/users/${userId}/subscription`,
    //     token,
    //     handleErrors
    //   );

    //   if (cancelSubResponse.status !== 200) {
    //     return setError(true);
    //   }
    // }

    const resp = await deleteAndReturnRespWithAuth(
      `api/v1/user/${getUserId()}`,
      token,
      handleErrors
    );
    if (resp && resp.status === 200) {
      setDeleted(true);
    } else if (resp && resp.status === 400) {
      setDeleted(true);
      setError(true);
      setErrorType(400);
    } else if (resp && resp.status === 403) {
      setDeleted(true);
      setError(true);
      setErrorType(403);
    } else {
      setDeleted(true);
      setError(true);
      setErrorType(500);
    }
    setIsLoading(false);
  };

  const logout = () => {
    context.onLogout();
  };

  return (
    <React.Fragment>
      {!deleted ? (
        <DialogComponent
          onClose={close}
          open={true}
          aria-labelledby="slet-profil"
          aria-describedby="Slet din profil."
          disableRestoreFocus
        >
          <CloseIcon onClick={() => close()}>&#10799;</CloseIcon>
          <DialogTitle>Vigtigt</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
            <DialogContentText>
              OBS! Vi gemmer de informationer om betalingstransaktioner, som er
              foreskrevet i regnskabsloven, se handelsbetingelserne.
            </DialogContentText>
            <br />
            {!subscriber ? (
              <DialogContentText>
                Vil du fortsætte med at slette din brugerprofil?
              </DialogContentText>
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <ButtonContainer>
              {isLoading ? (
                <Loader />
              ) : (
                <RegularButton
                  title="Bekræft"
                  onButtonClick={() => {
                    handleDeleteProfile();
                  }}
                />
              )}
            </ButtonContainer>
          </DialogActions>
        </DialogComponent>
      ) : (
        <React.Fragment>
          {error ? (
            <DialogComponent
              onClose={close}
              open={true}
              aria-labelledby="fejl"
              aria-describedby="fejl"
              disableRestoreFocus
            >
              <DialogContent>
                <DialogContentText>
                  {errorType === 403 &&
                    `Du kan ikke slette profilen, indtil din sidste reservation er udløbet.`}
                  {errorType === 400 &&
                    `Det er ikke muligt at slette profilen, hvis man har en aktiv abonnement. Opsig abonnementet først.`}
                  {errorType !== 400 &&
                    errorType !== 403 &&
                    "Der er sket en fejl. Prøv igen."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonContainer>
                  <RegularButton
                    title="Ok"
                    onButtonClick={() => {
                      close();
                    }}
                  />
                </ButtonContainer>
              </DialogActions>
            </DialogComponent>
          ) : (
            <DialogComponent
              onClose={() => logout()}
              open={true}
              aria-labelledby="profil-slettet"
              aria-describedby="Din profil er slettet."
              disableRestoreFocus
            >
              <DialogContent>
                <DialogContentText>Din profil er slettet.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonContainer>
                  <RegularButton
                    title="Ok"
                    onButtonClick={() => {
                      logout();
                    }}
                  />
                </ButtonContainer>
              </DialogActions>
            </DialogComponent>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default DeleteProfileDialog;
