import moment from 'moment';
import { getSelectedIntroMeeting } from '../../../context/getData';
import { postAndReturnRegistrationData } from '../../HelperFunctions';

const getTimeStamp = () => {
    const now = Date.now();
    return moment(now).format('YYYY-MM-DD HH:mm:ss');
};

// TODO Make the season variable a server side environment variable that the client loads,
// to allow for changing season, without having to build and deploy a new frontend.
const changeMonthToDate = (monthName) => {
    // Change season here for controlling what courses are offered during registration
    let season = '2023/2024';
    const part = season.split('/');
    const firstPart = part.length > 1 ? part[0] : '';
    const secondPart = part.length > 1 ? part[0] : '';

    switch (monthName) {
        // Season starts in august and we must open up new season 2-3 months before that
        case 'Aug':
            return firstPart + '-08-25';
        case 'Sep':
            return firstPart + '-09-25';
        case 'Okt':
            return firstPart + '-10-25';
        case 'Nov':
            return firstPart + '-11-25';
        case 'Dec':
            return firstPart + '-12-25';
        case 'Jan':
            return secondPart + '-01-25';
        case 'Feb':
            return secondPart + '-02-25';
        case 'Mar':
            return secondPart + '-03-25';
        case 'Apr':
            return secondPart + '-04-25';
        case 'Maj':
            return secondPart + '-05-25';
        case 'Jun':
            return secondPart + '-06-25';
        case 'Jul':
            return secondPart + '-07-25';
        default:
            break;
    }
};

const formatRates = (ratesArr) => {
    // Change the month names to date when the rates should be pulled from bank account
    if (ratesArr && ratesArr.length > 0) {
        const modifiedRatesArr = ratesArr.map((rateObj) => {
            return {
                price: rateObj.price,
                month: changeMonthToDate(rateObj.name)
            };
        });
        return modifiedRatesArr;
    }
};

const getAmountToReserve = (paymentRates) => {
    // Find the highest rate
    // For now it is hardcoded
    return 500;
};

// Make the right format for post request
const formatCourses = (allChildrenData, setError, setErrorMessage) => {
    let coursesOfChildren = [];

    allChildrenData.forEach((child) => {
        if (child && child.priorities) {
            const courses = child.priorities.map((prio) => {
                let priority;
                // Set Priority
                if (prio.type === 'first priority') {
                    priority = 'first';
                } else {
                    priority = 'second';
                }

                return { id: prio.courseId, priority };
            });
            // Simplified child obj = only contains data neccessary for sending to server.
            const simplifiedChildObj = {};

            simplifiedChildObj.selectedCourses = courses;

            simplifiedChildObj.comment = child.comment;
            simplifiedChildObj.participantId = child.participantId
                ? child.participantId
                : null;
            simplifiedChildObj.childFirstname = child.participantId
                ? null
                : child.childFirstname;
            simplifiedChildObj.childSurname = child.participantId
                ? null
                : child.childSurname;
            simplifiedChildObj.groupId = child.familyGroupId;
            simplifiedChildObj.familyGroupName = child.familyGroupName;

            simplifiedChildObj.amountToReserve = getAmountToReserve(
                child.rates.rates
            ); // We should find the highest rate to reserve

            const modifiedRates = formatRates(child.rates.rates);
            simplifiedChildObj.paymentRates = modifiedRates;
            coursesOfChildren.push(simplifiedChildObj);
        } else {
            setError(true);
            setErrorMessage('Noget gik galt, prøv igen.');
        }
    });

    return coursesOfChildren;
};

// Make the right format for post request
const formatParentMeetings = (allChildrenData) => {
    const parentMeetings = [];
    allChildrenData.forEach((child) => {
        if (
            child &&
            child.selectedMeetings &&
            child.selectedMeetings.selectedParentMeetings &&
            child.selectedMeetings.selectedExtraParentMeetings
        ) {
            child.selectedMeetings.selectedParentMeetings.forEach((meeting) =>
                parentMeetings.push({ id: meeting.id })
            );
            child.selectedMeetings.selectedExtraParentMeetings.forEach(
                (meeting) => parentMeetings.push({ id: meeting.id })
            );
        }
    });

    return parentMeetings;
};

// const getGiftCard = (regData) => {
//   if (regData.giftCard) {
//     return { id: regData.id, price: regData.price };
//   } else {
//     return {};
//   }
// };

// Get rates of all children, add the month rates together. So even though, we displayed the rates by children in the
// process page, if there are more children, we need to get that money at the same time (2 children's course in one month = 2 * monthly rate)
// const formatRates = (allChildrenData, setError, setErrorMessage) => {
//   const ratesArray = [];
//   allChildrenData.forEach((child) => {
//     if (child && child.rates) {
//       ratesArray.push(child.rates);
//     } else {
//       setError(true);
//       setErrorMessage("Noget gik galt. Prøv igen.");
//     }
//   });

//   console.log(ratesArray);
// };

// eslint-disable-next-line
const formatIntroMeetings = () => {
    const selectedIntroMeeting = getSelectedIntroMeeting();
    if (selectedIntroMeeting && selectedIntroMeeting.id) {
        return { id: selectedIntroMeeting.id };
    }
};

/* 
  Place an order with a number of orderlines for each product (a specific class) 
  as described in BackOffice OpenAPI REST API in doc/openapi/backoffice/restapi.yaml
  under Order schema component:

    components: # https://swagger.io/docs/specification/components/
    schemas:
      Order: # Can be referenced as '#/components/schemas/Order'


  Example below is from app/src/containers/registration/checkout/registrationData.json 

  POST https://dev.denlillemusikskole.dk/api/v1/user/1/order

  NB: Example post order request (new backend expects Bearer access token in header)
      Before bearer token was send in password request body field

  {
  "timestamp": "2021-03-10 13:48:33",
  "firstname": "Julie",
  "surname": "Toft",
  "childFirstname": "Selma",
  "childSurname": "Jensen",
  "email": "julie@email.dk",
  "phone": "2345435234",
  "street": "Bulowsvej",
  "housenumber": "10",
  "postcode": "2000",
  "city": "København V",
  "comment": "blabla",
  "orderItems": {
    "courses": [{"id": "2170", "priority": "first"}, {"id": "2171", "priority": "second"}],
    "introMeetings": { "id": "2777" },
    "parentMeetings": [],
    "extraParentMeetings": [],
    "workingDay": [{"meetingId": "1112"}]
  },
  "giftcard": {},
  "paymentRates": [
    { "month": "Jun", "amount": "660", "paid": false },
    { "month": "Jul", "amount": "660", "paid": false },
    { "month": "Aug", "amount": "660", "paid": false },
    { "month": "Sep", "amount": "660", "paid": false },
    { "month": "Okt", "amount": "660", "paid": false },
    { "month": "Nov", "amount": "660", "paid": false },
    { "month": "Dec", "amount": "660", "paid": false },
    { "month": "Jan", "amount": "660", "paid": false },
    { "month": "Feb", "amount": "660", "paid": false },
    { "month": "Mar", "amount": "660", "paid": false },
    { "month": "Apr", "amount": "0", "paid": false },
    { "month": "Maj", "amount": "0", "paid": false }
  ]
}
*/
// Make POST order request to server
export const sendOrder = async (
    allChildrenData,
    userId,
    type,
    amount,
    currency,
    description,
    rates,
    formRef,
    // webshopItems,
    setError,
    setErrorMessage,
    setIsDisabled
) => {
    // If there are no children added, we need to warn them, and they shouldn't continue with enrollment until they added a child.
    if (allChildrenData.length === 0) {
        setError(true);
        setErrorMessage('Du skal tilføje mindst et barn.');
        return;
    }

    if (!userId) {
        setError(true);
        setErrorMessage('Du skal være logged ind for at lave en tilmelding.');
        return;
    }

    // This customer specific JSON order structure used by the frontend
    // will be converted to the backend JSON order structure, with
    // orders and orderlines referencing products or each participant.
    const requestBody = {
        giftCard: {},
        orderItems: {
            courses: formatCourses(allChildrenData, setError, setErrorMessage),
            introMeetings: [{ id: getSelectedIntroMeeting().id }],
            parentMeetings: formatParentMeetings(allChildrenData),
            webshopItems: []
        },
        userId,
        type,
        currency,
        description,
        siteUrl: window.location.host,
        timestamp: getTimeStamp(),
        show3DSecure: 1
    };

    // Send order request data to server
    console.log('requestbody', requestBody);
    const response = await postAndReturnRegistrationData(
        `/api/v1/user/${userId}/order`,
        requestBody,
        setError,
        setErrorMessage
    );
    return response;
};

const hardcodedRequestBody = {
    currency: 'DKK',
    description: 'Musik kursus',
    giftCard: {},
    orderItems: {
        courses: [
            {
                amountToReserve: 500,
                childFirstname: 'Lilla',
                childSurname: 'Fisher',
                comment: '',
                participantId: null,
                familyGroupName: 'Fisher',
                groupDescription: null,
                groupId: null,
                selectedCourses: [
                    { id: 2176, priority: 'first' },
                    { id: 2177, priority: 'second' }
                ],
                paymentRates: [
                    { month: '2023-07-25', price: 0 },
                    { month: '2023-08-25', price: 0 },
                    { month: '2023-09-25', price: 0 },
                    { month: '2023-10-25', price: 0 },
                    { month: '2023-11-25', price: 0 },
                    { month: '2023-12-25', price: 0 },
                    { month: '2024-01-25', price: 0 },
                    { month: '2024-02-25', price: 990 },
                    { month: '2024-03-25', price: 990 },
                    { month: '2024-04-25', price: 990 },
                    { month: '2024-05-25', price: 990 },
                    { month: '2024-06-25', price: 990 }
                ]
            }
        ],
        introMeetings: [{ id: 135115 }],
        parentMeetings: [{ id: 8 }],
        userId: 225,
        siteUrl: 'dev.denlillemusikskole.dk',
        type: 'subscription',
        show3DSecure: 1
    }
};

// const getChildrenInfo = (allChildrenData) => {
//     let childrenInfo = [];
//     // eslint-disable-next-line
//     allChildrenData.map((child) => {
//         childrenInfo.push({
//             birthdate: child.birthdate,
//             childFirstname: child.childFirstname,
//             childSurname: child.childSurname,
//             childId: child.childId,
//             familyGroupName: child.familyGroupName,
//             groupId: child.familyGroupId,
//             groupDescription: child.groupDescription
//         });
//     });

//     return childrenInfo;
// };

// Disabled QuickPay integration while testing /order end point
// eslint-disable-next-line
const calculateCheckSum = async (
    formParams,
    formRef,
    setError,
    setErrorMessage
) => {
    // Nodejs route
    const quickPayChecksumURL = '/api/v1/payment/params-checksum';
    const paramsFromResp = await postAndReturnRegistrationData(
        quickPayChecksumURL,
        formParams,
        setError,
        setErrorMessage
    );
    submitQuickPayForm(paramsFromResp, formRef);
};

const submitQuickPayForm = (formParams, formRef) => {
    formParams.type = 'payment';
    formParams.language = 'da';
    formParams.description = 'Description text';
    formParams.payment_methods = 'dankort, mobilepay';
    //The old way by submitting a form
    let form = document.createElement('form');
    form.action = 'https://payment.quickpay.net';
    form.method = 'POST';
    form.innerHTML = returnFilledHtmlForm(formParams);

    formRef.current.append(form);
    form.submit();
};

const returnFilledHtmlForm = (formParams) => {
    return `<form method="POST" action="">
  <input type="hidden" name="version" value="v10">
  <input type="hidden" name="merchant_id" value="${formParams.merchant_id}">
  <input type="hidden" name="agreement_id" value="${formParams.agreement_id}">
  <input type="hidden" name="order_id" value="${formParams.order_id}">
  <input type="hidden" name="amount" value="${formParams.amount}">
  <input type="hidden" name="currency" value="DKK">
  <input type="hidden" name="continueurl" value="${formParams.continueurl}">
  <input type="hidden" name="cancelurl" value="${formParams.cancelurl}">
  <input type="hidden" name="callbackurl" value="${formParams.callbackurl}">
  <input type="hidden" name="checksum" value="${formParams.checksum}">
  <input type="submit" value="Continue to payment...">
</form>`;
};

// Make POST order request to server
export const sendRenewCardOrder = async (
    // allChildrenData,
    userId,
    childId,
    type,
    // amount,
    currency,
    description,
    // rates,
    formRef,
    // webshopItems,
    setError,
    setErrorMessage,
    setIsDisabled
) => {
    if (userId) {
        const requestBody = {
            // giftCard: {},
            // orderItems: {
            //   courses: []
            //   introMeetings: [],
            //   parentMeetings: [],
            //   workDays: [],
            //   webshopItems: [],
            // },
            userId,
            childId,
            type,
            // amount,
            currency,
            description,
            // rates,
            // childrenInfo: [],
            siteUrl: window.location.host,
            timestamp: getTimeStamp()
        };

        // // Send data to server
        console.log('requestbody', requestBody);
        const response = await postAndReturnRegistrationData(
            `/api/v1/user/${userId}/renewCard`,
            requestBody,
            setError,
            setErrorMessage
        );
        return response;
    } else {
        setError(true);
        setErrorMessage('Noget gik galt. Prøv igen.');
    }
};
