import React, { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import {
    getLatestSimpleRegData,
    returnAuthenticated
} from '../../../context/getData';

import { removeWhiteSpace } from '../../HelperFunctions';
import {
    validateName,
    validatePhone,
    validatePassword,
    validatePasswordMatch,
    hasErrors,
    validateEmail
} from '../information/RegisterInformationFunctions';
import { findUnfilledInputOrRegister } from './InformationIntroMeetingFunctions';
import { Box, Typography } from '@material-ui/core';

import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import RegularHeadline from '../../../components/headlines/RegularHeadline';

import ProgressBar from '../../../components/progress-bars/ProgressBar';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import InformationTextInput from '../../../components/text-input/InformationTextInput';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import BirthdatePicker from '../../../components/birthdate-picker/BirthdatePicker';
import CheckboxComponent from '../../../components/checkbox/Checkbox';
import CookieDialog from '../../../components/dialogs/cookie-dialog/CookieDialog';
import PersonalDataDialog from '../../../components/dialogs/personal-data-dialog/PersonalDataDialog';

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const BirthdatePickerContainer = styled.div`
    margin: 0.8rem 0 1.5rem 0;
`;

const CheckboxText = styled(Typography)`
    margin-top: 6px;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 50rem;

    @media (max-width: 1010px) {
        width: 85%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        width: 100%;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

function InformationIntromeeting(props) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [passwordInputType, setPasswordInputType] = useState('password');
    // const [passwordVisible, setPasswordVisible] = useState(false);
    const [checkboxErrorRules, setCheckboxErrorRules] = useState('false');
    const [acceptedRules, setAcceptedRules] = useState(false);
    // eslint-disable-next-line
    const [personalDataDialog, setPersonalDataDialog] = useState(false);
    // eslint-disable-next-line
    const [cookieRulesDialog, setCookieRulesDialog] = useState(false);

    const [firstnameObj, setFirstnameObj] = useState({
        label: 'firstname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Dit fornavn',
        placeholder: 'Fx. Simone'
    });
    const [surnameObj, setSurnameObj] = useState({
        label: 'surname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Dit efternavn',
        placeholder: 'Fx. Jensen Toft'
    });
    const [emailObj, setEmailObj] = useState({
        label: 'email',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Email adresse',
        placeholder: 'Fx. dinemail@email.dk'
    });
    const [phoneObj, setPhoneObj] = useState({
        label: 'phone',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mobil',
        placeholder: 'Fx. 12345678'
    });
    const [passwordObj, setPasswordObj] = useState({
        label: 'password',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Password',
        placeholder: 'Skriv kodeordet'
    });
    const [repeatPasswordObj, setRepeatPasswordObj] = useState({
        label: 'repeatPassword',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gentag password',
        placeholder: 'Gentag kodeordet'
    });

    const [childFirstnameObj, setChildFirstnameObj] = useState({
        label: 'childFirstname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Barnets fornavn',
        placeholder: 'Barnets fornavn'
    });

    const [childSurnameObj, setChildSurnameObj] = useState({
        label: 'childSurname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Barnets efternavn',
        placeholder: 'Barnets efternavn'
    });

    const [birthdate, setBirthdate] = useState('');
    const [day, setDay] = useState('01');
    const [month, setMonth] = useState('01');
    const [year, setYear] = useState('2022');

    const contentRef = useRef();
    const parentNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    // const passwordRef = useRef();

    const context = useContext(Context);

    // const handleVisibility = () => {
    //     if (!passwordVisible) {
    //         setPasswordVisible(true);
    //         setPasswordInputType('text');
    //     } else {
    //         setPasswordVisible(false);
    //         setPasswordInputType('password');
    //     }
    // };

    const saveInputValue = (valueObject) => {
        const valueNoWhiteSpace = removeWhiteSpace(valueObject.value);

        if (
            valueObject.label === 'Dit fornavn' ||
            valueObject.label === 'Dit efternavn'
        ) {
            validateName(
                valueNoWhiteSpace,
                valueObject.label,
                setFirstnameObj,
                setSurnameObj,
                firstnameObj,
                surnameObj
            );
        } else if (valueObject.label === 'Mobil') {
            validatePhone(
                valueNoWhiteSpace,
                valueObject.label,
                setPhoneObj,
                null,
                phoneObj
            );
        } else if (valueObject.label === 'Password') {
            validatePassword(valueObject.value, setPasswordObj, passwordObj);
        } else if (valueObject.label === 'Gentag password') {
            validatePasswordMatch(
                passwordObj.value,
                valueObject.value,
                setRepeatPasswordObj,
                repeatPasswordObj
            );
        } else if (valueObject.label === 'Email adresse') {
            validateEmail(valueNoWhiteSpace, setEmailObj, emailObj);
        } else if (
            valueObject.label === 'Barnets fornavn' ||
            valueObject.label === 'Barnets efternavn'
        ) {
            validateName(
                valueNoWhiteSpace,
                valueObject.label,
                setChildFirstnameObj,
                setChildSurnameObj,
                childFirstnameObj,
                childSurnameObj,
                true
            );
        }
    };

    const handleError = (message) => {
        setError(true);
        setErrorMessage(message ? message : 'Noget gik galt. Prøv igen.');
    };

    const onSubmit = () => {
        const states = [
            firstnameObj,
            surnameObj,
            phoneObj,
            emailObj,
            childFirstnameObj,
            childSurnameObj
        ];

        const setStates = [
            { label: 'firstname', function: setFirstnameObj },
            { label: 'surname', function: setSurnameObj },
            { label: 'phone', function: setPhoneObj },
            { label: 'email', function: setEmailObj },
            { label: 'firstname', function: setFirstnameObj },
            { label: 'childFirstname', function: setChildFirstnameObj },
            { label: 'childSurname', function: setChildSurnameObj }
        ];

        // Come back: need to validate email with server to check if email is already used
        // Check for errors
        if (!acceptedRules) {
            setCheckboxErrorRules('true');
            setError(true);
            setErrorMessage(
                'Du mangler at acceptere persondata- og cookiepolitikken.'
            );
            return setError(true);
        }
        if (!hasErrors(states)) {
            findUnfilledInputOrRegister(
                states,
                setStates,
                context,
                props,
                parentNameRef,
                emailRef,
                phoneRef,
                null,
                handleError,
                birthdate
            );
        } else {
            // Trow error in dialog
            setError(true);
            setErrorMessage('Du skal udfylde alle felter.');
        }
    };

    const removeErrorOnChange = (label) => {
        const states = [
            firstnameObj,
            surnameObj,
            phoneObj,
            emailObj,
            passwordObj,
            repeatPasswordObj,
            childFirstnameObj,
            childSurnameObj
        ];
        const changedField = states.find(
            (state) => state.danishLabel === label
        );
        changedField.error = false;
        changedField.errorMessage = '';
    };

    const recoverInformation = (simpleRegData) => {
        if (simpleRegData && simpleRegData.information) {
            const infoFromContext = simpleRegData.information;
            setFirstnameObj({
                ...firstnameObj,
                value: infoFromContext.firstname
            });
            setSurnameObj({ ...surnameObj, value: infoFromContext.surname });
            setEmailObj({ ...emailObj, value: infoFromContext.email });
            setPhoneObj({ ...phoneObj, value: infoFromContext.phone });
            setChildFirstnameObj({
                ...childFirstnameObj,
                value: infoFromContext.childFirstname
            });
            setChildSurnameObj({
                ...childSurnameObj,
                value: infoFromContext.childSurname
            });
        }
    };

    const handleCheckBoxChangeRules = () => {
        if (checkboxErrorRules) {
            setCheckboxErrorRules('false');
        }
        setAcceptedRules(!acceptedRules);
    };

    // If user decides to log in during this step, send them back to choose meeting
    useEffect(() => {
        if (returnAuthenticated()) {
            props.history.push('/tilmelding/vaelg-intromoede');
        }
    }, [context.state.currentUser, props]);

    useEffect(() => {
        // Turn dates into YYYY-MM-DD format
        const birthdateString = year + '-' + month + '-' + day;
        setBirthdate(birthdateString);
    }, [day, month, year]);

    // ComponentDidMount
    useEffect(() => {
        const simpleRegData = getLatestSimpleRegData(context);
        recoverInformation(simpleRegData);
        contentRef.current.scrollIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <span ref={contentRef}></span>
            <NavHeaderDesktop />
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <RegistrationContentContainer
                magin-top="2.5rem"
                mobilePaddingTop="1rem"
            >
                <ProgressBar currentStage="Oplysninger" type="intromeeting" />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/vaelg-intromoede"
                        destination="Intromøde"
                    />
                </Box>
                <RegularHeadline title="Oplysninger" component="h1" />
                <DescriptionText variant="body2">
                    For at oprette din profil skal vi bruge et par oplysninger.{' '}
                    <br />
                    Du kan tilføje yderligere oplysninger eller rette i dem inde
                    på ‘Min Side’
                </DescriptionText>

                <NameContainer ref={parentNameRef}>
                    <InformationTextInput
                        stateObj={firstnameObj}
                        onInputChange={saveInputValue}
                        removeErrorOnChange={removeErrorOnChange}
                    />
                    <InformationTextInput
                        stateObj={surnameObj}
                        onInputChange={saveInputValue}
                        removeErrorOnChange={removeErrorOnChange}
                    />
                </NameContainer>
                <span ref={phoneRef}></span>
                <NameContainer ref={emailRef}>
                    <InformationTextInput
                        type="number"
                        stateObj={phoneObj}
                        onInputChange={saveInputValue}
                        removeErrorOnChange={removeErrorOnChange}
                    />
                    <InformationTextInput
                        stateObj={emailObj}
                        onInputChange={saveInputValue}
                        removeErrorOnChange={removeErrorOnChange}
                    />
                </NameContainer>

                {/* <NameContainer ref={passwordRef}>
          <InformationTextInput
            stateObj={passwordObj}
            onInputChange={saveInputValue}
            type={passwordInputType}
            handleVisibility={handleVisibility}
            visible={passwordVisible}
            removeErrorOnChange={removeErrorOnChange}
          />

          <InformationTextInput
            stateObj={repeatPasswordObj}
            onInputChange={saveInputValue}
            type={passwordInputType}
            handleVisibility={handleVisibility}
            visible={passwordVisible}
            customClass="last-text-input"
            handleEnterPress={onSubmit}
            removeErrorOnChange={removeErrorOnChange}
          />
        </NameContainer> */}

                <NameContainer>
                    <InformationTextInput
                        stateObj={childFirstnameObj}
                        onInputChange={saveInputValue}
                        removeErrorOnChange={removeErrorOnChange}
                    />
                    <InformationTextInput
                        stateObj={childSurnameObj}
                        onInputChange={saveInputValue}
                        removeErrorOnChange={removeErrorOnChange}
                    />
                </NameContainer>

                <Typography variant="body2">
                    Fødselsdatoen for dit barn
                </Typography>
                <BirthdatePickerContainer>
                    <BirthdatePicker
                        birthdate={birthdate}
                        day={day}
                        month={month}
                        year={year}
                        setDay={setDay}
                        setMonth={setMonth}
                        setYear={setYear}
                    />
                </BirthdatePickerContainer>

                <Box display="flex" position="relative" className="lilla">
                    <CheckboxComponent
                        onCheckboxChange={handleCheckBoxChangeRules}
                        position="static"
                        error={checkboxErrorRules}
                        linksInText="true"
                    />

                    <CheckboxText variant="body2" error={checkboxErrorRules}>
                        Jeg erklærer at have læst og forstået{' '}
                        <UnderlinedText
                            onClick={() => setPersonalDataDialog(true)}
                        >
                            persondatapolitikken
                        </UnderlinedText>{' '}
                        og{' '}
                        <UnderlinedText
                            onClick={() => setCookieRulesDialog(true)}
                        >
                            cookiepolitikken
                        </UnderlinedText>{' '}
                        for oprettelse af profil på Bülowsvej 10.
                    </CheckboxText>
                </Box>

                <RegistrationButton onButtonClick={onSubmit} title="Tilmeld" />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/vaelg-intromoede"
                        destination="Intromøde"
                    />
                </Box>
            </RegistrationContentContainer>
            {/* Come back: error text to danish */}
            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => setError(false)}
                />
            )}

            {personalDataDialog && (
                <PersonalDataDialog
                    close={() => setPersonalDataDialog(false)}
                />
            )}

            {cookieRulesDialog && (
                <CookieDialog close={() => setCookieRulesDialog(false)} />
            )}
        </div>
    );
}

export default withRouter(InformationIntromeeting);
