import React, { Component } from "react";
import { Context } from "../../../context/Context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import { getUser } from '../../../util';
import RegularLink from "../../links/RegularLink";
import Avatar from "../../../assets/avatar.png";

const ProfileDialogContainer = styled.div`
  width: 25rem;
  // height: 10rem;
  padding: 2rem;
  border-radius: 5px;
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const LinkContainer = styled(Box)`
  margin-top: 1.5rem;
  width: 80%;
`;

const UserFirstname = styled(Typography)`
  /* text-align: left; */
`;

const AvatarImg = styled.img`
  width: 2.5rem;
`;

const LogoutLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

class ProfileDialog extends Component {
  static contextType = Context;

  handleLogoutClick = () => {
    this.context.onLogout();
  };

  handleAdminDashboard = () => {
    document.location.href = '/admin/dashboard';
  }

  render() {
    const user = getUser();
    // const isAdmin = (user.role==='admin');
    // const isSuperuser = (['superuser','admin'].indexOf(user.role)>=0);
    const isPartner = (['partner','superuser','admin'].indexOf(user.role)>=0);
  

    return (
      <ProfileDialogContainer>
        <Box display="flex">
          <Box>
            <AvatarImg src={Avatar} />
          </Box>
          <Box ml="1rem" mt=".5rem">
            <UserFirstname variant="subtitle1">{this.props.userFirstname}</UserFirstname>
          </Box>
        </Box>
        <LinkContainer display="flex" justifyContent="space-between">
          <Box>
            <Link
              onClick={() => {
                this.props.closeProfileDialog();
              }}
              to="/min-side"
            >
              <RegularLink title="Min side" />
            </Link>
          </Box>
          <Box>
            <Link
              onClick={() => {
                this.props.closeProfileDialog();
              }}
              to="/rediger-profil"
            >
              <RegularLink title="Rediger profiler" />
            </Link>
          </Box>
        </LinkContainer>
        <LinkContainer display="flex" justifyContent="space-between">
          <Box>
            <LogoutLink
              variant="subtitle1"
              onClick={() => this.handleLogoutClick()}
            >
              Log ud
            </LogoutLink>
          </Box>
          <Box style={{ visibility: (isPartner ? 'visible' : 'hidden')}}>
            <LogoutLink
              variant="subtitle1"
              onClick={() => this.handleAdminDashboard()}
            >
              Dashboard
            </LogoutLink>
          </Box>

        </LinkContainer>
      </ProfileDialogContainer>
    );
  }
}

export default ProfileDialog;
