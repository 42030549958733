/*
    These examples show how to make an OpenLayers map at a given location with restricted zoom
    https://openlayers.org/en/latest/examples/permalink.html#map=17/1396192.69/7494502.24/0
    https://openlayers.org/en/latest/examples/zoom-constrained.html
*/

import React from "react";
import { withStyles } from '@material-ui/core/styles';
import 'ol/ol.css';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';

const styles = theme => ({
  container: {
    width: '100%',
    height: 400
  }
});

class OpenLayers extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount() {
        // default zoom, center and rotation
        var zoom = 18;
        var center = [1396192.69, 7494502.24];
        var rotation = 0;

        if (window.location.hash !== '') {
        // try to restore center, zoom-level and rotation from the URL
        var hash = window.location.hash.replace('#map=', '');
        var parts = hash.split('/');
        if (parts.length === 4) {
            zoom = parseInt(parts[0], 10);
            center = [parseFloat(parts[1]), parseFloat(parts[2])];
            rotation = parseFloat(parts[3]);
        }
        }

        var map = new Map({
        layers: [
            new TileLayer({
            source: new OSM(),
            }) ],
        target: 'map',
        view: new View({
            center: center,
            zoom: zoom,
            rotation: rotation,
        }),
        });

        var shouldUpdate = true;
        var view = map.getView();
        var updatePermalink = function () {
        if (!shouldUpdate) {
            // do not update the URL when the view was changed in the 'popstate' handler
            shouldUpdate = true;
            return;
        }

        var center = view.getCenter();
        var hash =
            '#map=' +
            view.getZoom() +
            '/' +
            Math.round(center[0] * 100) / 100 +
            '/' +
            Math.round(center[1] * 100) / 100 +
            '/' +
            view.getRotation();
        var state = {
            zoom: view.getZoom(),
            center: view.getCenter(),
            rotation: view.getRotation(),
        };
        window.history.pushState(state, 'map', hash);
        };

        map.on('moveend', updatePermalink);

        // restore the view state when navigating through the history, see
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
        window.addEventListener('popstate', function (event) {
        if (event.state === null) {
            return;
        }
        map.getView().setCenter(event.state.center);
        map.getView().setZoom(event.state.zoom);
        map.getView().setRotation(event.state.rotation);
        shouldUpdate = false;
        });
    }
  
    render() {
        const { classes } = this.props;
        return (
            <div id="map" className={classes.container}>                
            </div>
        );
    }
}

export default withStyles(styles)(OpenLayers);