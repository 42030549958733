import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { Context } from '../../../context/Context';
import {
    getAuthentication,
    getChildAlreadyRegistered,
    getLatestChildInRegFlow,
    returnAuthenticated
} from '../../../context/getData';

import {
    Box,
    FormControl,
    FormHelperText,
    MenuItem,
    Typography
} from '@material-ui/core';
import BirthdatePicker from '../../../components/birthdate-picker/BirthdatePicker';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';

import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import RegularHeadline from '../../../components/headlines/RegularHeadline';

import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import TextInput from '../../../components/text-input/TextInput';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import { capitalize, removeWhiteSpace } from '../../HelperFunctions';
import { getAndReturnData } from '../../../context/helperFunctions';
import SelectComponent from '../../../components/select/SelectComponent';
import {
    createListOfChildrenAndRecoverData,
    handleReenrollmentSubmit,
    onSubmit,
    setDayRemoveId
} from './BirthDateFunctions';
import Loader from '../../../components/loader/Loader';

import {
    getParticipantId,
    removeParticipantIdFromStorage
} from '../GlobalRegistrationFunctions';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';

const BirthdatePickerContainer = styled.div`
    margin: 0.8rem 0 1.5rem 0;
`;

const LinkComponent = styled.span`
    text-decoration: underline;
`;

const ChildNameContainer = styled.div`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        & .MuiFormControl-root {
            width: 100%;
        }
    }
`;

const SelectErrorTextContainer = styled(Box)`
    .MuiFormHelperText-root {
        /* color={overridings.palette.colors.purple} */
        color: ${(props) => props.theme.palette.error.main};
        position: relative;
        bottom: 5px;
    }
`;

function BirthDate(props) {
    const optionsInitial = [
        { value: 'select', title: 'Vælg barn' },
        { value: 'new child', title: 'Tilmeld nyt barn' }
    ];
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [birthdate, setBirthdate] = useState('');
    const [day, setDay] = useState('01');
    const [month, setMonth] = useState('01');
    const [year, setYear] = useState('2022');
    const [childFirstname, setChildFirstname] = useState('');
    const [childSurname, setChildSurname] = useState('');

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstnameInputError, setFirstnameInputError] = useState(false);
    const [firstnameInputErrorText, setFirstnameInputErrorText] = useState('');
    const [surnameInputError, setSurnameInputError] = useState(false);
    const [surnameInputErrorText, setSurnameInputErrorText] = useState('');
    const [familyNameInputError, setFamilyNameInputError] = useState(false);
    const [familyNameInputErrorText, setFamilyNameInputErrorText] = useState(
        ''
    );
    const [selectedChild, setSelectedChild] = useState('new child');
    const [childrenOfUser, setChildrenOfUser] = useState([]);
    const [options, setOptions] = useState(optionsInitial);
    const [isLoading, setIsLoading] = useState(false);
    const [childFetchingError, setChildFetchingError] = useState(false);
    const [initialChildObj, setInitialChildObj] = useState({});
    const [selectedFamilyObj, setSelectedFamilyObj] = useState({
        label: 'Vælg familie',
        value: 'not selected'
    });
    const [familiesArr, setFamiliesArr] = useState([]);
    const [selectError, setSelectError] = useState(false);
    const [newFamilyName, setNewFamilyName] = useState('');

    const contentRef = useRef();

    const context = useContext(Context);

    // Get authentication
    useEffect(() => {
        getAuthentication(setIsAuthenticated);
    }, [context.state.currentUser]);

    const setSurnameError = () => {
        setSurnameInputError(true);
        setSurnameInputErrorText('Navnet er ugyldigt eller mangler');
    };

    const setFirstnameError = () => {
        setFirstnameInputError(true);
        setFirstnameInputErrorText('Navnet er ugyldigt eller mangler');
    };

    const setFamilyNameError = () => {
        setFamilyNameInputError(true);
        setFamilyNameInputErrorText(
            'Navnet er ugyldigt, findes allerede eller mangler'
        );
    };

    const removeFirstnameError = () => {
        setFirstnameInputError(false);
        setFirstnameInputErrorText('');
    };

    const removeSurnameError = () => {
        setSurnameInputError(false);
        setSurnameInputErrorText('');
    };

    const removeFamilyNameError = () => {
        setFamilyNameInputError(false);
        setFamilyNameInputErrorText('');
    };

    const handleNameChange = async (valueObj) => {
        const regex = /^[A-Za-z æøåÆØÅ]+$/;

        const capitalizedValue = capitalize(removeWhiteSpace(valueObj.value));

        if (!valueObj || valueObj.value === '' || !regex.test(valueObj.value)) {
            if (valueObj.label === 'Barnets fornavn') {
                setFirstnameError();
            } else if (valueObj.label === 'Barnets efternavn') {
                setSurnameError();
            } else if (valueObj.label === 'Familienavn') {
                setFamilyNameError();
            }

            // Name cannot be longer than 200 characters
        } else if (valueObj.value.length > 200) {
            if (valueObj.label === 'Barnets fornavn') {
                setFirstnameError();
            } else if (valueObj.label === 'Barnets efternavn') {
                setSurnameError();
            } else if (valueObj.label === 'Familienavn') {
                setFamilyNameError();
            }
        } else {
            if (valueObj.label === 'Barnets fornavn') {
                setChildFirstname(capitalizedValue);
                removeFirstnameError();
                removeParticipantIdFromStorage(context);
                // Remove participant id from context if there is
                if (getParticipantId()) {
                }
            } else if (valueObj.label === 'Barnets efternavn') {
                setChildSurname(capitalizedValue);
                removeSurnameError();
                removeParticipantIdFromStorage(context);
            } else {
                if (valueObj.label === 'Barnets fornavn') {
                    setChildFirstname(capitalizedValue);
                    removeFirstnameError();
                    removeParticipantIdFromStorage(context);
                    // Remove participant id from context if there is
                    if (getParticipantId()) {
                    }
                } else if (valueObj.label === 'Barnets efternavn') {
                    setChildSurname(capitalizedValue);
                    removeSurnameError();
                    removeParticipantIdFromStorage(context);
                } else {
                    // Here change family name
                    const allFamilies = await getAndReturnData(
                        `/api/v1/families-names`,
                        handleError
                    );
                    if (allFamilies.includes(capitalizedValue)) {
                        setFamilyNameError('Familienavnet findes allerede');
                    } else {
                        setNewFamilyName(capitalizedValue);
                        removeFamilyNameError();
                    }
                }
            }
        }
    };

    useEffect(() => {}, [newFamilyName]);

    const handleChildOptionChange = (e) => {
        if (e.target && e.target.value) {
            setSelectedChild(e.target.value);
        }
    };

    const handleError = (errorMessage) => {
        setIsLoading(false);
        setError(true);
        setErrorMessage(errorMessage);
    };

    useEffect(() => {
        // Turn dates into YYYY-MM-DD format
        const birthdateString = year + '-' + month + '-' + day;
        setBirthdate(birthdateString);
    }, [day, month, year]);

    // If variable from context is true, we need to display an error message and propt the user to log in if they want to add
    // an already existing child.
    const checkChildAlreadyRegistered = () => {
        if (getChildAlreadyRegistered()) {
            setError(true);
            setErrorMessage(
                'Det barn, du prøver at tilføje, findes allerede i systemet. Log ind for at tilmelde dig igen.'
            );
            context.saveChildAlreadyRegistered(false);
        }
    };

    const handleFamilySelectChange = (e) => {
        setSelectError(false);
        if (e.target.value === 'not selected') {
            setSelectedFamilyObj({
                label: 'not selected',
                value: e.target.value
            });
        } else if (e.target.value === 'new family') {
            setSelectedFamilyObj({
                label: 'new family',
                value: e.target.value
            });
        } else {
            setSelectedFamilyObj({
                label: 'existing family',
                value: e.target.value
            });
        }
    };

    //ComponentDidMount
    useEffect(() => {
        setIsLoading(true);
        checkChildAlreadyRegistered();

        // Check if there is childInRegFlow - if there is, we want to recover the data related to him/her.
        const childInRegFlow = getLatestChildInRegFlow(context);

        // If user is logged in, get list of children that are related to the user
        // const listOfParticipantIds = getListOfParticipants();

        createListOfChildrenAndRecoverData(
            optionsInitial,
            context,
            contentRef,
            childInRegFlow,
            setDay,
            setMonth,
            setYear,
            setChildFirstname,
            setChildSurname,
            setNewFamilyName,
            setSelectedChild,
            returnAuthenticated(),
            handleError,
            setChildrenOfUser,
            setOptions,
            setIsLoading,
            setChildFetchingError,
            setInitialChildObj,
            setSelectedFamilyObj,
            setFamiliesArr
        );

        // If the user is logged in, find out if there are any families
        // To that we need to find out if there are any participants

        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <span ref={contentRef}></span>
            <NavHeaderDesktop />
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                <ProgressBar currentStage="Fødselsdato" />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/tilmeldingstype"
                        destination="Tilmeldingstype"
                    />
                </Box>

                {/* If user is logged in, show the option of selecting already existing child */}
                {isAuthenticated ? (
                    <Box mb="2rem">
                        <RegularHeadline
                            component="h1"
                            title="Vælg eksisterende eller nyt barn"
                            marginbottom="1.5rem"
                        />
                        {isLoading ? (
                            <React.Fragment>
                                <Loader />
                            </React.Fragment>
                        ) : (
                            <Box mb="1.5rem">
                                <FormControl variant="outlined">
                                    <SelectComponent
                                        onChange={(e) =>
                                            handleChildOptionChange(e)
                                        }
                                        name="option"
                                        value={selectedChild}
                                        option={selectedChild}
                                        width="15rem"
                                    >
                                        {options.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </SelectComponent>
                                </FormControl>
                                {/* Come back: danish text */}
                                {childFetchingError && (
                                    <Box mt="1rem">
                                        <Typography variant="body2">
                                            OBS! Kunne ikke indlæse oplysninger
                                            om eksisterende børn. Prøv at
                                            opdatere siden eller kontakt os.
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {selectedChild === 'new child' ? (
                            <Box>
                                {' '}
                                <RegularHeadline
                                    component="h1"
                                    title="Hvornår er dit barn født?"
                                    marginbottom="1.5rem"
                                />
                                <Typography variant="body2">
                                    Indtast fødselsdatoen eller terminsdatoen
                                    for dit barn for at se hvilke hold der egner
                                    sig til barnet.
                                </Typography>
                                <BirthdatePickerContainer>
                                    <BirthdatePicker
                                        birthdate={birthdate}
                                        day={day}
                                        month={month}
                                        year={year}
                                        setDay={setDayRemoveId}
                                        setMonth={setMonth}
                                        setYear={setYear}
                                    />
                                </BirthdatePickerContainer>
                                {/* Come back: change english error text to danish */}
                                <ChildNameContainer>
                                    <TextInput
                                        valueFromParent={childFirstname}
                                        onInputChange={handleNameChange}
                                        inputError={firstnameInputError}
                                        smallErrorText={firstnameInputErrorText}
                                        type="text"
                                        id="childFirstname"
                                        label="Barnets fornavn"
                                        marginBottom="0"
                                    />
                                    <TextInput
                                        valueFromParent={childSurname}
                                        onInputChange={handleNameChange}
                                        inputError={surnameInputError}
                                        smallErrorText={surnameInputErrorText}
                                        type="text"
                                        id="childSurname"
                                        label="Barnets efternavn"
                                        marginBottom="0"
                                    />
                                </ChildNameContainer>
                                {/* Come back: danish text */}
                                <Box mt="2rem">
                                    <RegularHeadline
                                        component="h1"
                                        title="Family groups"
                                        marginbottom="1.5rem"
                                    />
                                    <Typography variant="body2">
                                        Some text about parent needing to select
                                        or create a family name
                                    </Typography>
                                    {/* If user is logged in, they should choose a family or create a new */}
                                    {returnAuthenticated() &&
                                        familiesArr.length > 0 && (
                                            <Box mb="1.5rem" mt="1rem">
                                                <FormControl variant="outlined">
                                                    <SelectComponent
                                                        onChange={(e) =>
                                                            handleFamilySelectChange(
                                                                e
                                                            )
                                                        }
                                                        name="family"
                                                        value={
                                                            selectedFamilyObj.value
                                                        }
                                                        option={
                                                            selectedFamilyObj.value
                                                        }
                                                        width="15rem"
                                                    >
                                                        {familiesArr.map(
                                                            (fam) => (
                                                                <MenuItem
                                                                    key={
                                                                        fam.value
                                                                    }
                                                                    value={
                                                                        fam.value
                                                                    }
                                                                >
                                                                    {fam.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </SelectComponent>
                                                </FormControl>
                                            </Box>
                                        )}
                                    {selectedFamilyObj.value === 'new family' ||
                                    familiesArr.length < 1 ? (
                                        <TextInput
                                            valueFromParent={newFamilyName}
                                            onInputChange={handleNameChange}
                                            inputError={familyNameInputError}
                                            smallErrorText={
                                                familyNameInputErrorText
                                            }
                                            type="text"
                                            id="newFamilyName"
                                            label="Familienavn"
                                            marginBottom="0"
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {selectError && (
                                        <SelectErrorTextContainer maxWidth="fit-content">
                                            <FormHelperText>
                                                Select a family
                                            </FormHelperText>
                                        </SelectErrorTextContainer>
                                    )}
                                </Box>
                                <RegistrationButton
                                    onButtonClick={() =>
                                        onSubmit(
                                            isAuthenticated,
                                            selectedChild,
                                            selectedFamilyObj,
                                            error,
                                            birthdate,
                                            context,
                                            firstnameInputError,
                                            surnameInputError,
                                            familyNameInputError,
                                            childFirstname,
                                            childSurname,
                                            newFamilyName,
                                            initialChildObj,
                                            props,
                                            setFirstnameInputError,
                                            setFirstnameInputErrorText,
                                            setSurnameInputError,
                                            setSurnameInputErrorText,
                                            setFamilyNameError,
                                            setError,
                                            setErrorMessage
                                        )
                                    }
                                />
                            </Box>
                        ) : (
                            <Box>
                                <RegistrationButton
                                    onButtonClick={() =>
                                        handleReenrollmentSubmit(
                                            props,
                                            selectedChild,
                                            childrenOfUser,
                                            error,
                                            context,
                                            setError,
                                            setErrorMessage
                                        )
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                ) : (
                    <React.Fragment>
                        {' '}
                        <RegularHeadline
                            component="h1"
                            title="Hvornår er dit barn født?"
                            marginbottom="1.5rem"
                        />
                        <Box mb="1rem">
                            <Typography variant="body2">
                                Har du et barn, der allerede går i undervisning,
                                eller er du allerede registreret? Log ind{' '}
                                <LinkComponent>
                                    <Link
                                        className="frontpage-job"
                                        to={{
                                            pathname: '/login',
                                            state: { modal: true }
                                        }}
                                    >
                                        here
                                    </Link>
                                </LinkComponent>
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            Indtast fødselsdatoen for dit barn for at se hvilke
                            hold der egner sig til barnet.
                        </Typography>
                        <BirthdatePickerContainer>
                            <BirthdatePicker
                                birthdate={birthdate}
                                day={day}
                                month={month}
                                year={year}
                                setDay={setDay}
                                setMonth={setMonth}
                                setYear={setYear}
                            />
                        </BirthdatePickerContainer>
                        {/* Come back: change english error text to danish */}
                        <ChildNameContainer>
                            <TextInput
                                valueFromParent={childFirstname}
                                onInputChange={handleNameChange}
                                inputError={firstnameInputError}
                                smallErrorText={firstnameInputErrorText}
                                type="text"
                                id="childFirstname"
                                label="Barnets fornavn"
                                marginBottom="0"
                            />
                            <TextInput
                                valueFromParent={childSurname}
                                onInputChange={handleNameChange}
                                inputError={surnameInputError}
                                smallErrorText={surnameInputErrorText}
                                type="text"
                                id="childSurname"
                                label="Barnets efternavn"
                                marginBottom="0"
                            />
                        </ChildNameContainer>
                        <Box mt="2rem">
                            <RegularHeadline
                                component="h1"
                                title="Family groups"
                                marginbottom="1.5rem"
                            />
                            <Typography variant="body2">
                                Some text about parent needing to select or
                                create a family name
                            </Typography>
                            <TextInput
                                valueFromParent={newFamilyName}
                                onInputChange={handleNameChange}
                                inputError={familyNameInputError}
                                smallErrorText={familyNameInputErrorText}
                                type="text"
                                id="newFamilyName"
                                label="Familienavn"
                                marginBottom="0"
                            />
                        </Box>
                        <RegistrationButton
                            onButtonClick={() =>
                                onSubmit(
                                    isAuthenticated,
                                    selectedChild,
                                    selectedFamilyObj,
                                    error,
                                    birthdate,
                                    context,
                                    firstnameInputError,
                                    surnameInputError,
                                    familyNameInputError,
                                    childFirstname,
                                    childSurname,
                                    newFamilyName,
                                    initialChildObj,
                                    props,
                                    setFirstnameInputError,
                                    setFirstnameInputErrorText,
                                    setSurnameInputError,
                                    setSurnameInputErrorText,
                                    setFamilyNameError,
                                    setError,
                                    setErrorMessage
                                )
                            }
                        />
                    </React.Fragment>
                )}
            </RegistrationContentContainer>

            {/* Come back: give proper danish error text */}
            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => {
                        setError(false);
                    }}
                />
            )}
        </div>
    );
}

export default withRouter(BirthDate);
