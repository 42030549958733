import React from "react";
import styled from "styled-components";

const StaircaseImageComponent = styled.img`
  width: ${(props) => props.width}rem;
`;

function StaircaseImage({ src, alt, width, customClassName }) {
  return <StaircaseImageComponent src={src} alt={alt} width={width} className={customClassName} />;
}

export default StaircaseImage;
