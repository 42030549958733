export const getReturnUrl = (props) => {
  if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.from &&
    props.location.state.from.path
  ) {
    return props.location.state.from.path;
  } else {
    return "/undervisning";
  }
};

export const getDestination = (props) => {
  if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.from &&
    props.location.state.from.title
  ) {
    return props.location.state.from.title;
  } else {
    return "Undervisning";
  }
};
