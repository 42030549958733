import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import RegularHeadline from '../../../components/headlines/RegularHeadline';
import Questionmark from '../../../components/Questionmark/Questionmark';
import { Link } from 'react-router-dom';
import MessageList from '../../../components/message-list';
import MessagePlaceholder from '../../../components/message-list/MessagePlaceholder';
import { getMessage } from '../../../components/message-list/MessageListHelper';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const LinkComponent = styled.span`
    text-decoration: underline;
`;

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

function IntroMeetingDescription({ type, isAuthenticated }) {
    const [messageList, setMessageList] = useState([]);

    return (
        <div>
            <MessageList
                type="html"
                name="Choose intro meeting"
                state="final"
                change={setMessageList}
            />
            <Box display="flex" pt="1rem">
                <RegularHeadline
                    component="h1"
                    title={getMessage(
                        messageList,
                        'Choose intro meeting',
                        'title',
                        'Vælg intromøde'
                    )}
                    marginbottom="1.5rem"
                />
                <Questionmark
                    width="1rem"
                    messageListName="Choose intro meeting explainer text"
                />
            </Box>

            {/* <DescriptionText variant="body2">
                For at kunne tilmelde dit barn fra 1 år og opefter til musik på
                Den Lille Musikskole, skal mindst en af de primære følgevoksne
                deltage i vores generelle intromøde.
            </DescriptionText> */}
            <Box mb="2rem">
                <MessagePlaceholder
                    list={messageList}
                    name="Choose intro meeting"
                    value=""
                />
            </Box>
            {!type && (
                <React.Fragment>
                    {!isAuthenticated && (
                        <DescriptionText variant="body2">
                            Har du været til intromøde? Log in{' '}
                            <LinkComponent>
                                <Link
                                    className="frontpage-job"
                                    to={{
                                        pathname: '/login',
                                        state: {
                                            modal: true,
                                            from: {
                                                path: '/tilmelding/vaelg-moede',
                                                title: 'Møder'
                                            }
                                        }
                                    }}
                                >
                                    here
                                </Link>
                            </LinkComponent>
                            .
                        </DescriptionText>
                    )}
                </React.Fragment>
            )}
            {/* {type === 'intromeeting' && isAuthenticated ? null : (
                <DescriptionText variant="body2">
                    Vælg et intromøde, som passer dig:
                </DescriptionText>
            )} */}
        </div>
    );
}

export default IntroMeetingDescription;
