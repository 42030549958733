import { getSession } from "../util";

// In case user is coming from a different site, following a link
// Checks if there is anything in props.location.state
export const checkLocationStatePath = (props) => {
  if (!props.location.state) {
    // Return to home
    return "/";
  } else {
    return props.location.state.from.path;
  }
};

// In case user is coming from a different site, following a link
// Checks if there is anything in props.location.state
export const checkLocationStateTitle = (props) => {
  if (!props.location.state) {
    // Add no title
    return null;
  } else {
    return props.location.state.from.title;
  }
};

//Error handling in fetch
export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const fetchWithTimeout = async (resource, options) => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
};

// Come back: danish text
const handleRegisterErrors = (error, setError, setErrorMessage) => {
  if (error) {
    setError(true);
    setErrorMessage("Something went wrong, please try again.");
    // throw Error(error.statusText);
  }
};

// It is different from postAndReturnData in how it handles errors
export const postAndReturnRegistrationData = async (
  resource,
  requestBody,
  setError,
  setErrorMessage
) => {
  try {
    const session = getSession();
    console.log(resource);
    const response = await fetchWithTimeout(resource, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + session.token,
      },
      timeout: 8000,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    if (setError && setErrorMessage) {
      handleRegisterErrors(err, setError, setErrorMessage);
    }
  }
};

export const postAndReturnResp = async (resource, requestBody, handleError) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 8000,
    });
    return response;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const postAndReturnData = async (resource, requestBody, handleError) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 8000,
    });
    const data = await response.json();
    console.log(response);
    return data;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const postAndReturnDataAndResponse = async (
  resource,
  requestBody,
  handleError
) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 8000,
    });

    const data = await response.json();
    console.log(response);
    const result = { data: data, response: response };
    console.log(result);
    return result;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const getAndReturnDataAndResponse = async (resource, handleError) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "GET",

      timeout: 8000,
    });
    const data = await response.json();
    const result = { data: data, response: response };
    return result;
  } catch (error) {
    console.log(error);
    if (handleError) {
      handleError();
    }
  }
};
export const getAndReturnData = async (resource, handleError) => {
  try {
    const session = getSession();
    const response = await fetchWithTimeout(resource, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + session.token,
      },
      timeout: 8000,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const getAndReturnResp = async (resource, handleError) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "GET",

      timeout: 8000,
    });

    return response;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const putAndReturnResp = async (resource, requestBody, handleError) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "PUT",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 8000,
    });
    return response;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const patchAndReturnResp = async (
  resource,
  requestBody,
  handleError
) => {
  try {
    const response = await fetchWithTimeout(resource, {
      method: "PATCH",
      body: JSON.stringify(requestBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 8000,
    });
    if (response.status === 200) {
      return response;
    }
    return response;
  } catch (err) {
    let msg = resource + "\n" + err;
    console.log(msg);
    handleError(msg);
  }
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getColorFromTheme = (color, props) => {
  if (color === "blue") {
    return props.theme.palette.colors.blue;
  } else if (color === "green") {
    return props.theme.palette.colors.green;
  } else if (color === "orange") {
    return props.theme.palette.colors.orange;
  } else if (color === "yellow") {
    return props.theme.palette.colors.yellow;
  } else if (color === "purple") {
    return props.theme.palette.colors.purple;
  } else {
    return props.theme.palette.colors.green;
  }
};

// If email is valid it returns true
export const validateEmail = (email) => {
  var regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (regex.test(email) && email.length < 250) {
    return true;
  } else {
    return false;
  }
};

// Removes white space from string
export const removeWhiteSpace = (value) => {
  return value.replace(/^\s+/, "").replace(/\s+$/, "");
};
