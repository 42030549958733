import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CustomQuestionmark from "../Questionmark/CustomQuestionmark";

const ParentNameContainer = styled(Box)`
  width: 35%;
`;

const RelatedToContainer = styled(Box)`
  width: 30%;
`;

const ReadinessContainer = styled(Box)`
  width: 35%;
`;

const ParentnameText = styled(Typography)`
  padding-top: 0.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const RelationText = styled(Typography)`
  padding-top: 0.2rem;
`;

const ReadinessText = styled(Typography)`
  padding-top: 0.2rem;
`;
function ParentComponent({ companionData, isCurrentUser }) {
  // Come back: get danish text
  return (
    <Box display="flex" mb="1.5rem">
      <ParentNameContainer>
        <Box display="flex">
          {isCurrentUser ? <PersonIcon /> : <PersonOutlineIcon />}

          <ParentnameText variant="body2">
            {companionData.firstname} {companionData.surname}
          </ParentnameText>
        </Box>
      </ParentNameContainer>
      <RelatedToContainer>
        <RelationText>
          {companionData.child.firstname && companionData.child.firstname}{" "}
          {companionData.child.surname && companionData.child.surname}
        </RelationText>
      </RelatedToContainer>
      <ReadinessContainer>
        <Box display="flex">
          <ReadinessText>
            {companionData.accompanyReady ? "Ja" : "Endnu ikke"}
          </ReadinessText>
          <CustomQuestionmark
            width="1rem"
            beenToIntroMeeting={companionData.beenToIntroMeeting}
            beenToParentMeeting={companionData.beenToParentMeeting}
          />
        </Box>
      </ReadinessContainer>
    </Box>
  );
}

export default ParentComponent;
