import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

const ContentContainer = styled(Box)`
  width: 90%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
    margin-top: ${(props) => (props.margintop ? props.margintop : "3rem")};
  }
`;

function AboutContentContainer({ children, marginTop }) {
  return <ContentContainer margintop={marginTop}>{children}</ContentContainer>;
}

export default AboutContentContainer;
