import React from "react";
import styled from "styled-components";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";

import SelectComponent from "../../../components/select/SelectComponent";

const SelectContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const InputLabelComponent = styled(InputLabel)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  margin-bottom: 0.5rem;
  font-size: 0.875rem;

  &.MuiInputLabel-formControl {
    position: static;
    transform: none;
  }

  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const CustomFormControl = styled(FormControl)`
  width: ${(props) => props.width && props.width};
`;

function SelectWithLabel({
  label,
  onChange,
  name,
  value,
  id,
  optionsArr,
  width,
}) {
  console.log(optionsArr);
  return (
    <SelectContainer>
      <CustomFormControl variant="outlined" width={width}>
        <InputLabelComponent htmlFor={id}>{label}</InputLabelComponent>

        <SelectComponent
          onChange={onChange}
          name={name}
          value={value}
          id={id}
          width={width ? width : "20rem"}
        >
          {optionsArr.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.name}
            </MenuItem>
          ))}
        </SelectComponent>
      </CustomFormControl>
    </SelectContainer>
  );
}

export default SelectWithLabel;
