import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const ButtonComponent = styled(Button)`
  border-radius: 30px;
  border: 2px solid
    ${(props) => props.border || props.theme.palette.primary.contastText};
  text-transform: none;

  width: 100%;
  padding: 8px;
  background-color: ${(props) => props.backgroundcolor || "transparent"};
  position: relative;
`;

const ChevronRight = styled.span`
    position: absolute;
    right: 1.5rem;
    font-size: 1.2rem;
`

const RegistrationLinkButton = ({ style, title, size, color, border }) => {
  return (
    <ButtonComponent
      variant="outlined"
      size={size}
      backgroundcolor={color}
      border={border}
      disableRipple
      style={style}
    >
      {title}
      {title === 'Videre' &&  <ChevronRight>&#62;</ChevronRight>}
    </ButtonComponent>
  );
};

export default RegistrationLinkButton;
