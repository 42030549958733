import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoginDialog from '../../components/dialogs/login-dialog/LoginDialog';
import DialogsBackground from '../DialogsBackground';

class LoginPage extends Component {
    constructor() {
        super();
        this.state = {
            isAuthenticated: false
        };
        this.modalRef = React.createRef();
    }

    render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    {this.props.isModal ? (
                        <div
                            ref={this.modalRef}
                            onClick={() => {
                                this.props.history.goBack();
                            }}
                        >
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <LoginDialog
                                    prevLocation={this.props.prevLocation}
                                    isModal={this.props.isModal}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <DialogsBackground />
                            <LoginDialog
                                fullPageVView={'true'}
                                prevLocation={this.props.prevLocation}
                                isModal={this.props.isModal}
                            />
                        </div>
                    )}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

export default withRouter(LoginPage);
