import React from "react";
import styled from "styled-components";

const BulletContainer = styled.label`
  background-color: ${(props) =>
    props.isActive
      ? `${props.theme.palette.primary.contrastText}`
      : `${props.theme.palette.primary.light}`};
  padding: ${(props) => (props.isActive ? "6px" : "4px")};
  margin: 2rem;
  height: 7px;
  width: 7px;
  border: 1px solid #626161;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    border: 1px solid
      ${(props) => `${props.theme.palette.primary.contrastText}`};
  }
  @media (max-width: 960px) {
    background-color: ${(props) =>
      props.isActive
        ? `${props.theme.palette.primary.contrastText}`
        : `${props.theme.palette.primary.main}`};
    border: 2px solid
      ${(props) => `${props.theme.palette.primary.contrastText}`};
    height: 12px;
    width: 12px;
  }
  @media (max-width: 375px) {
    margin: 1rem;
  }
`;

function CarouselBullet(props) {
  const goToSpecificSlide = () => {
    props.onClick(props.bulletId - 1);
  };

  const isActiveBullet = () =>
    props.currentSlide === props.bulletId - 1 ? true : false;
  const isActive = isActiveBullet();
  return <BulletContainer isActive={isActive} onClick={goToSpecificSlide} />;
}

export default CarouselBullet;