import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const NavHeadlineComponent = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-size: 1.2rem;
  margin-left: 1rem;
  display: inline-block;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    font-size: 1rem;

  }
`;

const NavHeadline = ({ title }) => {
  return <NavHeadlineComponent variant="body1">{title}</NavHeadlineComponent>;
};

export default NavHeadline;
