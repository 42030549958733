import { getAllChildrenInReg } from "../../../context/getData";

export const getOrderFromStorage = (
  setAllChildrenData,
  props
) => {
  const allChildrenData = getAllChildrenInReg();
  if (allChildrenData && allChildrenData.length > 0) {
    setAllChildrenData(allChildrenData);
  } else {
      props.history.push('/tilmelding/tilmeldingstype')
  }
};
