import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

const ExplainerContentComponent = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

function ExplainerContent({children}) {
  return <ExplainerContentComponent>{children}</ExplainerContentComponent>;
}

export default ExplainerContent;
