import React from "react";
import styled from "styled-components";

const HorizontalLineComponent = styled.hr`
  width: 100%;
  border: ${(props) => `1px solid ${props.theme.palette.primary.contrastText}`};

  margin-top:  ${(props) =>
    props.margintop ? props.margintop : "3rem"};
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : "3rem"};
`;

function HorizontalLine({marginTop, marginBottom }) {
  return (
    <HorizontalLineComponent
      marginbottom={marginBottom}
      margintop={marginTop}
    ></HorizontalLineComponent>
  );
}

export default HorizontalLine;
