import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
// import ReturnLinkReusable from "../../../components/return-link/ReturnLinkReusable";
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';

import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import RegularHeadline from '../../../components/headlines/RegularHeadline';
import HorizontalLine from '../../../components/horizontal-line/HorizontalLine';

import TermsOfPurchase from '../../../components/terms-of-purchase/TermsOfPurchase';
import CheckboxComponent from '../../../components/checkbox/Checkbox';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import DankortIcon from '../../../assets/dankort.png';
import { Context } from '../../../context/Context';

import {
    getChildname,
    getCourseName,
    getFirstPriorities,
    getOrderFromStorage,
    getProcessType,
    getSecondPriorities
} from './CheckoutFunctions';
import { sendOrder } from './CheckoutSubmitFunctions';
import { isEmailFilled } from '../GlobalRegistrationFunctions';
import RegularButton from '../../../components/buttons/RegularButton/RegularButton';
import {
    getLatestChildInRegFlow,
    getOrderInfo,
    getUserId
} from '../../../context/getData';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import WarningChildRemoval from '../../../components/dialogs/warning-child-removal/WarningChildRemoval';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import { fetchWithTimeout } from '../../../context/helperFunctions';
const DescriptionText = styled(Typography)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    }
`;

const DescriptionTextBold = styled(Typography).attrs((props) => ({
    className: props.customclassname
}))`
    font-family: 'Benton Sans Bold';
    margin-right: 0.3rem;

    &.bigger-margin {
        margin-bottom: 1rem;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    }
`;

const CheckboxText = styled(Typography)`
    margin-top: 0.8rem;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const DankortImage = styled.img`
    width: 3.5rem;
    height: fit-content;
    margin-right: 1rem;
`;

const DescriptionContainer = styled(Box)`
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        margin-bottom: 1rem;
    }
`;

const NotesContainer = styled(Box)`
    width: 60%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const HiddenFormContainer = styled.div`
    display: none;

    & form {
        display: none;
    }
`;

function Checkout(props) {
    const [accepted, setAccepted] = useState(false);
    const [checkboxError, setCheckboxError] = useState('false');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [warning, setWarning] = useState(false);
    const [childToRemove, setChildToRemove] = useState({});
    // const [childName, setChildName] = useState("");
    // const [courseName, setCourseName] = useState("");
    // const [processType, setProcessType] = useState("");
    // const [firstPrios, setFirstPrios] = useState([]);
    // const [secondPrios, setSecondPrios] = useState([]);
    // const [products, setProducts] = useState([]);
    // Come back: check if we need this at all

    // const [priceSum, setPriceSum] = useState(0);
    const [email, setEmail] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const [allChildrenData, setAllChildrenData] = useState([]);
    // eslint-disable-next-line
    const [verificationMissingDialog, setVerificationMissingDialog] =
        useState(false);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef();
    const contentRef = useRef();
    const termsRef = useRef();

    const context = useContext(Context);

    const handleCheckBoxChange = () => {
        if (checkboxError) {
            setCheckboxError('false');
        }
        setAccepted(!accepted);
    };
    const handleError = () => {
        setError(true);
        setIsDisabled(false);
        setErrorMessage([
            'Noget gik galt.',
            <br />,
            'Du kan enten prøve igen eller kontakte os.',
            <br />,
            'Vi beklager.'
        ]);
    };
    const getPaymentParamsChecksum = async (
        userId,
        orderId,
        type,
        amount,
        currency,
        description,
        productsBody
    ) => {
        let params;
        let requestURL = '/api/v1/payment/params-checksum';
        let requestBody = {
            userId,
            orderId,
            type,
            amount,
            currency,
            description,
            show3DSecure: 1,
            orders: productsBody
        };
        try {
            const response = await fetchWithTimeout(requestURL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                body: JSON.stringify(requestBody),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 10000
            });
            if (response.status === 200) {
                params = await response.json();
            } else {
                // If we have an error, due to that the user is not verified, we need to show an error message about that.
                const data = await response.json();
                if (data && data.message) {
                    return { error: data.message };
                }
            }
        } catch (err) {
            // Timeouts if the request takes more than 10 seconds
            console.log(err);
            // const aborted = (err.name === 'AbortError');
        }
        return params;
    };

    // eslint-disable-next-line
    const handlePaymentForm = () => {
        console.log('handling payment form');
        const orderInfo = getOrderInfo();
        if (!orderInfo) {
            return handleError();
        }
        // anonymous self-executing function must be followed by a semicolon or
        // next statement is compiled as an argument to the result of the first
        (async () => {
            let response = await getPaymentParamsChecksum(
                getUserId(),
                0,
                orderInfo.orderType,
                // for now this is hardcoded, since we only have one product, which can be bought only one at a time.
                orderInfo.orderTotal,
                'DKK',
                orderInfo.orderName,
                {
                    products: orderInfo.products,
                    rates: orderInfo.rates ? orderInfo.rates : []
                }
            );

            if (!response || !response.params || response.error) {
                // console.log(response.error);
                // console.log("Payment params checksum timed out!");
                if (response.error === 'User is not verified.') {
                    setVerificationMissingDialog(true);
                } else if (response.error === 'User is already subscribed.') {
                    setError(true);
                    setErrorMessage('Du har allerede oprettet et abonnement.');
                } else {
                    console.log('Error');
                    setError(true);
                    setErrorMessage('Noget gik galt. Prøv igen.');
                }

                setIsLoading(false);
            } else {
                // console.log("Submit form to QuickPay payment window");
                // console.log(JSON.stringify(response.params, null, 2));

                let form = document.getElementById('quickpayform');
                for (let key in response.params) {
                    let input = document.createElement('INPUT');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', key);
                    input.setAttribute('value', response.params[key]);
                    form.appendChild(input);
                }
                form.submit();
            }
        })();
    };
    const onSubmit = async (event) => {
        console.log('submitting');
        // Check if checkbox checked
        if (isDisabled) {
            return;
        } else {
            setIsDisabled(true);
        }
        if (accepted) {
            // This is hardcoded for now
            let response = await sendOrder(
                allChildrenData,
                getUserId(),
                'subscription',
                5940,
                'DKK',
                'Musik kursus',
                // This will need to be changed when we have more children in the reg.flow
                getLatestChildInRegFlow().rates.rates,
                formRef,
                // webshopItems,
                setError,
                setErrorMessage,
                setIsDisabled
            );
            if (!response || !response.params) {
                // console.log(response.error);
                // console.log("Payment params checksum timed out!");
                // if (response.error === "User is not verified.") {
                //   setVerificationMissingDialog(true);
                // }else {
                // if (response.error === 'Bad request. The family name is already exist.') {
                //   setError(true);
                //   setIsDisabled(false);
                //   // Danish
                //   setErrorMessage("Familienavnet findes allerede");
                // }
                // else {
                if (
                    response &&
                    response.error ===
                        'Bad request. The family name is already exist.'
                ) {
                    setError(true);
                    setIsDisabled(false);
                    // Danish
                    setErrorMessage('Familienavnet findes allerede');
                    return;
                } else if (
                    response &&
                    response.error ===
                        'Bad request. The child is already exist.'
                ) {
                    setIsDisabled(false);
                    // Danish
                    setErrorMessage('Barnet findes allerede');
                    setError(true);
                    return;
                } else {
                    setError(true);
                    setIsDisabled(false);
                    setErrorMessage('Noget gik galt. Prøv igen.');
                }
            } else {
                // console.log("Submit form to QuickPay payment window");
                // console.log(JSON.stringify(response.params, null, 2));

                let form = document.getElementById('quickpayform');
                for (let key in response.params) {
                    let input = document.createElement('INPUT');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', key);
                    input.setAttribute('value', response.params[key]);
                    form.appendChild(input);
                }
                form.submit();
                // Call QP endpoint
                //  handlePaymentForm();
            }
        } else {
            setCheckboxError('true');
            setError(true);
            setIsDisabled(false);
            // Come back: danish text
            setErrorMessage('You did not accept the terms and conditions.');
            termsRef.current.scrollIntoView();
        }
    };

    const handleAddChild = () => {
        // Remove child from childInRegFlow, remove coursesData, meetingsData from context
        context.saveChildInRegFlow({});
        context.saveMeetingsData({});
        context.saveCoursesData({});

        props.history.push('/tilmelding/foedselsdato');
    };

    const handleRemoveChild = (firstname, surname, birthdate) => {
        setChildToRemove({
            childFirstname: firstname,
            childSurname: surname,
            birthdate
        });
        setWarning(true);
    };

    const confirmChildRemoval = () => {
        const selectedChild = allChildrenData.find(
            (child) =>
                child.childFirstname === childToRemove.childFirstname &&
                child.childSurname === childToRemove.childSurname &&
                child.birthdate === childToRemove.birthdate
        );
        const filteredArray = allChildrenData.filter(
            (child) => child !== selectedChild
        );
        // Update local storage
        context.saveAllChildrenInReg(filteredArray);

        setAllChildrenData(filteredArray);
        setChildToRemove({});
        setWarning(false);
    };

    const handleModifyChild = (firstname, surname) => {
        // Load selected child into childInRegFlow
        const selectedChild = allChildrenData.filter(
            (child) =>
                child.childFirstname === firstname &&
                child.childSurname === surname
        );
        if (selectedChild && selectedChild.length === 1) {
            context.saveChildInRegFlow(selectedChild[0]);
            // Save selected child to storage as childToBeModified, because then we will know that it has to be removed from allChildrenInReg,
            // when we push the modified child object.
            context.saveChildToModify(selectedChild[0]);

            props.history.push('/tilmelding/foedselsdato');
        } else {
            setIsDisabled(false);
            setError(true);
            setErrorMessage('Noget gik galt, kontakt os venligst.');
        }
    };

    // ComponentDidMount
    useEffect(() => {
        // Load information from storage
        getOrderFromStorage(
            setEmail,
            setAllChildrenData,
            // setRatesData,
            // setWebshopItems,
            setError,
            setErrorMessage,
            '/tilmelding/tilmeldingstype',
            props,
            context
        );

        contentRef.current.scrollIntoView();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <NavHeaderDesktop />
            <span ref={contentRef}></span>
            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                <ProgressBar currentStage={'Kasse'} />
                {/* Since by this step all users are logged in, they should not be able to see any return link. 
        If they want to modify a child, they need to click on the Rediger button at the selected child. 
        If they want to modify their profile information, they will need to do that in My page. */}
                {/* <Box width="fit-content">
          <ReturnLinkReusable
            url="/tilmelding/oplysninger"
            destination="Oplysninger"
          />
        </Box> */}

                <RegularHeadline
                    title="Kasse"
                    coponent="h1"
                    marginbottom="1.5rem"
                />
                {/* We want to show this text only to users who just got registered. */}
                {isEmailFilled() && (
                    <Box mb="1rem">
                        <DescriptionText variant="body2">
                            Your user is created for {email}.
                        </DescriptionText>
                    </Box>
                )}
                {allChildrenData.length > 0 ? (
                    <React.Fragment>
                        {allChildrenData.map((child) => (
                            <Box
                                key={child.childFirstname + child.childSurname}
                                mb="3rem"
                                mt="2rem"
                            >
                                <DescriptionContainer>
                                    <DescriptionTextBold variant="body2">
                                        Vare:
                                    </DescriptionTextBold>
                                    <DescriptionText variant="body2">
                                        Musikundervisning for{' '}
                                        {getChildname(child)}
                                    </DescriptionText>
                                </DescriptionContainer>

                                <DescriptionContainer>
                                    <DescriptionTextBold variant="body2">
                                        Kursustype:
                                    </DescriptionTextBold>
                                    <DescriptionText variant="body2">
                                        {getCourseName(child)}
                                    </DescriptionText>
                                </DescriptionContainer>

                                <DescriptionContainer mb="1rem">
                                    <DescriptionTextBold variant="body2">
                                        Tilmeldingstype:
                                    </DescriptionTextBold>
                                    <DescriptionText variant="body2">
                                        {getProcessType(child)}
                                    </DescriptionText>
                                </DescriptionContainer>

                                <Box mb="1rem">
                                    <DescriptionTextBold variant="body2">
                                        Første prioritet:
                                    </DescriptionTextBold>
                                    {getFirstPriorities(
                                        child,
                                        child.process.price
                                    ) &&
                                    getFirstPriorities(
                                        child,
                                        child.process.price
                                    ).length > 0 ? (
                                        <React.Fragment>
                                            {getFirstPriorities(
                                                child,
                                                child.process.price
                                            ).map((prio) => (
                                                <Box mb="1rem" key={prio.id}>
                                                    <DescriptionText variant="body2">
                                                        {prio.day} kl.{' '}
                                                        {prio.time} på{' '}
                                                        {prio.location} med{' '}
                                                        {prio.teacher}
                                                    </DescriptionText>
                                                    <DescriptionText variant="body2">
                                                        Fuld pris:{' '}
                                                        {prio.priceSum ? (
                                                            'DKK ' +
                                                            prio.priceSum
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: '#FFFF00'
                                                                }}
                                                            >
                                                                Der er sket en
                                                                fejl, kontakt
                                                                venligst
                                                                kontoret
                                                            </span>
                                                        )}
                                                    </DescriptionText>
                                                </Box>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <Box mb="2rem">
                                            <DescriptionText>
                                                Ikke vælgt
                                            </DescriptionText>
                                        </Box>
                                    )}
                                </Box>

                                <Box>
                                    <DescriptionTextBold variant="body2">
                                        Anden prioritet:
                                    </DescriptionTextBold>

                                    {getSecondPriorities(
                                        child,
                                        child.process.price
                                    ) &&
                                    getSecondPriorities(
                                        child,
                                        child.process.price
                                    ).length > 0 ? (
                                        <React.Fragment>
                                            {getSecondPriorities(
                                                child,
                                                child.process.price
                                            ).map((prio) => (
                                                <Box mb="1rem" key={prio.id}>
                                                    <DescriptionText variant="body2">
                                                        {prio.day} kl.{' '}
                                                        {prio.time} på{' '}
                                                        {prio.place} med{' '}
                                                        {prio.teacher}
                                                    </DescriptionText>
                                                    <DescriptionText variant="body2">
                                                        Fuld pris: DKK{' '}
                                                        {prio.priceSum}
                                                    </DescriptionText>
                                                </Box>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <Box mb="2rem">
                                            <DescriptionText>
                                                Ikke vælgt
                                            </DescriptionText>
                                        </Box>
                                    )}
                                </Box>
                                <Box width="10rem" mb="2rem">
                                    <RegularButton
                                        title="Rediger ordre"
                                        onButtonClick={() =>
                                            handleModifyChild(
                                                child.childFirstname,
                                                child.childSurname
                                            )
                                        }
                                    />
                                </Box>
                                <Box width="10rem">
                                    <RegularButton
                                        title="Fjern ordre"
                                        onButtonClick={() =>
                                            handleRemoveChild(
                                                child.childFirstname,
                                                child.childSurname,
                                                child.birthdate
                                            )
                                        }
                                    />
                                </Box>
                            </Box>
                        ))}
                    </React.Fragment>
                ) : (
                    <DescriptionText variant="body2">
                        Du har ikke tilføjet nogen børn.
                    </DescriptionText>
                )}

                {allChildrenData.length > 0 ? (
                    <></>
                ) : (
                    // <RegistrationButton
                    //   title="Tilmeld endnu et barn"
                    //   minWidth="15rem"
                    //   onButtonClick={handleAddChild}
                    // />
                    <React.Fragment>
                        <RegistrationButton
                            title="Tilmeld et barn"
                            minWidth="15rem"
                            onButtonClick={handleAddChild}
                        />
                    </React.Fragment>
                )}

                <HorizontalLine marginBottom="2rem" />

                {/* For now products are not available */}
                {/* <DescriptionTextBold variant="body2" customclassname="bigger-margin">
          Tilkøb:
        </DescriptionTextBold>
        {products.map((product) => (
          <DescriptionText key={product.productName} variant="body2">
            {product.productName} 150,-
          </DescriptionText>
        ))} */}

                {/* <HorizontalLine /> */}
                <span ref={termsRef}></span>
                <Box pt="1rem">
                    <RegularHeadline title="Købsvilkår" />
                </Box>

                <TermsOfPurchase />

                <Box display="flex" position="relative" width="fit-content">
                    <CheckboxComponent
                        onCheckboxChange={handleCheckBoxChange}
                        position="static"
                        error={checkboxError}
                    />

                    <CheckboxText
                        variant="body2"
                        onClick={() => handleCheckBoxChange()}
                        error={checkboxError}
                    >
                        Jeg godkender ovenstående aftale
                    </CheckboxText>
                </Box>

                <RegistrationButton onButtonClick={onSubmit} />
                <HiddenFormContainer ref={formRef}></HiddenFormContainer>
                <NotesContainer mt="3rem">
                    <DescriptionText variant="body2">
                        Hvis du har tekniske problemer med tilmeldingen, kontakt
                        venligst admin@leonardo.dk
                    </DescriptionText>
                </NotesContainer>

                <NotesContainer
                    display="flex"
                    alignItems="center"
                    mt="1rem"
                    mb="3rem"
                >
                    <DankortImage src={DankortIcon} alt="dankort" />
                    <DescriptionText variant="body2">
                        Betaling sker med Dankort, VISA/Dankort. Andre korttyper
                        understøttes pt. ikke. Alle priser er i danske kroner
                        (DKK) og inklusive moms.
                    </DescriptionText>
                </NotesContainer>

                {/* Hidden form is needed for QuickPay payment */}
                <form
                    id="quickpayform"
                    action="https://payment.quickpay.net"
                    method="POST"
                ></form>

                {error && (
                    <ErrorDialog
                        errorMessage={errorMessage}
                        close={() => {
                            setErrorMessage('');
                            setError(false);
                        }}
                    />
                )}

                {warning && (
                    <WarningChildRemoval
                        close={() => {
                            setWarning(false);
                            setChildToRemove({});
                        }}
                        onConfirm={confirmChildRemoval}
                        childToRemove={childToRemove}
                    />
                )}

                {/* <NextLink url="/tilmelding/opsummering" title="Videre" /> */}
            </RegistrationContentContainer>
        </div>
    );
}

export default withRouter(Checkout);
