import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { Typography } from "@material-ui/core";
import AboutHeadline from "../../components/headlines/AboutHeadline";
import QABox from "../../components/QABox/QABox";
// import QAData from "../../data/QAData";
import QABoxesContainer from "../../components/containers/about/QABoxesContainer";

// CMS feature
import MessageList from "../../components/message-list";
// import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
// import { getMessage } from "../../components/message-list/MessageListHelper";

function Faq({ getCurrentUrl }) {
  const { url } = useRouteMatch();

  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    // Return current url to parent - current menu gets differnt styling
    getCurrentUrl(url, "FAQ");
  }, [getCurrentUrl, url]);

  return (
    <div>
      <MessageList type="html" name="FAQ%" state="final" change={setMessageList}/>

      <AboutHeadline title="FAQ" />
      <Typography variant="body2">
        Svar til de oftest stillede spørgsmål
      </Typography>
      <QABoxesContainer>
        { messageList.map((message) => (
          <QABox messageId={message.id} title={message.title} html={message.html} key={'faq_'+message.id} width="100%" />
        ))}
      </QABoxesContainer>
    </div>
  );
}

export default Faq;
