

import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const ExplainerVideoContainerComponent = styled(Box)`
width: 45%;

& video {
  width: 100%;
}

@media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
  width: 100%;
}
`;

function ExplainerVideoContainer({children}) {
    return (
        <ExplainerVideoContainerComponent>{children}</ExplainerVideoContainerComponent>
    );
}

export default ExplainerVideoContainer;