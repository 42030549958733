import React from "react";
import styled from "styled-components";

const CalendarHeadlineContainer = styled.div`
  width: 14.28%;
  padding: 8px;
`;
function CalendarHeadline({ children }) {
  return <CalendarHeadlineContainer>{children}</CalendarHeadlineContainer>;
}
export default CalendarHeadline;
