import React, { useState } from "react";
import ExplainerTextContainer from "../../components/containers/explainers/ExplainerTextContainer.jsx";
import ExplainerVideoContainer from "../../components/containers/explainers/ExplainerVideoContainer.jsx";
import ExplainerContent from "../../components/explainers/ExplainerContent.jsx";
// import ExplainerText from "../../components/explainers/ExplainerText.jsx";
import EducationHeadline from "../../components/headlines/EducationHeadline.js";
import VideoComponent from "../../components/video/VideoComponent.jsx";
// import { educationData } from "../../data/EducationData.jsx";
import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";

function Instruments(props) {
  // eslint-disable-next-line
  const [messageList, setMessageList] = useState(undefined); // Array of messages
/*
  const isntrumentEducationData = educationData.filter(
    (education) => education.title === "Instrument"
  );
*/
  return (
    <React.Fragment>
      <MessageList type="html" name="Education Instrument" state="final" change={setMessageList}/>      
      <EducationHeadline title={(messageList ? getMessage(messageList,"Education Instrument",'title','Instrumentalundervisning') : '')} />
      <ExplainerContent>
        <ExplainerTextContainer>
          { messageList && 
            <MessagePlaceholder list={messageList} name="Education Instrument" value=""/>
          }
        </ExplainerTextContainer>
        <ExplainerVideoContainer>
          <VideoComponent src={(messageList ? getMessage(messageList,"Education Instrument",'text','/videos/harp-video.mp4') : '/videos/harp-video.mp4')} type="video/mp4" />
        </ExplainerVideoContainer>
      </ExplainerContent>
    </React.Fragment>
  );
}

export default Instruments;
