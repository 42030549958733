import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavHeadline from "../../headlines/NavHeadline";

const NavContainerComponent = styled.div`
display: block;
position: static;
& a {
    display: block;
}
  @media (min-width: 960px) {
    display: none;
  }
`;

function NavContainerMobile({children}) {
  return (
      <React.Fragment>
    <NavContainerComponent >
      <Link to="/tilmelding/tilmeldingstype">
        <NavHeadline title="Tilmelding" />
      </Link>
      <Link to="/undervisning">
        <NavHeadline title="Om undervisning" />
      </Link>
      <Link to="/om-skolen/vision">
        <NavHeadline title="Om skolen" />
      </Link>
    </NavContainerComponent>
    {children}
    </React.Fragment>
  );
}

export default NavContainerMobile;