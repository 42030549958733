import React, { useState } from "react";
import styled from "styled-components";
import LeftArrow from "../navigate-carousel/LeftArrow";
import RightArrow from "../navigate-carousel/RightArrow";
import SchoolQualitySlide from "./SchoolQualitySlide";
import CarouselBullet from "../navigate-carousel/CarouselBullet";
import { Fade } from "@material-ui/core";
import data from "./data";

const CarouselContainer = styled.div`
  // margin: 5rem auto 10rem auto;
  // max-height: 520px;
  // height: 520px;
  display: none;
  @media (max-width: 960px) {
    display: block;
    margin: 5rem auto 20rem auto;
    max-height: 700px;
    height: 700px;
    position: relative;
    // background-color: black;
  }
`;

const ArrowsWithBullets = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  @media (max-width: 960px) && (min-width: 500px) {
    bottom: -4rem;
  }
  @media (max-width: 500px) {
    bottom: -8rem;
  }
  @media (max-width: 350px) {
    bottom: -11rem;
  }
`;

const BulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: static;
  justify-content: center;
  @media (max-width: 960px) {
  }
`;

// react-touch is compatible with latest React, just implement Swipeable here. Removed import { Swipeable } from "react-touch";
const Swipeable = (props) => {
  return (
    <div style={{
        overflowX: 'scroll',
        overflowY: 'scroll',      
        '&::WebkitScrollbar': {
          display: 'none',     
        },                   
        '&': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none'
        }
    }}>
      {props.children}
    </div>
  );
}

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [checked, setChecked] = useState(true);

  const slidesNumber = data.length;

  const handleArrowClick = (slideId) => {
    const newIndex = slideId;
    console.log(slideId);
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
      setCurrentSlide(newIndex);
    }, 200);
  };

  const handleSwipe = (direction) => {
    if (direction === "left") {
      currentSlide === slidesNumber - 1
        ? handleArrowClick(0)
        : handleArrowClick(currentSlide + 1);
    } else {
      currentSlide === 0
        ? handleArrowClick(slidesNumber - 1)
        : handleArrowClick(currentSlide - 1);
    }
  };
  return (
    <CarouselContainer>
      <Swipeable
        onSwipeLeft={() => handleSwipe("left")}
        onSwipeRight={() => handleSwipe("right")}
      >
        <Fade in={checked}>
          <div>
            <SchoolQualitySlide
              slideInfo={data[currentSlide]}
            ></SchoolQualitySlide>
          </div>
        </Fade>
      </Swipeable>

      <ArrowsWithBullets>
        {/* Use Fade animation  */}
        <LeftArrow
          currentSlide={currentSlide}
          slidesNumber={slidesNumber}
          onClick={handleArrowClick}
        />
        <BulletsContainer>
          {data.map((slide) => {
            return (
              <CarouselBullet
                key={slide.id}
                bulletId={slide.id}
                currentSlide={currentSlide}
                onClick={handleArrowClick}
              />
            );
          })}
        </BulletsContainer>
        <RightArrow
          currentSlide={currentSlide}
          slidesNumber={slidesNumber}
          onClick={handleArrowClick}
        />
      </ArrowsWithBullets>
    </CarouselContainer>
  );
}

export default Carousel;
