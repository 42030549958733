import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorageJson } from "../../util";
import { withCookies } from "react-cookie";
import { getCurrentUserInfo } from "../../context/getData";

// Example of role based authentication <AuthenticatedRoute path="/admin/*" role="admin">

function AuthenticatedRoute(props) {
  // eslint-disable-next-line
  const { role, path, children, ...rest } = props;
  const user = getCurrentUserInfo();
  // if (user !== undefined)
  //  console.log('Authenticated route', props.path, 'user', user, 'user id', 'must be',role);

  /* const locationContext = useContext(Context); */

  const attemptedUrl = window.location.pathname;
  // Check if system update in progress
  let key = document.location.hostname + ":system-update";
  let systemUpdate = false;
  if (typeof Storage !== "undefined" && localStorage.getItem(key) === "true")
    systemUpdate = true;
  // Admin paths should we accessible during system updates
  if (props.path && props.path.startsWith("/admin/")) systemUpdate = false;
  let userRole = (user!==undefined?user.role:'user')
  // If AuthenticatedRoute props.role is not specified then allow the users role 
  let roleList = (role!==undefined?role.split(','):[userRole]);
  /*
  if (!role) console.log('Authenticated route',path,'called without role'); 
  else console.log('Authenticated route',path,'role',userRole,'must match',roleList.join(','));
  */
  const checkAuth = () => {
    const key = document.location.hostname + ":state-history";
    const dataFromLocalStorage = getLocalStorageJson(key, {});
    // Check local storage and cookies for user
    // If user is not authenticated, redirect to Login page
    if (
      dataFromLocalStorage.currentUser &&
      dataFromLocalStorage.currentUser.userFirstname !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isAuthenticated = checkAuth();
  const storageKey = document.location.hostname + ":state-history";
  const dataFromLocalStorage = getLocalStorageJson(storageKey, {});
  const systemUsers = [
    "admin@denlillemusikskole.dk",
    "test@denlillemusikskole.dk",
    "lk@denlillemusikskole.dk",
    "rj@denlillemusikskole.dk",
    "nlh@denlillemusikskole.dk",
    "admin@leonardoudvikling.dk",
    "lk@leonardoudvikling.dk",
    "rj@leonardoudvikling.dk",
    "nlh@leonardoudvikling.dk",
  ];
  let userEmail;
  if (
    dataFromLocalStorage &&
    dataFromLocalStorage.currentUser &&
    dataFromLocalStorage.currentUser.userEmail
  ) {
    userEmail = dataFromLocalStorage.currentUser.userEmail;
  }

  //Might need this part later when testing IOS.
  // const saveCurrentLocation = useCallback(() => {
  //   const props = { ...rest };
  //   // Check this out. I made the condition because when checking courses from my page, it gets props with a delay, so not working
  //   if (props.location) {
  //     locationContext.saveLocation(props.location.pathname);
  //   }
  //   // Come back: check the dependencies
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   return function cleanup() {
  //     if (document.body.offsetWidth < 960) {
  //       saveCurrentLocation();
  //     }
  //   };
  // }, [saveCurrentLocation]);

  // During system update only admin users are allowed
  let goHome = false;
  if (systemUpdate === true && userRole!=='admin' && !systemUsers.includes(userEmail)) goHome = true;
  if (role!==undefined && roleList.indexOf(userRole)<0) goHome = true;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? ( 
          goHome ? (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: attemptedUrl,
                },
              }}
            />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: attemptedUrl },
            }}
          />
        )
      }
    />
  );
}

export default withCookies(AuthenticatedRoute);
