import React from "react";
import styled from "styled-components";

const LineContainer = styled.div`
  width: 100%;
  position: relative;
`;

const LineActive = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.colors.purple};
  position: absolute;
  top: 1.3rem;
`;

const LineInactive = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 1.3rem;
`;

function StageLine({ status }) {
  const getStatus = () => {
    if (status === "active" || status === "completed") {
      return <LineActive />;
    } else {
      return <LineInactive />;
    }
  };

  return <LineContainer>{getStatus()}</LineContainer>;
}

export default StageLine;
