import React from 'react';
import styled from 'styled-components';
import { overridings } from '../../../themes/DarkTheme';
import RegistrationLinkButton from '../LinkButton/RegistrationLinkButton';

const RegistrationButtonContainer = styled.div`
    width: ${(props) => (props.width ? props.width : '18%')};
    min-width: ${(props) => props.minwidth && props.minwidth};
    margin-bottom: 2rem;
    margin-top: ${(props) => (props.margintop ? props.margintop : '3rem')};
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 50%;
        max-width: ${(props) => !props.minwidth && '10rem'};
        min-width: ${(props) => props.minwidth && props.minwidth};
    }
`;

function RegistrationButton({
    style,
    onButtonClick,
    title,
    width,
    minWidth,
    marginTop
}) {
    return (
        <RegistrationButtonContainer
            minwidth={minWidth}
            width={width}
            onClick={() => onButtonClick()}
            margintop={marginTop}
        >
            <RegistrationLinkButton
                style={style}
                title={title ? title : 'Videre'}
                size="medium"
                color={overridings.palette.colors.purple}
                border={overridings.palette.colors.purple}
            />
        </RegistrationButtonContainer>
    );
}

export default RegistrationButton;
