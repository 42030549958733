import { Box } from '@material-ui/core';
import React, { useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import RegularHeadline from '../../../components/headlines/RegularHeadline';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import { overridings } from '../../../themes/DarkTheme';

import CourseTypes from '../../../components/course-types/CourseTypes';

import { Context } from '../../../context/Context';
import MessageList from '../../../components/message-list';
import MessagePlaceholder from '../../../components/message-list/MessagePlaceholder';
import { getMessage } from '../../../components/message-list/MessageListHelper';

const DescriptionContainer = styled(Box)`
    width: 80%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const ButtonsContainer = styled(Box)`
    display: flex;
    // justify-content: space-evenly;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        & div {
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

function RegistrationType(props) {
    const contentRef = useRef();

    // eslint-disable-next-line
    const getRightHeaderImage = () => {
        // Display mobile size images
        let url;
        if (document.body.offsetWidth < overridings.breakpoints.values.md) {
            url = '/images/headers/headers-mobile/header-mobile-vision.jpg';
        } else {
            url = '/images/headers/header-vision.jpg';
        }
        console.log('getRightHeaderImage', url);
        return url;
    };

    const context = useContext(Context);
    const [messageList, setMessageList] = useState([]);

    // ComponentDidMount
    useEffect(() => {
        // Take user to top of the page
        contentRef.current.scrollIntoView(true);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <MessageList
                type="html"
                name="Registration type"
                state="final"
                change={setMessageList}
            />
            {/* <ReusableHeader imageUrl={getRightHeaderImage()} /> */}
            <span ref={contentRef}></span>
            <NavHeaderDesktop />
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>
            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="2rem"
            >
                <Box width="fit-content">
                    <ReturnLinkReusable url="/" destination="Forsiden" />
                </Box>
                <RegularHeadline
                    title={getMessage(
                        messageList,
                        'Registration type',
                        'title',
                        'Vælg tilmelding'
                    )}
                    component="h1"
                    marginbottom="1.5rem"
                />
                <DescriptionContainer>
                    <MessagePlaceholder
                        list={messageList}
                        name="Registration type"
                        value=""
                    />
                </DescriptionContainer>
                <ButtonsContainer
                    style={{
                        justifyContent: context.state.features.enableOrder
                            ? 'space-evenly'
                            : 'left'
                    }}
                >
                    {context.state.features.enableOrder && (
                        <RegistrationButton
                            onButtonClick={() =>
                                props.history.push('/tilmelding/foedselsdato')
                            }
                            title="Tilmeld dit barn"
                            width="15rem"
                            minWidth="15rem"
                        />
                    )}

                    <RegistrationButton
                        style={{
                            display: context.state.features.enableOrderEvent
                                ? 'block'
                                : 'none'
                        }}
                        onButtonClick={() => {
                            console.log('to "/tilmelding/vaelg-intromoede"');
                            props.history.push('/tilmelding/vaelg-intromoede');
                        }}
                        title="Tilmeld intromøde"
                        width="15rem"
                        minWidth="15rem"
                    />
                </ButtonsContainer>

                <div>
                    <CourseTypes />
                </div>
            </RegistrationContentContainer>
        </div>
    );
}

export default withRouter(RegistrationType);
