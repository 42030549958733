import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useRef
} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
    getAllChildrenInReg,
    getAllWorkdays,
    getChildInRegFlow,
    getLatestChildInRegFlow
} from '../../../context/getData';
import { Context } from '../../../context/Context';
import {
    isBirthdateValid,
    isCourseSelected
} from '../GlobalRegistrationFunctions';

import { Box, Typography } from '@material-ui/core';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';

import ProgressBar from '../../../components/progress-bars/ProgressBar';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import RegularHeadline from '../../../components/headlines/RegularHeadline';
import Questionmark from '../../../components/Questionmark/Questionmark';
import ProcessBar from '../../../components/bars/process-bar/ProcessBar';

import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import YourPriceSection from './YourPriceSection';
import RatesSection from './rates-section/RatesSection';
import { getBasicProcessPrice } from './ChooseProcessFunctions';
import Workdays from './Workdays';
import { getAndReturnData } from '../../HelperFunctions';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import ProcessDialog from '../../../components/dialogs/process-dialog/ProcessDialog';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';

//For now products are not available
// const products = [
//   {
//     name: "Min sangbog",
//     price: "200",
//     description:
//       "Min Sangbog er en central del af holdundervisningen på Den Lille Musikskole. Alle elever skal have deres eget eksemplar.",
//   },
//   {
//     name: "Something else",
//     price: "250",
//     description: "Cillum laboris ex aliqua culpa ipsum labore veniam.",
//   },
// ];

// const DescriptionText = styled(Typography)`
//   margin-bottom: 1rem;
// `;

const PaymentRatesContainerOuter = styled.div`
    background: ${(props) => props.theme.palette.secondary.main};
    padding: 0.5rem 0;
`;

const RefBox = styled.div`
    opacity: 0;
`;

function ChooseProcess(props) {
    const [selectedProcess, setSelectedProcess] = useState('');
    // const [selectedProduct, setSelectedProduct] = useState([]);
    const [rates, setRates] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [customFirstRate, setCustomFirstRate] = useState('0');
    const [priceSum, setPriceSum] = useState(0);
    // This number should come from api
    // Come back: this should be dynamic once the season has started
    // eslint-disable-next-line
    const [numberOfLessons, setNumberOfLessons] = useState(33);
    const [workdaysOpen, setWorkdaysOpen] = useState(false);
    const [workdays, setWorkdays] = useState([]);
    const [selectedWorkdays, setSelectedWorkdays] = useState([]);
    const [isWorkdaysLoading, setIsWorkdaysLoading] = useState(false);
    const [processDialogOpen, setProcessDialogOpen] = useState(false);
    // Even if user chose a discounted process, we should reserve the amount for the regular process price.
    // (This is valid as long as there are only 2 processes available - basic and discount)
    const [amountToReserve, setAmountToReserve] = useState(0);

    const contentRef = useRef();
    const processRef = useRef();
    const workdaysRef = useRef();

    const context = useContext(Context);

    let price = getBasicProcessPrice(getChildInRegFlow());

    const processTypes = [
        {
            name: 'Basisforløb',
            price: price ? price : '', // TODO Price based on number of lessons rest of year
            description:
                'Mest populære. Binding for hele sæsonen fra 180 kr. pr. lektion',
            active: true
        },
        // {
        //   name: "Rabatforløb",
        //   price: getDiscountProcessPrice(getBasicProcessPrice(getChildInRegFlow())),
        //   description:
        //     "Beregnet til familier, der ikke har råd til basismedlemsskabet. Forudsætter obligatorisk deltagelse i to årlige arbejdsdage.",
        //   active: false,
        // },
        {
            name: 'Ikke-bindende forløb',
            price: 0,
            description:
                'Uden binding for hele sæsonen fra 225 kr. pr. lektion',
            active: false
        },
        {
            name: 'Prøveforløb',
            price: 1400,
            description:
                'Prøveforløb er X kursusgange af sæsonens 33 lektioner, som ligger fra 1. juli til 30. juni.',
            active: false
        }
        /*
        {
            name: 'Uden møde forløb',
            price: 0,
            description: '',
            active: false
        }
        */
    ];

    // Record selected process
    const onSelect = (title, price) => {
        setSelectedProcess({ title, price });
        if (title === 'Rabatforløb') {
            setScrollView(workdaysRef);
        }
    };

    const calculateSum = useCallback(() => {
        const sum = numberOfLessons * selectedProcess.price;
        setPriceSum(sum);
        if (selectedProcess.title && selectedProcess.title === 'Rabatforløb') {
            setAmountToReserve(sum / 0.75);
        } else {
            setAmountToReserve(sum);
        }
    }, [selectedProcess, numberOfLessons]);

    // For now products are not available
    // const getSwitchValue = useCallback(
    //   (productName, value, price) => {
    //     const productObject = { productName, price };
    //     const selectedProductCopy = [...selectedProduct];
    //     // Save or remove product
    //     if (
    //       selectedProduct.find((product) => product.productName === productName)
    //     ) {
    //       // Remove product
    //       if (value === false) {
    //         const filteredCopy = selectedProductCopy.filter(
    //           (product) => product.productName !== productName
    //         );
    //         setSelectedProduct(filteredCopy);
    //       }
    //     } else if (value === true) {
    //       selectedProductCopy.push(productObject);
    //       setSelectedProduct(selectedProductCopy);
    //     }
    //   },
    //   [selectedProduct]
    // );

    const onSubmit = () => {
        // Validation: Check if user has selected a process
        if (selectedProcess === '') {
            setError(true);
            setErrorMessage('You need to select a forløb.');
            setScrollView();
        } else if (
            selectedProcess.title === 'Rabatforløb' &&
            selectedWorkdays.length < 2
        ) {
            // If user chose the Rabatforløb, but did not choose 2 workdays, we display error message.
            setError(true);
            setErrorMessage(
                'Du skal vælge mindst to arbejdsdage, hvis du vælger Rabatforløb.'
            );
            setScrollView(workdaysRef);
        } else {
            // Add data to localstorage/cookies
            const childInRegFlow = getLatestChildInRegFlow(context);
            // registrationData.products = selectedProduct;
            childInRegFlow.process = selectedProcess;
            // childInRegFlow.process.priceSum = priceSum;
            childInRegFlow.rates = rates;
            childInRegFlow.customFirstRate = customFirstRate;
            childInRegFlow.selectedWorkdays = selectedWorkdays;
            // We need to send the full amount of money that needs to be reserved on the card
            // Especially important in the case of Discount process, since if parent won't show up to both of the arbejdsdag, we will need to
            // get the regular price for the course
            childInRegFlow.amountToReserve = amountToReserve;
            context.saveChildInRegFlow(childInRegFlow);

            props.history.push('/tilmelding/vaelg-moede');
        }
    };

    const recoverProcess = () => {
        const prevSelectedProcess = getLatestChildInRegFlow(context).process;
        if (prevSelectedProcess) {
            setSelectedProcess(prevSelectedProcess);
        }
    };

    const recoverSelectedWorkdays = () => {
        const selectedWorkdaysFromStorage =
            getLatestChildInRegFlow(context).selectedWorkdays;
        if (
            selectedWorkdaysFromStorage &&
            selectedWorkdaysFromStorage.length > 0
        ) {
            setSelectedWorkdays(selectedWorkdaysFromStorage);
        }
    };

    // Not having products in first round
    // const recoverProduct = () => {
    //   const prevSelectedProduct = getChildInRegFlow(context).product;
    //   if (prevSelectedProduct) {
    //     setSelectedProduct(prevSelectedProduct);
    //   }
    // };

    const setScrollView = (ref) => {
        if (ref) {
            ref.current.scrollIntoView();
        } else {
            processRef.current.scrollIntoView();
        }
    };

    const recoverRates = useCallback(() => {
        if (getLatestChildInRegFlow(context).rates) {
            const prevSelectedRates = getChildInRegFlow().rates.rates;
            if (prevSelectedRates) {
                const selectedMonths = prevSelectedRates.filter(
                    (month) => month.selected === true
                );
                const ratesData = {
                    rates: prevSelectedRates,
                    numberOfRates: selectedMonths.length
                };
                context.saveRatesData(ratesData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If Rabatforløb is selected, the workdays should be displayed
    // If other process is selected, we should remove the selected workdays too
    useEffect(() => {
        if (selectedProcess.title && selectedProcess.title === 'Rabatforløb') {
            setWorkdaysOpen(true);
        } else {
            setWorkdaysOpen(false);
            setSelectedWorkdays([]);
        }
    }, [selectedProcess]);

    const fetchAllWorkdays = useCallback(async () => {
        setIsWorkdaysLoading(true);
        const handleError = (error) => {
            console.log(error);
        };
        // Come back: fix url. We should be calling the server from here, and not from nodejs, but
        // due to the different domains and Cors policy issue, the middleware had to be set up.
        const data = await getAndReturnData(
            '/api/v1/event/workdays',
            handleError
        );
        if (data && data.length > 0) {
            setWorkdays(data);
            context.saveAllWorkdays(workdays);
        }
        setIsWorkdaysLoading(false);
        // eslint-disable-next-line
    }, []);

    // Load workdays if not already in storage
    useEffect(() => {
        if (workdaysOpen) {
            if (getAllWorkdays() && getAllWorkdays().length > 0) {
                // Workdays are saved in storage, load them
                setWorkdays(getAllWorkdays());
            } else {
                // Fetch workdays from API
                fetchAllWorkdays();
            }
        }
    }, [workdaysOpen, fetchAllWorkdays]);

    //Every time rates are updated in context, save it to here, so it will be saved as registration data
    useEffect(() => {
        setRates(context.state.ratesData);
    }, [context.state.ratesData]);

    // Save customFirst rate from context
    useEffect(() => {
        setCustomFirstRate(context.state.customFirstRate);
    }, [context.state.customFirstRate]);

    //Calculate new sum price every time user selects a new process
    useEffect(() => {
        calculateSum();
    }, [calculateSum]);

    //ComponentDidMount
    useEffect(() => {
        // Check if user has birtdate and priority/course selected. If not, redirect to birthdate page.
        if (isBirthdateValid() && isCourseSelected()) {
            // Check if there is data saved in localstorage, if yes, apply that
            if (getLatestChildInRegFlow(context)) {
                recoverProcess();
                // recoverProduct();
                recoverRates();
                recoverSelectedWorkdays();
                // Scroll to content
                contentRef.current.scrollIntoView();
                // setScrollView(contentRef);
            }
        } else {
            // If childInRegFlow is empty, or birthdate or course are not selected,
            // we should check if there are other children already in allChildrenInRegFlow.
            // If there are, we should redirect user to checkout page.
            if (getAllChildrenInReg() && getAllChildrenInReg().length > 0) {
                props.history.push('/tilmelding/kasse');
            } else {
                props.history.push('/tilmelding/foedselsdag');
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <NavHeaderDesktop />
            {/* Ref is used for scrollview position */}
            <span ref={contentRef}></span>
            <RegistrationContentContainer
                marginTop="2.5rem"
                marginBottom="1.5rem"
                mobilePaddingTop="1rem"
            >
                <ProgressBar currentStage={'Vælg forløb'} />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/vaelg-hold"
                        destination="Hold"
                    />
                </Box>

                <Box ref={processRef}>
                    <Box display="flex">
                        <RegularHeadline
                            component="h1"
                            title="Vælg forløb"
                            marginbottom="1.5rem"
                        />
                        <Questionmark
                            width="1rem"
                            explainerText="Explainer text here"
                        />
                    </Box>

                    <Typography variant="body2">
                        Dit barn får præcis den samme undervisning, uanset
                        hvilket forløb, du vælger.
                    </Typography>
                </Box>
            </RegistrationContentContainer>

            {processTypes.map((type) => (
                <ProcessBar
                    key={type.name}
                    title={type.name}
                    price={type.price}
                    description={type.description}
                    onSelect={onSelect}
                    selectedProcess={selectedProcess}
                    active={type.active}
                    setProcessDialogOpen={setProcessDialogOpen}
                    // isDiscountProcess={type.name === "Rabatforløb" ? true : false}
                />
            ))}

            <RefBox ref={workdaysRef}>test</RefBox>

            <Box>
                {workdaysOpen && (
                    <React.Fragment>
                        <Workdays
                            workdays={workdays}
                            selectedWorkdays={selectedWorkdays}
                            setSelectedWorkdays={setSelectedWorkdays}
                            isLoading={isWorkdaysLoading}
                        />
                    </React.Fragment>
                )}
            </Box>

            <RegistrationContentContainer>
                <YourPriceSection />
            </RegistrationContentContainer>

            <PaymentRatesContainerOuter>
                <RegistrationContentContainer>
                    <RatesSection
                        setError={setError}
                        setErrorMessage={setErrorMessage}
                        priceSum={priceSum}
                        setScrollView={setScrollView}
                    />
                </RegistrationContentContainer>
            </PaymentRatesContainerOuter>

            {/* For now products are not available */}
            {/* <RegistrationContentContainer>
        <RegularHeadline title="Tilkøb" marginbottom="1.5rem"/>
        <DescriptionText variant="body2">
          Voluptate eu est laborum culpa ea laboris veniam ex culpa id elit
          dolor laborum ea.
        </DescriptionText>
      </RegistrationContentContainer>
      {products.map((product) => (
        <ProductBar
          key={product.name}
          title={product.name}
          description={product.description}
          price={product.price}
          getSwitchValue={getSwitchValue}
          selectedProduct={selectedProduct}
        />
      ))} */}

            <RegistrationContentContainer>
                <RegistrationButton onButtonClick={onSubmit} />
                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/vaelg-hold"
                        destination="Hold"
                    />
                </Box>
            </RegistrationContentContainer>

            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => {
                        setErrorMessage('');
                        setError(false);
                    }}
                />
            )}
            {processDialogOpen && (
                <ProcessDialog close={() => setProcessDialogOpen(false)} />
            )}
        </div>
    );
}

export default withRouter(ChooseProcess);
