import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const CrossMarkComp = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
  font-size: 1rem;
  font-weight: bold;
  position: relative;
  display: inline;
`;

function CrossMark(props) {
  return <CrossMarkComp variant="body2">x</CrossMarkComp>;
}

export default CrossMark;
