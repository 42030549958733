import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const OuterContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

const TermsOfPurchaseContainer = styled.div`
  height: ${(props) => (props.height ? props.height : "25rem")};

  overflow: scroll;
  overflow-x: hidden;

  & :last-child {
    margin-bottom: 5rem;
  }
`;

const GradientOverlay = styled.div`
  height: 6rem;
  width: 90%;
  background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
  position: absolute;
  bottom: 0;
`;

const Text = styled(Typography)`
  width: 60%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 90%;
  }
`;

function TermsOfPurchase({ height }) {
  return (
    <OuterContainer>
      <TermsOfPurchaseContainer height={height}>
        <Text variant="body2">
          Du har tilmeldt Tester til Musikalsk Legestue II
        </Text>
        <Text variant="body2" gutterBottom>
          Kurset består af ét sammenhængende forløb á 13 lektioner til en samlet
          pris på 2080 kr fordelt på 1 rater á 2080 kr. OBS: Beregningen er
          foretaget ud fra først mulige startdato: 17/2-2020 efter møder og
          fortrydelsesperiode. Ved senere opstart vil antal kursusgange og pris
          automatisk blive reduceret. Dette kræver ikke en ny godkendelse af
          nærværende aftale. Undervisningen kan lægges fra den 17/2-2020 til den
          29/6-2020.
        </Text>
        <Text variant="body2" gutterBottom>
          Du kan fortryde tilmeldingen indtil 3 dage før første
          undervisningsgang. Derefter er tilmeldingen uopsigelig, undtagen ved
          alvorlig fysisk eller psykisk sygdom. Hvis læreren bliver syg, vil
          skolen forsøge at skaffe en vikar. Er dette ikke muligt, sendes besked
          via SMS om aflysningen. Undervisningen vil blive erstattet i en
          fleksuge. Datoen kan ses i kalenderen seneste 3 dage efter
          raskmeldingen.
        </Text>
        <Text variant="body2" gutterBottom>
          Læreren kan også flytte undervisningen til en fleksuge af andre
          årsager, dette adviseres per mail seneste 3 dage før den planlagte
          undervisningsgang. I mailen vil også fremgå den nye dato.
        </Text>
        <Text variant="body2" gutterBottom>
          Alle fleksuger/dage står i kalenderen fra sæsonstart.
        </Text>
        <Text variant="body2">
          Hvis der opstår længerevarende fysisk eller psykisk sygdom hos barnet
          eller den voksne følger, i den periode kurset afvikles, kan kurset
          afbrydes, så snart kontoret har modtaget en mail vedlagt en læge-,
          psykolog- eller psykiatererklæring. Betaling for de resterende
          kursusgange vil blive returneret eller ratebetalinger stoppet med det
          samme.
        </Text>
        <Text variant="body2">
          Hvis en elev eller en følgevoksen udviser en adfærd, der ifølge
          underviserens skøn forhindrer øvrige deltagere i at få fuldt udbytte
          af undervisningen, vil kurset kunne opsiges med øjeblikkelig varsel
          uden økonomisk erstatning. Inden en sådan opsigelse, skal der have
          været mindst tre løsningsforsøg, deraf mindst en direkte fysisk
          samtale mellem skole og forældre uden børn tilstede.
        </Text>
        <Text variant="body2">
          Der er ophavsret på al pædagogik, metodik, repertoire og
          undervisningsmateriale, som følgevoksne og børn har set og/eller hørt
          på Den Lille Musikskole. Det må ikke videregives i nogen form uden
          skriftelig samtykke fra Den Lille Musikskole.
        </Text>
        <Text variant="body2">
          Når man tilmelder et barn på Michael Schillings hold, accepterer man
          samtidig, at der må tages billeder og video til brug på vores
          hjemmeside, Facebookside, i vores foldere og andet
          informationsmateriale.
        </Text>
        <Text variant="body2">
          Hvis vi får taget billeder eller video på andre læreres hold, vil
          disse kun blive delt med samtykke hertil fra mindst én følgevoksen til
          hvert barn på det pågældende hold.
        </Text>
        <Text variant="body2">
          Det er ikke tilladt at fotografere, snapchatte, lydoptage eller filme
          nogen former for undervisning, uden at spørge læreren først.
        </Text>
        <Text variant="body2">
          Overtrædelser af denne købsaftale kan medføre en opsigelse af aftalen.
          Skolen forbeholder sig ret til erstatning i form af fuld betaling af
          eventuelt restbeløb for kurset til fuld og hel betaling senest 14 dage
          efter aftalens opsigelse.
        </Text>
      </TermsOfPurchaseContainer>
      <GradientOverlay />
    </OuterContainer>
  );
}

export default TermsOfPurchase;
