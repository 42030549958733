// import moment from "moment";
const days = [
  { value: "", title: "" },
  { value: "01", title: "1" },
  { value: "02", title: "2" },
  { value: "03", title: "3" },
  { value: "04", title: "4" },
  { value: "05", title: "5" },
  { value: "06", title: "6" },
  { value: "07", title: "7" },
  { value: "08", title: "8" },
  { value: "09", title: "9" },
  { value: "10", title: "10" },
  { value: "11", title: "11" },
  { value: "12", title: "12" },
  { value: "13", title: "13" },
  { value: "14", title: "14" },
  { value: "15", title: "15" },
  { value: "16", title: "16" },
  { value: "17", title: "17" },
  { value: "18", title: "18" },
  { value: "19", title: "19" },
  { value: "20", title: "20" },
  { value: "21", title: "21" },
  { value: "22", title: "22" },
  { value: "23", title: "23" },
  { value: "24", title: "24" },
  { value: "25", title: "25" },
  { value: "26", title: "26" },
  { value: "27", title: "27" },
  { value: "28", title: "28" },
  { value: "29", title: "29" },
  { value: "30", title: "30" },
  { value: "31", title: "31" },
];

const months = [
  { value: "", title: "" },
  { value: "01", title: "Januar" },
  { value: "02", title: "Februar" },
  { value: "03", title: "Marts" },
  { value: "04", title: "April" },
  { value: "05", title: "Maj" },
  { value: "06", title: "Juni" },
  { value: "07", title: "Juli" },
  { value: "08", title: "August" },
  { value: "09", title: "September" },
  { value: "10", title: "Oktober" },
  { value: "11", title: "November" },
  { value: "12", title: "December" },
];

let years= [{ value: "", title: "" }];
// Add all years since year 2000
let thisYear = (new Date()).getFullYear();
for (let y=2000; y<thisYear;y++) {
  years.push({ value: y.toString(), title: y.toString() });
}

export {days, months, years};
