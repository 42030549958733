import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

// Needed as a placeholder, since the navbar is set to fixed
const NavbarSpace = styled.div`
  height: 3.5rem;
`;

const BackgroundComp = styled.div`
height: ${(props) => props.height};
  min-height: 25rem;
  background-image: 
    url("${(props) => props.imageUrl}");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: ${(props) =>
    props.bgposition ? props.bgposition : "top"};
  position: relative;
`;

function ReusableHeaderBackgroundMobile({ imageUrl, height, bgPosition }) {
  return (
    <MainContainer>
      <NavbarSpace />
      <BackgroundComp
        height={height}
        imageUrl={imageUrl}
        bgposition={bgPosition}
      />
    </MainContainer>
  );
}

export default ReusableHeaderBackgroundMobile;
