import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

// Components
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@material-ui/core";
import MailImage from "../mail-image/MailImage";
import StaircaseImageFlipped from "../staircase-image/StaircaseImageFlipped";

const CustomCard = styled(Card)`
  border-bottom: 2px solid ${(props) => props.colorcode};
  border-radius: 0;
  height: 100%;
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const ReadMoreLink = styled(Typography)`
  color: ${(props) => props.colorcode};

  text-decoration: underline;
  display: block;
`;

const CustomCardMedia = styled(CardMedia)`
  height: 15rem;
`;

const CustomCardContent = styled(CardContent)`
  color: ${(props) => props.colorcode};
  position: relative;

  & a {
    color: ${(props) => props.colorcode};
  }
`;

const StaircaseImageContainer = styled(Box)`
  width: 1.8rem;
  margin-right: 0.5rem;
  & img {
    padding-top: 0.25rem;
  }
`;

const MailImageContainer = styled.div`
  position: absolute;
  right: 1rem;
  margin-top: 0.3rem;
`;

function EmployeeCard({
  name,
  image,
  instrument,
  setIsEmployeeSelected,
  color,
  colorName,
  email,
  to,
  history,
}) {
  const onCardClick = () => {
    history.push(to);
  };

  const onCardClickFromEmployees = () => {
    setIsEmployeeSelected(true);
    history.push(to);
  };

  return (
    <React.Fragment>
      {setIsEmployeeSelected ? (
        <CustomCard
          onClick={() => {
            onCardClickFromEmployees();
          }}
          colorcode={color}
        >
          <CardActionArea disableRipple>
            <CustomCardMedia title={name} image={image} />

            <CustomCardContent colorcode={color}>
              <Box display="flex">
                <StaircaseImageContainer>
                  <StaircaseImageFlipped
                    src={`/images/staircase/staircase-${colorName}.png`}
                    alt="staircase-logo"
                    width="0.95"
                  />
                </StaircaseImageContainer>
                <Box>
                  <Typography variant="body2">{name}</Typography>
                  <Typography gutterBottom variant="body2">
                    {instrument}
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    {/* Colorcode is lowercase on purpose, otherwise we get warning */}
                    <ReadMoreLink variant="body2" colorcode={color}>
                      Læs mere
                    </ReadMoreLink>
                    <a href={`mailto:${email}`}>
                      <MailImageContainer>
                        <MailImage
                          src={`/images/mail/mail-${colorName}.png`}
                          height="0.85"
                          alt="mail-logo"
                        />
                      </MailImageContainer>
                    </a>
                  </Box>
                </Box>
              </Box>
            </CustomCardContent>
          </CardActionArea>
        </CustomCard>
      ) : (
        <CustomCard
          onClick={() => {
            onCardClick();
          }}
          colorcode={color}
        >
          <CardActionArea disableRipple>
            <CustomCardMedia title={name} image={image} />

            <CustomCardContent colorcode={color}>
              <Box display="flex">
                <StaircaseImageContainer>
                  <StaircaseImageFlipped
                    src={`/images/staircase/staircase-${colorName}.png`}
                    alt="staircase-logo"
                    width="0.95"
                  />
                </StaircaseImageContainer>
                <Box>
                  <Typography variant="body2">{name}</Typography>
                  <Typography gutterBottom variant="body2">
                    {instrument}
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <ReadMoreLink variant="body2" colorcode={color}>
                      Læs mere
                    </ReadMoreLink>
                    <a href={`mailto:${email}`}>
                      <MailImageContainer>
                        <MailImage
                          src={`/images/mail/mail-${colorName}.png`}
                          height="0.85"
                          alt="mail-logo"
                        />
                      </MailImageContainer>
                    </a>
                  </Box>
                </Box>
              </Box>
            </CustomCardContent>
          </CardActionArea>
        </CustomCard>
      )}
    </React.Fragment>
  );
}

export default withRouter(EmployeeCard);
