import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { postAndReturnResp } from "../../../containers/HelperFunctions";
import {
  displaySmallError,
  // displayUsernames,
  // getUserIdFromResp,
  // sendInvitationEmail,
  validateEmail,
} from "./AddParentDialogFunctions";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,

  Typography,
} from "@material-ui/core";
import RegularButton from "../../buttons/RegularButton/RegularButton";
import Loader from "../../loader/Loader";
import TextInput from "../../text-input/TextInput";
import ErrorDialog from "../error-dialog/ErrorDialog";
import {
  postAndReturnRespWithAuth,
} from "../../../context/helperFunctions";
import { getToken } from "../../../context/getData";
import SuccessDialog from "../success-dialog/SuccessDialog";
import CheckboxComponent from "../../checkbox/Checkbox";

const Parentname = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding-top: 0.3rem;
`;

const SuccessText = styled(Typography)`
  color: ${(props) => props.theme.palette.success.main};
  padding-top: 0.3rem;
`;

// const BigSuccessTextComp = styled(Typography)`
//   color: ${(props) => props.theme.palette.success.main};
// `;

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem !important;
  }
`;

// const SelectErrorTextContainer = styled(Box)`
//   .MuiFormHelperText-root {
//     /* color={overridings.palette.colors.purple} */
//     color: ${(props) => props.theme.palette.error.main};
//     position: relative;
//     bottom: 5px;
//   }
// `;

const CheckboxText = styled(Typography)`
  margin-top: 0.8rem;

  color: ${(props) =>
    props.error === "true"
      ? props.theme.palette.error.main
      : props.theme.palette.primary.contrastText};
`;

function AddParentDialog({ close, userId, families }) {
  const [email, setEmail] = useState("");
  const [inputError, setInputError] = useState(false);
  const [smallErrorText, setSmallErrorText] = useState("");
  const [existingParentname, setExistingParentname] = useState("");
  const [existingParentId, setExistingParentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [bigSuccessText, setBigSuccessText] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // TODO: Rename family to the more generic relation group type  
  // eslint-disable-next-line  
  const [selectedFamilyObj, setSelectedFamilyObj] = useState({
    label: "Vælg familie",
    value: "Ikke angivet",
  });
  // eslint-disable-next-line
  const [familiesArr, setFamiliesArr] = useState([]);
  // eslint-disable-next-line
  const [selectError, setSelectError] = useState(false);
  const [children, setChildren] = useState([]);
  const [selectedChildrenIds, setSelectedChildrenIds] = useState([]);

  const handleInputChange = (valueObject) => {
    validateEmail(
      valueObject.value,
      setEmail,
      setInputError,
      setSmallErrorText
    );
  };

  const sendInvitation = async (email, selectedChildrenIds) => {
    const token = getToken();
    // The backend checks if there are any users with the given email.
    // If yes, they get an email with a link, which they have to click and we do something..
    // If not, we still send an email with a link to signup.
    const responseAndData = await postAndReturnRespWithAuth(
      `api/v1/user/${userId}/request-connection`,
      { selectedChildrenIds, email },
      token,
      handleError
    );
    const respStatus = responseAndData.status;
    if (respStatus === 200) {
      setSuccess(true);
      // Come back: danish text
      setSuccessMessage(`Invitation is sent to ${email}.`);
    } else if (respStatus === 403) {
      handleError("You are using your own email. You cannot invite yourself.");
    } else {
      // Come back: danish text
      handleError("Could not send email. Please try again.");
    }
  };

  const handleError = (msg) => {
    setError(true);
    if (msg) {
      setErrorMessage(msg);
    } else {
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  const removeErrorOnChange = () => {
    setInputError(false);
    setSmallErrorText("");

    // If it is a new search, then remove existing parent name
    if (existingParentname) {
      setExistingParentId(null);
      setExistingParentname("");
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (selectedChildrenIds.length < 1) {
      // Come back: danish text
      setErrorMessage("You must select a child.");
      setIsLoading(false);
      return setError(true);
    }

    if (email && email !== "" && !inputError) {
      // Endpoint: /user/validate
      await sendInvitation(email, selectedChildrenIds);
      // } else if (selectedFamilyObj.value === "Ikke angivet") {
      //   setSelectError(true);
      //   setIsLoading(false);
    } else {
      // Come back: need danish text
      displaySmallError(
        setInputError,
        setSmallErrorText,
        "Email is missing or invalid.",
        setIsLoading
      );
    }
  };

  const sendRequest = async () => {
    setIsLoading(true);
    const response = await postAndReturnResp(
      `api/v1/user/${userId}/requestConnection?id=${existingParentId}`,
      {},
      handleError
    );
    if (response.status === 200) {
      setIsLoading(false);
      setRequestSent(true);
    } else {
      setIsLoading(false);
      setError(true);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  // ComponentDidMount
  useEffect(() => {
    if (families && families.length > 0) {
      const familyNamesAndIds = [{ name: "Vælg", value: "Ikke angivet" }];

      families.forEach((fam) => {
        const famObject = {
          name: fam.familyName,
          value: fam.groupId,
        };
        familyNamesAndIds.push(famObject);
      });

      setFamiliesArr(familyNamesAndIds);

      const childrenArr = [];
      families.forEach((fam) => {
        fam.participants.forEach((p) => {
          childrenArr.push(p);
        });
      });
      setChildren(childrenArr);
    }
    // TODO: Can we avoid ignoring the families dependency?
    // eslint-disable-next-line
  }, []);

  // const handleSelectChange = (e) => {
  //   setSelectError(false);
  //   setSelectedFamilyObj({ ...selectedFamilyObj, value: e.target.value });
  // };

  const handleChildCheck = (e) => {
    // Check if id is already part of selectedChildrenIds
    if (selectedChildrenIds.includes(e.target.name)) {
      //Remove it
      const newSelectedChildrenIds = selectedChildrenIds.filter(
        (id) => id !== e.target.name
      );
      setSelectedChildrenIds(newSelectedChildrenIds);
    } else {
      setSelectedChildrenIds([...selectedChildrenIds, e.target.name]);
    }
  };

  const handleCheckboxError = () => {
    console.log("error");
  };

  return (
    <CustomDialog
      onClose={close}
      open={true}
      aria-labelledby="add-parent"
      aria-describedby="add-parent-description"
      disableRestoreFocus
    >
      <DialogTitle id="add-parent">Tilføj følgevoksnen</DialogTitle>
      {/* Come back: danish text */}
      <DialogContent>
        <DialogContentText id="add-parent-description">
          Select a child, whom you want the new parent have access to
        </DialogContentText>
        {children.map((child) => (
          <Box display="flex" position="relative" key={child.id}>
            <Box width="fit-content">
              <CheckboxComponent
                onCheckboxChange={(e) => handleChildCheck(e)}
                position="static"
                error={handleCheckboxError}
                name={child.id}
              />
            </Box>

            <CheckboxText
              variant="body2"
              //  error={checkboxErrorTerms}
            >
              {child.firstname}
            </CheckboxText>
          </Box>
        ))}
        {/* <SelectWithLabel
          label={selectedFamilyObj.label}
          onChange={handleSelectChange}
          name="family"
          value={selectedFamilyObj.value}
          id="selectedFamily"
          optionsArr={familiesArr}
          width="100%"
        />
        {selectError && (
          <SelectErrorTextContainer maxWidth="fit-content">
            <FormHelperText>Select a family</FormHelperText>
          </SelectErrorTextContainer> */}

        <DialogContentText id="add-parent-description">
          Enter email address of person you want to invite as følgevoksnen.
        </DialogContentText>
        <TextInput
          label="Email"
          onInputChange={handleInputChange}
          smallErrorText={smallErrorText}
          inputError={inputError}
          id="email"
          type="text"
          valueFromParent={email}
          handleEnterPress={handleInputChange}
          removeErrorOnChange={removeErrorOnChange}
        />
        {/* {bigSuccessText !== "" && (
          <BigSuccessTextComp variant="subtitle1">
            {bigSuccessText}
          </BigSuccessTextComp>
        )} */}
        {existingParentname && (
          <div>
            <Typography variant="subtitle1">
              Email is already used. If you wish to connect your account, send a
              request.
            </Typography>
            <Box display="flex" mt="2rem">
              <Box mr="2rem">
                <Parentname variant="subtitle2">
                  {existingParentname}
                </Parentname>
              </Box>

              <Box width="10rem">
                {requestSent ? (
                  <SuccessText variant="subtitle2">
                    Invitation sent.
                  </SuccessText>
                ) : (
                  <RegularButton
                    title="Send request"
                    onButtonClick={sendRequest}
                  />
                )}
              </Box>
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {existingParentname === "" ? (
              <Button onClick={() => handleSubmit()}>Send invitation</Button>
            ) : (
              <Button onClick={() => close()}>Luk</Button>
            )}
          </React.Fragment>
        )}
      </DialogActions>
      {error && (
        <ErrorDialog
          errorMessage={errorMessage}
          close={() => {
            setError(false);
            setIsLoading(false);
          }}
        />
      )}

      {success && (
        <SuccessDialog
          title="Succes"
          successMessage={successMessage}
          close={() => {
            setSuccess(false);
            close();
          }}
        />
      )}
    </CustomDialog>
  );
}

export default AddParentDialog;
