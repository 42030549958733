import moment from 'moment';
import { capitalize } from '../../HelperFunctions';

export const recoverSelectElement = (
    registrationData,
    setOption,
    setOptionSelected
) => {
    const value = registrationData.selectParentMeetingValue;

    if (value) {
        setOption(value);
        // If it now is selected, parent meetings section should expand
        if (value === 'now') {
            setOptionSelected(true);
        } else {
            setOptionSelected(false);
        }
    }
};

export const recoverMeetings = (
    childInRegFlow,
    selectedIntroMeetingFromStorage,
    setSelectedIntroMeeting,
    setSelectedParentMeetings,
    setSelectedExtraParentMeetings
) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('debug') === 'true')
        console.log(
            'recoverMeetings:',
            childInRegFlow ? JSON.stringify(childInRegFlow, null, 2) : undefined
        );
    if (childInRegFlow) {
        if (childInRegFlow.selectedMeetings) {
            const prevParentMeeting =
                childInRegFlow.selectedMeetings.selectedParentMeetings;
            const prevExtraParentMeeting =
                childInRegFlow.selectedMeetings.selectedExtraParentMeetings;
            setSelectedParentMeetings(prevParentMeeting);
            setSelectedExtraParentMeetings(prevExtraParentMeeting);
        }
    }

    if (selectedIntroMeetingFromStorage) {
        setSelectedIntroMeeting(selectedIntroMeetingFromStorage);
    }
};

export const getDayName = (date) => {
    const dayName = moment(date).format('dddd');
    return capitalize(dayName);
};

export const getDateString = (date) => {
    const day = moment(date).format('DD');
    const month = moment(date).format('MMMM');
    const year = moment(date).format('YYYY');
    return `${day}. ${month}, ${year} `;
};

export const getTimeString = (startdate, enddate) => {
    const start = moment(startdate).format('HH:mm');
    const end = moment(enddate).format('HH:mm');
    return `kl. ${start}-${end}`;
};
