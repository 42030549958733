import React, { useState } from "react";
import styled from "styled-components";
import { ClickAwayListener, Popper } from "@material-ui/core";
import QuestionmarkIcon from "../../assets/questionmark.png";
import CheckMark from "../marks/CheckMark";
import CrossMark from "../marks/CrossMark";

const QuestionmarkComponent = styled.img.attrs((props) => ({
  className: props.className,
}))`
  width: ${(props) => props.width};
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
`;

const QuestionmarkContainer = styled.div``;

const PopperText = styled.div`
  background-color: ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: 0.5rem;
`;

// This is a custom Questionmark, which is used on Min Side
function CustomQuestionmark({
  width,
  beenToIntroMeeting,
  beenToParentMeeting,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>
      <QuestionmarkContainer>
        <ClickAwayListener onClickAway={handleClickAway}>
          <QuestionmarkComponent
            src={QuestionmarkIcon}
            alt="questionmark"
            width={width}
            onClick={handleClick}
            aria-describedby={id}
            className="questionmark-component"
          />
        </ClickAwayListener>
      </QuestionmarkContainer>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <PopperText>
          Intromøde: {beenToIntroMeeting ? <CheckMark /> : <CrossMark />},
          Følgevosknemøde: {beenToParentMeeting ? <CheckMark /> : <CrossMark />}
        </PopperText>
      </Popper>
    </React.Fragment>
  );
}

export default CustomQuestionmark;
