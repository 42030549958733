import { ClickAwayListener, Popper } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import QuestionmarkIcon from '../../assets/questionmark.png';
import MessageList from '../message-list';
import MessagePlaceholder from '../message-list/MessagePlaceholder';

const QuestionmarkComponent = styled.img.attrs((props) => ({
    className: props.className
}))`
    width: ${(props) => props.width};
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
`;

const QuestionmarkContainer = styled.div``;

const PopperText = styled.div`
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 0.5rem;
`;

function Questionmark({ width, explainerText, messageListName }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [messageList, setMessageList] = React.useState([]);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <React.Fragment>
            <MessageList
                type="html"
                name="Choose intro meeting explainer text"
                state="final"
                change={setMessageList}
            />
            <QuestionmarkContainer>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <QuestionmarkComponent
                        src={QuestionmarkIcon}
                        alt="questionmark"
                        width={width}
                        onClick={handleClick}
                        aria-describedby={id}
                        className="questionmark-component"
                    />
                </ClickAwayListener>
            </QuestionmarkContainer>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                {messageList ? (
                    <MessagePlaceholder
                        list={messageList}
                        name={messageListName}
                        value=""
                    />
                ) : (
                    <PopperText>{explainerText}</PopperText>
                )}
            </Popper>
        </React.Fragment>
    );
}

export default Questionmark;
