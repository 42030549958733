import { getLatestChildInRegFlow } from '../../../context/getData';

export const createMeetingsData = (introMeetings, parentMeetings) => {
    const meetingsObj = {};
    meetingsObj.introMeetings = introMeetings;
    meetingsObj.parentMeetings = parentMeetings.filter(
        (meeting) => !meeting.title.includes('ekstra')
    );
    meetingsObj.extraParentMeetings = parentMeetings.filter(
        (meeting) =>
            meeting.title.includes('ekstra') || meeting.title.includes('Ekstra')
    );
    return meetingsObj;
};

// Set meetings, there are any (from server)
export const getMeetings = (courseObject, introMeetings, parentMeetings) => {
    courseObject.forEach((course) => {
        if (course.introMeetings) {
            // if (course.introMeetings.length !== 0) { // Data is coming here strangely, so for now I'll hardcode
            //   introMeetings.push(...course.introMeetings);
            // }
            // const hardcodedMeeting = course.introMeetings.
            introMeetings.push(course.introMeetings);
        }

        if (course.parentMeetings) {
            if (course.parentMeetings.length !== 0) {
                parentMeetings.push(...course.parentMeetings);
            }
        }
    });
};

export const saveMeetings = (receivedCourseType, context) => {
    const introMeetings = [];
    const parentMeetings = [];
    getMeetings(receivedCourseType, introMeetings, parentMeetings);
    const meetingsData = createMeetingsData(introMeetings[0], parentMeetings);
    context.saveMeetingsData(meetingsData);
};

export const recoverPriorities = (setPriorities, context) => {
    // If there is priority selects in local storage, use and load them
    if (getLatestChildInRegFlow(context)) {
        const previousPriorities = getLatestChildInRegFlow(context).priorities;
        if (previousPriorities) {
            setPriorities(previousPriorities);
        }
    }
};

export const recoverComment = (setComment, context) => {
    if (getLatestChildInRegFlow(context)) {
        const previousComment = getLatestChildInRegFlow(context).comment;
        if (previousComment) {
            setComment(previousComment);
        }
    }
};

export const getChildFullname = (firstname, surname) => {
    if (firstname && surname) {
        return firstname + ' ' + surname;
    } else {
        return '';
    }
};

export const getChildFullnameStorage = (childInRegFlow) => {
    if (childInRegFlow.childFirstname && childInRegFlow.childSurname) {
        return (
            childInRegFlow.childFirstname + ' ' + childInRegFlow.childSurname
        );
    } else {
        return '';
    }
};
