import React from "react";
import styled from "styled-components";

const HeaderOverlayComponent = styled.div`
  //   margin-top: -17.5vw;
  z-index: 200;
  width: 100%;
  /* min-height: 206px; */
  // max-height: 206px;
  height: 12rem;

  background-image: url("/images/header-overlay.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: transparent;
  position: absolute;
  bottom: 0rem;

  @media (min-width: 1187px) {
    height: 35%;
    min-height: 16rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }

  // @media (max-width: 728px) {
  //   height: 9rem;
  // }

  // @media (max-width: 652px) {
  //   height: 8rem;
  // }

  // @media (max-width: 580px) {
  //   height: 6rem;
  // }

  // @media (max-width: 441px) {
  //   height: 5rem;
  // }

  // @media (max-width: 368px) {
  //   height: 4rem;
  // }
`;

function HeaderOverlay(props) {
  return <HeaderOverlayComponent />;
}

export default HeaderOverlay;
