import React from "react";
import styled, { keyframes } from "styled-components";
import Day from "./Day.jsx";
import moment from "moment";

const displayOpacity = keyframes`
  {
    0%{
        opacity:0%
      }
      50%{
        opacity:50%   
      }
    100%{
    opacity:100%
  }
}
`;
const WeekContainer = styled.div`
  display: flex;
  position: relative;
  animation: ${displayOpacity} 0.5s linear forwards;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

function Week(props) {
  const isThereEvents = props.globalEvents.length;
  const days = [];
  let isInPast;
  const createDays = () => {
    for (let count = 0; count < 7; count++) {
      isInPast = moment(props.weekInfo.dates[count].date).isBefore(
        moment(new Date()).format("YYYY-MM-DD")
      );
      days.push(
        <Day
          key={count}
          dayInfo={props.weekInfo.dates[count]}
          isPast={isInPast}
          openEventDetails={props.openEventDetails}
          selectedEventInCalendar={props.selectedEventInCalendar}
          globalEvents={props.globalEvents}
          calendar={props.calendar}
          weekInfo={props.weekInfo}
        />
      );
    }
    return days;
  };
  return (
    <WeekContainer
      isThereEvents={isThereEvents}
      eventsArray={props.eventsArray}
    >
      {createDays()}
    </WeekContainer>
  );
}
export default Week;
