import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const CheckboxComp = styled(Checkbox)`
    position: ${(props) =>
        props.position === 'static' ? 'static' : 'absolute'};
    top: ${(props) => (props.position === 'static' ? '0' : '40%')};
    right: ${(props) => (props.position === 'static' ? '0' : '6rem')};
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        right: ${(props) => (props.position === 'static' ? '0' : '1rem')};
    }
`;

const CustomRadioButtonChecked = styled(RadioButtonChecked)`
    color: ${(props) => props.theme.palette.primary.contrastText};
`;

const CustomRadioButtonUnchecked = styled(RadioButtonUnchecked)`
    color: ${(props) => props.theme.palette.primary.contrastText};

    &.MuiSvgIcon-root {
        color: ${(props) =>
            props.error === 'true'
                ? props.theme.palette.error.main
                : props.theme.palette.primary.contrastText};
    }
`;

function RadioCheckboxComponent({
    onCheckboxChange,
    checked,
    position,
    error,
    name
}) {
    return (
        <CheckboxComp
            onChange={(event) => onCheckboxChange(event)}
            icon={<CustomRadioButtonUnchecked error={error} />}
            checkedIcon={<CustomRadioButtonChecked />}
            name={name}
            checked={checked}
            disableRipple
            position={position}
        />
    );
}

export default RadioCheckboxComponent;
