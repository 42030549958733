import React from "react";
import styled from "styled-components";
import ContentContainer from "../../components/containers/ContentContainer";
import NavHeaderDesktop from "../../components/headers/nav-header/NavHeaderDesktop";
import NavHeaderMobileContainer from "../../components/headers/nav-header/NavHeaderMobileContainer";
import NavHeaderMobile from "../../components/headers/nav-header/NavHeaderMobile";
import RegularHeadline from "../../components/headlines/RegularHeadline";
import { overridings } from "../../themes/DarkTheme";
import { useContext } from "react";
import { Context } from "../../context/Context";
import CookieRules from "../../components/cookie-rules/CookieRules";
import RegularButton from "../../components/buttons/RegularButton/RegularButton";

const ButtonContainer = styled.div`
  width: 20%;
  min-width: 12rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

function CookiePage(props) {
  const context = useContext(Context);
  const showPrivacy = () => {
    context.saveIsOpenPrivacy(true);
  };

  return (
    <div>
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.7 }}
      > */}
      <NavHeaderDesktop />
      <NavHeaderMobileContainer>
        <NavHeaderMobile />
      </NavHeaderMobileContainer>

      <ContentContainer>
        <RegularHeadline title="Cookiepolitik" />
        <ButtonContainer>
          {" "}
          <RegularButton
            size="large"
            paddingHorizontal="18px"
            paddingVertical="6px"
            title="Cookie indstillinger"
            onButtonClick={() => showPrivacy()}
            background={overridings.palette.colors.purple}
          />
        </ButtonContainer>

        <CookieRules />
      </ContentContainer>
      {/* </motion.div> */}
    </div>
  );
}

export default CookiePage;
