import { postAndReturnResp } from "../../../containers/HelperFunctions";

export const validateEmail = (
  email,
  setEmail,
  setInputError,
  setSmallErrorText
) => {
  // eslint-disable-next-line
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regex.test(email) && email.length < 250) {
    setEmail(email);
  } else {
    setInputError(true);
    setSmallErrorText("Email is missing or invalid");
  }
};

export const displaySmallError = (
  setInputError,
  setSmallErrorText,
  smallErrorText,
  setIsLoading
) => {
  setInputError(true);
  setSmallErrorText(smallErrorText);
  setIsLoading(false);
};

export const getUserIdFromResp = (resp) => {
  if (resp) {
    return resp.id;
  }
};

export const sendInvitationEmail = async (
  email,
  handleError,
  setIsLoading,
  setBigSuccessText
) => {
  const resp = await postAndReturnResp(
    `api/v1/invite/?email=${email}`,
    handleError
  );
  setIsLoading(false);
  if (resp.status === 200) {
    setBigSuccessText("Invitation sent.");
  }
};

// Display username is already exists in DB.
export const displayUsernames = (
  userData,
  userId,
  setExistingParentname,
  setExistingParentId,
  setIsLoading,
  setInputError,
  setSmallErrorText
) => {
  if (
    userData &&
    userData.firstname &&
    userData.surname &&
    userData.firstname !== "" &&
    userData.surname !== ""
  ) {
    // Save user's name and id
    setExistingParentname(`${userData.firstname} ${userData.surname}`);
    setExistingParentId(userId);
    setIsLoading(false);
  } else {
    displaySmallError(
      setInputError,
      setSmallErrorText,
      "Something went wrong. Please try again.",
      setIsLoading
    );
  }
};
