import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { getColorFromTheme } from "../../containers/HelperFunctions";

const LinkComponent = styled(Typography)`
  color: ${(props) =>
    props.customcolor
      ? getColorFromTheme(props.customcolor, props)
      : props.theme.palette.primary.contrastText};
  text-decoration: underline !important;
  cursor: pointer;
`;

const RegularLink = ({ title, customColor, onClick }) => {
  if (onClick) {
    return (
      <LinkComponent
        variant="body2"
        customcolor={customColor}
        onClick={() => onClick()}
      >
        {title}
      </LinkComponent>
    );
  } else {
    return (
      <LinkComponent variant="body2" customcolor={customColor}>
        {title}
      </LinkComponent>
    );
  }
};

export default RegularLink;
