import { Typography } from "@material-ui/core";
import styled from "styled-components";
import React from "react";
// import { getColor } from "../../containers/my-page/MyPageFunctions";
import ParticipantComponent from "../participant/ParticipantComponent";

const FamilyName = styled(Typography)`
  margin-bottom: 1rem;
  text-decoration: underline;
`;

const FamilyComp = styled.div`
  margin-top: 2.5rem;
`;

function FamilyComponent({ family, handlePaymentForm }) {
  return (
    <FamilyComp>
      <FamilyName className="family-name" variant="h6">
        {family.familyName} family
      </FamilyName>
      {family.participants.map((p) => (
        <ParticipantComponent
          key={p.id}
          participant={p}
          handlePaymentForm={handlePaymentForm}
          //   color={getColor(p.courses)}
        />
      ))}
    </FamilyComp>
  );
}

export default FamilyComponent;
