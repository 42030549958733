import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';
// import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import {
    validateEmail,
    removeWhiteSpace,
    postAndReturnDataAndResponse,
    getAndReturnResponse
} from '../../../context/helperFunctions';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography, Box, FormHelperText } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { returnAuthenticated } from '../../../context/getData';
import { Context } from '../../../context/Context';
import SuccessDialog from '../../dialogs/success-dialog/SuccessDialog';
import RegularButton from '../../buttons/RegularButton/RegularButton';

const DialogComponent = styled(Dialog)`
    & .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.5);
        overflow-y: scroll;
    }

    & .MuiPaper-root {
        box-shadow: inset 0 0 5px grey;
        width: 22rem;
        background-color: ${(props) => props.theme.palette.common.black};
        position: ${(props) => (props.isModal ? 'absolute' : 'unset')};
        top: 3%;
        right: 4%;
    }
    @media (max-width: 960px) {
        & .MuiBackdrop-root {
            overflow: visible;
        }
        & .MuiPaper-root {
            position: relative;
            margin-bottom: -32px;
            top: -10%;
            right: 0px;
        }
    }
`;
const InputLabelComponent = styled(InputLabel)`
    color: ${(props) => props.theme.palette.common.white};
    margin-top: 0.8rem;
`;

const TextFieldComponent = styled(TextField)`
  input[type="password"]::-ms-reveal,
  input[type="password"]::-ms-clear {
    display: none;
  }
  width: 100%;
  & .MuiInputBase-root {
    // border: 2px solid ${(props) => props.theme.palette.common.white};
    padding: 5px;
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px inset;
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    // -webkit-box-shadow: 0 0 0 100px transparent inset !important;

    -webkit-box-shadow: 0 0 0 100px
      ${(props) => props.theme.palette.common.black} inset !important;
  }
  .MuiOutlinedInput-input:-webkit-autofill:hover {
    // -webkit-box-shadow: 0 0 0 100px transparent inset !important;

    -webkit-box-shadow: 0 0 0 100px
      ${(props) => props.theme.palette.common.black} inset !important;
  }

  .MuiOutlinedInput-input:-webkit-autofill:focus {
    // -webkit-box-shadow: 0 0 0 100px transparent inset !important;

    -webkit-box-shadow: 0 0 0 100px
      ${(props) => props.theme.palette.common.black} inset !important;
  }

  .MuiOutlinedInput-input:-webkit-autofill:active {
    // -webkit-box-shadow: 0 0 0 100px transparent inset;

    -webkit-box-shadow: 0 0 0 100px
      ${(props) => props.theme.palette.common.black} inset !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.palette.common.white};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${(props) =>
          props.error
              ? props.theme.palette.error.main
              : props.theme.palette.common.white};
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.error.main};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.palette.error.main};
    max-width: fit-content;
  }
  .MuiFormHelperText-contained {
    margin: 5px 0 0 0;
  }
  // position: relative;
  // background-color: ${(props) => props.theme.palette.common.white};
`;

const DialogActionsContainer = styled(DialogActions)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 8px 10px 8px;
`;
const BoxContainer = styled(Box)`
    & .MuiFormHelperText-root {
        color: ${(props) => props.theme.palette.error.dark};
        text-align: center;
        font-size: 1rem;
        margin: 1rem;
    }
`;
const LoginButtonContainer = styled.div`
    width: 100%;
    padding: 10px 12px;
`;
const ForgotPAsswordLink = styled(Typography)`
    text-decoration: underline;
    font-size: 1rem;
    margin: 0.5rem 0 0.5rem 20px;
`;
// eslint-disable-next-line
const NewUserButtonContainer = styled.div`
    width: 100%;
    padding: 1rem 18px;
    background-color: ${(props) => props.theme.palette.background.paper};
`;

function Login(props) {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [dialogOpen, setDialogOpen] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [prevLocation, setPrevLocation] = useState('/');
    const [emailValidateMessage, setEmailValidateMessage] = useState('');
    const [passwordvalidateMessage, setpasswordValidateMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [systemUpdateDialog, setSystemUpdateDialog] = useState(false);
    const context = useContext(Context);

    const handleChange = (event) => {
        event.target.id === 'username'
            ? setUserName(event.target.value)
            : setPassword(event.target.value);
    };
    const handleError = (message) => {
        setLoginError(message);
    };
    const handleErrorDependOnStatus = (status) => {
        if (status === 404) {
            setUserNameError(true);
            setLoginError('Denne email har vi ikke registretet.');
        } else if (status === 400) {
            setPasswordError(true);
            setLoginError('Adgangskode er forkert');
        } else setLoginError('Der er sket en fejl.');
    };
    const handleBlur = (event) => {
        if (event.target.id === 'username') {
            if (
                !validateEmail(removeWhiteSpace(userName)) &&
                userName.length > 0
            ) {
                setUserNameError(true);
                setEmailValidateMessage('Ugyldig emailadresse');
            } else {
                setUserNameError(false);
                setEmailValidateMessage('');
            }
        } else {
            password.length > 250
                ? setpasswordValidateMessage('Kodeordet er for langt.')
                : setpasswordValidateMessage('');
        }
    };
    const getSystemUpdateText = useCallback(async () => {
        let res = await getAndReturnResponse('/api/v1/file/system-update');
        let body = await res.text();
        let key = document.location.hostname + ':system-update';
        if (res.status === 200 && body) {
            if (typeof Storage !== 'undefined')
                localStorage.setItem(key, 'true');
            return body;
        }
        if (typeof Storage !== 'undefined') localStorage.setItem(key, 'false');
        return null;
        // eslint-disable-next-line
    }, []);
    const handleSubmit = async (event) => {
        let fromPath;
        if (
            props.history.location &&
            props.history.location.state &&
            props.history.location.state.from &&
            props.history.location.state.from.path
        ) {
            fromPath = props.history.location.state.from.path;
        }

        event.preventDefault();
        const requestBody = {
            email: removeWhiteSpace(userName),
            password: password
        };
        if (userName.length === 0 && password.length === 0) {
            setLoginError('Du skal skrive din email og dit kodeord.');
            setUserNameError(true);
            setPasswordError(true);
        } else if (userName.length === 0) {
            setEmailValidateMessage('Du skal skrive din email.');
            setUserNameError(true);
        } else if (password.length === 0) {
            setpasswordValidateMessage('Du skal skrive dit kodeord.');
            setPasswordError(true);
        } else if (!validateEmail(removeWhiteSpace(userName))) {
            setLoginError('Ugyldig email');
        } else if (password.length > 250) {
            setLoginError('Ugyldigt kodeord');
        } else {
            // POST request to backend
            const result = await postAndReturnDataAndResponse(
                '/api/v1/user/login',
                requestBody,
                handleError
            );
            if (result) {
                if (
                    result &&
                    result.response &&
                    result.response.status &&
                    result.response.status === 200
                ) {
                    const convertedData = await result.data.json();
                    if (
                        convertedData &&
                        convertedData.user &&
                        convertedData.user.id &&
                        convertedData.user.firstname &&
                        convertedData.user.email &&
                        convertedData.userToken
                    ) {
                        //  Save user firstname and id to context and local storage
                        context.saveUser({
                            userFirstname: convertedData.user.firstname,
                            userEmail: convertedData.user.email,
                            userId: convertedData.user.id,
                            emailVerifiedAt: convertedData.user.emailVerifiedAt,
                            phoneVerifiedAt: convertedData.user.phoneVerifiedAt,
                            subscriber: convertedData.user.subscriber,
                            role: convertedData.user.role,
                            token: convertedData.userToken,
                            tokenExpires: convertedData.expires,
                            lastDayOfSubscription:
                                convertedData.user.lastDayOfSubscription,
                            subscriptionDueDate:
                                convertedData.user.subscriptionDueDate
                        });
                        // Redirect user
                        //  If we are updating the system
                        async function fetchData() {
                            let systemUpdateText = await getSystemUpdateText();
                            return systemUpdateText;
                        }

                        const systemUpdateText = await fetchData();
                        if (systemUpdateText != null) {
                            return setSystemUpdateDialog(true);
                        }

                        // If user is coming to login page from other website, redirect to homepage
                        if (!props.location.state) {
                            props.history.push('/');
                        } else {
                            if (
                                props.history.location &&
                                props.history.location.state &&
                                props.history.location.state
                                    .fromOnlyRegistration
                            ) {
                                // If user is coming from RegisterOnly page, we should direct them to register page, which will take them where they should be (kasse)
                                props.history.push('/tilmelding/oplysninger');
                            } else if (fromPath) {
                                return props.history.push(fromPath);
                            } else {
                                // Or go take them back to previous page (what the url was just before navigating to /login)
                                props.history.goBack();
                            }
                        }
                    } else {
                        // Come back: handle error
                    }
                } else {
                    handleErrorDependOnStatus(result.response.status);
                }
            } else {
                console.log('Undefiend response');
            }
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
        if (returnAuthenticated()) {
            props.history.push(prevLocation);
        } else {
            if (
                prevLocation === '/min-side' ||
                prevLocation === '/betalinger' ||
                prevLocation === '/rediger-profil' ||
                prevLocation === '/reserveringer' ||
                prevLocation === '/dine-betalinger'
            ) {
                props.history.push('/');
            } else {
                props.history.push(prevLocation);
            }
        }
    };
    // eslint-disable-next-line
    const handleNewUserButtonClick = () => {
        props.history.push({
            pathname: '/tilmelding/oplysninger',
            state: { isOnlySignup: true }
        });
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };
    const denyCopyPAste = (e) => {
        e.preventDefault();
    };
    // ComponentDidMount
    useEffect(() => {
        //Redirects user if already logged in.
        if (returnAuthenticated()) {
            props.history.push('/');
        }
        if (
            props.prevLocation.pathname !== '/glemt-dit-kodeord' &&
            props.prevLocation.pathname !== '/login'
        ) {
            setPrevLocation(props.prevLocation.pathname);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div id="login-dialog">
            {/* If isModalView false, then the background should not be visible, since the modal's own route was opened - so there is no page in the background. */}
            <DialogComponent
                fullpageview={props.fullPageView}
                open={dialogOpen}
                // aria-labelledby="form-dialog-title"
                onSubmit={handleSubmit}
                onClose={handleClose}
                isModal={props.isModal}
            >
                <form id="login-dialog-form" onSubmit={handleSubmit}>
                    <DialogContent>
                        <InputLabelComponent shrink htmlFor="username">
                            Email
                        </InputLabelComponent>
                        <TextFieldComponent
                            // InputProps={{ disableUnderline: true }}
                            margin="dense"
                            id="username"
                            // onFocus={handleFocus}
                            fullWidth
                            value={userName}
                            onChange={handleChange}
                            variant="outlined"
                            onBlur={handleBlur}
                            error={userNameError}
                            helperText={emailValidateMessage}
                            autoFocus={true}
                            required
                            type="email"
                        />
                        <InputLabelComponent shrink htmlFor="password">
                            Kodeord
                        </InputLabelComponent>
                        <TextFieldComponent
                            margin="dense"
                            id="password"
                            fullWidth
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            onCopy={denyCopyPAste}
                            onPaste={denyCopyPAste}
                            onBlur={handleBlur}
                            required
                            error={passwordError}
                            helperText={passwordvalidateMessage}
                            variant="outlined"
                            onKeyPress={(event) => handleKeyPress(event)}
                            InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onMouseEnter={() =>
                                                setShowPassword(true)
                                            }
                                            onMouseLeave={() =>
                                                setShowPassword(false)
                                            }
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </DialogContent>

                    <BoxContainer maxWidth="fit-content">
                        <FormHelperText>{loginError}</FormHelperText>
                    </BoxContainer>
                    <DialogActionsContainer>
                        <LoginButtonContainer>
                            <RegularButton
                                type="submit"
                                title="Log ind"
                                onButtonClick={handleSubmit}
                            />
                        </LoginButtonContainer>
                        <ForgotPAsswordLink>
                            <Link
                                to={{
                                    pathname: '/glemt-dit-kodeord',
                                    state: { modal: true }
                                }}
                            >
                                Glemt dit kodeord?
                            </Link>
                        </ForgotPAsswordLink>
                    </DialogActionsContainer>
                </form>
                {/* <NewUserButtonContainer>
          <RegularButton
            title="Opret brugerprofil"
            onButtonClick={handleNewUserButtonClick}
          />
        </NewUserButtonContainer> */}
            </DialogComponent>
            {systemUpdateDialog && (
                <SuccessDialog
                    // title="Succes"
                    close={() => {
                        props.history.push('/');
                        window.location.reload();
                    }}
                    successMessage={
                        'Siden er skrivebeskyttet under systemopdateringer. Kom venligst tilbage senere.'
                    }
                />
            )}
        </div>
    );
}

export default withRouter(withCookies(Login));
