import React from 'react';
import styled from 'styled-components';
import { getStatus } from './ProgressBarFunctions';
import { Box } from '@material-ui/core';
import ProgressStage from './ProgressStage';

import StageLine from './StageLine';
import { useEffect } from 'react';
import { useState } from 'react';
import { getUserId } from '../../context/getData';

const ProgressStageContainer = styled(Box)`
    margin-bottom: 4rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: 1.5rem;
    }
`;

// Stages of enrollment signup
const stagesInitial = [
    { stageNumber: 1, stageTitle: 'Tilmeldingstype', status: 'initial' },
    { stageNumber: 2, stageTitle: 'Fødselsdato', status: 'initial' },
    { stageNumber: 3, stageTitle: 'Vælg hold', status: 'initial' },
    { stageNumber: 4, stageTitle: 'Vælg forløb', status: 'initial' },
    { stageNumber: 5, stageTitle: 'Møder', status: 'initial' },
    { stageNumber: 6, stageTitle: 'Oplysninger', status: 'initial' },
    { stageNumber: 7, stageTitle: 'Kasse', status: 'initial' },
    { stageNumber: 8, stageTitle: 'Opsummering', status: 'initial' } // If you change the title, change condition in StageTitle component as well accordingly!
];

const stagesAuthenticated = [
    { stageNumber: 1, stageTitle: 'Tilmeldingstype', status: 'initial' },
    { stageNumber: 2, stageTitle: 'Fødselsdato', status: 'initial' },
    { stageNumber: 3, stageTitle: 'Vælg hold', status: 'initial' },
    { stageNumber: 4, stageTitle: 'Vælg forløb', status: 'initial' },
    { stageNumber: 5, stageTitle: 'Møder', status: 'initial' },
    // { stageNumber: 6, stageTitle: "Oplysninger", status: "initial" },
    { stageNumber: 6, stageTitle: 'Kasse', status: 'initial' },
    { stageNumber: 7, stageTitle: 'Opsummering', status: 'initial' } // If you change the title, change condition in StageTitle component as well accordingly!
];

// Stages of intromeeting signup
const intromeetingStagesInitial = [
    { stageNumber: 1, stageTitle: 'Tilmeldingstype', status: 'initial' },
    { stageNumber: 2, stageTitle: 'Vælg intromøde', status: 'initial' },
    { stageNumber: 3, stageTitle: 'Oplysninger', status: 'initial' },
    { stageNumber: 5, stageTitle: 'Opsummering', status: 'initial' }
];

const authIntromeetingStagesInitial = [
    { stageNumber: 1, stageTitle: 'Tilmeldingstype', status: 'initial' },
    { stageNumber: 2, stageTitle: 'Vælg intromøde', status: 'initial' },
    { stageNumber: 3, stageTitle: 'Opsummering', status: 'initial' }
];

function ProgressBar({ currentStage, type }) {
    const getStagesData = (type) => {
        if (type === 'intromeeting') {
            return intromeetingStagesInitial;
        } else if (type === 'authIntromeeting') {
            return authIntromeetingStagesInitial;
        } else {
            if (getUserId()) {
                // If user is authenticated, we dont show 'Oplysninger'
                return stagesAuthenticated;
            }
            return stagesInitial;
        }
    };

    const stagesData = getStagesData(type);
    const stages = getStatus(stagesData, currentStage);
    const [trimmedStages, setTrimmedStages] = useState([]);
    const [firstStage, setFirstStage] = useState([]);

    useEffect(() => {
        //Separating first and rest of the stages - to use horizontal line between stages
        setTrimmedStages(stages.filter((stage) => stage.stageNumber > 1));
        setFirstStage(stages.filter((stage) => stage.stageNumber === 1));
    }, [stages]);

    return (
        <ProgressStageContainer display="flex" justifyContent="space-between">
            {firstStage.map((stage) => (
                <ProgressStage
                    key={stage.stageNumber}
                    stageNumber={stage.stageNumber}
                    stageTitle={stage.stageTitle}
                    status={stage.status}
                    currentStage={currentStage}
                    type={type}
                />
            ))}
            {trimmedStages.map((stage) => (
                <React.Fragment key={stage.stageNumber}>
                    <StageLine status={stage.status} />
                    <ProgressStage
                        stageNumber={stage.stageNumber}
                        stageTitle={stage.stageTitle}
                        status={stage.status}
                        currentStage={currentStage}
                        type={type}
                    />
                </React.Fragment>
            ))}
        </ProgressStageContainer>
    );
}

export default ProgressBar;
