// list     Reference to array of messages
// name     Name of message
// property Name of property to return (if not specified entire message is returned)
// value    Default value if property is not found is empty string
// type     Optional defaults to html message

import { gridColumnLookupSelector } from '@mui/x-data-grid';

// state    Optional defaults to final message
const getMessage = (list, name, property, value, type, state) => {
    if (!list) throw new Error('getMessage is missing list property');
    if (!name) throw new Error('getMessage is missing name property');
    type = type ? type.toLowercase() : 'html';
    state = state ? state.toLowercase() : 'final';
    value = value ? value : ''; // Default value
    let message = list.find(
        (message) =>
            message.name === name &&
            message.type === type &&
            message.state === state
    );
    if (!property) {
        // Add defaults for metadata only if entire message is returned
        if (message) {
            if (!message.MetaData) message.MetaData = {};
            if (!message.MetaData.title) message.MetaData.title = '';
            if (!message.MetaData.description)
                message.MetaData.description = '';
            if (!message.MetaData.author) message.MetaData.author = '';
            if (!message.MetaData.keywords) message.MetaData.keywords = '';
            if (!message.MetaData.ogURL) message.MetaData.ogURL = '';
            if (!message.MetaData.ogType) message.MetaData.ogType = '';
            if (!message.MetaData.ogTitle) message.MetaData.ogTitle = '';
            if (!message.MetaData.ogDescription)
                message.MetaData.ogDescription = '';
            if (!message.MetaData.ogImage) message.MetaData.ogImage = ''; // Image URL
            if (!message.MetaData.ogLocale) message.MetaData.ogLocale = ''; // en_US
            if (!message.MetaData.ogLocaleAlternate)
                message.MetaData.ogLocaleAlternate = ''; // en_US,da_DK
            if (!message.MetaData.fbAppID) message.MetaData.fbAppID = '';
        }
        return message;
    }
    return message && message[property] ? message[property] : value;
};

// list     Reference to array of messages
// name     Name of message
// property Name of property to return (if not specified entire message is returned)
// value    Default value if property is not found is empty string
// type     Optional defaults to html message
// state    Optional defaults to final message
const getMetaData = (list, name, property, value, type, state) => {
    if (!list) throw new Error('getMetaData is missing list property');
    if (!name) throw new Error('getMetaData is missing name property');
    type = type ? type.toLowercase() : 'html';
    state = state ? state.toLowercase() : 'final';
    value = value ? value : ''; // Default value
    let message = list.find(
        (message) =>
            message.name === name &&
            message.type === type &&
            message.state === state
    );
    if (!property) {
        // Add defaults for metadata only if entire message is returned
        if (message) {
            if (!message.MetaData) message.MetaData = {};
            if (!message.MetaData.title) message.MetaData.title = '';
            if (!message.MetaData.description)
                message.MetaData.description = '';
            if (!message.MetaData.author) message.MetaData.author = '';
            if (!message.MetaData.keywords) message.MetaData.keywords = '';
            if (!message.MetaData.ogURL) message.MetaData.ogURL = '';
            if (!message.MetaData.ogType) message.MetaData.ogType = '';
            if (!message.MetaData.ogTitle) message.MetaData.ogTitle = '';
            if (!message.MetaData.ogDescription)
                message.MetaData.ogDescription = '';
            if (!message.MetaData.ogImage) message.MetaData.ogImage = ''; // Image URL
            if (!message.MetaData.ogLocale) message.MetaData.ogLocale = ''; // en_US
            if (!message.MetaData.ogLocaleAlternate)
                message.MetaData.ogLocaleAlternate = ''; // en_US,da_DK
            if (!message.MetaData.fbAppID) message.MetaData.fbAppID = '';
        }
        return message;
    }
    return message && message.MetaData && message.MetaData[property]
        ? message.MetaData[property]
        : value;
};

export { getMessage, getMetaData };
