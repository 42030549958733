import React from "react";
import styled, { keyframes } from "styled-components";
import ArrowRightIcon from "@material-ui/icons/ArrowRightAlt";

const handleColorType = (props) => {
  switch (props.color) {
    case "concert":
      return `${props.theme.palette.colors.purple}`;
    case "workday":
      return `${props.theme.palette.colors.green}`;
    case "meeting":
      return `${props.theme.palette.colors.yellow}`;
    default:
      return `${props.theme.palette.colors.blue}`;
  }
};
const EventContainer = styled.div`
  min-height: 3rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: 960px) {
    border-left: 2px solid ${(props) => handleColorType(props)};
    margin: 5px 0px;
    padding: 0px 15px;
  }
`;
const EventsHorizontalLine = styled.hr`
  height: 3px;
  background-color: ${(props) => handleColorType(props)};
  border: none;
  width: 75%;
  margin: 4px 0px;
  @media (max-width: 960px) {
    display: none;
  }
`;
const EventTitle = styled.span`
  font-size: 0.8rem;
`;
const EventStartTimeWithArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const EventStartTime = styled.span`
  font-size: 0.8rem;
  @media only screen and (max-width: 1300px) {
    font-size: 0.75rem;
  }
`;
const displayOpacity = keyframes`
  {
    0%{
        opacity:0%
      }
      50%{
        opacity:50%   
      }
    100%{
    opacity:100%
  }
}
`;
const ArrowRightIconsContainer = styled.div``;
const ArrowRightIconContainer = styled.div`
  animation: ${displayOpacity} 0.5s linear forwards;
`;
const ArrowRightDownIcon = styled.span`
  font-weight: bold;
  font-size: 1rem;
  animation: ${displayOpacity} 0.5s linear forwards;
`;
const DayDateContainerMobile = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: inline;
    position: absolute;
    left: -3rem;
  }
`;
const DayShortName = styled.span`
  text-transform: uppercase;
`;
function EventInCalendar(props) {
  const handleLongTitle = (title, media) =>
    title.length > 15 ? title.slice(0, 13) + "..." : title;
  // window.matchMedia("(max-width: 700px)").addListener(handleLongTitle)
  return (
    <EventContainer
      onClick={() => props.openEventDetails(props.eventDetails)}
      color={props.eventDetails.type}
    >
      <DayDateContainerMobile>
        <DayShortName>{props.dayInfo.nameShort}</DayShortName>
        <br />
        {props.dayInfo.displayDateShort}
      </DayDateContainerMobile>
      <EventsHorizontalLine color={props.eventDetails.type} />
      <EventTitle>
        {/*console.log('eventsDetails:', props.eventDetails)*/}
        {handleLongTitle(
          props.eventDetails.title,
          window.matchMedia("(max-width: 1300px)")
        )}
      </EventTitle>   
      <EventStartTimeWithArrowContainer>
        <EventStartTime>
          kl.{props.eventDetails.starttime.slice(0, 2)}.
          {props.eventDetails.starttime.slice(3, 5)}
         
        </EventStartTime>

        <ArrowRightIconsContainer>
          {props.selectedEventInCalendar === props.eventDetails ? (
            <ArrowRightDownIcon>&#8600;</ArrowRightDownIcon>
          ) : (
            <ArrowRightIconContainer>
              <ArrowRightIcon />
            </ArrowRightIconContainer>
          )}
        </ArrowRightIconsContainer>
      </EventStartTimeWithArrowContainer>
    </EventContainer>
  );
}
export default EventInCalendar;
