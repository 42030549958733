import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import LeftArrow from "../navigate-carousel/LeftArrow";
import RightArrow from "../navigate-carousel/RightArrow";
import ParentsWordsSlide from "./ParentsWordsSlide";
import CarouselBullet from "../navigate-carousel/CarouselBullet";
import { Fade } from "@material-ui/core";
// import data from "./data";
import RegularHeadline from "../../headlines/RegularHeadline";

import { Context } from "../../../context/Context";
import MessageList from "../../../components/message-list";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@material-ui/core/IconButton';

const CarouselContainer = styled.div`
  margin: 5rem auto 10rem auto;
  align-items: center;
  max-height: 520px;
  height: 520px;
  @media (max-width: 960px) {
    display: none;
  }
`;

const SlideContentWithBullets = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  max-height: 450px;
  height: 450px;
  position: relative;
`;

const SlideWithArrows = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`;
const BulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
`;

const HeadlineContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

function Carousel(props) {
  const context = useContext(Context);
  const user = context.state.currentUser;
  const isSuperuser = (['superuser','admin'].indexOf(user.role)>=0);
  const [data, setData] = useState([]);
  const [messageList, setMessageList] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [checked, setChecked] = useState(true);

  const slidesNumber = data.length;

  const handleArrowClick = (slideId, direction) => {
    const newIndex = slideId;
    // console.log(slideId);
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
      setCurrentSlide(newIndex);
    }, 200);
  };

  // ComponentDidMount
  useEffect(() => {  
    let stories = messageList.map( (message,index) => {
        return {
          id: index+1,
          title: message.title,
          description: (''+message.html).trim(),
          image: message.text,
          messageId: message.id
        }
      }
    );
    setData(stories);
    // eslint-disable-next-line
  }, [messageList.length]);
  
  const handleEdit = (messageId) => {
    window.open('/message/'+messageId+'/edit','_blank');
  }

  return (
    <CarouselContainer>
      <MessageList type="html" name="Parent stories%" state="final" change={setMessageList}/>

      <div style={{ display: (context.state.features.enableStories?'block':'none')}}>
        <HeadlineContainer>
          <RegularHeadline title="Hvad siger forældrene?" />
        </HeadlineContainer>

        <SlideWithArrows>
          <LeftArrow
            currentSlide={currentSlide}
            slidesNumber={slidesNumber}
            onClick={handleArrowClick}
          />
          <SlideContentWithBullets>
            <Fade in={checked} exit={false} mountOnEnter unmountOnExit>              
                <div>
                  {data.length > 0 &&
                    <>
                      { isSuperuser && 
                        <IconButton size="small" style={{ width: 30, height: 30, background: '#FF0000', margin: 4 }} component="span" onClick={(event) => handleEdit(data[currentSlide].messageId)}>
                          <EditIcon/>
                        </IconButton>
                      }                  
                      <ParentsWordsSlide slideInfo={data[currentSlide]}></ParentsWordsSlide>
                    </>  
                  }
                </div>              
            </Fade>
            {/* Test Fade animation */}
            {/* <Fade in={checked} >
            <div><SchoolQualitySlide slideInfo={data[currentSlide]}></SchoolQualitySlide></div>
          </Fade>
        */}
            <BulletsContainer>
              {data.map((slide) => {
                return (
                  <CarouselBullet
                    key={slide.id}
                    bulletId={slide.id}
                    currentSlide={currentSlide}
                    onClick={handleArrowClick}
                  />
                );
              })}
            </BulletsContainer>
          </SlideContentWithBullets>
          <RightArrow
            currentSlide={currentSlide}
            slidesNumber={slidesNumber}
            onClick={handleArrowClick}
          />
        </SlideWithArrows>
      </div>
    </CarouselContainer>
  );
}

export default Carousel;
