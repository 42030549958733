import { Box, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import RegularHeadline from "../../../components/headlines/RegularHeadline";
import Questionmark from "../../../components/Questionmark/Questionmark";

const YourPriceContainer = styled.div`
  width: 50%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

const DescriptionText = styled(Typography)`
  margin-bottom: 1rem;
`;

function YourPriceSection(props) {
  return (
    <YourPriceContainer>
      <Box display="flex">
        <RegularHeadline title="Din pris" marginbottom="1.5rem" />
        <Questionmark width="1rem" explainerText="Explainer text here" />
      </Box>

      <DescriptionText variant="body2">
        Dit barn får præcis den samme undervisning, uanset hvilket medlemsskab,
        du vælger.
      </DescriptionText>
      <DescriptionText variant="body2">
        Din pris for sæsonen <br />
        Maksimalt 5280 kr. Denne pris forudsætter, at dit barn starter
        undervisning d. 19/8-2019. Hvis dit barn starter senere, vil din pris
        blive tilsvarende lavere.
      </DescriptionText>

      <DescriptionText variant="body2">
        Fortrydelsesret
        <br />
        Du kan fortryde dit køb indtil 3 dage før dit barns undervisning
        starter.
      </DescriptionText>
    </YourPriceContainer>
  );
}

export default YourPriceSection;
