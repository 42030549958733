import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getLocationNameFromRoomId } from '../../util';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DeleteDialog from '../dialogs/delete-event-dialog/DeleteEventDialog';
import SuccessDialog from '../dialogs/success-dialog/SuccessDialog';
import { deleteAndReturnResp } from '../../context/helperFunctions';
import { getUserId } from '../../context/getData';
import { getMeetingsOfUser } from '../../containers/my-page/MyPageFunctions';
import { overridings } from '../../themes/DarkTheme';

const DesktopRow = styled(Box)`
    display: flex;
    margin-bottom: 1rem;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const MobileRow = styled(Box)`
    display: none;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        background: ${overridings.palette.primary.main};
        padding: 1rem;
        margin-bottom: 1rem;
    }
`;

const MeetingTypeText = styled(Box)`
    width: 150px;
`;
const DateText = styled(Box)`
    width: 150px;
`;
const TimeText = styled(Box)`
    width: 200px;
`;
const LocationText = styled(Box)`
    width: 100px;
`;

const EditText = styled(Box)`
    width: 50px;
`;

const IconContainer = styled.div`
    cursor: pointer;
    display: inline;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
    & .MuiSvgIcon-root {
        width: 0.9rem;
        height: 0.9rem;
    }
`;

const IconText = styled(Typography)`
    text-decoration: ${(props) =>
        props.eventstate === 'true' ? 'none' : 'underline'};
    cursor: pointer;
    position: relative;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
`;

function UpcomingMeetingRow({
    meetingData,
    handleError,
    setUpcomingMeetings,
    setPastMeetings
}) {
    const [selectedEventIdToDelete, setSelectedEventIdToDelete] = useState(
        null
    );
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successfulDeleteDialog, setSuccessfulDeleteDialog] = useState(false);

    // A dialog is displayed to make the user confirm the deletion
    // If they press yes, the removeBookedEvent will be run
    const handleDeleteClick = async (eventId) => {
        setSelectedEventIdToDelete(eventId);
        setDeleteDialog(true);
    };

    // User is able to delete its own event by confirming the deletion in the DeleteDialog
    const removeBookedEvent = async (eventId) => {
        setIsLoading(true);

        const resp = await deleteAndReturnResp(
            `${
                window.location.origin
            }/api/v1/users/${getUserId()}/events/${eventId}`,
            handleError
        );
        if (resp && resp.status === 200) {
            setSuccessfulDeleteDialog(true);
            setDeleteDialog(false);
            setSelectedEventIdToDelete(null);
            setIsLoading(false);
        } else {
            handleError(
                'Noget gik galt. Kunne ikke fjerne reservationen.  Prøv igen.'
            );
            setDeleteDialog(false);
            setSelectedEventIdToDelete(null);
            setIsLoading(false);
        }
    };

    return (
        <>
            <DesktopRow>
                <MeetingTypeText>
                    <Typography variant="body2">
                        {meetingData['Event.title']}
                    </Typography>
                </MeetingTypeText>
                <DateText>
                    <Typography variant="body2" align="center">
                        {moment(meetingData['Event.startdate']).format(
                            'DD.MM.YYYY'
                        )}
                    </Typography>
                </DateText>
                <TimeText>
                    <Typography variant="body2" align="center">
                        kl. {meetingData['Event.starttime']} -{' '}
                        {meetingData['Event.endtime']}
                    </Typography>
                </TimeText>
                <LocationText>
                    <Typography variant="body2" align="center">
                        Lokale{' '}
                        {getLocationNameFromRoomId(meetingData['Event.roomId'])}
                    </Typography>
                </LocationText>
                <EditText>
                    <Box
                        bgcolor={'#1f1e1e'}
                        width="8rem"
                        display="flex"
                        height="100%"
                    >
                        <IconContainer actiontype="delete">
                            <DeleteIcon />
                        </IconContainer>
                        <IconText
                            variant="body2"
                            actiontype="delete"
                            onClick={() => {
                                handleDeleteClick(meetingData['Event.id']);
                            }}
                        >
                            Annuller
                        </IconText>
                    </Box>
                </EditText>
            </DesktopRow>

            <MobileRow>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    mb="0.5rem"
                >
                    <Typography variant="body2">
                        {meetingData['Event.title']}
                    </Typography>

                    <Box
                        bgcolor={'000000'}
                        width="8rem"
                        display="flex"
                        height="100%"
                    >
                        <IconContainer actiontype="delete">
                            <DeleteIcon />
                        </IconContainer>
                        <IconText
                            variant="body2"
                            actiontype="delete"
                            onClick={() => {
                                handleDeleteClick(meetingData['Event.id']);
                            }}
                        >
                            Annuller
                        </IconText>
                    </Box>
                </Box>

                <Typography variant="body2">
                    Dato:{' '}
                    {moment(meetingData['Event.startdate']).format(
                        'DD.MM.YYYY'
                    )}
                </Typography>

                <Typography variant="body2">
                    Tid: kl. {meetingData['Event.starttime']} -{' '}
                    {meetingData['Event.endtime']}
                </Typography>

                <Typography variant="body2">
                    Lokale{' '}
                    {getLocationNameFromRoomId(meetingData['Event.roomId'])}
                </Typography>
            </MobileRow>

            {deleteDialog && (
                <DeleteDialog
                    close={() => setDeleteDialog(false)}
                    removeBookedEvent={removeBookedEvent}
                    eventId={meetingData['Event.id']}
                    isLoading={isLoading}
                    roomId={meetingData['Event.id']}
                    startdate={meetingData['Event.startdate']}
                    starttime={meetingData['Event.starttime']}
                    endtime={meetingData['Event.endtime']}
                    eventType={meetingData['Event.title']}
                />
            )}

            {successfulDeleteDialog && (
                <SuccessDialog
                    successMessage="Din mødetilmelding er annulleret."
                    close={() => {
                        getMeetingsOfUser(
                            getUserId(),
                            setUpcomingMeetings,
                            setPastMeetings,
                            handleError
                        );
                        setSuccessfulDeleteDialog(false);
                    }}
                />
            )}
        </>
    );
}

export default UpcomingMeetingRow;
