// eslint-disable-next-line
import React, { useEffect } from "react";
import styled from "styled-components";
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useQuill } from "react-quilljs";
// eslint-disable-next-line
import { getAndReturnData } from "../../containers/HelperFunctions";

const TextEditorContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  & .ql-container {
    border: 0px solid #ccc;
  }
`;
function TextFromAdminEditor(props) {
  // eslint-disable-next-line
  const { quill, quillRef } = useQuill({
    modules: { toolbar: false },
    readOnly: true,
  });

  // eslint-disable-next-line
  const handleError = (err) => {
    console.log("ERROR", err);
  };
  /*
  // NLH outcommented below Quill test
  const textFromAdmin = async () => {
    const data = await getAndReturnData(`api/v1/getRteContent`, handleError);
    if (!data || data.length === 0) {
      console.log("No data was coming from Quill");
      return;
    } else {
      if (quill) {
        // quill.disable();
        quill.clipboard.dangerouslyPasteHTML(data.data);
      }
    }
  };
  useEffect(() => {
    textFromAdmin();
    // eslint-disable-next-line
  }, [quill]);
  */
  return (
    <TextEditorContainer>
      <div ref={quillRef} />
    </TextEditorContainer>
  );
}

export default TextFromAdminEditor;
