import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import EducationComponent from "../../components/education-component/EducationComponent";
import { getDestination, getReturnUrl } from "./EducationFunctions";
// import babyMusicData from "../../data/BabyMusicData";
import MessageList from "../../components/message-list";

function BabyMusic(props) {

  // eslint-disable-next-line
  const [messageList, setMessageList] = useState(undefined); // Array of messages
  const [babyMusicData, setBabyMusicData] = useState(undefined); 

  const setBabyMusicDataFromMessageList = (list) => {
    setMessageList(list);
    if (list && list.length>0) {
      setBabyMusicData({
        MetaData: list[0].MetaData,
        title: (list[0].title?list[0].title:''),
        id: list[0].url.substring(list[0].url.lastIndexOf('/')+1),
        imageUrl: (list[0].text?list[0].text:''),
        description: (list[0].html?list[0].html:''),
        color: "orange",
        colorRGB: "250, 152, 43,",
        url: (list[0].url?list[0].url:'')
      });        
    }
  }

  return (
    <React.Fragment>
      <MessageList type="html" name="Education BM" state="final" change={setBabyMusicDataFromMessageList}/>
      { babyMusicData && 
        <EducationComponent
          data={babyMusicData}
          url={babyMusicData.url}
          previousUrl={getReturnUrl(props)}
          destination={getDestination(props)}
        />
      }
    </React.Fragment>
  );
}

export default withRouter(BabyMusic);
