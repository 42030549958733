import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EducationCard from '../cards/EducationCard';
import EducationHeadline from '../headlines/EducationHeadline';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

import MessageList from '../../components/message-list';
import MessagePlaceholder from '../../components/message-list/MessagePlaceholder';
import { getMessage } from '../../components/message-list/MessageListHelper';

const DescriptionContainer = styled(Box)`
    width: 80%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

function EducationGrid({ data, url, returnPath, returnTitle }) {
    const [messageList, setMessageList] = useState([]);
    const [updatedData, setUpdateData] = useState([]);

    //  Set the right order of education data
    useEffect(() => {
        if (data && data.length) {
            const newOrderData = [];

            data.forEach((course) => {
                if (course.name === 'Education BM') {
                    newOrderData.splice(0, 0, course);
                }
                if (course.name === 'Education ML I-VII') {
                    newOrderData.splice(1, 0, course);
                }
                if (course.name === 'Education HL') {
                    newOrderData.splice(2, 0, course);
                }
                if (course.name === 'Education Instrument') {
                    newOrderData.splice(3, 0, course);
                }
            });
            // Create a duplicate of HL
            const hoereLaereCourse = data.find(
                (course) => course.name === 'Education Hørelære'
            );
            newOrderData.splice(3, 0, hoereLaereCourse);
            const choirCourse = data.find(
                (course) => course.name === 'Education Choir'
            );
            newOrderData.push(choirCourse);
            setUpdateData(newOrderData);
        }
    }, [data]);

    const getExternalUrl = (name, url) => {
        if (name === 'Education Instrument') {
            return url;
        }
    };

    return (
        <React.Fragment>
            <MessageList
                type="html"
                name="Education courses"
                state="final"
                change={setMessageList}
            />

            <EducationHeadline
                title={getMessage(
                    messageList,
                    'Education courses',
                    'title',
                    'Undervisningsforløb'
                )}
            />
            <DescriptionContainer>
                <MessagePlaceholder
                    list={messageList}
                    name="Education courses"
                    value=""
                />
            </DescriptionContainer>
            {updatedData && (
                <Grid container spacing={2}>
                    {updatedData.map((d) => (
                        <Grid item xs={12} sm={6} md={4} key={d.title}>
                            <EducationCard
                                title={d.title}
                                imageUrl={d.imageUrl}
                                color={d.colorRGB}
                                colorName={d.color}
                                url={d.url}
                                id={d.id}
                                returnPath={returnPath}
                                returnTitle={returnTitle}
                                externalUrl={getExternalUrl(d.name, d.url)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </React.Fragment>
    );
}

export default EducationGrid;
