// For rendering months in UI
export const getSpecificMonths = (startId, endId, monthsData) => {
  const filteredMonths = monthsData.filter(
    (month) => month.id >= startId && month.id <= endId
  );
  return filteredMonths;
};

const removePrice = (monthsArray) => {
  for (let i = 0; i < monthsArray.length; i++) {
    monthsArray[i].price = 0;
  }
};

const renderPrice = (months, ratePerMonth, lastMonth) => {
  months.forEach((month) => {
    if (month.selected) {
      month.price = ratePerMonth;
    }
  });

  if (lastMonth) {
    months.push(lastMonth);
  }
};

export const sortMonths = (months) => {
  const sortedMonths = months.sort((a, b) => a.id - b.id);
  return sortedMonths;
};

// const getNewRatePerMonth = (priceSum, customFirstRate, numberOfRates) => {
//   const difference = priceSum - customFirstRate;
//   return Math.round(difference / (numberOfRates - 1));
// };

const calculateCustomFirstRateMonths = (
  priceSum,
  customFirstRate,
  numberOfRates,
  selectedMonths
) => {
  const difference = priceSum - customFirstRate;
  const ratePerMonth = Math.ceil(difference / (numberOfRates - 1));
  const firstMonth = selectedMonths[0];
  firstMonth.price = customFirstRate;

  // Remove first month, calculate prices, and add first month again
  selectedMonths.shift();
  // If ratePerMonth is not an integer, price is rounded, therefore need to remove access from the last month
  const lastMonthDifference = difference - (numberOfRates - 1) * ratePerMonth;

  if (lastMonthDifference !== 0) {
    //Remove last month, give new price
    const lastMonth = selectedMonths.pop();
    lastMonth.price = ratePerMonth + lastMonthDifference;
    renderPrice(selectedMonths, ratePerMonth, lastMonth);
    selectedMonths.unshift(firstMonth);
  } else {
    // Remove last month, add new price if there is a difference
    renderPrice(selectedMonths, ratePerMonth);
    selectedMonths.unshift(firstMonth);
  }
};

// Get difference if priceSum/numberOfRates is not an integer
// const getDifference = (ratePerMonth, priceSum, numberOfRates) => {
//   const sum = ratePerMonth * numberOfRates;
//   if (sum !== priceSum) {
//     // Check the difference
//     const difference = priceSum - sum;
//     return difference;
//   } else {
//     return null;
//   }
// };

// const addDifference = (monthsData, difference) => {
//   // For now add or deduct the difference to/from the last month - but need to clarify with Michael
//   const selectedMonths = monthsData.filter((month) => month.selected === true);
//   const lastIndex = selectedMonths.length - 1;
//   selectedMonths[lastIndex].price =
//     selectedMonths[lastIndex].price + difference;
// };

// Update prices according to number of rates
export const updatePrices = (
  months,
  priceSum,
  numberOfRates,
  customFirstRate
) => {
  const monthsDataCopy = [...months];
  const ratePerMonth = Math.ceil(priceSum / numberOfRates);

  const selectedMonths = monthsDataCopy.filter((month) => month.selected);
  const notSelectedMonths = monthsDataCopy.filter((month) => !month.selected);
  if (customFirstRate > 0) {
    // If only one month is selected, they should pay all money in that month regardless of the given first rate
    if (selectedMonths.length === 1) {
      selectedMonths[0].price = priceSum;
      // Remove price from unselected months
      removePrice(notSelectedMonths);
    } else {
      calculateCustomFirstRateMonths(
        priceSum,
        customFirstRate,
        numberOfRates,
        selectedMonths
      );
      // Remove price from unselected months
      removePrice(notSelectedMonths);
      // const newRatePerMonth = getNewRatePerMonth(
      //   priceSum,
      //   customFirstRate,
      //   numberOfRates
      // );
      // const newSum = priceSum - customFirstRate;
      // difference = getDifference(newRatePerMonth, newSum, numberOfRates - 1);
    }
  } else {
    removePrice(notSelectedMonths);
    const difference = priceSum - numberOfRates * ratePerMonth;
    // If ratePerMonth was not an integer, we round it up, and remove the access amount in the last month
    if (difference !== 0) {
      // Give new price to last month
      const lastMonth = selectedMonths.pop();
      lastMonth.price = ratePerMonth + difference;
      renderPrice(selectedMonths, ratePerMonth, lastMonth);
    } else {
      renderPrice(selectedMonths, ratePerMonth);
    }
  }

  const mergedSortedMonths = sortMonths([
    ...selectedMonths,
    ...notSelectedMonths,
  ]);
  return mergedSortedMonths;
};

export const updateSelection = (
  monthsData,
  numberOfRates,
  setMonthsData,
  priceSum,
  customFirstRate
) => {
  const numberOfRatesNumber = Number(numberOfRates);
  // Remove disabled months
  const disabledMonths = monthsData.filter((month) => month.disabled);
  const selectedMonths = monthsData.filter((month) => month.selected === true);
  const notSelectedMonths = monthsData.filter(
    (month) => month.selected === false && !month.disabled
  );

  // if there are more selected months, than the numberOfRates, remove the last ones and make them unselected
  if (selectedMonths.length > numberOfRates) {
    const remainder = selectedMonths.length - numberOfRatesNumber;
    const removedMonths = selectedMonths.splice(numberOfRatesNumber, remainder);
    selectedMonths.splice(numberOfRatesNumber, remainder);
    // Make the removed months unselected
    removedMonths.forEach((month) => (month.selected = false));
    // Add that month back
    selectedMonths.push(...removedMonths);
  } else {
    const remainder = numberOfRatesNumber - selectedMonths.length;
    // Make the first unselected months selected
    if (notSelectedMonths.length > 0) {
      for (let i = 0; i < remainder; i++) {
        notSelectedMonths[i].selected = true;
      }
    }
  }

  const mergedSortedMonths = sortMonths([
    ...disabledMonths,
    ...selectedMonths,
    ...notSelectedMonths,
  ]);

  setMonthsData(
    updatePrices(
      mergedSortedMonths,
      priceSum,
      numberOfRatesNumber,
      customFirstRate
    )
  );
};
