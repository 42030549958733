import React, { useState, useEffect } from "react";
import { useParams, useRouteMatch, Link, withRouter } from "react-router-dom";
import Participants from "./Participants";
import LessonsOverview from "./LessonsOverview";
import ReturnLinkReusable from "../../components/return-link/ReturnLinkReusable";
import ReusableHeader from "../../components/headers/reusable-header/ReusableHeader";
import ContentContainer from "../../components/containers/ContentContainer";
import { overridings } from "../../themes/DarkTheme";

// HTTP request here to get data
const data = [
  {
    id: 1,
    name: "Høle11",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin molestie.",

    participants: [
      { name: "Anne" },
      { name: "Kristof" },
      { name: "Adam" },
      { name: "Lillu" },
    ],
    lessons: [
      { date: "2021-01-03", title: "First class after winter break" },
      { date: "2021-01-08", title: "Great class" },
    ],
    teacher: {
      name: "Michael",
      id: 6,
    },
  },
  {
    id: 2,
    name: "ML2",
    description:
      "Mauris finibus, massa eu tempor volutpat, magna dolor euismod dolor.",

    participants: [
      { name: "Anne" },
      { name: "Kristof" },
      { name: "Adam" },
      { name: "Lillu" },
    ],
    lessons: [
      { date: "2021-01-03", title: "First class after winter break" },
      { date: "2021-01-08", title: "Great class" },
    ],
    teacher: {
      name: "Michael",
      id: 6,
    },
  },
  {
    id: 3,
    name: "ML5",
    description:
      "Maecenas condimentum porttitor auctor. Maecenas viverra fringilla felis, eu pretium.",

    participants: [
      { name: "Anne" },
      { name: "Kristof" },
      { name: "Adam" },
      { name: "Lillu" },
    ],
    lessons: [
      { date: "2021-01-03", title: "First class after winter break" },
      { date: "2021-01-08", title: "Great class" },
    ],
    teacher: {
      name: "Michael",
      id: 6,
    },
  },
  {
    id: 4,
    name: "Babymusik",
    description:
      "Ut hendrerit venenatis lacus, vel lacinia ipsum fermentum vel. Cras.",

    participants: [
      { name: "Anne" },
      { name: "Kristof" },
      { name: "Adam" },
      { name: "Lillu" },
    ],
    lessons: [
      { date: "2021-01-03", title: "First class after winter break" },
      { date: "2021-01-08", title: "Great class" },
    ],
    teacher: {
      name: "Michael",
      id: 6,
    },
  },
];

const Course = (props) => {
  const { url } = useRouteMatch();
  const { courseId } = useParams();
  const [participantsExpanded, setParticipantsExpanded] = useState(false);
  const [lessonsExpanded, setLessonsExpanded] = useState(false);
  const course = data.find((p) => p.id === Number(courseId));
  let courseData;

  if (course) {
    courseData = (
      <div>
        <h3> {course.name} </h3>
      </div>
    );
  } else {
    courseData = <h2> Sorry. course doesn't exist </h2>;
  }

  const toggleExpandingDiv = (name) => {
    if (name === "participants") {
      setParticipantsExpanded(!participantsExpanded);
    } else {
      setLessonsExpanded(!lessonsExpanded);
    }
  };

  // Checks if user came here by using 'See all participants'  link
  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.participantsExpanded) {
        setParticipantsExpanded(true);
      }
    }
  }, [props.location.state]);
  return (
    <React.Fragment>
      {document.body.offsetWidth < overridings.breakpoints.values.md ? (
        <ReusableHeader imageUrl="/images/headers/headers-mobile/header-mobile-vision.jpg" />
      ) : (
        <ReusableHeader imageUrl="/images/headers/header-vision.jpg" />
      )}

      <ContentContainer>
        <div>
          <ReturnLinkReusable url={"/min-side"} destination={"Min side"} />
          <div>{courseData}</div>
          <p>
            Underviser:{" "}
            <Link
              to={{
                pathname: `/om-skolen/medarbejdere/${course.teacher.urlName}`,
                state: { from: { path: url, title: "Mit hold" } },
              }}
            >
              {course.teacher.name}
            </Link>
          </p>
          <div>
            <h2>Seneste opslag</h2>
            <ul>
              <li>Post 1</li>
              <li>Post 2</li>
            </ul>
            <Link to={`/hold/${courseId}/alle-opslag`}>Se alle opslag</Link>
          </div>
          <div>
            <h2
              onClick={() => {
                toggleExpandingDiv("participants");
              }}
            >
              Deltagere
            </h2>
            {participantsExpanded && (
              <Participants data={course.participants} />
            )}
          </div>
          <div>
            <h2
              onClick={() => {
                toggleExpandingDiv("lessons");
              }}
            >
              Lektionsoversigt
            </h2>
            {lessonsExpanded && <LessonsOverview data={course.lessons} />}
          </div>
          <div>
            <h2>Billeder</h2>
            <Link
              to={{
                pathname: `/hold/${courseId}/alle-billeder`,
                state: { from: { path: url, title: "Mit hold" } },
              }}
            >
              Se alle billeder
            </Link>
          </div>
        </div>
      </ContentContainer>
    </React.Fragment>
  );
};

export default withRouter(Course);
