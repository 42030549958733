import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Week from "./Week.jsx";
import CalendarHeadlinesContainer from "./calendar-headlines/CalendarHeadlinesContainer";
import moment from "moment";  // Luxon will replace moment, that is no longer being developed
import { DateTime } from "luxon";
import RightArrow from "./arrow/RightArrow.jsx";
import LeftArrow from "./arrow/LeftArrow.jsx";
import RegularHeadline from "../headlines/RegularHeadline";
import GlobalEventDetails from "./GlobalEventDetails";
import { buildCalendar, getWeekNumber, nextWeek } from "./CalendarFunctions";
import Loader from "../loader/Loader";
import Fade from "@material-ui/core/Fade";
import { getAndReturnData } from "../../containers/HelperFunctions";
import { withRouter } from "react-router-dom";
import { withContext } from "../containers/ContextContainer";
import { Context } from "../../context/Context";

const CalendarContainerDesktop = styled.div`
  display: block;
  min-height: 28.9rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  @media (max-width: 960px) {
    display: none;
  }
`;
const CalendarWithArrowsContainer = styled.div`
  display: flex;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
`;
const CalendarContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  width: 100%;
`;
const LoaderContainer = styled.div`
  padding: 18%;
`;
function Calendar(props) {
  const context = useContext(Context);

  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [dateObject, setDateObject] = useState(moment());
  const [eventInfofromApi, setEventInfofromApi] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  // for open event'details
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const firstWeekInformation = {
      dates: buildCalendar(dateObject),
      weekNumber: getWeekNumber(dateObject),
    },
    secondWeekInformation = {
      dates: buildCalendar(nextWeek(dateObject)),
      weekNumber: getWeekNumber(nextWeek(dateObject)),
    };
   let firstDayInCalendar = firstWeekInformation.dates[0].date,
     lastDayInCalendar = secondWeekInformation.dates[6].date;
     

  // Handle click for next or past two weeks
  const handleArrowClick = (week) => {
    setDateObject(week);
    // // Change date in url query param too
    // this.props.history.push(
    //   `/kalender/${moment(week).format(
    //     "YYYY-MM-DD"
    //   )}`
    // );
    
  };

  // Fetch data from Api
  const getAndSaveCalendarData = async () => {
    firstDayInCalendar= DateTime.now().toISO().substring(0,10);  
    lastDayInCalendar = DateTime.now().plus({months:1}).toISO().substring(0,10);  
    const response = await getAndReturnData(
      `/api/v1/event/public/${firstDayInCalendar}/${lastDayInCalendar}`,
      (err) => {
        console.log('Calendar getAndSaveCalendarData:',err);
      }
    );
    // console.log('response',response)
    let data=[]
    if(response && response.eventList){
      data = response.eventList;
      // console.log('data:', data)
      if (!data || data.length === 0) {
        setCalendarLoaded(true);
      } else {
        setEventInfofromApi(data);
        setCalendarLoaded(true);
      }
    }
    else{
      console.log('Calendar getAndSaveCalendarData returned no data.');
    }
 
  };

  useEffect(() => {
    setCalendarLoaded(false);
    // Get events
    getAndSaveCalendarData();
    // return () => { // cleanup logic }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [dateObject]);

  // Handle Expand Calendar to show event details
  const handleOpenEventDetails = (eventDetails) => {
    if (isOpenEvent) {
      // If the current event is the same old one then close the eventDetails else open it
      if (selectedEvent === eventDetails) {
        setIsOpenEvent(false);
        setSelectedEvent({});
      } else {
        setSelectedEvent(eventDetails);
      }
    } else {
      setIsOpenEvent(true);
      setSelectedEvent(eventDetails);
    }
  };

  // Handle (X) close event details
  const closeOpenEvent = () => {
    setIsOpenEvent(false);
    setSelectedEvent({});
  };
  return (
    <CalendarContainerDesktop style={{ display: (context.state.features.enableCalendar?'block':'none')}}>
      {calendarLoaded ? (
        <CalendarWithArrowsContainer>
          {/* {eventInfofromApi.length > 0 ? ( */}
            <>
              <LeftArrow
                onClick={handleArrowClick}
                dateObj={dateObject}
                calendar={"global-calendar"}
              />
              <CalendarContainer>
                <RegularHeadline
                  component="h2"
                  title={`Hvad sker der mellem ${moment(
                    firstDayInCalendar
                  ).format("Do MMMM YYYY")} - ${moment(
                    lastDayInCalendar
                  ).format("Do MMMM YYYY")}`}
                />
                <CalendarHeadlinesContainer />
                <Week
                  weekInfo={firstWeekInformation}
                  openEventDetails={handleOpenEventDetails}
                  selectedEventInCalendar={selectedEvent}
                  globalEvents={eventInfofromApi}
                  weekNumber={firstWeekInformation.weekNumber}
                  calendar={"global-calendar"}
                />
                <Week
                  weekInfo={secondWeekInformation}
                  openEventDetails={handleOpenEventDetails}
                  selectedEventInCalendar={selectedEvent}
                  globalEvents={eventInfofromApi}
                  weekNumber={secondWeekInformation.weekNumber}
                  calendar={"global-calendar"}
                />
                {isOpenEvent ? (
                  <Fade in={isOpenEvent} timeout={500}>
                    <div>
                      <GlobalEventDetails
                        eventDetails={selectedEvent}
                        closeOpenEvent={closeOpenEvent}
                      ></GlobalEventDetails>
                    </div>
                  </Fade>
                ) : (
                  <div></div>
                )
                }
              </CalendarContainer>
              <RightArrow
                onClick={handleArrowClick}
                dateObj={dateObject}
                calendar={"global-calendar"}
              />
            </>
          {/* ) : (
            <Typography variant="body2">
              Der er ingen begivenheder, eller du er offline
            </Typography>
          )} */}
        </CalendarWithArrowsContainer>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </CalendarContainerDesktop>
  );
}
export default withContext(withRouter(Calendar));
