import React, { useEffect, useRef, useState /*, useContext */ } from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { overridings } from '../../themes/DarkTheme';
import EducationContentContainer from '../../components/containers/education/EducationContentContainer';
import ExplainerTextContainer from '../../components/containers/explainers/ExplainerTextContainer';
import ExplainerVideoContainer from '../../components/containers/explainers/ExplainerVideoContainer';

import ExplainerContent from '../../components/explainers/ExplainerContent';
import ExplainerText from '../../components/explainers/ExplainerText';
import ReusableHeader from '../../components/headers/reusable-header/ReusableHeader';
import EducationHeadline from '../../components/headlines/EducationHeadline';
import ReturnLinkReusable from '../../components/return-link/ReturnLinkReusable';
import CourseTypes from '../../components/course-types/CourseTypes';

import VideoComponent from '../../components/video/VideoComponent';
import Instruments from './Instruments';
import EducationGrid from '../../components/education-grid/EducationGrid';
import { getDestination, getReturnUrl } from './EducationFunctions';

// CMS feature
// import { Context } from "../../context/Context";
import MessageList from '../../components/message-list';
import MessagePlaceholder from '../../components/message-list/MessagePlaceholder';
import { getMessage } from '../../components/message-list/MessageListHelper';

function Educations(props) {
    const { url } = useRouteMatch();

    const contentRef = useRef();

    const getRightHeaderImage = () => {
        // Display mobile size images
        let url;
        if (document.body.offsetWidth < overridings.breakpoints.values.md) {
            if (props.headline === 'Instrument') {
                url =
                    '/images/headers/headers-mobile/header-mobile-instruments.png';
            } else {
                url = '/images/headers/headers-mobile/header-mobile-vision.jpg';
            }
        } else {
            if (props.headline === 'Instrument') {
                url = '/images/headers/header-instruments.png';
            } else {
                url = '/images/headers/header-vision.jpg';
            }
        }
        return url;
    };

    // const context = useContext(Context);
    const [messageList, setMessageList] = useState([]);

    // ComponentDidMount
    useEffect(() => {
        // Take user to top of the page
        contentRef.current.scrollIntoView(true);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <MessageList
                type="html"
                name="Education"
                state="final"
                change={setMessageList}
            />
            <ReusableHeader imageUrl={getRightHeaderImage()} />{' '}
            <span ref={contentRef}></span>
            <EducationContentContainer>
                {props.headline === 'Instrument' && (
                    <ReturnLinkReusable
                        url={getReturnUrl(props)}
                        destination={getDestination(props)}
                    />
                )}
                {/* Styling and layout is different for instruments page than it is for other educations */}
                {props.headline === 'Instrument' ? (
                    <Instruments />
                ) : (
                    <React.Fragment>
                        <EducationHeadline
                            title={getMessage(
                                messageList,
                                'Education',
                                'title',
                                'Undervisning'
                            )}
                        />
                        <ExplainerContent>
                            <ExplainerTextContainer>
                                <ExplainerText>
                                    <MessagePlaceholder
                                        list={messageList}
                                        name="Education"
                                        value=""
                                    />
                                </ExplainerText>
                            </ExplainerTextContainer>

                            <ExplainerVideoContainer>
                                <VideoComponent
                                    src="/videos/piano-video.mp4"
                                    type="video/mp4"
                                />
                            </ExplainerVideoContainer>
                        </ExplainerContent>
                    </React.Fragment>
                )}

                <div>
                    <EducationGrid
                        data={props.data}
                        url={url}
                        returnPath={url}
                        returnTitle="Undervisning"
                    />
                </div>
                <div>
                    <CourseTypes />
                </div>
            </EducationContentContainer>
        </div>
    );
}

export default withRouter(Educations);
