import { getCurrentUserInfo, getToken } from '../../../context/getData';
import {
    capitalize,
    postAndReturnResponseWithAuth,
    putAndReturnRespWithAuth
} from '../../../context/helperFunctions';
import {
    returnSetStateObj,
    returnStateObj,
    returnValue,
    setScrollView,
    throwError
} from '../../registration/information/RegisterInformationFunctions';

const sendVerificationEmail = async (userId, token, handleError) => {
    const response = await postAndReturnResponseWithAuth(
        `/api/v1/user/${userId}/send-verification-email`,
        {},
        token,
        handleError
    );
    return response;
};

const sendVerificationSms = async (userId, token, handleError) => {
    return await postAndReturnResponseWithAuth(
        `/api/v1/user/${userId}/send-verification-sms`,
        {},
        token,
        handleError
    );
};

const showSuccessOrError = async (
    token,
    userId,
    handleError,
    setError,
    setErrorMessage,
    setSuccess,
    setSuccessMessage,
    setSuccessUpdate,
    isPhoneChanged,
    isEmailChanged,
    setIsLoadingSubmit
) => {
    // If isEmailChanged or isPhoneChanged is true, we need to call the verification endpoint, in order to send out verification email/sms
    // Then display a dialog, which says that email has been sent out.
    if (isPhoneChanged && isEmailChanged) {
        const sendVerEmailResponse = await sendVerificationEmail(
            userId,
            token,
            handleError
        );
        const sendVerSmsResponse = await sendVerificationSms(
            userId,
            token,
            handleError
        );
        if (
            sendVerEmailResponse.status !== 200 ||
            sendVerSmsResponse.status !== 200
        ) {
            setErrorMessage('Noget gik galt. Prøv igen.');
            console.error('Could not send email or sms verification');
        } else {
            setSuccess(true);
            setSuccessMessage(
                'Der sendes en mail og sms til din email og telefon. Du skal verificere dem, før du foretager din næste ordre.'
            );
        }
        setIsLoadingSubmit(false);
    } else if (isEmailChanged && !isPhoneChanged) {
        const sendVerEmailResponse = await sendVerificationEmail(
            userId,
            token,
            handleError
        );
        if (sendVerEmailResponse.status !== 200) {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen.');
            console.error('Could not send email or sms verification');
        } else {
            setSuccess(true);
            setSuccessMessage(
                'Opdateret. Der er sendt en mail til din nye mailadresse. Du skal verificere det, før du foretager din næste ordre.'
            );
        }
        setIsLoadingSubmit(false);
    } else if (!isEmailChanged && isPhoneChanged) {
        const sendVerSmsResponse = await sendVerificationSms(
            userId,
            token,
            handleError
        );
        if (sendVerSmsResponse.status !== 200) {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen.');
            console.error('Could not send email or sms verification');
        } else {
            setSuccess(true);
            setSuccessMessage(
                'Opdateret. Der er sendt en sms til dit nye telefonnummer. Du skal verificere det, før du foretager din næste ordre.'
            );
        }
        setIsLoadingSubmit(false);
    } else {
        // setSuccess(true);
        setSuccessUpdate(true);
        setIsLoadingSubmit(false);
    }
};

const createAndSendRequest = async (
    userId,
    handleError,
    setError,
    setErrorMessage,
    setSuccess,
    setSuccessMessage,
    setSuccessUpdate,
    statesArr,
    setEditPasswordOpen,
    context,
    isPhoneChanged,
    isEmailChanged,
    setIsLoadingSubmit,
    acceptedPublicProfile,
    // If this is defined, it means that the user is trying to edit the Public profile
    // In order to do a put request to putUser, we need to send information like name, surname, etc...
    // This info we keep from userDataFromDb
    userDataFromDb,

    userCategories,
    isProfilePublic
) => {
    console.log('create and send request');
    let requestBodyWithPass, requestBodyWithoutPass;

    // User is trying to update the public profile - load name, address, etc from db.

    // Contains password
    requestBodyWithPass = {
        firstname: returnValue(statesArr, 'firstname'),
        surname: returnValue(statesArr, 'surname'),
        street: returnValue(statesArr, 'street'),
        housenumber: returnValue(statesArr, 'housenumber'),
        city: returnValue(statesArr, 'city'),
        postcode: returnValue(statesArr, 'postcode'),
        email: returnValue(statesArr, 'email'),
        phone: returnValue(statesArr, 'phone'),
        password: returnValue(statesArr, 'password'),
        activityName: returnValue(statesArr, 'activityName'),
        activityDescription: returnValue(statesArr, 'activityDescription'),
        webpageLink: returnValue(statesArr, 'webpageLink'),
        activityPhone: returnValue(statesArr, 'activityPhone'),
        organization: returnValue(statesArr, 'organization'),
        isProfilePublic: isProfilePublic,
        userCategories: userCategories,
        title: returnValue(statesArr, 'title'),
        educationalBg: returnValue(statesArr, 'educationalBg'),
        workingWith: returnValue(statesArr, 'workingWith'),
        profHelp: returnValue(statesArr, 'profHelp'),
        practicalHelp: returnValue(statesArr, 'practicalHelp'),
        relationToChild: returnValue(statesArr, 'relationToChild')
    };

    // Does not contain new (or any) password
    requestBodyWithoutPass = {
        firstname: returnValue(statesArr, 'firstname'),
        surname: returnValue(statesArr, 'surname'),
        street: returnValue(statesArr, 'street'),
        housenumber: returnValue(statesArr, 'housenumber'),
        city: returnValue(statesArr, 'city'),
        postcode: returnValue(statesArr, 'postcode'),
        email: returnValue(statesArr, 'email'),
        phone: returnValue(statesArr, 'phone'),
        activityName: returnValue(statesArr, 'activityName'),
        activityDescription: returnValue(statesArr, 'activityDescription'),
        webpageLink: returnValue(statesArr, 'webpageLink'),
        activityPhone: returnValue(statesArr, 'activityPhone'),
        organization: returnValue(statesArr, 'organization'),
        isProfilePublic: isProfilePublic,
        userCategories: userCategories,
        title: returnValue(statesArr, 'title'),
        educationalBg: returnValue(statesArr, 'educationalBg'),
        workingWith: returnValue(statesArr, 'workingWith'),
        profHelp: returnValue(statesArr, 'profHelp'),
        practicalHelp: returnValue(statesArr, 'practicalHelp'),
        relationToChild: returnValue(statesArr, 'relationToChild')
    };

    // Update db with PUT
    const token = getToken();
    let updateResponse;

    if (returnValue(statesArr, 'password')) {
        updateResponse = await putAndReturnRespWithAuth(
            `/api/v1/user/${userId}`,
            requestBodyWithPass,
            token,
            handleError
        );
    } else {
        console.log('from here', requestBodyWithoutPass);
        updateResponse = await putAndReturnRespWithAuth(
            `/api/v1/user/${userId}`,
            requestBodyWithoutPass,
            token,
            handleError
        );
    }

    if (updateResponse.status === 200) {
        // Update user storage (- needed, because if the user changed for example the first namme, it is loaded from context on other pages.)
        const userDataFromStorage = getCurrentUserInfo();
        // If it is an update on the public profile, then no need to update name and email
        if (userDataFromDb) {
            context.saveUser(userDataFromStorage);
        } else {
            userDataFromStorage.userFirstname = returnValue(
                statesArr,
                'firstname'
            );
            userDataFromStorage.userEmail = returnValue(statesArr, 'email');
            context.saveUser(userDataFromStorage);
        }

        showSuccessOrError(
            token,
            userId,
            handleError,
            setError,
            setErrorMessage,
            setSuccess,
            setSuccessMessage,
            setSuccessUpdate,
            isPhoneChanged,
            isEmailChanged,
            setIsLoadingSubmit
        );
    } else {
        const responseData = await updateResponse.json();
        setError(true);
        if (responseData.message === 'email must be unique') {
            setErrorMessage('Denne email er allerede registreret.');
            setIsLoadingSubmit(false);
        } else {
            setErrorMessage('Noget gik galt. Prøv igen.');
            setIsLoadingSubmit(false);
        }
    }
    // Close password edit
    if (setEditPasswordOpen) {
        setEditPasswordOpen(false);
    }
};

export const setErrorScrollView = (
    firstErrorLabel,
    nameRef,
    emailRef,
    phoneRef,
    addressRef,
    passwordRef
) => {
    if (firstErrorLabel === 'firstname' || firstErrorLabel === 'surname') {
        setScrollView(nameRef);
    } else if (firstErrorLabel === 'email') {
        setScrollView(emailRef);
    } else if (firstErrorLabel === 'phone') {
        setScrollView(phoneRef);
    } else if (
        firstErrorLabel === 'street' ||
        firstErrorLabel === 'housenumber' ||
        firstErrorLabel === 'postcode' ||
        firstErrorLabel === 'city'
    ) {
        setScrollView(addressRef);
    } else if (
        firstErrorLabel === 'password' ||
        firstErrorLabel === 'repeatPassword'
    ) {
        setScrollView(passwordRef);
    }
};

export const findUnfilledInputOrSave = async (
    userId,
    handleError,
    setError,
    setErrorMessage,
    setSuccess,
    setSuccessMessage,
    setSuccessUpdate,
    statesArr,
    setStatesArr,
    nameRef,
    emailRef,
    phoneRef,
    addressRef,
    passwordRef,
    setEditPasswordOpen,
    editPasswordOpen,
    context,
    isPhoneChanged,
    isEmailChanged,
    setIsLoadingSubmit,
    acceptedPublicProfile,
    userDataFromDb,
    userCategories
) => {
    // Check if edit password is open, and if it is, if the value is empty
    // Edit password is not open or there is no input value there, do not take password into consideration
    let filteredStatesArr;
    const passwordState = statesArr.find((state) => state.label === 'password');
    const repeatPasswordState = statesArr.find(
        (state) => state.label === 'repeatPassword'
    );

    if (
        editPasswordOpen &&
        (passwordState.value !== '' || repeatPasswordState !== '')
    ) {
        // Include all states
        filteredStatesArr = statesArr;
    } else {
        // Remove password and repeat password, since they have not been filled out/ changed
        filteredStatesArr = statesArr.filter(
            (state) =>
                state.label !== 'password' && state.label !== 'repeatPassword'
        );
    }

    // If there is a field where it is not filled
    if (
        filteredStatesArr.some(
            (state) =>
                (state.required === true && !state.value) ||
                (state.required === true && state.value === '')
        )
    ) {
        // Push it into the notFilledState array if the field is required
        const notFilledState = filteredStatesArr.filter(
            (state) =>
                (state.required === true && !state.value) ||
                (state.required === true && state.value === '')
        );

        // Throw error in these States
        notFilledState.forEach((state) =>
            throwError(
                state.label,
                returnStateObj(statesArr, state.label),
                returnSetStateObj(setStatesArr, state.label)
            )
        );

        // Set scroll view to the first element that has error
        const firstErrorLabel = notFilledState[0].label;
        setErrorScrollView(
            firstErrorLabel,
            nameRef,
            emailRef,
            phoneRef,
            addressRef,
            passwordRef
        );
        setIsLoadingSubmit(false);
    } else {
        createAndSendRequest(
            userId,
            handleError,
            setError,
            setErrorMessage,
            setSuccess,
            setSuccessMessage,
            setSuccessUpdate,
            statesArr,
            setEditPasswordOpen,
            context,
            isPhoneChanged,
            isEmailChanged,
            setIsLoadingSubmit,
            acceptedPublicProfile,
            userDataFromDb,
            userCategories
        );
    }
};

// Come back: danish text
export const validateTextarea = (value, setStateObj, stateObj) => {
    if (value.length < 500) {
        setStateObj({ ...stateObj, value });
    } else {
        setStateObj({
            ...stateObj,
            value,
            textareaError: true,
            errorMessage: 'Max 500 bogstaver.'
        });
    }
};

export const validateStringAddress = (
    value,
    label,
    setStreetObj,
    setHousenumberObj,
    setCityObj,
    streetObj,
    housenumberObj,
    cityObj
) => {
    const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-]+$/;
    const regexCity = /^[A-Za-z æøåüÆØÅÜ.,-]+$/;
    const capitalizedValue = capitalize(value);
    if (label === 'Husnummer' || label === 'Gade') {
        if (
            regex.test(capitalizedValue) &&
            capitalizedValue.length < 500 &&
            capitalizedValue.length > 0
        ) {
            switch (label) {
                case 'Gade':
                    setStreetObj({ ...streetObj, value: capitalizedValue });
                    break;
                case 'Husnummer':
                    setHousenumberObj({
                        ...housenumberObj,
                        value: capitalizedValue
                    });
                    break;
                default:
                    break;
            }
        } else {
            switch (label) {
                case 'Gade':
                    setStreetObj({
                        ...streetObj,
                        error: true,
                        errorMessage: `Dit gadenavn mangler eller er ukorrekt.`,
                        value: capitalizedValue
                    });
                    break;
                case 'Husnummer':
                    setHousenumberObj({
                        ...housenumberObj,
                        error: true,
                        errorMessage: `Dit husnummer mangler eller er ukorrekt.`,
                        value: capitalizedValue
                    });
                    break;

                default:
                    break;
            }
        }
    } else {
        if (
            regexCity.test(capitalizedValue) &&
            capitalizedValue.length < 500 &&
            capitalizedValue.length > 0
        ) {
            setCityObj({ ...cityObj, value: capitalizedValue });
        } else {
            setCityObj({
                ...cityObj,
                error: true,
                errorMessage: `Dit bynavn mangler eller er ukorrekt.`,
                value: capitalizedValue
            });
        }
    }
};

export const validatePostcode = (value, setPostcodeObj, postcodeObj) => {
    const regex = /^[0-9]+$/;
    if (regex.test(value) && value.length === 4) {
        setPostcodeObj({ ...postcodeObj, value });
    } else {
        setPostcodeObj({
            ...postcodeObj,
            error: true,
            errorMessage: `Dit postnummer mangler eller er ukorrekt (4 cifre).`,
            value
        });
    }
};

export const validatePassword = (value, setPasswordObj, passwordObj) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
    // If value length is 0, do not write any error, since we can assume that the user did not want to change the password, but there is no way to close the change password input fields.
    if (value.length < 6 && value.length > 0) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage:
                'Kodeordet skal være på mindst 8 tegn og indeholde mindst et tal og både store og små bogstaver.',
            value
        });
    } else if (value.length > 250) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage: 'Kodeordet kan indeholder  max 250 bogstaver.',
            value
        });
    } else if (!regex.test(value) && value.length !== 0) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage:
                'Kodeordet skal være på mindst 8 tegn og indeholde mindst et tal og både store og små bogstaver.',
            value
        });
    } else {
        setPasswordObj({ ...passwordObj, value });
    }
};
