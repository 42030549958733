import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
// import NavHeaderMobile from "../headers/nav-header/NavHeaderMobile.js";
import Loader from "../loader/Loader";
import { nextMonth } from "./CalendarFunctions";
import EventInMobileCalendar from "./EventInMobileCalendar";
import RegularHeadline from "../headlines/RegularHeadline";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ReturnLinkReusable from "../return-link/ReturnLinkReusable";
import { getAndReturnData } from "../../containers/HelperFunctions";
import NavHeaderMobileContainer from "../containers/header/NavHeaderMobileContainer";
import NavHeaderMobile from "../headers/nav-header/NavHeaderMobile";
import debounce from "lodash.debounce";

const CalendarContainerMobile = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: block;
    width: 85%;
    margin: 2rem auto 4rem auto;
  }
`;
const CalendarHeader = styled.div`
  position: relative;
  padding-top: 1rem;
`;
const CurrentMonth = styled.span`
  text-transform: uppercase;
  font-size: 0.9rem;
`;
const MonthContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 50px;
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 14px 0px;
`;
const Month = styled.span`
  margin: 3% 7.5%;
  text-transform: uppercase;
  font-size: 0.9rem;
`;
const FilterIconContainer = styled.span`
  position: absolute;
  right: 0px;
  top: 1rem;
  fontsize: large;
`;
const CalendarContainer = styled.div``;
const EventsContainer = styled.div`
  margin: 0.5rem 0rem 1rem 0rem;
`;


// react-touch is compatible with latest React, just implement Swipeable here. Removed import { Swipeable } from "react-touch";
// const Swipeable = (props) => {
//   return (
//     <div style={{
//         overflowX: 'scroll',
//         overflowY: 'scroll',      
//         '&::WebkitScrollbar': {
//           display: 'none',     
//         },                   
//         '&': {
//           '-ms-overflow-style': 'none',
//           'scrollbar-width': 'none'
//         }
//     }}>
//       {props.children}
//     </div>
//   );
// }

function MobileCalendarMoreEvents() {
  const [calendarLoaded, setCalendarLoaded] = useState(false);
  const [eventInformationFromApi, setEventInfofromApi] = useState([]);
  let firstDay = moment().format("YYYY-MM-DD");
  let allEvents;
  // eslint-disable-next-line
  const [lastDay, setLastDay] = useState(
    nextMonth(firstDay).format("YYYY-MM-DD")
  );
  const sortEventsByDate = (event1, event2) => {
    if (
      moment(event1.date).format("YYYY-MM-DD") >
      moment(event2.date).format("YYYY-MM-DD")
    )
      return 1;
    if (
      moment(event1.date).format("YYYY-MM-DD") <
      moment(event2.date).format("YYYY-MM-DD")
    )
      return -1;
    if (
      moment(event1.date).format("YYYY-MM-DD") ===
      moment(event2.date).format("YYYY-MM-DD")
    )
      return 0;
  };

   // Check is user scrolled to bottom of the page in case of using mobile version
   window.onscroll = debounce(() => {
    console.log( lastDay,window.innerHeight,document.documentElement.scrollTop , document.documentElement.offsetHeight);
    // setLastDay(nextMonth(lastDay).format("YYYY-MM-DD"));
   // Checks that the page has scrolled to the bottom
    // if (
    //   parseInt(window.innerHeight + document.documentElement.scrollDow) ===
    //     document.documentElement.offsetHeight ||
    //   parseInt(
    //     window.innerHeight + document.documentElement.scrollTop + 1
    //   ) === document.documentElement.offsetHeight ||
    //   parseInt(
    //     window.innerHeight + document.documentElement.scrollTop - 1
    //   ) === document.documentElement.offsetHeight
    // ) {
    //   setLastDay(nextMonth(lastDay).format("YYYY-MM-DD"));
    // }
  }, 100);
  const displayEvents = (events) =>
    events.map((event, key) => {
      if (key !== 0) {
        if (
          moment(event.date).format("MMMM") ===
          moment(events[key - 1].date).format("MMMM")
        ) {
          return <EventInMobileCalendar key={key} eventInfo={event} />;
        } else {
          return (
            <div key={key}>
              <MonthContainer>
                <Month>{moment(event.date).format("MMMM")}</Month>
              </MonthContainer>
              <EventInMobileCalendar eventInfo={event} />
            </div>
          );
        }
      } else {
        return <EventInMobileCalendar key={key} eventInfo={event} />;
      }
    });
  const handleError = (err) => {
    console.log(err);
  };

  const getAndSaveCalendarData = async () => {
    const response = await getAndReturnData( 
      `/api/v1/event/public/${firstDay}/${lastDay}`,
      handleError
    );
    let data=[]
    if(response && response.eventList){
      data = response.eventList;
      console.log('data:', data)
    if (!data || data.length === 0) {
      // Stop loader
      setCalendarLoaded(true);
      console.log('Noget gik galt');
    } else {
      allEvents = data.sort(sortEventsByDate);
      setEventInfofromApi(allEvents);
      console.log(allEvents);
      // Stop loader
      setCalendarLoaded(true);
    }
   } else{
      handleError('Noget gik galt')
    }
    
  };
  // Fetch data from Api
  useEffect(() => {
    // Start loader
    setCalendarLoaded(false);
    // Get events

    getAndSaveCalendarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const handleSwipe = () => {

  //   setLastDay(nextMonth(lastDay).format("YYYY-MM-DD"));
  //   // getAndSaveCalendarData();
  // };
  return (
    <React.Fragment>
      <NavHeaderMobileContainer>
        <NavHeaderMobile />
      </NavHeaderMobileContainer>
      <CalendarContainerMobile>
        {/* <NavHeaderMobile /> */}
        <ReturnLinkReusable url="/" destination="forsiden" />
        {calendarLoaded ? (

            <CalendarContainer>
              <CalendarHeader>
                <RegularHeadline
                  component="h2"
                  title="Kommende aktiviteter"
                  marginbottom={"1rem"}
                />
                <FilterIconContainer>
                  <FilterListIcon fontSize={"large"} />
                </FilterIconContainer>
                {eventInformationFromApi.length > 0 ? (
                  <CurrentMonth>
                    {moment(eventInformationFromApi[0].date).format("MMMM")}
                  </CurrentMonth>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </CalendarHeader>
              {eventInformationFromApi.length > 0 ? (
                <React.Fragment>
                  <EventsContainer>
                    {displayEvents(eventInformationFromApi)}
                  </EventsContainer>
                </React.Fragment>
              ) : (
                <Typography variant="body2">
                  Der er ingen begivenheder, eller du er offline
                </Typography>
              )}
            </CalendarContainer>
         
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </CalendarContainerMobile>
    </React.Fragment>
  );
}
export default withRouter(MobileCalendarMoreEvents);
