import { Grid } from "@material-ui/core";
import React, { Component } from "react";
// import { handleErrors } from "../../containers/HelperFunctions";
// import { instrumentData } from "../../data/InstrumentData";

import AudioComponent from "../audio/AudioComponent";
import EmployeeCard from "../cards/EmployeeCard";
import EmployeeListContainer from "../containers/about/EmployeeListContainer";
import ExplainerImageContainer from "../containers/explainers/ExplainerImageContainer";
import ExplainerTextContainer from "../containers/explainers/ExplainerTextContainer";
import QABoxesContainer from "../containers/about/QABoxesContainer";
import ExplainerContent from "../explainers/ExplainerContent";
import ExplainerImage from "../explainers/ExplainerImage";
// import ExplainerText from "../explainers/ExplainerText";
import EducationHeadline from "../headlines/EducationHeadline";
import QABox from "../QABox/QABox";
import ReturnLinkReusable from "../return-link/ReturnLinkReusable";

import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";

class EducationComponent extends Component {
  state = {
    teachers: [],
    instruments: [],
    messageList: [],
    employeeMessageList: [],
    babyMusicFAQMessageList: [],
    messageName: undefined,
  };

  componentDidMount() {
    // Map educationId from URL to message name 
    const { data } = this.props;
    //console.log('EducationComponent: Data',JSON.stringify(data,null,2));
    // TODO: Use message url to find /undervisning/sammenspil
    let messageName = '';
    if (data.id==='babymusik') messageName = 'Education BM';
    else if (data.id==='hoereleg') messageName = 'Education HL';
    else if (data.id==='instrumenter') messageName = 'Education Instrument';
    else if (data.id==='sammenspil') messageName = 'Education Musical interplay';
    else if (data.id==='blokfloejte') messageName = 'Instrument Recorder';
    else if (data.id==='cello') messageName = 'Instrument Cello';
    else if (data.id==='guitar') messageName = 'Instrument Guitar';
    else if (data.id==='harpe') messageName = 'Instrument Harp';
    else if (data.id==='klaver') messageName = 'Instrument Piano';
    else if (data.id==='slagtoej') messageName = 'Instrument Drums';
    else if (data.id==='violin') messageName = 'Instrument Violin';
    this.setState({ messageName });

    // TODO Read teacher data from Message data seeders named 'Education %'
    /*
    const teachersData = [];
    this.props.data.teachers.forEach((teacher) => {
      fetch(`/api/v1/employee/${teacher.urlName}`)
        .then(handleErrors)
        .then((resp) => resp.json())
        .then((data) => {
          teachersData.push(data);
          return teachersData;
        })
        .then((teachersData) => {
          this.setState({ teachers: teachersData });
        });
    });
    */

    // TODO Read instrument data from Message data seeders named 'Instument %'    
    // Get all instruments
    // const instruments = instrumentData.map((instrument) => instrument.title);
    // this.setState({ instruments });
   
  }

  render() {
    const { data, url } = this.props;
   
    const setEmployeeMessageList = (list) => {
      // console.log('data',data);
      // See api\data\employees.js data for structure expected by frontend component
      // Instead of calling /api/v1/employees to get employee and other
      // employees list, we call the message end point
      let teacherList = list
        .filter( message => {
          let text = (message.title+' '+message.html).toLowerCase();
          let found = text.startsWith('underviser') && (text.indexOf(data.id)>=0);
          return found;
        })
        .map( message => {
          let name = message.name.substring('Employee '.length); 
          let urlName = name.toLowerCase().replaceAll(' ','_');
          let mailToIndex = message.html.indexOf('href="mailto:')+'href="mailto:'.length;
          let mailToEnd = message.html.indexOf('"',mailToIndex);
          let email = '';
          if (mailToIndex>0 && mailToEnd>0) {
            email = message.html.substring(mailToIndex,mailToEnd);
          }
          return {
            name,
            urlName,
            instrument: message.title,
            id: 1,
            imageUrl: message.text,
            color: "#DDDDDD",
            colorName: "green",
            email
          }
        });
        this.setState({ 
          teachers: teacherList, 
          employeeMessageList: list 
        });
    }

    const setBabyMusicFAQMessageList = (list) => {
      if (data.id==='babymusik') {
        this.setState({ 
          babyMusicFAQMessageList: list 
        });
      }
    }

    const setMessageList = (list) => {
      // console.log('setMessageList',list);
      this.setState({ 
        messageList: list
      });
    }

    return (
      <div>        
        {/* TODO: Use MessageList type=url to find message based on url not name */}
        { this.state.messageName &&
          <MessageList type="html" name={this.state.messageName} state="final" change={setMessageList}/>
        }
        <MessageList type="html" name="Employee%" state="final" change={setEmployeeMessageList}/>
        <MessageList type="html" name="BM FAQ%" state="final" change={setBabyMusicFAQMessageList}/>

        {/* If user is on an instrument page, the back link will lead to Instruments */}
        {this.state.instruments.includes(data.title) ? (
          <ReturnLinkReusable
            url={"/undervisning/instrumenter"}
            destination="Instrumenter"
          />
        ) : (
          <ReturnLinkReusable
            url={
              this.props.previousUrl ? this.props.previousUrl : "/undervisning"
            }
            destination={
              this.props.destination ? this.props.destination : "Undervisning"
            }
          />
        )}

        <React.Fragment>
          <EducationHeadline title={(this.state.messageName ? getMessage(this.state.messageList,this.state.messageName,'title','') : '')} />

          <ExplainerContent>
            <ExplainerTextContainer>
              { this.state.messageName && this.state.messageList.length > 0 && 
                <MessagePlaceholder list={this.state.messageList} name={this.state.messageName} value=""/>
              }
              <AudioComponent
                src="/music/music-sample.mp3"
                type="audio/mpeg"
                width="100%"
              />
            </ExplainerTextContainer>
            <ExplainerImageContainer>
              <ExplainerImage src={data.imageUrl} alt={data.title} />
            </ExplainerImageContainer>
          </ExplainerContent>
          <EducationHeadline title="Undervisere" />
          <EmployeeListContainer>
            {this.state.teachers.length > 0 &&
              <React.Fragment>
                {this.state.teachers.map((teacher) => (
                  <Grid item xs={12} sm={6} md={4} key={teacher.name}>
                    <EmployeeCard
                      name={teacher.name}
                      image={teacher.imageUrl}
                      instrument={teacher.instrument}
                      url={url}
                      fromTitle="Medarbejdere"
                      color={teacher.color}
                      colorName={teacher.colorName}
                      email={teacher.email}
                      to={{
                        pathname: `/om-skolen/medarbejdere/${teacher.urlName}`,
                        state: { from: { path: url, title: data.title } },
                      }}
                    />
                  </Grid>
                ))}
              </React.Fragment>
            }
          </EmployeeListContainer>
          {/* Course explanations - not sure if it is needed here */}
          {data.courses && (
            <QABoxesContainer>
              { this.state.babyMusicFAQMessageList.map((message) => (
                <QABox key={'message_'+message.id} messageId={message.id} title={message.title} html={message.html} width="100%" />
              ))}
            </QABoxesContainer>
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default EducationComponent;
