import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const CheckMarkComp = styled(Typography)`
  color: ${(props) => props.theme.palette.success.main};
  font-size: 1rem;
  display: inline;
  
`;

function CheckMark(props) {
  return <CheckMarkComp variant="body2">&#10003;</CheckMarkComp>;
}

export default CheckMark;
