import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const EmployeeListContainerComponent = styled(Grid)`
  margin-bottom: 3rem;
`;

function EmployeeListContainer({ children }) {
  return (
    <EmployeeListContainerComponent container spacing={4}>{children}</EmployeeListContainerComponent>
  );
}

export default EmployeeListContainer;
