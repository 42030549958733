import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  updatePrices,
  getSpecificMonths,
  updateSelection,
} from "./RateCalculatorFunctions";
import { Context } from "../../context/Context";
import { Box } from "@material-ui/core";
import MonthBox from "./MonthBox";
import moment from "moment";
import { capitalize } from "../../containers/HelperFunctions";

const RowContainer = styled(Box)`
  border-bottom: 2px solid ${(props) => props.theme.palette.secondary.light};
  & div {
    border-right: 2px solid ${(props) => props.theme.palette.secondary.light};
  }
`;

const Frame = styled(Box)`
  width: fit-content;
  height: fit-content;
  border-top: 2px solid ${(props) => props.theme.palette.secondary.light};
  border-left: 2px solid ${(props) => props.theme.palette.secondary.light};
  margin-left: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin: 2rem auto 0 auto;
  }
`;

const monthsDataInitial = [
  { name: "Jul", selected: false, price: 0, id: 1, disabled: false },
  { name: "Aug", selected: false, price: 0, id: 2, disabled: false },
  { name: "Sep", selected: false, price: 0, id: 3, disabled: false },
  { name: "Okt", selected: false, price: 0, id: 4, disabled: false },
  { name: "Nov", selected: false, price: 0, id: 5, disabled: false },
  { name: "Dec", selected: false, price: 0, id: 6, disabled: false },
  { name: "Jan", selected: false, price: 0, id: 7, disabled: false },
  { name: "Feb", selected: false, price: 0, id: 8, disabled: false },
  { name: "Mar", selected: false, price: 0, id: 9, disabled: false },
  { name: "Apr", selected: false, price: 0, id: 10, disabled: false },
  { name: "Maj", selected: false, price: 0, id: 11, disabled: false },
  { name: "Jun", selected: false, price: 0, id: 12, disabled: false },
];

function RateCalculator({
  priceSum,
  customFirstRate,
  setError,
  setErrorMessage,
  changeRatePrice,
  setMaxNumberOfRates,
  setScrollView,
}) {
  const [monthsData, setMonthsData] = useState(monthsDataInitial);
  const [numberOfRates, setnumberOfRates] = useState(1);

  const context = useContext(Context);

  const updateContext = (rates, numberOfRates) => {
    context.saveRatesData({ rates, numberOfRates });
  };

  // Remove or add selection and price
  const handleMonthSelection = (monthName) => {
    if (priceSum < 1 || isNaN(priceSum)) {
      setScrollView();
      return setTimeout(() => {
        // Come back: need Danish text
        setErrorMessage("You need to select a forløb first.");
        setError(true);
      }, 500);
    }
    // If user selected a disabled month, show a popup
    for (let i = 0; i < monthsData.length; i++) {
      if (monthsData[i].name === monthName && monthsData[i].disabled) {
        // Come back: need danish text
        setErrorMessage("Cannot select past rates.");
        return setError(true);
      }
    }

    let newnumberOfRates = numberOfRates;
    const monthsDataCopy = [...monthsData];

    const selectedMonths = monthsData.filter(
      (month) => month.selected === true
    );

    monthsDataCopy.forEach((month) => {
      if (month.name === monthName) {
        if (month.selected) {
          //There should be at least one month selected
          if (selectedMonths.length === 1) {
            setError(true);
            setErrorMessage("You need to select at least 1 month.");
          } else {
            month.selected = false;
            newnumberOfRates = numberOfRates - 1;
          }
        } else {
          month.selected = true;
          newnumberOfRates = numberOfRates + 1;
        }
      }
    });

    const updatedRates = updatePrices(
      monthsDataCopy,
      priceSum,
      newnumberOfRates,
      Number(customFirstRate)
    );

    setMonthsData(updatedRates);
    setnumberOfRates(newnumberOfRates);
    updateContext(updatedRates, newnumberOfRates);
  };

  const countEnabledMonths = (monthsArr) => {
    let count = 0;
    for (let i = 0; i < monthsArr.length; i++) {
      if (!monthsArr[i].disabled) {
        count++;
      }
    }
    return count;
  };

  // Add sumprice to first month
  const initialPriceUpdate = () => {
    // If the signup is for this current semester, we need to disable the months, which have passed
    // Come back: This should be not hardcoded! This should come from some env file, and set to true, if we open for the next
    // Season's registration
    const isSignupForThisSeason = true;
    if (isSignupForThisSeason) {
      // We need to find the current month, that will be the start month.
      const now = moment(Date.now());
      const thisMonthRaw = moment(now).format("MMM");
      const thisMonth = capitalize(thisMonthRaw);
      // Find the index current month in the modifiedMOnthsArr
      const currentMonthObjInArr = monthsData.find(
        (month) => month.name === thisMonth
      );
      const indexOfCurrentMonth = monthsData.indexOf(currentMonthObjInArr);
      // Need to disable all the other months , which is before that index
      for (let i = 0; i < indexOfCurrentMonth; i++) {
        monthsData[i].disabled = true;
      }
      monthsData[indexOfCurrentMonth].price = priceSum;
      monthsData[indexOfCurrentMonth].selected = true;
      setMaxNumberOfRates(countEnabledMonths(monthsData));
    } else {
      monthsData[0].price = priceSum;
      monthsData[0].selected = true;
    }
    setMonthsData(monthsData);
    updateContext(monthsData, numberOfRates);
  };

  useEffect(() => {
    initialPriceUpdate();
    // This effect should be equivalent to componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Number of rates coming from parent is changed, update
  useEffect(() => {
    updateSelection(
      monthsData,
      numberOfRates,
      setMonthsData,
      priceSum,
      Number(customFirstRate)
    );
    //  Eslint complains about not adding monthsData as dependency. But if I add it, then it becomes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfRates, customFirstRate, priceSum]);

  // Get rates from context
  useEffect(() => {
    if (context.state.ratesData) {
      const ratesData = context.state.ratesData.rates;
      const numberOfRates = context.state.ratesData.numberOfRates;
      if (ratesData) {
        if (ratesData.length > 0) {
          setnumberOfRates(numberOfRates);
          setMonthsData(ratesData);
        }
      }
    }
  }, [context.state.ratesData]);

  useEffect(() => {
    // If monthsdata changes, update rate price too
    changeRatePrice(monthsData);
  }, [monthsData, changeRatePrice]);

  return (
    <Frame>
      <RowContainer display="flex">
        {document.body.offsetWidth < 1120
          ? getSpecificMonths(1, 3, monthsData).map((month) => (
              <MonthBox
                key={month.id}
                monthName={month.name}
                price={month.price}
                selected={month.selected}
                handleMonthSelection={handleMonthSelection}
                isDisabled={month.disabled}
              />
            ))
          : getSpecificMonths(1, 4, monthsData).map((month) => (
              <MonthBox
                key={month.id}
                monthName={month.name}
                price={month.price}
                selected={month.selected}
                handleMonthSelection={handleMonthSelection}
                isDisabled={month.disabled}
              />
            ))}
      </RowContainer>

      <RowContainer display="flex">
        {document.body.offsetWidth < 1120
          ? getSpecificMonths(4, 6, monthsData).map((month) => (
              <MonthBox
                key={month.id}
                monthName={month.name}
                price={month.price}
                selected={month.selected}
                handleMonthSelection={handleMonthSelection}
                isDisabled={month.disabled}
              />
            ))
          : getSpecificMonths(5, 8, monthsData).map((month) => (
              <MonthBox
                key={month.id}
                monthName={month.name}
                price={month.price}
                selected={month.selected}
                handleMonthSelection={handleMonthSelection}
                isDisabled={month.disabled}
              />
            ))}
      </RowContainer>

      <RowContainer display="flex">
        {document.body.offsetWidth < 1120
          ? getSpecificMonths(7, 9, monthsData).map((month) => (
              <MonthBox
                key={month.id}
                monthName={month.name}
                price={month.price}
                selected={month.selected}
                handleMonthSelection={handleMonthSelection}
                isDisabled={month.disabled}
              />
            ))
          : getSpecificMonths(9, 12, monthsData).map((month) => (
              <MonthBox
                key={month.id}
                monthName={month.name}
                price={month.price}
                selected={month.selected}
                handleMonthSelection={handleMonthSelection}
                isDisabled={month.disabled}
              />
            ))}
      </RowContainer>

      {document.body.offsetWidth < 1120 && (
        <RowContainer display="flex">
          {getSpecificMonths(10, 12, monthsData).map((month) => (
            <MonthBox
              key={month.id}
              monthName={month.name}
              price={month.price}
              selected={month.selected}
              handleMonthSelection={handleMonthSelection}
              isDisabled={month.disabled}
            />
          ))}
        </RowContainer>
      )}
    </Frame>
  );
}

export default RateCalculator;
