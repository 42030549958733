import {
    getAllChildrenInReg,
    getChildInRegFlow,
    getLatestRegInformation,
    returnAuthenticated
} from '../../../context/getData';

// Calculate prices for each priority
const addPriceSum = (priorities, processPrice) => {
    const numberLessons = !priorities.number_lessons_left
        ? 33
        : priorities.number_lessons_left;

    priorities.forEach((prio) => {
        // for now there is no data coming from server as number lessons left, so I code it to 42
        const priceSum = numberLessons * processPrice;
        if (priceSum && !isNaN(priceSum)) {
            prio.priceSum = priceSum;
        } else {
            prio.priceSum = undefined; // display text in 'Der er sket en fejl' in checkout;
        }
    });
    return priorities;
};

export const applyPriorities = (
    priorities,
    process,
    setFirstPrios,
    setSecondPrios,
    setCourseName
) => {
    const firstPrio = priorities.filter(
        (prio) => prio.type === 'first priority'
    );
    const secondPrio = priorities.filter(
        (prio) => prio.type === 'second priority'
    );
    const firstPrioCourses = firstPrio.map((prio) => prio.course);
    const secondPrioCourses = secondPrio.map((prio) => prio.course);

    setFirstPrios(addPriceSum(firstPrioCourses, process.price));
    setSecondPrios(addPriceSum(secondPrioCourses, process.price));

    // By assuming that there is only one type of course that a child can apply to, the first course from first priorities is used as course name.
    if (firstPrioCourses.length < 1) {
        setCourseName(secondPrioCourses[0].name);
    } else {
        setCourseName(firstPrioCourses[0].name);
    }
};

export const applyChildName = (info, setChildName) => {
    if (info.childFirstname) {
        setChildName(info.childFirstname);
    }
};

export const applyProcess = (process, setProcessType) => {
    if (process.title) {
        setProcessType(process.title);
    }
};

export const applyProducts = (products, setProducts) => {
    if (products) {
        if (products.length > 0) {
            setProducts(products);
        }
    }
};

// Get order and user info from local storage
export const getOrderFromStorage = (
    setEmail,
    setAllChildrenData,
    // setRatesData,
    // setWebshopItems,
    setError,
    setErrorMessage,
    redirectLink,
    props,
    context
) => {
    // If user unauthenticated and not having any children in allChildrenInReg, something is wrong, we send them back to the beginning of the flow.
    if (!returnAuthenticated() || !getAllChildrenInReg()) {
        props.history.push(redirectLink);
    } else {
        // Load children data
        const childInRegFlow = getChildInRegFlow();
        console.log('show children data', childInRegFlow);
        // const ratesData = getRatesData();
        if (childInRegFlow) {
            setAllChildrenData([childInRegFlow]);
        } else {
            setError(true);
            setErrorMessage(
                'Kunne ikke indlæse ordrer, eller du har ikke tilføjet et barn. Prøv igen, eller kontakt os.'
            );
        }

        // if (ratesData) {
        //   setRatesData(ratesData);
        // }

        // if (webshopItems) {
        //   setWebshopItems(webshopItems);
        // }

        const regInformation = getLatestRegInformation();
        if (regInformation && regInformation.email) {
            setEmail(regInformation.email);
        }
    }
};

export const getChildname = (childData) => {
    if (childData && childData.childFirstname && childData.childSurname) {
        return childData.childFirstname + ' ' + childData.childSurname;
    } else {
        return 'Kunne ikke indlæse navnet. Kontakt os venligst.';
    }
};

export const getCourseName = (childData) => {
    if (childData && childData.priorities && childData.priorities.length > 0) {
        if (
            childData.priorities[0].course &&
            childData.priorities[0].course.name
        ) {
            return childData.priorities[0].course.name;
        } else {
            return 'Kunne ikke indlæse kursustypen. Kontakt os venligst.';
        }
    } else {
        return 'Kunne ikke indlæse kursustypen. Kontakt os venligst.';
    }
};

export const getProcessType = (childData) => {
    if (childData && childData.process && childData.process.title) {
        return childData.process.title;
    } else {
        return 'Kunne ikke indlæse kursustypen. Kontakt os venligst.';
    }
};

export const getFirstPriorities = (childData, processPrice) => {
    if (childData.priorities && childData.priorities.length > 0) {
        const firstPrio = childData.priorities.filter(
            (prio) => prio.type === 'first priority'
        );
        if (firstPrio && firstPrio.length > 0) {
            const firstPrioCourses = firstPrio.map((prio) => prio.course);
            return addPriceSum(firstPrioCourses, processPrice);
        }
    }
};

export const getSecondPriorities = (childData, processPrice) => {
    if (childData.priorities && childData.priorities.length > 0) {
        const secondPrio = childData.priorities.filter(
            (prio) => prio.type === 'second priority'
        );
        if (secondPrio && secondPrio.length > 0) {
            const secondPrioCourses = secondPrio.map((prio) => prio.course);
            return addPriceSum(secondPrioCourses, processPrice);
        }
    }
};

export const removeChildFromAllChildrenArr = (
    selectedChild,
    allChildrenData,
    context
) => {
    const filteredAllChildren = allChildrenData.filter(
        (child) => child !== selectedChild
    );
    context.saveAllChildrenInReg(filteredAllChildren);
};
