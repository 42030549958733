import {
    getChildInRegFlow,
    getLatestChildInRegFlow,
    getToken,
    getUserId
} from '../../../context/getData';
import { removeParticipantIdFromStorage } from '../GlobalRegistrationFunctions';
import { getAndReturnDataWithAuth } from '../../../context/helperFunctions';

const mergeArrays = (arr1, arr2) => {
    // Remove last item
    const lastItem = arr1.pop();
    const mergedArrays = [...arr1, ...arr2];
    // Add last item back
    mergedArrays.push(lastItem);
    return mergedArrays;
};

const assignFamiliesToSelect = async (families, setFamiliesArr) => {
    // Come back: danish text
    // WARNING!!!! If you change the text here in the name or in the value, you also need to
    // change it in the rest of the file!!
    const familyNamesAndIds = [
        { name: 'Vælg familie', value: 'not selected' },
        { name: 'Tilføj ny familie', value: 'new family' }
    ];
    families.forEach((fam) => {
        const famObject = {
            name: fam.familyName,
            value: fam.groupId
        };
        familyNamesAndIds.push(famObject);
    });

    setFamiliesArr(familyNamesAndIds);
};

const getFamilies = async (handleError, setIsLoading) => {
    const families = await returnParticipants(
        getUserId(),
        handleError,
        setIsLoading
    );
    return families;
};

export const createListOfChildrenAndRecoverData = async (
    options,
    context,
    contentRef,
    childInRegFlow,
    setDay,
    setMonth,
    setYear,
    setChildFirstname,
    setChildSurname,
    setNewFamilyName,
    setSelectedChild,
    isAuthenticated,
    handleError,
    setChildrenOfUser,
    setOptions,
    setIsLoading,
    setChildFetchingError,
    setInitialChildObj,
    setSelectedFamilyObj,
    setFamiliesArr
) => {
    // Check if there is a childInRegFlow. If there is, we need to recover the data in relation to her.
    // Save the childInRegFlow as initial child object - it will be used to compare whether the data changed or not.
    if (childInRegFlow) {
        setInitialChildObj(childInRegFlow);
    }
    // Get list of user's children and recover data if user is authenticated
    if (isAuthenticated) {
        // Get families
        const families = await getFamilies(handleError, setIsLoading);

        // Check if there are any participant id-s related to current user
        if (families && families.length > 0) {
            assignFamiliesToSelect(families, setFamiliesArr);
            // Need to get participants from the families arr
            const participants = [];
            families.forEach((fam) => {
                for (let i = 0; i < fam.participants.length; i++) {
                    fam.participants[i].familyGroupName = fam.familyName;
                    participants.push(fam.participants[i]);
                }
            });

            // Check if we got any data from api, if yes, recover data without the list of children.
            // if (returnAuthenticated()) {
            if (participants && participants.length > 0) {
                // We know that we got some data from api, but we should also check whether it is an empty array
                // if (Object.keys(participants[0]).length > 0) {
                // if there is actually information about the children, recover user info
                const participantsModified = participants.map((part) => ({
                    value: part.id,
                    title: part.firstname + ' ' + part.surname
                }));
                setChildrenOfUser(participants);
                setOptions(mergeArrays(options, participantsModified));
                recoverData(
                    context,
                    mergeArrays(options, participantsModified),
                    setDay,
                    setMonth,
                    setYear,
                    setChildFirstname,
                    setChildSurname,
                    setNewFamilyName,
                    setSelectedChild,
                    setIsLoading,
                    isAuthenticated,
                    contentRef,
                    setChildFetchingError,
                    setSelectedFamilyObj
                );
            }
        } else {
            // If there are no participants related to the current user, we should set the selected option to add new child + recover data
            recoverData(
                context,
                'new child',
                setDay,
                setMonth,
                setYear,
                setChildFirstname,
                setChildSurname,
                setNewFamilyName,
                setSelectedChild,
                setIsLoading,
                isAuthenticated,
                contentRef,
                setChildFetchingError,
                setSelectedFamilyObj
            );
        }
    } else {
        // If user is not authenticated, recover if there is any data, but no need to load select option.
        recoverData(
            context,
            [],
            setDay,
            setMonth,
            setYear,
            setChildFirstname,
            setChildSurname,
            setNewFamilyName,
            setSelectedChild,
            setIsLoading,
            isAuthenticated,
            contentRef,
            setChildFetchingError,
            setSelectedFamilyObj
        );
    }
};

// If user is logged in, and has a child that is already attending a class, they can select those children, and reinroll them.
export const handleReenrollmentSubmit = (
    props,
    selectedChild,
    childrenOfUser,
    error,
    context,
    setError,
    setErrorMessage
) => {
    if (selectedChild === 'select') {
        // Throw error as they must select an option
        setError(true);
        setErrorMessage('Du skal vælge en eksisterende eller nyt bar');
    } else {
        let childInRegFlow = getChildInRegFlow() ? getChildInRegFlow() : {};

        if (
            !error &&
            selectedChild &&
            selectedChild !== 'select' &&
            selectedChild !== 'new child'
        ) {
            // Get selected child's name, and birthdate. Save that to the storage.
            const selectedChildInfo = childrenOfUser.find(
                (child) => child.id === selectedChild
            );
            if (
                selectedChildInfo &&
                selectedChildInfo.firstname &&
                selectedChildInfo.surname &&
                selectedChildInfo.birthdate &&
                selectedChildInfo.id
            ) {
                childInRegFlow.birthdate = selectedChildInfo.birthdate;
                childInRegFlow.childFirstname = selectedChildInfo.firstname;
                childInRegFlow.childSurname = selectedChildInfo.surname;
                childInRegFlow.id = selectedChildInfo.id;
                childInRegFlow.selectedChildOption = selectedChild;
                childInRegFlow.selectedFamilyObjLabel = '';
                childInRegFlow.selectedFamilyObjValue = '';
                childInRegFlow.familyGroupName = '';
                childInRegFlow.familyGroupId = '';

                context.saveChildInRegFlow(childInRegFlow);
                props.history.push('/tilmelding/vaelg-hold');
            }
        }
    }
};

const saveChildToContext = (
    initialChildObject,
    childInRegFlow,
    birthdate,
    childFirstname,
    childSurname,
    selectedChild,
    context,
    props,
    newFamilyName,
    selectedFamilyObj
) => {
    if (selectedChild && selectedChild !== 'select') {
        childInRegFlow.selectedChildOption = selectedChild;
    }
    childInRegFlow.selectedFamilyObjLabel = selectedFamilyObj.label;
    childInRegFlow.selectedFamilyObjValue = selectedFamilyObj.value;

    if (selectedFamilyObj.label === 'existing family') {
        childInRegFlow.familyGroupName = '';
        childInRegFlow.familyGroupId = Number(selectedFamilyObj.value);
    } else {
        childInRegFlow.familyGroupName = newFamilyName;
        childInRegFlow.familyGroupId = '';
    }

    // Check if the initialChildObj is empty. If it is, user is trying to add a new child, all good. But if it has value, we need to compare
    // if the name, birthdates and select option are the same as in the states. If they are not, we need to delete selectedMeetings, priorities and rates from
    // childInRegFlow. If they are the same, we can just take user to next page.
    if (
        Object.keys(initialChildObject).length > 1 &&
        initialChildObject &&
        initialChildObject.childFirstname &&
        initialChildObject.childSurname &&
        initialChildObject.birthdate
    ) {
        // Compare names, birthdate
        if (
            initialChildObject.childSurname !== childSurname ||
            initialChildObject.childFirstname !== childFirstname ||
            initialChildObject.birthdate !== birthdate
        ) {
            // They are not matching, so delete selectedMeetings, priorities, rates from childInRegFlow
            childInRegFlow.priorities = [];
            childInRegFlow.process = {};
            childInRegFlow.rates = {};
            childInRegFlow.selectedMeetings = undefined;
        }
    }

    childInRegFlow.birthdate = birthdate;
    childInRegFlow.childFirstname = childFirstname;
    childInRegFlow.childSurname = childSurname;

    context.saveChildInRegFlow(childInRegFlow);

    props.history.push('/tilmelding/vaelg-hold');
};

export const onSubmit = (
    isAuthenticated,
    selectedChild,
    selectedFamilyObj,
    error,
    birthdate,
    context,
    firstnameInputError,
    surnameInputError,
    familyNameInputError,
    childFirstname,
    childSurname,
    newFamilyName,
    initialChildObject,
    props,
    setFirstnameInputError,
    setFirstnameInputErrorText,
    setSurnameInputError,
    setSurnameInputErrorText,
    setFamilyNameError,
    setError,
    setErrorMessage
) => {
    let childInRegFlow = getChildInRegFlow() ? getChildInRegFlow() : {};

    // Check if there is user id saved in storage, because if there is, the unauthenticated user
    // is trying to create a new user which already exists (they must have) filled the flow before as authenticated, but logged out, and now they try again
    // The system remembers that the flow has been filled, however we need to prompt the user to log in, add a different child, because it already exists.
    // Lilla's comment: What about if the user cleared cache? Or new device? We must check it with the database if the child exists there.
    if (childInRegFlow && childInRegFlow.participantId && !isAuthenticated) {
        setError(true);
        // Come back: danish text
        setErrorMessage(
            'Dette barn er allerede tilmeldt. Log ind for at gentilmelde.'
        );
    } else {
        // If user selected create new family, but didn't give a name, throw error
        if (
            (selectedFamilyObj.value === 'new family' &&
                newFamilyName === '') ||
            familyNameInputError
        ) {
            // Come back: Danish text
            setError(true);
            setErrorMessage('Family name is missing or invalid');
            return setFamilyNameError();
        }

        // Save birthdate and full name
        if (
            !error &&
            !firstnameInputError &&
            !surnameInputError &&
            childFirstname !== '' &&
            childSurname !== ''
        ) {
            saveChildToContext(
                initialChildObject,
                childInRegFlow,
                birthdate,
                childFirstname,
                childSurname,
                selectedChild,
                context,
                props,
                newFamilyName,
                selectedFamilyObj
            );
        } else {
            setError(true);
            setErrorMessage('Du skal indtaste dit barns fornavn og efternavn.');
            if (childFirstname === '') {
                setFirstnameInputError(true);
                setFirstnameInputErrorText('Krævet');
            }

            if (childSurname === '') {
                setSurnameInputError(true);
                setSurnameInputErrorText('Krævet');
            }
        }
        // }
    }
};

// Fired on component did mount to check if we have any saved data
// in local storage. If we do, we need to load it from there
export const recoverData = (
    context,
    options,
    setDay,
    setMonth,
    setYear,
    setChildFirstname,
    setChildSurname,
    setNewFamilyName,
    setSelectedChild,
    setIsLoading,
    isAuthenticated,
    contentRef,
    setChildFetchingError,
    setSelectedFamilyObj
) => {
    // Check if data is expired
    const childInRegFlow = getLatestChildInRegFlow(context);
    if (childInRegFlow) {
        if (childInRegFlow.birthdate) {
            //Get and set info if already exists in local storage/cookies
            const day = childInRegFlow.birthdate.slice(8, 10);
            const month = childInRegFlow.birthdate.slice(5, 7);
            const year = childInRegFlow.birthdate.slice(0, 4);
            setDay(day);
            setMonth(month);
            setYear(year);
        }
        // Recover childFirstname if it is in context
        if (
            childInRegFlow.childFirstname &&
            childInRegFlow.childFirstname !== '' &&
            childInRegFlow.childSurname &&
            childInRegFlow.childSurname !== ''
        ) {
            setChildFirstname(childInRegFlow.childFirstname);
            setChildSurname(childInRegFlow.childSurname);
        }

        if (
            childInRegFlow.familyGroupName &&
            childInRegFlow.familyGroupName !== ''
        ) {
            setNewFamilyName(childInRegFlow.familyGroupName);
        }

        if (
            childInRegFlow.selectedFamilyObjLabel &&
            childInRegFlow.selectedFamilyObjValue
        ) {
            setSelectedFamilyObj({
                label: childInRegFlow.selectedFamilyObjLabel,
                value: childInRegFlow.selectedFamilyObjValue
            });
        }

        // Recover selectChild if user is authenticated
        if (options) {
            // Check if user has any children at all
            if (options === 'new child') {
                // There are no children for user, set the selected option to new child
                setSelectedChild('new child');
            } else if (options.length > 0 && options !== 'new child') {
                if (
                    childInRegFlow.selectedChildOption === 'select' ||
                    childInRegFlow.selectedChildOption === 'new child'
                ) {
                    // If childInRegFlow has select or new child saved as option, load that
                    setSelectedChild(childInRegFlow.selectedChildOption);
                } else {
                    // If the user have children, and there is something in the saved data, load that, or load 'select'
                    if (
                        childInRegFlow.selectedChildOption &&
                        options &&
                        options.length > 0
                    ) {
                        const selectedOption = options.find(
                            (option) =>
                                option.value ===
                                childInRegFlow.selectedChildOption
                        );
                        if (selectedOption && selectedOption.value) {
                            setSelectedChild(selectedOption.value);
                        } else {
                            setSelectedChild('select');
                        }
                    }
                }
            } else {
                // If there are no related participants to user, we should get an empty array in options. In this case, we should set the selected option (selectedChild) to add new chiild.
                setSelectedChild('new child');
            }
        } else {
            // Make sign to user that there was problem with fetching data
            setChildFetchingError(true);
        }
    } else {
        // If there is no registration data available, set the select element to vælg barn, nothing to recover
        if (options) {
            setSelectedChild('new child');
        } else {
            // If there is problem with fetching data, display error message
            setSelectedChild('new child');
            setChildFetchingError(true);
        }

        // If user is not authenticated, then they must give a new family name.
        setSelectedFamilyObj({
            label: 'Tilføj ny familie',
            value: 'new family'
        });
    }

    setIsLoading(false);

    if (contentRef && contentRef.current) {
        contentRef.current.scrollIntoView();
    }
};

// When user changes any of the date pickers, we need to make sure that participantId is removed
export const setDayRemoveId = (setDay, context) => {
    removeParticipantIdFromStorage(context);
    return setDay;
};

export const returnParticipants = async (userId, handleError, setIsLoading) => {
    const familiesData = await getAndReturnDataWithAuth(
        `/api/v1/user/${userId}/participant`,
        getToken(),
        handleError
    );

    if (familiesData && familiesData.families) {
        return familiesData.families;
    } else {
        handleError();
        setIsLoading(false);
    }
};
