import React from "react";
import styled from "styled-components";

import { Box } from "@material-ui/core";

const ExplainerTextContainerComponent = styled(Box)`
  width: 45%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
    margin-bottom: 3rem;
  }
`;

function ExplainerTextContainer({ children }) {
  return (
    <ExplainerTextContainerComponent>
      {children}
    </ExplainerTextContainerComponent>
  );
}

export default ExplainerTextContainer;
