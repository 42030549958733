import React from "react";
import HeaderComponentContainer from "../../containers/header/HeaderComponentContainer";
import NavHeaderDesktop from "../nav-header/NavHeaderDesktop";
import NavHeaderMobile from "../nav-header/NavHeaderMobile";
import ReusableHeaderBackgroundMobile from "./ReusableHeaderBackgroundMobile";

function ReusableHeader({ imageUrl, bgPosition }) {
  return (
    <React.Fragment>
      <NavHeaderMobile />
      <ReusableHeaderBackgroundMobile
        imageUrl={imageUrl}
        bgPosition={bgPosition}
      />
      <HeaderComponentContainer imageUrl={imageUrl}>
        <NavHeaderDesktop />
      </HeaderComponentContainer>
    </React.Fragment>
  );
}

export default ReusableHeader;
