import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { getUserId } from '../../context/getData';

import {
    getCompanions,
    //  getColor,
    //  getCompanions,
    getParticipants,
    getMeetingsOfUser,
    getUserInfo,
    isCurrentUser
} from './MyPageFunctions';
import { Box, Typography } from '@material-ui/core';
import NavHeaderDesktop from '../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../components/headers/nav-header/NavHeaderMobile';

import RegularHeadline from '../../components/headlines/RegularHeadline';
// import ParticipantComponent from "../../components/participant/ParticipantComponent";
import HorizontalLine from '../../components/horizontal-line/HorizontalLine';
import ParentComponent from '../../components/parent/ParentComponent';
import RegularLink from '../../components/links/RegularLink';

import Loader from '../../components/loader/Loader';
import RegularButton from '../../components/buttons/RegularButton/RegularButton';
import { overridings } from '../../themes/DarkTheme';
import AddParentDialog from '../../components/dialogs/add-parent-dialog/AddParentDialog';
import ErrorDialog from '../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobileContainer from '../../components/containers/header/NavHeaderMobileContainer';
import FamilyComponent from '../../components/family/FamilyComponent';

import { sendRenewCardOrder } from '../../containers/registration/checkout/CheckoutSubmitFunctions';
import UpcomingMeetingRow from '../../components/upcoming-meeting-row/UpcomingMeetingRow';
import ChangePaymentEmail from '../../components/change-payment-email/ChangePaymentEmail';

const InfoContainer = styled.div`
    margin: 3rem 0;
    padding: 3rem 0 4rem 0;
    background: ${(props) => props.theme.palette.secondary.main};
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: 2rem;
        margin-top: 0;
        padding-top: 2rem;
    }
`;

const UpcomingMeetingsContainer = styled(Box)`
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const InfoContentContainer = styled(Box)`
    width: 85%;
    margin: 0 auto;
`;

const ChildrenContainer = styled(Box)`
    width: 40%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: 4rem;
        width: 100%;
    }
`;

const ParentsContainer = styled(Box)`
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const ParentNameContainer = styled(Box)`
    width: 35%;
`;

const RelationContainer = styled(Box)`
    width: 30%;
`;

const ReadinessContainer = styled(Box)`
    width: 35%;
`;

const CustomHeadline = styled(Typography)`
    font-size: 1.5rem;
    cursor: pointer;
`;

const NameHeadlineContainer = styled(Box)`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const OpenDoorButtonContainer = styled(Box)`
    width: 18%;
    min-width: 10rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 50%;
        max-width: 10rem;
        margin-top: 3rem;
        margin-bottom: 4rem;
    }
`;

const MeetingTypeText = styled(Box)`
    width: 150px;
`;
const DateText = styled(Box)`
    width: 150px;
`;
const TimeText = styled(Box)`
    width: 200px;
`;
const LocationText = styled(Box)`
    width: 100px;
`;

const EditText = styled(Box)`
    width: 50px;
`;

const DesktopContainer = styled(Box)`
    display: block;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const MeetingsHeadlineContainerDesktop = styled(Box)`
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const IntroMeetingPromptContainer = styled(Box)`
    margin-bottom: 2rem;
    color: ${(props) => props.theme.palette.error.main};
`;

const MyPage = (props) => {
    const [userId, setUserId] = useState(null);
    const [userFirstname, setUserFirstname] = useState('');
    const [userSurname, setUserSurname] = useState('');
    // eslint-disable-next-line
    const [companions, setCompanions] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [families, setFamilies] = useState([]);
    const [upcomingMeetings, setUpcomingMeetings] = useState([]);
    const [pastMeetings, setPastMeetings] = useState([]);
    const [promptIntroMeeting, setPromptIntroMeeting] = useState(true);
    // eslint-disable-next-line
    const { url } = useRouteMatch();

    // Make a GET request to get information about the current user - it is hardcoded currently
    // eslint-disable-next-line
    const onDoorOpenerClick = () => {
        console.log('opening door');
    };
    const handlePaymentForm = async (participantId) => {
        let response = await sendRenewCardOrder(
            // allChildrenData,
            getUserId(),
            participantId, // childId
            'subscription',
            // 5280,
            'DKK',
            '',
            // ratesData.rates,
            // formRef,
            // webshopItems,
            setError,
            setErrorMessage
            // setIsDisabled
        );
        console.log('RESPONSE:', response.error);
        if (
            response.error ===
            'Bad request. This child has no active payment transaction.'
        ) {
            setErrorMessage('Dette barn har ingen aktiv betalingstransaktion.');
            setError(true);
        } else if (!response || !response.params) {
            setErrorMessage('Noget gik galt. Prøv igen.');
            setError(true);
        } else {
            let form = document.getElementById('quickpayform');
            for (let key in response.params) {
                let input = document.createElement('INPUT');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', key);
                input.setAttribute('value', response.params[key]);
                form.appendChild(input);
            }
            form.submit();
        }
    };
    const handleError = () => {
        setError(true);
        // Come back: danish text
        setErrorMessage('Something went wrong. Try again.');
    };

    const closeErrorDialog = () => {
        setError(false);
        setErrorMessage('');
        // Come back: is it okay to refresh the page?
        // Refresh page
        window.location.reload();
    };

    const handleAddParentClick = () => {
        setDialogOpen(true);
    };

    const handleEditProfileClick = () => {
        props.history.push('/rediger-profil');
    };

    // ComponentDidMount
    useEffect(() => {
        setIsLoading(false);
        // Get user id from storage
        console.log('getuser', getUserId());
        const userId = getUserId();
        setUserId(userId);

        if (userId !== '') {
            // Get information of user
            getUserInfo(
                userId,
                setUserFirstname,
                setUserSurname,
                handleError,
                setIsLoading
            );
            getParticipants(userId, setFamilies, handleError, setIsLoading);
            getCompanions(userId, setCompanions, handleError, setIsLoading);
            getMeetingsOfUser(
                userId,
                setUpcomingMeetings,
                setPastMeetings,
                handleError,
                setPromptIntroMeeting
            );
            // setIsLoading(false);
            // Get list of children of user
        } else {
            // Log user out, because something is wrong
            // // context.onLogout();
            console.log('no userid!!');
        }

        // const participantData = postAndReturnData(`/Participants/${participantId}`)
    }, []);

    return (
        <React.Fragment>
            <React.Fragment>
                <NavHeaderMobileContainer>
                    <NavHeaderMobile />
                </NavHeaderMobileContainer>

                <NavHeaderDesktop />
            </React.Fragment>
            <InfoContainer>
                <InfoContentContainer>
                    <NameHeadlineContainer>
                        <Box mb="2rem">
                            <CustomHeadline
                                component="h1"
                                onClick={handleEditProfileClick}
                            >{`${userFirstname} ${userSurname}'s side`}</CustomHeadline>
                            <RegularLink
                                title="Rediger profil"
                                onClick={handleEditProfileClick}
                            />
                        </Box>
                        <OpenDoorButtonContainer>
                            <RegularButton
                                title="Åbn døren"
                                onButtonClick={onDoorOpenerClick}
                                background={overridings.palette.colors.purple}
                            />
                        </OpenDoorButtonContainer>
                        <OpenDoorButtonContainer>
                            {/* <RegularButton
                title="Renew blocked card"
                onButtonClick={handlePaymentForm}
                background={overridings.palette.colors.purple}
              /> */}
                        </OpenDoorButtonContainer>
                    </NameHeadlineContainer>

                    <RegularLink
                        title="Betalingsoversigt"
                        onClick={() => props.history.push('/okonomioverblik')}
                    />

                    {promptIntroMeeting && (
                        <IntroMeetingPromptContainer>
                            <Typography variant="body2">
                                You haven't been to an intro meeting. Sign up{' '}
                                <UnderlinedText
                                    onClick={() =>
                                        props.hisotry.push(
                                            '/tilmelding/vaelg-intromoede'
                                        )
                                    }
                                >
                                    here.
                                </UnderlinedText>
                            </Typography>
                        </IntroMeetingPromptContainer>
                    )}

                    <ChildrenContainer>
                        <RegularHeadline
                            title="Børn"
                            component="h2"
                            mobileMarginBottom="1.5rem"
                        />
                        {families && families.length > 0 ? (
                            families.map((f) => (
                                <FamilyComponent
                                    family={f}
                                    key={f.groupId}
                                    handlePaymentForm={handlePaymentForm}
                                />
                            ))
                        ) : (
                            <Typography variant="body2">
                                There are no children enrolled.
                            </Typography>
                        )}
                    </ChildrenContainer>

                    {upcomingMeetings.length > 0 && (
                        <UpcomingMeetingsContainer mt="3rem">
                            <RegularHeadline
                                title="Dine kommende møder"
                                component="h2"
                                mobileMarginBottom="1.5rem"
                            />

                            <MeetingsHeadlineContainerDesktop>
                                <MeetingTypeText>
                                    <Typography variant="body2">
                                        Møde
                                    </Typography>
                                </MeetingTypeText>
                                <DateText>
                                    <Typography align="center" variant="body2">
                                        Dato
                                    </Typography>
                                </DateText>
                                <TimeText>
                                    <Typography align="center" variant="body2">
                                        Tid
                                    </Typography>
                                </TimeText>
                                <LocationText>
                                    <Typography align="center" variant="body2">
                                        Lokale
                                    </Typography>
                                </LocationText>
                                <EditText>
                                    <Typography align="right" variant="body2">
                                        Annuller
                                    </Typography>
                                </EditText>
                            </MeetingsHeadlineContainerDesktop>
                            <DesktopContainer>
                                <HorizontalLine
                                    marginTop="1rem"
                                    marginBottom="1rem"
                                />
                            </DesktopContainer>

                            {upcomingMeetings.map((meeting) => (
                                <UpcomingMeetingRow
                                    key={meeting['Event.id']}
                                    meetingData={meeting}
                                    handleError={handleError}
                                    setUpcomingMeetings={setUpcomingMeetings}
                                    setPastMeetings={setPastMeetings}
                                    setIsLoading={setIsLoading}
                                />
                            ))}
                        </UpcomingMeetingsContainer>
                    )}

                    <ParentsContainer mt="3rem">
                        <RegularHeadline
                            title="Følgevoksne"
                            component="h2"
                            mobileMarginBottom="1.5rem"
                        />
                        {/* If there are no other parents, dont show this part, only the link to add. */}
                        <Box display="flex">
                            <ParentNameContainer>
                                <Typography variant="body2">Navn</Typography>
                            </ParentNameContainer>
                            <RelationContainer>
                                {/* Come back: Danish text */}
                                <Typography variant="body2">
                                    Related to
                                </Typography>
                            </RelationContainer>
                            <ReadinessContainer>
                                <Typography variant="body2">
                                    Følgeklar
                                </Typography>
                            </ReadinessContainer>
                        </Box>

                        <HorizontalLine marginTop="1rem" marginBottom="1rem" />
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <React.Fragment>
                                {companions && companions.length > 0 ? (
                                    companions.map((c) => (
                                        <ParentComponent
                                            key={c.id}
                                            companionData={c}
                                            isCurrentUser={isCurrentUser(
                                                c.id,
                                                userId
                                            )}
                                        />
                                    ))
                                ) : (
                                    <Typography variant="body2">
                                        There are other companions.
                                    </Typography>
                                )}
                            </React.Fragment>
                        )}

                        <Box mt="2rem">
                            <RegularLink
                                title="+ Tilføj følgevoksen"
                                onClick={handleAddParentClick}
                            />
                        </Box>
                    </ParentsContainer>
                </InfoContentContainer>
            </InfoContainer>
            {dialogOpen && (
                <AddParentDialog
                    families={families}
                    close={() => setDialogOpen(false)}
                    userId={userId}
                />
            )}
            {/* Hidden form is needed for QuickPay payment */}
            <form
                id="quickpayform"
                action="https://payment.quickpay.net"
                method="POST"
            ></form>
            {error && (
                <ErrorDialog
                    close={closeErrorDialog}
                    errorMessage={errorMessage}
                />
            )}
        </React.Fragment>
    );
};

export default withRouter(MyPage);
