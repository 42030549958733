import React from "react";
import styled from "styled-components";

// Needed as a placeholder, since the navbar is set to fixed
const NavbarSpace = styled.div`
  height: 3.5rem;
`;

const BackgroundComp = styled.div`
height: ${(props) => props.height};
  min-height: 25rem;
  background-image: 
    url("${(props) => props.imageUrl}");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: top;
  position: relative;
`;

function HomeHeaderBackgroundMobile({ imageUrl, height }) {
  return (
    <div>
      <NavbarSpace />
      <BackgroundComp height={height} imageUrl={imageUrl} />
    </div>
  );
}

export default HomeHeaderBackgroundMobile;
