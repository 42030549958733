import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const ContentContainerComponent = styled(Box)`
  width: 85%;
  max-width: 1200px;
  margin: 4rem auto;
`;

function ContentContainer({ children }) {
  return <ContentContainerComponent>{children}</ContentContainerComponent>;
}

export default ContentContainer;
