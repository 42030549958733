import React, { useState, useEffect } from "react";
import {
  Route,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import styled from "styled-components";
// import { handleErrors } from "../HelperFunctions";

// Components
import { Grid
//  , Box, Typography 
} from "@material-ui/core";
import AboutHeadline from "../../components/headlines/AboutHeadline";
import Employee from "./Employee";
import EmployeeCard from "../../components/cards/EmployeeCard";
import EmployeeListContainer from "../../components/containers/about/EmployeeListContainer";

// CMS feature
import MessageList from "../../components/message-list";
// import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
// import { getMessage } from "../../components/message-list/MessageListHelper";

const EmployeesContainer = styled.div`
  width: 90%;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

function Employees({ getCurrentUrl }) {
  const [messageList, setMessageList] = useState([]);

  const location = useLocation();
  const { url } = useRouteMatch();
  const [isEmployeeSelected, setIsEmployeeSelected] = useState(false);
  const [otherEmployees, setOtherEmployees] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const returnToAllEmployees = () => {
    setIsEmployeeSelected(false);
  };

  useEffect(() => {
    // Check if there is an employee in the url
    if (
      location.pathname !== "/om-skolen/medarbejdere" &&
      location.pathname !== "/om-skolen/medarbejdere/"
    ) {
      // If there is an employee in the url, then set state
      setIsEmployeeSelected(true);
      //Get  name of employee
    } else {
      // otherwise all employees will be displayed
      setIsEmployeeSelected(false);
    }
  }, [setIsEmployeeSelected, location.pathname]);

  useEffect(() => {
    // Return current url to parent - current menu gets differnt styling
    getCurrentUrl(url, "Medarbejdere");

    // See api\data\employees.js data for structure expected by frontend component
    // Instead of calling /api/v1/employees to get employee and other
    // employees list, we call the message end point
    let teacherList = messageList
      .filter( message => message.title.startsWith('Underviser')||message.title.startsWith('underviser'))
      .map( message => {
        let name = message.name.substring('Employee '.length); 
        let urlName = name.toLowerCase().replaceAll(' ','_');
        let mailToIndex = message.html.indexOf('href="mailto:')+'href="mailto:'.length;
        let mailToEnd = message.html.indexOf('"',mailToIndex);
        let email = '';
        if (mailToIndex>0 && mailToEnd>0) {
          email = message.html.substring(mailToIndex,mailToEnd);
        }
        return {
          name,
          urlName,
          instrument: message.title,
          id: 1,
          imageUrl: message.text,
          color: "#DDDDDD",
          colorName: "green",
          email
        }
      });
    setTeachers(teacherList);

    let otherEmployeeList = messageList
      .filter( message => !(message.title.startsWith('Underviser')||message.title.startsWith('underviser')))
      .map( message => {
        let name = message.name.substring('Employee '.length); 
        let urlName = name.toLowerCase().replaceAll(' ','_');
        let mailToIndex = message.html.indexOf('href="mailto:')+'href="mailto:'.length;
        let mailToEnd = message.html.indexOf('"',mailToIndex);
        let email = '';
        if (mailToIndex>0 && mailToEnd>0) {
          email = message.html.substring(mailToIndex,mailToEnd);
        }
        return {
          name,
          urlName,
          instrument: message.title,
          id: 1,
          imageUrl: message.text,
          color: "#DDDDDD",
          colorName: "orange",
          email
        }

      });
      setOtherEmployees(otherEmployeeList);

/*
    //Make an http request to get employees data
    fetch("/api/v1/employees")
      .then(handleErrors)
      .then((resp) => resp.json())
      .then((data) => {
        setOtherEmployees(data.otherEmployees);
        setTeachers(data.teachers);
      })
      .catch((error) => {
        console.log(error);
      });
*/      
  }, [getCurrentUrl, url, messageList]);

  // console.log(teachers);

  return (
    <EmployeesContainer>
      <MessageList type="html" name="Employee%" state="final" change={setMessageList}/>

      {!isEmployeeSelected && (
        <React.Fragment>
          <AboutHeadline title="Medarbejdere" />

          <EmployeeListContainer>
            {teachers.length > 0 &&
              <React.Fragment>
                {teachers.map((employee,index) => (
                  <Grid item xs={12} sm={6} md={4} key={'employee_'+index+'_id_'+employee.id}>
                    <EmployeeCard
                      name={employee.name}
                      image={employee.imageUrl}
                      instrument={employee.instrument}
                      url={url}
                      setIsEmployeeSelected={setIsEmployeeSelected}
                      color={employee.color}
                      colorName={employee.colorName}
                      email={employee.email}
                      to={{
                        pathname: `${url}/${employee.urlName}`,
                        state: { from: { path: url, title: "Medarbejdere" } },
                      }}
                    />
                  </Grid>
                ))}
              </React.Fragment>
            }
          </EmployeeListContainer>

          <AboutHeadline title="Øvrige medarbejdere" />
          <EmployeeListContainer>
            {otherEmployees.length > 0 &&
              <React.Fragment>
                {otherEmployees.map((employee,index) => (
                  <Grid item xs={12} sm={6} md={4} key={'other_employee_'+index+'_id_'+employee.id}>
                    <EmployeeCard
                      name={employee.name}
                      image={employee.imageUrl}
                      instrument={employee.instrument}
                      url={url}
                      fromTitle="Medarbejdere"
                      setIsEmployeeSelected={setIsEmployeeSelected}
                      color={employee.color}
                      colorName={employee.colorName}
                      email={employee.email}
                      to={{
                        pathname: `${url}/${employee.urlName}`,
                        state: { from: { path: url, title: "Medarbejdere" } },
                      }}
                    />
                  </Grid>
                ))}
              </React.Fragment>
            }
          </EmployeeListContainer>
        </React.Fragment>
      )}

      <Route path={`${url}/:employeeName`}>
        <Employee returnToAllEmployees={returnToAllEmployees} parentUrl={url} />
      </Route>
    </EmployeesContainer>
  );
}

export default withRouter(Employees);
