import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LinkComponent = styled(Link)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    text-decoration: none;
    width: fit-content;
`;

const LinkText = styled(Typography)`
    margin-bottom: 1.5rem;
`;

const Arrow = styled.span`
    margin-right: 0.5rem;
`;

function ReturnLinkReusable({ destination, url }) {
    return (
        <LinkComponent to={`${url}`}>
            <LinkText variant="subtitle2">
                <Arrow>&#60;</Arrow> Tilbage
                {/* {destination && ` til ${destination}`} */}
            </LinkText>
        </LinkComponent>
    );
}

export default ReturnLinkReusable;
