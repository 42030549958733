import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    findUnfilledInputOrSave,
    setErrorScrollView,
    validatePassword,
    validatePostcode,
    validateStringAddress,
    validateTextarea
} from './EditProfileFunctions';

import { Box, Typography } from '@material-ui/core';

import { useContext } from 'react';
// import { motion } from "framer-motion";
import InformationTextInput from '../../../components/text-input/InformationTextInput';
import RegularButton from '../../../components/buttons/RegularButton/RegularButton';
import { Context } from '../../../context/Context';
import DeleteProfileDialog from '../../../components/dialogs/delete-profile-dialog/DeleteProfileDialog';
import {
    hasErrors,
    validateEmail,
    validateName,
    validatePasswordMatch,
    validatePhone
} from '../../registration/information/RegisterInformationFunctions';
import {
    removeWhiteSpace,
    getAndReturnDataWithAuth
} from '../../../context/helperFunctions';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import RegularHeadline from '../../../components/headlines/RegularHeadline';
import Loader from '../../../components/loader/Loader';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';
import { overridings } from '../../../themes/DarkTheme';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import { getToken, getUserId } from '../../../context/getData';
import SuccessDialog from '../../../components/dialogs/success-dialog/SuccessDialog';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import SelectWithLabel from './SelectWithLabel';
import Questionmark from '../../../components/Questionmark/Questionmark';
import ProfileTextArea from '../../../components/profile-text-area/ProfileTextArea';

const SelectContainer = styled(Box)`
    width: 20rem;
    // The followings helps so the textfields are not to far from each other.
    @media (min-width: 1050px) {
        width: 21rem;
    }

    @media (min-width: 1120px) {
        width: 22rem;
    }

    @media (min-width: 1120px) {
        width: 23rem;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const LightBackground = styled.div`
    background-color: ${(props) => props.theme.palette.secondary.main};
    margin: 1rem 0;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-top: 0;
    }
`;

const CustomContentContainer = styled.div`
    width: 85%;
    max-width: 1200px;
    padding: 2rem 0;
    margin: 0 auto;
`;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;

    width: 80%;
    max-width: 50rem;

    @media (max-width: 1010px) {
        width: 85%;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        width: 100%;
    }
`;
const SucessMessage = styled.div`
    font-size: 1.2rem;
    color: green;
`;

const DescriptionText = styled(Typography)`
    margin-bottom: 1.3rem;
`;

const DescriptionTextContainer = styled.div`
    width: 80%;
    @media (max-width: 1010px) {
        width: 85%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const SubmitContainer = styled.div``;

const PasswordContainer = styled(Box)`
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 21rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    width: 20%;
    min-width: 12rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const relationTypes = [
    { name: 'Vælg', value: 'Ikke angivet' },
    { name: 'Mor', value: 'mor' },
    { name: 'Far', value: 'far' },
    { name: 'Bedstemor', value: 'bedstemor' },
    { name: 'Bedstefar', value: 'bedstefar' },
    { name: 'Farmor', value: 'farmor' },
    { name: 'Farfar', value: 'farfar' },
    { name: 'Morfar', value: 'morfar' },
    { name: 'Mormor', value: 'mormor' },
    { name: 'Barnepige', value: 'barnepige' },
    { name: 'Andet', value: 'andet' }
];

function EditProfile(props) {
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successUpdate, setSuccessUpdate] = useState('');
    const [userDataFromDb, setUserDataFromDb] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isSmsVerified, setIsSmsVerified] = useState(false);
    const [editPasswordOpen, setEditPasswordOpen] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [passwordVisible, setPasswordVisible] = useState(false);
    // Password is coming from db, however it is not displayed in the UI.
    // const [originalPassword, setOriginalPassword] = useState("");
    const [deleteProfileDialogOpen, setDeleteProfileDialogOpen] = useState(
        false
    );

    // Needed, so we can check, if user has changed the email or phone
    const [initialEmail, setInitialEmail] = useState('');
    const [initialPhone, setInitialPhone] = useState('');

    const [relationObj, setRelationObj] = useState({
        label: 'relationToChild',
        value: 'Ikke angivet'
    });

    // Come back: change label to name or title
    const [firstnameObj, setFirstnameObj] = useState({
        label: 'firstname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Fornavn',
        placeholder: 'Fornavn',
        required: true
    });
    const [surnameObj, setSurnameObj] = useState({
        label: 'surname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Efternavn',
        placeholder: 'Efternavn',
        required: true
    });
    const [streetObj, setStreetObj] = useState({
        label: 'street',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gade',
        placeholder: 'Gade',
        required: true
    });
    const [housenumberObj, setHousenumberObj] = useState({
        label: 'housenumber',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Husnummer',
        placeholder: 'Husnummer',
        required: true
    });
    const [postcodeObj, setPostcodeObj] = useState({
        label: 'postcode',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Postnummer',
        placeholder: 'Postnummer',
        required: true
    });
    const [cityObj, setCityObj] = useState({
        label: 'city',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Bynavn',
        placeholder: 'Bynavn',
        required: true
    });
    const [emailObj, setEmailObj] = useState({
        label: 'email',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mailadresse',
        placeholder: 'Mailadresse',
        required: true
    });
    const [phoneObj, setPhoneObj] = useState({
        label: 'phone',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mobil',
        placeholder: 'Mobil',
        required: true
    });
    const [newPasswordObj, setNewPasswordObj] = useState({
        label: 'password',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Kodeord',
        placeholder: 'Kodeord',
        required: true
    });
    const [repeatPasswordObj, setRepeatPasswordObj] = useState({
        label: 'repeatPassword',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gentag kodeord',
        placeholder: 'Gentag Kodeordet',
        required: true
    });
    // eslint-disable-next-line
    const [turnOffAllMailsObj, setTurnOffAllMailsObj] = useState({
        label: 'turnOffAllMails',
        value: false
    });

    const [educationalBgObj, setEducationalBgObj] = useState({
        label: 'educationalBg',
        value: '',
        errorMessage: '',
        textareaError: false,
        danishLabel: 'Uddannelsesbaggrund',
        placeholder: 'Fx. Cand. psych.'
    });

    const [titleObj, setTitleObj] = useState({
        label: 'title',
        value: '',
        errorMessage: '',
        textareaError: false,
        danishLabel: 'Titel',
        placeholder: 'Fx. Psykolog'
    });

    const [workingWithObj, setWorkingWithObj] = useState({
        label: 'workingWith',
        value: '',
        errorMessage: '',
        textareaError: false,
        danishLabel: 'Hvad arbejder du med?',
        placeholder: 'Fx. ledelsesudvikling'
    });

    const [profHelpObj, setProfHelpObj] = useState({
        label: 'profHelp',
        value: '',
        errorMessage: '',
        textareaError: false,
        danishLabel: 'Bidrage med min faglige ekspertise',
        placeholder: 'Fx. blabla.'
    });

    const [practicalHelpObj, setPracticalHelpObj] = useState({
        label: 'practicalHelp',
        value: '',
        errorMessage: '',
        textareaError: false,
        danishLabel: 'Bidrage med praktiske opgaver inde og ude',
        placeholder: 'Fx. blab lba.'
    });

    // Refs
    const contentRef = useRef();
    const addressRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const passwordRef = useRef();
    const nameRef = useRef();

    const context = useContext(Context);

    // eslint-disable-next-line
    const handleVisibility = () => {
        if (!passwordVisible) {
            setPasswordVisible(true);
            setPasswordInputType('text');
        } else {
            setPasswordVisible(false);
            setPasswordInputType('password');
        }
    };

    const removeErrorOnChange = (label) => {
        setSuccessUpdate(false);
        const states = [
            firstnameObj,
            surnameObj,
            streetObj,
            cityObj,
            housenumberObj,
            postcodeObj,
            phoneObj,
            emailObj,
            newPasswordObj,
            repeatPasswordObj
        ];
        const changedField = states.find(
            (state) => state.danishLabel === label
        );
        changedField.error = false;
        changedField.errorMessage = '';
    };

    const saveTextarea = (valueObj) => {
        console.log('saving text area', valueObj);
        if (valueObj.label === 'educationalBg') {
            validateTextarea(
                valueObj.value,
                setEducationalBgObj,
                educationalBgObj
            );
        } else if (valueObj.label === 'title') {
            validateTextarea(valueObj.value, setTitleObj, titleObj);
        } else if (valueObj.label === 'workingWith') {
            validateTextarea(valueObj.value, setWorkingWithObj, workingWithObj);
        } else if (valueObj.label === 'profHelp') {
            validateTextarea(valueObj.value, setProfHelpObj, profHelpObj);
        } else {
            validateTextarea(
                valueObj.value,
                setPracticalHelpObj,
                practicalHelpObj
            );
        }
    };

    const removeTextareaError = (title) => {
        if (title === 'educationalBg') {
            setEducationalBgObj({
                ...educationalBgObj,
                textareaError: false,
                errorMessage: ''
            });
        } else if (title === 'title') {
            setTitleObj({
                ...titleObj,
                textareaError: false,
                errorMessage: ''
            });
        } else if (title === 'workingWith') {
            setWorkingWithObj({
                ...workingWithObj,
                textareaError: false,
                errorMessage: ''
            });
        } else if (title === 'profHelp') {
            setProfHelpObj({
                ...profHelpObj,
                textareaError: false,
                errorMessage: ''
            });
        } else {
            setPracticalHelpObj({
                ...practicalHelpObj,
                textareaError: false,
                errorMessage: ''
            });
        }
    };

    const saveInputValue = (valueObject) => {
        console.log('saving input', valueObject);
        const valueNoWhiteSpace = removeWhiteSpace(valueObject.value);
        if (
            valueObject.label === 'Fornavn' ||
            valueObject.label === 'Efternavn'
        ) {
            validateName(
                valueNoWhiteSpace,
                valueObject.label,
                setFirstnameObj,
                setSurnameObj,
                firstnameObj,
                surnameObj
            );
        } else if (
            valueObject.label === 'Gade' ||
            valueObject.label === 'Bynavn' ||
            valueObject.label === 'Husnummer'
        ) {
            validateStringAddress(
                valueNoWhiteSpace,
                valueObject.label,
                setStreetObj,
                setHousenumberObj,
                setCityObj,
                streetObj,
                housenumberObj,
                cityObj
            );
        } else if (valueObject.label === 'Postnummer') {
            validatePostcode(valueNoWhiteSpace, setPostcodeObj, postcodeObj);
        } else if (
            valueObject.label === 'Mobil' ||
            valueObject.label === 'Aktivitets telefonnummer'
        ) {
            if (valueObject.label === 'Aktivitets telefonnummer') {
                if (!isEmailVerified && !isSmsVerified) {
                    setError(true);
                    setErrorMessage(
                        'Du skal bekræfte din email og dit telefonnummer.'
                    );
                } else if (!isEmailVerified) {
                    setError(true);
                    setErrorMessage('Du skal bekræfte din email.');
                } else if (!isSmsVerified) {
                    setError(true);
                    setErrorMessage(`Du skal bekræfte dit telefonnummer.`);
                }
            }
            validatePhone(
                valueNoWhiteSpace,
                valueObject.label,
                setPhoneObj,
                null,
                phoneObj,
                null
            );
        } else if (valueObject.label === 'Kodeord') {
            validatePassword(
                valueObject.value,
                setNewPasswordObj,
                newPasswordObj
            );
        } else if (valueObject.label === 'Gentag kodeord') {
            validatePasswordMatch(
                newPasswordObj.value,
                valueObject.value,
                setRepeatPasswordObj,
                repeatPasswordObj
            );
        } else if (valueObject.label === 'Mailadresse') {
            validateEmail(valueNoWhiteSpace, setEmailObj, emailObj);
        }
    };

    // Come back: handle errors properly
    const handleErrors = (err) => {
        console.log(err);
    };

    const isEmailChanged = (finalEmail) => {
        if (finalEmail !== initialEmail) {
            return true;
        }
    };

    const isPhoneChanged = (finalPhone) => {
        if (finalPhone !== initialPhone) {
            return true;
        }
    };

    const onSubmit = () => {
        setIsLoadingSubmit(true);
        let isEditPasswordOpen;
        // Check if user has opened the password, but not filled it. If they have not filled it, we want to close it.
        if (
            editPasswordOpen &&
            newPasswordObj.value === '' &&
            repeatPasswordObj.value === ''
        ) {
            setEditPasswordOpen(false);
            isEditPasswordOpen = false;
        } else {
            isEditPasswordOpen = editPasswordOpen;
        }

        const states = [
            relationObj,
            educationalBgObj,
            titleObj,
            workingWithObj,
            profHelpObj,
            practicalHelpObj,
            firstnameObj,
            surnameObj,
            streetObj,
            cityObj,
            housenumberObj,
            postcodeObj,
            phoneObj,
            emailObj,
            newPasswordObj,
            repeatPasswordObj
        ];

        const setStates = [
            { label: 'relationToChild', function: setRelationObj },
            { label: 'firstname', function: setFirstnameObj },
            { label: 'surname', function: setSurnameObj },
            { label: 'street', function: setStreetObj },
            { label: 'city', function: setCityObj },
            { label: 'housenumber', function: setHousenumberObj },
            { label: 'postcode', function: setPostcodeObj },
            { label: 'phone', function: setPhoneObj },
            { label: 'email', function: setEmailObj },
            { label: 'password', function: setNewPasswordObj },
            { label: 'repeatPassword', function: setRepeatPasswordObj },
            { label: 'educationalBg', function: setEducationalBgObj },
            { label: 'title', function: setTitleObj },
            { label: 'workingWith', function: setWorkingWithObj },
            { label: 'profHelp', function: setProfHelpObj },
            { label: 'practicalHelp', function: setPracticalHelpObj }
        ];

        // Check for errors
        if (!hasErrors(states)) {
            findUnfilledInputOrSave(
                userId,
                handleErrors,
                setError,
                setErrorMessage,
                setSuccess,
                setSuccessMessage,
                setSuccessUpdate,
                states,
                setStates,
                nameRef,
                emailRef,
                phoneRef,
                addressRef,
                passwordRef,
                setEditPasswordOpen,
                isEditPasswordOpen,
                context,
                isPhoneChanged(phoneObj.value),
                isEmailChanged(emailObj.value),
                setIsLoadingSubmit,
                null,
                null,
                userDataFromDb.isProfilePublic
            );
        } else {
            // Trow error in dialog
            setError(true);
            setErrorMessage('Der er en fejl i et eller flere felter.');
            if (hasErrors(states) && hasErrors(states).label) {
                setErrorScrollView(
                    hasErrors(states).label,
                    nameRef,
                    emailRef,
                    phoneRef,
                    addressRef,
                    passwordRef
                );
            }
            setIsLoadingSubmit(false);
        }
    };

    const recoverRelationObj = (userData) => {
        if (!userData.relationToChild || userData.relationToChild === '') {
            setRelationObj({ ...relationObj, value: 'Ikke angivet' });
        } else {
            setRelationObj({ ...relationObj, value: userData.relationToChild });
        }
    };

    const recoverUserData = async (userId) => {
        const token = getToken();
        const userData = await getAndReturnDataWithAuth(
            `/api/v1/user/${userId}`,
            token,
            handleErrors
        );
        if (userData) {
            setUserDataFromDb(userData);
            if (userData.emailVerifiedAt) {
                setIsEmailVerified(true);
            }
            if (userData.phoneVerifiedAt) {
                setIsSmsVerified(true);
            }
            recoverRelationObj(userData);
            setProfHelpObj({ ...profHelpObj, value: userData.profHelp });
            setPracticalHelpObj({
                ...practicalHelpObj,
                value: userData.practicalHelp
            });
            setCityObj({ ...cityObj, value: userData.city });
            setEducationalBgObj({
                ...educationalBgObj,
                value: userData.educationalBg
            });
            setTitleObj({ ...titleObj, value: userData.title });
            setCityObj({ ...cityObj, value: userData.city });
            setEmailObj({ ...emailObj, value: userData.email });
            setFirstnameObj({ ...firstnameObj, value: userData.firstname });
            setHousenumberObj({
                ...housenumberObj,
                value: userData.housenumber
            });
            // setOriginalPassword(userData.password);
            setPhoneObj({ ...phoneObj, value: userData.phone });
            setPostcodeObj({ ...postcodeObj, value: userData.postcode });
            setStreetObj({ ...streetObj, value: userData.street });
            setSurnameObj({ ...surnameObj, value: userData.surname });
            setInitialEmail(userData.email);
            setInitialPhone(userData.phone);
            setWorkingWithObj({
                ...workingWithObj,
                value: userData.workingWith
            });
        }
        setIsLoading(false);
        return userData;
    };

    const handleDeleteProfileClick = () => {
        setDeleteProfileDialogOpen(true);
    };

    const handleSelectChange = (e) => {
        setRelationObj({ ...relationObj, value: e.target.value });
    };

    // componentDidMount
    useEffect(() => {
        setIsLoading(true);

        // Get userId
        const userId = getUserId();
        if (userId !== '') {
            setUserId(userId);
        } else {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen.');
        }

        // Load information from db
        recoverUserData(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* // <motion.div
    //   initial={{ opacity: 0 }}
    //   animate={{ opacity: 1 }}
    //   exit={{ opacity: 0 }}
    //   transition={{ duration: 0.7 }}
    // > */}
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            {/* <NavHeaderDesktop /> */}

            <LightBackground ref={contentRef}>
                <CustomContentContainer>
                    <Box mb="2rem">
                        <ReturnLinkReusable
                            destination="Min side"
                            url="/min-side"
                        />
                    </Box>

                    <RegularHeadline title="Rediger Profil" component="h1" />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Box>
                            {/* <SelectContainer>
                <SelectWithLabel
                  label="Relation til barnet"
                  onChange={handleSelectChange}
                  name="relationToChild"
                  value={relationObj.value}
                  id="relationToChild"
                  optionsArr={relationTypes}
                  width="100%"
                />
              </SelectContainer>  */}

                            <NameContainer ref={nameRef}>
                                <InformationTextInput
                                    stateObj={firstnameObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                    width="100%"
                                    required={true}
                                />
                                <InformationTextInput
                                    required={true}
                                    stateObj={surnameObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                    width="100%"
                                />
                            </NameContainer>

                            <NameContainer ref={emailRef}>
                                <InformationTextInput
                                    required={true}
                                    stateObj={emailObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                    type="email"
                                />
                                <span ref={phoneRef}></span>
                                <InformationTextInput
                                    required={true}
                                    stateObj={phoneObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                />
                            </NameContainer>
                            <span ref={passwordRef}></span>
                            {editPasswordOpen ? (
                                <Box>
                                    <NameContainer>
                                        <InformationTextInput
                                            stateObj={newPasswordObj}
                                            onInputChange={saveInputValue}
                                            type={passwordInputType}
                                            handleVisibility={handleVisibility}
                                            visible={passwordVisible}
                                            removeErrorOnChange={
                                                removeErrorOnChange
                                            }
                                            required={true}
                                        />
                                        <InformationTextInput
                                            stateObj={repeatPasswordObj}
                                            onInputChange={saveInputValue}
                                            type={passwordInputType}
                                            handleVisibility={handleVisibility}
                                            visible={passwordVisible}
                                            customClass="last-text-input"
                                            // handleEnterPress={onSubmit}
                                            removeErrorOnChange={
                                                removeErrorOnChange
                                            }
                                            required={true}
                                        />
                                    </NameContainer>
                                </Box>
                            ) : (
                                <PasswordContainer>
                                    <RegularButton
                                        size="large"
                                        paddingHorizontal="18px"
                                        paddingVertical="6px"
                                        title="Skift adgangskode"
                                        onButtonClick={() =>
                                            setEditPasswordOpen(true)
                                        }
                                    />
                                </PasswordContainer>
                            )}

                            <NameContainer ref={addressRef}>
                                <InformationTextInput
                                    required={true}
                                    stateObj={streetObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                />

                                <InformationTextInput
                                    required={true}
                                    stateObj={housenumberObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                />
                            </NameContainer>
                            <NameContainer>
                                <InformationTextInput
                                    required={true}
                                    stateObj={postcodeObj}
                                    onInputChange={saveInputValue}
                                    type="number"
                                    removeErrorOnChange={removeErrorOnChange}
                                />

                                <InformationTextInput
                                    required={true}
                                    stateObj={cityObj}
                                    onInputChange={saveInputValue}
                                    removeErrorOnChange={removeErrorOnChange}
                                />
                            </NameContainer>

                            <SubmitContainer>
                                {isLoadingSubmit ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {successUpdate && (
                                            <SucessMessage>
                                                Opdateret
                                            </SucessMessage>
                                        )}
                                        <RegistrationButton
                                            onButtonClick={() => onSubmit()}
                                            minWidth="12rem"
                                            width="12rem"
                                            title="Gem"
                                            background={
                                                overridings.palette.colors
                                                    .purple
                                            }
                                        />
                                    </>
                                )}
                            </SubmitContainer>
                        </Box>
                    )}
                </CustomContentContainer>
            </LightBackground>

            <CustomContentContainer>
                <Box display="flex">
                    <RegularHeadline title="Øvrig information" component="h3" />
                    {/* Come back: need danish text */}
                    <Questionmark
                        width="1rem"
                        explainerText="Dolor irure duis nostrud ex dolore excepteur do deserunt enim officia nulla aliquip Lorem id."
                    />
                </Box>

                <DescriptionTextContainer>
                    <DescriptionText variant="body2">
                        Vi bliver så glade hvis du vil fortælle noget mere om
                        dig selv. Måske vil du hen ad vejen lade nogle af dine
                        kompetencer komme i spil på Den Lille Musikskole
                    </DescriptionText>
                </DescriptionTextContainer>

                {isLoading ? (
                    <Loader />
                ) : (
                    <Box>
                        <ProfileTextArea
                            saveValue={saveTextarea}
                            rows="7"
                            stateObj={educationalBgObj}
                            removeErrorOnChange={removeTextareaError}
                        ></ProfileTextArea>

                        <ProfileTextArea
                            saveValue={saveTextarea}
                            rows="7"
                            stateObj={titleObj}
                            removeErrorOnChange={removeTextareaError}
                        ></ProfileTextArea>

                        <ProfileTextArea
                            saveValue={saveTextarea}
                            rows="7"
                            stateObj={workingWithObj}
                            removeErrorOnChange={removeTextareaError}
                        ></ProfileTextArea>

                        <Box mt="3rem" mb="2.5rem">
                            <RegularHeadline title="Jeg kan fx byde ind med at…" />
                        </Box>

                        <ProfileTextArea
                            saveValue={saveTextarea}
                            rows="7"
                            stateObj={profHelpObj}
                            removeErrorOnChange={removeTextareaError}
                        ></ProfileTextArea>

                        <ProfileTextArea
                            saveValue={saveTextarea}
                            rows="7"
                            stateObj={practicalHelpObj}
                            removeErrorOnChange={removeTextareaError}
                        ></ProfileTextArea>
                    </Box>
                )}

                <SubmitContainer>
                    {isLoadingSubmit ? (
                        <Loader />
                    ) : (
                        <>
                            {successUpdate && (
                                <SucessMessage>Opdateret</SucessMessage>
                            )}
                            <RegistrationButton
                                onButtonClick={() => onSubmit()}
                                minWidth="12rem"
                                width="12rem"
                                title="Gem"
                                background={overridings.palette.colors.purple}
                            />
                        </>
                    )}
                </SubmitContainer>
            </CustomContentContainer>

            <LightBackground>
                <CustomContentContainer>
                    <Box>
                        <RegularHeadline title="Slet profil" />
                        <DescriptionTextContainer>
                            <DescriptionText variant="body2">
                                Når du sletter din profil, kan du ikke længere
                                logge ind og se dine oplysninger. Jævnfør
                                regnskabsloven er vi forpligtet til at gemme
                                regnskabsinformationer om betalinger i 5 år.
                                Derfor beholder vi navn og adresse, men
                                anonymiserer email og telefonnummer.
                            </DescriptionText>
                        </DescriptionTextContainer>
                        <ButtonContainer>
                            <RegularButton
                                title="Slet profil"
                                onButtonClick={() => handleDeleteProfileClick()}
                            />
                        </ButtonContainer>
                    </Box>
                </CustomContentContainer>
            </LightBackground>

            {/* Come back: error text to danish */}
            {error && (
                <ErrorDialog
                    dialogTitle="Vigtigt"
                    errorMessage={errorMessage}
                    close={() => setError(false)}
                />
            )}
            {success && (
                <SuccessDialog
                    title="Succes"
                    successMessage={successMessage}
                    close={() => setSuccess(false)}
                    setScrollView={() => contentRef.current.scrollIntoView()}
                />
            )}

            {deleteProfileDialogOpen && (
                <DeleteProfileDialog
                    close={() => setDeleteProfileDialogOpen(false)}
                    userId={userId}
                    context={context}
                    message={`
              Du er nu ved at slette din brugerprofil. Dermed kan du ikke oprette offentlig brugerprofil, abonnement eller reservere lokale på bulowsvej10.dk.  
              Du er altid velkommen til at oprette en ny brugerprofil, så du igen kan få adgang til at oprette en Offentlig profil, et abonnement og reservere lokaler.
              
              `}
                />
            )}
        </>
        // </motion.div>
    );
}

export default EditProfile;
