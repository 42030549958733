import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Typography,
} from "@material-ui/core";
import BirthdatePicker from "../../components/birthdate-picker/BirthdatePicker";
import { getAndReturnDataAndResponse } from "../../containers/HelperFunctions";
// import ErrorDialog from "../../components/dialogs/error-dialog/ErrorDialog";
import HorizontalLine from "../../components/horizontal-line/HorizontalLine";
import ClassInfo from "./ClassInfo";

import moment from "moment";
// CMS feature
import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";

const CourseTypesContainer = styled.div`
  margin: 3rem 0 1.5rem 0;
`;
const BirthdatePickerContainer = styled.div`
  margin: 0.8rem 0 1.5rem 0;
`;
const Courses = styled.div`
margin: 0.8rem 0 1.5rem 0;
`;
const CourseType = styled.div`
margin: 0.8rem 0 1.5rem 0;
`;
// const Classes = styled.div`
// margin: 0.8rem 0 1.5rem 0;
// `;
const Text = styled(Typography)`
  font-family: "Benton Sans Regular";
  font-size: 18px;
`;
const CourseName = styled(Typography)`
  font-family:'Benton Sans Bold';
  font-size: 20px;
  margin-bottom:1rem;
  margin-top:4rem;
  color: #39e0e1;
  /* color:${(props) => props.theme.palette.colors.purple} */
`;
function CourseTypes() {

    // eslint-disable-next-line
    const [birthdate, setBirthdate] = useState("");
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    // TODO Rename courseTypesData to productTypeData??
    const [courseTypesData, setCourseTypesData] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Noget gik galt. Prøv igen.");

    const handleError = (err) => {
        console.log("error", err);
    };
    const getCourseTypesInfo = async (birthdateString) => {
        // console.log('getCourseTypesInfo:',birthdateString);
        // Ckeck that all YYYY-MM-DD parts are selected
        if (birthdateString.length<10) return;
        let url;
        setBirthdate(birthdateString);
        if (!moment(birthdateString).isValid() || day === undefined || month === undefined || year === undefined) {
            url = `/api/v1/product/types`;
        } else {
            url = `/api/v1/product/types/${birthdateString}`;
        }
        const data = await getAndReturnDataAndResponse(url, handleError);
        // console.log('CourseTypeInfo:', data);
        if (data && data.response && data.response.status === 200) {
            // If there is no classes for the spesific age (needs Danish message)
            if (day!=='' && month!=='' && year!=='' && data.data.productTypes.length === 0) {
                setError(true);
                setErrorMessage("Der er ingen hold til dit barns aldersgruppe lige nu.");

            }
            setCourseTypesData(data.data.productTypes);

        }
        if (data.response.status === 400) {
            setErrorMessage("Noget gik galt. Prøv igen.");
            setError(true);
        }
    }

    const [messageList, setMessageList] = useState([]);
  
    // ComponentDidMount
    useEffect(() => {
        const birthdateString = year + "-" + month + "-" + day;
        getCourseTypesInfo(birthdateString);  
      // eslint-disable-next-line
    }, [day, month, year]);
  

    return (
        <CourseTypesContainer>
            <MessageList type="html" name="Class season" state="final" change={setMessageList}/>

            <Text style={{ fontSize: '25px' }}>
                {getMessage(messageList,"Class season",'title','Hold')}
            </Text>
            <Text variant="body2">
                <MessagePlaceholder list={messageList} name="Class season" value=""/>
            </Text>
            <BirthdatePickerContainer>
                <BirthdatePicker
                    // birthdate={birthdate}
                    day={day}
                    month={month}
                    year={year}
                    setDay={setDay}
                    setMonth={setMonth}
                    setYear={setYear}                    
                />
            </BirthdatePickerContainer>
            <Courses>
                {day && month && year 
                    ? <Text>Dit barns alder passer til:</Text> 
                    : <></>}
                {courseTypesData.map((courseType, key) => {
                    return (<CourseType key={key}>
                        <CourseName >{courseType.name}</CourseName>
                        {courseType.courses.map((course, key2) => {
                            return (<div key={key2}>
                                <HorizontalLine marginBottom="0rem" marginTop="0rem" />
                                <ClassInfo key={'cid_'+key2} name={course.name} day={course.day} time={course.time} teacher={course.teacher} status={course.status}>
                                </ClassInfo>
                            </div>)
                        })
                        }
                    </CourseType>)
                })}
            </Courses>
            { error && 
                <div>
                    {errorMessage}
                    <a href="" onClick={() => {
                        setError(false);
                    }}>&#160;[OK]</a>
                </div>
            }
            {/*error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => {
                        setError(false);
                    }}
                />
            )*/}
        </CourseTypesContainer>
    );
}

export default CourseTypes;
