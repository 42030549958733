import React, { Component } from "react";
// import { handleErrors } from "../HelperFunctions";

import { Grid } from "@material-ui/core";
import EducationHeadline from "../../components/headlines/EducationHeadline";
import ExplainerContent from "../../components/explainers/ExplainerContent";
import ExplainerTextContainer from "../../components/containers/explainers/ExplainerTextContainer";
// import ExplainerText from "../../components/explainers/ExplainerText";
import AudioComponent from "../../components/audio/AudioComponent";
// import ExplainerImageContainer from "../../components/containers/explainers/ExplainerImageContainer";
// import ExplainerImage from "../../components/explainers/ExplainerImage";
import EmployeeListContainer from "../../components/containers/about/EmployeeListContainer";
import EmployeeCard from "../../components/cards/EmployeeCard";
//import styled from "styled-components";
import ReturnLinkReusable from "../../components/return-link/ReturnLinkReusable";
import QABoxesContainer from "../../components/containers/about/QABoxesContainer";
import QABox from "../../components/QABox/QABox";
// import musicalPlaygroundData from "../../data/MusicalPlaygroundData";
import { withRouter } from "react-router-dom";
import { getDestination, getReturnUrl } from "./EducationFunctions";

import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";
/*
const List = styled.li`
  margin-bottom: 1rem;
`;
*/
class MusicalPlayground extends Component {
  state = {
    teachers: [],
    messageList: [],
    employeeMessageList: [],
    courseMessageList: []
  };

  componentDidMount() {
    /*
    const teachersData = [];
    musicalPlaygroundData.teachers.forEach((teacher) => {
      fetch(`/api/v1/employee/${teacher.urlName}`)
        .then(handleErrors)
        .then((resp) => resp.json())
        .then((data) => {
          teachersData.push(data);
          return teachersData;
        })
        .then((teachersData) => {
          this.setState({ teachers: teachersData });
        });
    });
    */
  }

  render() {
    const { url } = this.props;    
  
    const setMessageList = (list) => {
      this.setState({ messageList: list });
    }

    const setEmployeeMessageList = (list) => {
      // See api\data\employees.js data for structure expected by frontend component
      // Instead of calling /api/v1/employees to get employee and other
      // employees list, we call the message end point
      let teacherList = list
        .filter( message => message.title.startsWith('Underviser')||message.title.startsWith('underviser'))
        .map( message => {
          let name = message.name.substring('Employee '.length); 
          let urlName = name.toLowerCase().replaceAll(' ','_');
          let mailToIndex = message.html.indexOf('href="mailto:')+'href="mailto:'.length;
          let mailToEnd = message.html.indexOf('"',mailToIndex);
          let email = '';
          if (mailToIndex>0 && mailToEnd>0) {
            email = message.html.substring(mailToIndex,mailToEnd);
          }
          return {
            name,
            urlName,
            instrument: message.title,
            id: 1,
            imageUrl: message.text,
            color: "#DDDDDD",
            colorName: "green",
            email
          }
        });
        this.setState({ 
          teachers: teacherList, 
          employeeMessageList: list 
        });
    }

    const setCourseMessageList = (list) => {
      this.setState({         
        courseMessageList: list 
      });

    }

    return (
      <div>
        <ReturnLinkReusable
          url={getReturnUrl(this.props)}
          destination={getDestination(this.props)}
        />
        <MessageList type="html" name="Education ML I-VII" state="final" change={setMessageList}/>

        <EducationHeadline title={getMessage(this.state.messageList,"Education ML I-VII",'title','Musikals legestue I-VII')} />
        <ExplainerContent>          
          <ExplainerTextContainer>          
            <MessagePlaceholder list={this.state.messageList} name="Education ML I-VII" value=""/>

            <AudioComponent
                src="/music/music-sample.mp3"
                type="audio/mpeg"
                width="100%"
            />
          </ExplainerTextContainer>
          {/*
          <ExplainerImageContainer>
            <ExplainerImage
              src="/images/educations/education-musical-playground.jpg"
              alt="Musikalsk legestue"
            />
          </ExplainerImageContainer>
          */}
        </ExplainerContent>

        <MessageList type="html" name="Employee%" state="final" change={setEmployeeMessageList}/>

        <EducationHeadline title="Undervisere" />
        <EmployeeListContainer>
          {this.state.teachers.length > 0 &&
            <React.Fragment>
              {this.state.teachers.map((teacher,index) => (
                <Grid item xs={12} sm={6} md={4} key={'teacher_'+index}>
                  <EmployeeCard
                    name={teacher.name}
                    image={teacher.imageUrl}
                    instrument={teacher.instrument}
                    url={url}
                    fromTitle="Medarbejdere"
                    color={teacher.color}
                    colorName={teacher.colorName}
                    email={teacher.email}
                    to={{
                      pathname: `/om-skolen/medarbejdere/${teacher.urlName}`,
                      state: {
                        from: {
                          path: "/undervisning/musikalsk-legestue",
                          title: "Musikalsk legestue",
                        },
                      },
                    }}
                  />
                </Grid>
              ))}
            </React.Fragment>
          }
        </EmployeeListContainer>

        {/* Course explanations */}
        <MessageList type="html" name="Course ML%" state="final" change={setCourseMessageList}/>

        <QABoxesContainer>
          {this.state.courseMessageList.map((message) => (
            <QABox key={'message_'+message.id} messageId={message.id} title={message.title} html={message.html} width="100%" />
          ))}
        </QABoxesContainer>
        {/* </EducationContentContainer> */}
      </div>
    );
  }
}

export default withRouter(MusicalPlayground);
