import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const StageTitleInComplete = styled(Typography)`
  position: absolute;
  color: ${(props) => props.theme.palette.primary.light};
  right: ${(props) => {
    if (props.title === "Opsummering") {
      if (!props.type) {
        return "4rem";
      } else {
        return "4rem";
      }
    }
  }};

  @media (min-width: 1300px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "6rem";
        } else {
          return "6rem";
        }
      }
    }};
  }

  @media (min-width: 1500px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "7rem";
        } else {
          return "7rem";
        }
      }
    }};
  }

  @media (min-width: 1700px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "8rem";
        } else {
          return "8rem";
        }
      }
    }};
  }

  @media (min-width: 1900px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "9rem";
        } else {
          return "9rem";
        }
      }
    }};
  }

  @media (min-width: 2000px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "10rem";
        } else {
          return "10rem";
        }
      }
    }};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
`;

const StageTitleActive = styled(Typography)`
  position: absolute;
  color: ${(props) => props.theme.palette.primary.contrastText};
  right: ${(props) => {
    if (props.title === "Opsummering") {
      if (!props.type) {
        return "4rem";
      } else {
        return "4rem";
      }
    }
  }};

  @media (min-width: 1300px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "6rem";
        } else {
          return "6rem";
        }
      }
    }};
  }

  @media (min-width: 1500px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "7rem";
        } else {
          return "7rem";
        }
      }
    }};
  }

  @media (min-width: 1700px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "8rem";
        } else {
          return "8rem";
        }
      }
    }};
  }

  @media (min-width: 1900px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "9rem";
        } else {
          return "9rem";
        }
      }
    }};
  }

  @media (min-width: 2000px) {
    right: ${(props) => {
      if (props.title === "Opsummering") {
        if (!props.type) {
          return "10rem";
        } else {
          return "10rem";
        }
      }
    }};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
`;

const StageTitleCompleted = styled(Typography)`
  position: absolute;
  color: ${(props) => props.theme.palette.colors.purple};
  left: ${(props) => {
    if (!props.type) {
      if (props.title === "Tilmeldingstype") {
        return "4rem";
      }
    }
  }};

  @media (min-width: 1300px) {
    left: ${(props) => {
      if (!props.type) {
        if (props.title === "Tilmeldingstype") {
          return "auto";
        }
      }
    }};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
`;

function StageTitle({ status, stageTitle, type }) {
  const getRightColor = (status) => {
    if (status === "active") {
      return (
        <StageTitleActive
          variant="body2"
          status={status}
          title={stageTitle}
          type={type}
        >
          {stageTitle}
        </StageTitleActive>
      );
    } else if (status === "completed") {
      return (
        <StageTitleCompleted
          variant="body2"
          status={status}
          title={stageTitle}
          type={type}
        >
          {stageTitle}
        </StageTitleCompleted>
      );
    } else {
      return (
        <StageTitleInComplete
          variant="body2"
          status={status}
          title={stageTitle}
          type={type}
        >
          {stageTitle}
        </StageTitleInComplete>
      );
    }
  };
  return <React.Fragment>{getRightColor(status)}</React.Fragment>;
}

export default StageTitle;
