import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import CheckboxComponent from '../../checkbox/Checkbox';
import RadioCheckboxComponent from '../../radio-checkbox/RadioCheckbox';

const MeetingBarContainer = styled(Box)`
    width: 100%;
    background-color: ${(props) =>
        props.selected
            ? props.theme.palette.secondary.light
            : props.theme.palette.secondary.main};
    transition: all 0.3s;
    min-height: 9rem;
    height: ${(props) => (props.height ? `${props.height}` : '9rem')};
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.palette.secondary.light};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: relative;
    }
`;

const MeetingBarTextContainer = styled.div`
    margin-bottom: ${(props) => props.height && '1rem'};
    width: 50%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 70%;
        margin-top: ${(props) => props.height && '1rem'};
    }
`;

const MeetingBarContentContainer = styled.div`
    width: 85%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        // display: block; // Check mobile version!
        // height: auto;
        position: absolute;
        left: 2rem;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
`;

const MeetingTitle = styled(Typography)`
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
`;

const DescriptionText = styled(Typography)``;

const ExtraTextGreen = styled(Typography)`
    color: ${(props) => props.theme.palette.colors.green};
    margin-top: 1rem;
`;

const ExtraText = styled(Typography)`
    margin-top: 1rem;
`;

const CheckBoxContainer = styled.div``;

function MeetingBar({
    id,
    title,
    date,
    time,
    selectedMeeting,
    onSelect,
    extraTextGreen,
    extraText,
    numberOfSignups,
    height,
    selectedMeetings,
    type
}) {
    const [checked, setChecked] = useState(false);
    const [numberAttending, setNumberAttending] = useState();

    const saveNumberAttending = useCallback((value) => {
        setNumberAttending(value);
    }, []);

    // Check which meeting is checked
    const checkSelected = useCallback(() => {
        if (selectedMeeting) {
            if (selectedMeeting.id === id) {
                setChecked(true);
            } else {
                // If another meeting is selected, uncheck this one
                setChecked(false);
            }
        }
    }, [selectedMeeting, id]);

    const handleClick = () => {
        const meetingObject = {
            id,
            title,
            date,
            time
        };
        setChecked(!checked);
        onSelect(meetingObject, type);

        // Change numberAttending depending on if it's checked or not
        if (title === 'Potentielt følgevoksenmøde') {
            if (checked) {
                setNumberAttending(numberAttending - 1);
            } else {
                setNumberAttending(numberAttending + 1);
            }
        }
    };

    const handleCheckboxChange = () => {
        const meetingObject = {
            id,
            title,
            date,
            time
        };
        console.log('Select meeting:', JSON.stringify(meetingObject, null, 2));
        onSelect(meetingObject);
    };

    const getRightExtraText = () => {
        if (title === 'Potentielt følgevoksenmøde') {
            if (numberAttending >= 5) {
                return (
                    <ExtraTextGreen variant="body2">
                        &#10003; Afholdes da der er 5 nye tilmeldte
                    </ExtraTextGreen>
                );
            } else {
                return (
                    <ExtraText variant="body2">
                        Afholdes ved minimum 5 nye tilmeldte
                    </ExtraText>
                );
            }
        }
    };

    const checkSelectedMeeting = useCallback(() => {
        if (
            type === 'parentMeeting' ||
            type === 'extraParentMeeting' ||
            type === 'workday'
        ) {
            if (selectedMeetings.length > 0) {
                const matchingMeeting = selectedMeetings.filter(
                    (meeting) => meeting.id === id
                );

                if (matchingMeeting.length > 0) {
                    setChecked(true);
                } else if (type === 'parentMeeting') {
                    setChecked(false);
                }
            }
        }
    }, [id, selectedMeetings, type]);

    useEffect(() => {
        if (selectedMeeting && Object.keys(selectedMeeting).length > 0) {
            checkSelected();
        }
    }, [selectedMeeting, checkSelected]);

    useEffect(() => {
        if (title === 'Potentielt følgevoksenmøde') {
            saveNumberAttending(numberOfSignups);
        }
    }, [saveNumberAttending, title, numberOfSignups]);

    useEffect(() => {
        checkSelectedMeeting();
    }, [checkSelectedMeeting]);
    return (
        <MeetingBarContainer
            onClick={() => handleClick()}
            selected={checked}
            height={height}
        >
            <MeetingBarContentContainer>
                <MeetingBarTextContainer height={height}>
                    <MeetingTitle variant="h6">{title}</MeetingTitle>
                    <DescriptionText variant="body2">{date}</DescriptionText>
                    <DescriptionText variant="body2">{time}</DescriptionText>

                    {extraTextGreen && (
                        <ExtraTextGreen variant="body2">
                            {extraTextGreen} &#10003;
                        </ExtraTextGreen>
                    )}

                    {getRightExtraText()}
                    {extraText && (
                        <ExtraText variant="body2">{extraText}</ExtraText>
                    )}
                </MeetingBarTextContainer>
                <CheckBoxContainer>
                    <RadioCheckboxComponent
                        onCheckboxChange={handleCheckboxChange}
                        checked={checked}
                    />
                </CheckBoxContainer>
            </MeetingBarContentContainer>
        </MeetingBarContainer>
    );
}

export default MeetingBar;
