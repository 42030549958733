import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { Box } from "@material-ui/core";
import AboutContentContainer from "../../components/containers/about/AboutContentContainer";
import AboutHeadline from "../../components/headlines/AboutHeadline";

// CMS feature
import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";

function Practical({ getCurrentUrl }) {
  const { url } = useRouteMatch();

  useEffect(() => {
    // Return current url to parent - current menu gets differnt styling
    getCurrentUrl(url, "Praktisk");
  }, [getCurrentUrl,  url],);

  const [messageList, setMessageList] = useState([]);

  return (
    <Box display="flex">
      <MessageList type="html" name="Practical information" state="final" change={setMessageList}/>

      <AboutContentContainer marginTop='0rem'>
        <AboutHeadline title={getMessage(messageList,"Practical information",'title','Praktisk')} />

        <MessagePlaceholder list={messageList} name="Practical information" value=""/>
      </AboutContentContainer>
    </Box>
  );
}

export default Practical;
