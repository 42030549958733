import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from './context/Context';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import ThemeProvider from './themes/ThemeProvider';
import LightTheme from './themes/LightTheme';
import DarkTheme from './themes/DarkTheme';
import { getUser } from './util';
let user = getUser();
let theme = user && user.theme === 'light' ? LightTheme : DarkTheme;

const path = '/';
const debug = false;

// In development suppress warning that take to much focus and cant be fixed right now
if (debug && process.env.NODE_ENV === 'development') {
    // Ignore errors that can not be fixed right now, waiting for dependency libraries to fix these errors
    const errorIgnoreList = [
        'Warning: findDOMNode is deprecated in StrictMode.'
    ];
    const error = console.error;
    console.error = function () {
        if (typeof arguments[0] === 'string' && arguments.length > 1) {
            let message = arguments[0].replace(/%s/g, arguments[1]);
            let stackTrace = arguments[arguments.length - 1];
            for (let i in errorIgnoreList) {
                if ((message + stackTrace).indexOf(errorIgnoreList[i]) >= 0)
                    return;
            }
            console.log(message);
            console.log(stackTrace);
            return;
        }
        error(arguments);
    };
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={path}>
            <Provider>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
