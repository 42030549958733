import React from "react";
import ContentContainer from "../../components/containers/ContentContainer";

function LessonsOverview({ data }) {
  return (
    <div>
      <ContentContainer>
        <ul>
          {data.map((lesson) => (
            <li key={lesson.name} >
              {lesson.date} - {lesson.title}
            </li>
          ))}
        </ul>
      </ContentContainer>
    </div>
  );
}

export default LessonsOverview;
