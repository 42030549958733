import React from "react";
import styled from "styled-components";
import EventInCalendar from "./EventInCalendar";
import { Link, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

const DayContainer = styled.div`
  display: flex;
  border: 1px white solid;
  height: ${(props) =>
    props.calendar === "global-calendar" ? "10rem" : "auto"};
  min-height: ${(props) =>
    props.calendar === "global-calendar" ? "10rem" : "8rem "};
  width: 14.28%;
  position:relative;
  padding:5px;
  background-color: ${(props) =>
    (props.isTodayDate && `rgba(255,255,225,0.1)`) ||
    (props.isPastDate && `rgba(255,255,225,0.3)`)};
  @media (max-width: 960px) {
    width:100%;
    margin: 10px 0px;
    display: ${(props) => !props.isThereEvents && `none`};
    border: 0px solid white;}
`;
const DayDateContainer = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  ${(props) =>
    props.isTodayDate &&
    `
  background-color:${props.theme.palette.common.white};
  border-radius: 50%;
  color: ${props.theme.palette.common.black};
  font-size: 0.68rem;
  height: 1rem;
  width: 1rem;
  text-align: center;
  `}
  @media only screen and (min-width: 1300px) and (max-width: 1431px) {
    font-size: 0.75rem;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 0.6rem;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
const EventsContainer = styled.div`
  width: 100%;
`;
const SeeMoreLink = styled(Typography)`
  font-size: 0.8rem;
  text-decoration: underline;
  display: inline;
`;
function Day(props) {
  // console.log('Props,globalevents', props,props.globalEvents)
  const allEvents = props.globalEvents.filter(
    (globalEvent) => props.dayInfo.date === globalEvent.startdate
  );
  let eventsArray;
  if (props.calendar === "global-calendar") {
    eventsArray = allEvents.slice(0, 2);
    // console.log('Eventsarray day', eventsArray)
  } else {
    eventsArray = allEvents;
  }
  const isThereEvents = allEvents.length;
  return (
    <DayContainer
      calendar={props.calendar}
      isTodayDate={props.dayInfo.isToday}
      isPastDate={props.isPast}
      isThereEvents={isThereEvents}
    >
      <DayDateContainer isTodayDate={props.dayInfo.isToday}>
        {props.dayInfo.displayDateShort}
      </DayDateContainer>
      {isThereEvents ? (
        <EventsContainer>
          {eventsArray.map((event) => {
            return (
              <EventInCalendar
                key={event.id}
                eventDetails={event}
                openEventDetails={props.openEventDetails}
                isOpenDetailsInCalendar={props.isOpenDetailsInCalendar}
                selectedEventInCalendar={props.selectedEventInCalendar}
                dayInfo={props.dayInfo}
              ></EventInCalendar>
            );
          })}
          {isThereEvents > 2 && props.calendar === "global-calendar" ? (
            <div>
              <SeeMoreLink>
                <Link
                  to={{
                    pathname: "/kalender-se-flere",
                    state: { weekInfo: props.weekInfo },
                  }}
                >
                  Se flere{" "}
                </Link>
              </SeeMoreLink>
            </div>
          ) : (
            <></>
          )}
        </EventsContainer>
      ) : (
        <></>
      )}
    </DayContainer>
  );
}
export default withRouter(Day);
