import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';
import CheckboxComponent from '../../checkbox/Checkbox';
import { useCallback } from 'react';
import RadioCheckboxComponent from '../../radio-checkbox/RadioCheckbox';

const ProcessBarContainer = styled(Box)`
    width: 100%;
    background-color: ${(props) =>
        props.selected
            ? props.theme.palette.secondary.light
            : props.theme.palette.secondary.main};
    transition: all 0.3s;
    min-height: 9rem;
    height: ${(props) => (props.height ? `${props.height}` : '9rem')};
    position: relative;
    // If the process is Rabatforløb, we need different styling, since we need to be able to display working days.
    margin-bottom: ${(props) => (props.isdiscountprocess ? '0' : '1rem')};
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.palette.secondary.light};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: relative;
    }
`;

const ProcessBarTextContainer = styled.div`
    margin-bottom: ${(props) => props.height && '1rem'};
    width: 50%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 70%;
        margin-top: ${(props) => props.height && '1rem'};
    }
`;

const ProcessBarContentContainer = styled.div`
    width: 85%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: absolute;
        left: 2rem;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
`;

const ProcessTitle = styled(Typography)`
    font-size: 1.1rem;
`;

function ProcessBar({
    title,
    price,
    description,
    onSelect,
    selectedProcess,
    height,
    active,
    setProcessDialogOpen,
    isDiscountProcess
}) {
    const [checked, setChecked] = useState(false);

    const checkSelected = useCallback(() => {
        // If this current process is selected, keep the check true
        if (selectedProcess.title === title) {
            setChecked(true);
        } else {
            // If another process is selected, uncheck this one
            setChecked(false);
        }
    }, [selectedProcess, title]);

    useEffect(() => {
        checkSelected();
    }, [selectedProcess, checkSelected]);

    const handleCheckboxChange = () => {
        onSelect(title, price);
    };

    const handleClick = () => {
        // If clicked process is active, select it, if inactive, display dialog
        if (active) {
            setChecked(!checked);
            onSelect(title, price);
        } else {
            setProcessDialogOpen(true);
        }
    };

    return (
        <ProcessBarContainer
            onClick={() => handleClick()}
            selected={checked}
            height={height}
            // isdiscountprocess={isDiscountProcess}
        >
            <ProcessBarContentContainer>
                <ProcessBarTextContainer height={height}>
                    {/* Only display the price if there is any price given */}
                    <ProcessTitle variant="h6">
                        {title}
                        {price === 0 ? '' : `: Fra DKK ${price}`}
                    </ProcessTitle>
                    <Typography variant="body2">{description}</Typography>
                </ProcessBarTextContainer>
                {active && (
                    <div>
                        <RadioCheckboxComponent
                            onCheckboxChange={handleCheckboxChange}
                            checked={checked}
                        />
                    </div>
                )}
            </ProcessBarContentContainer>
        </ProcessBarContainer>
    );
}

export default ProcessBar;
