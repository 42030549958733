import React from 'react';
import styled from 'styled-components';
import { Typography } from "@material-ui/core";


const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3%;
  position: relative;
  width:100%;
  background-color: ${props => props.theme.palette.secondary.main};
  height: 350px;
  @media (max-width: 960px) && (min-width: 500px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    height: 450px;
  }
  @media (max-width: 350px) {
    height: 500px;
    }
 `;
const SlideInfoContainer = styled.div`
  display: flex; 
  height: 75%;
  @media (max-width: 960px) {
    display: block;
  }
 `;
const ParentInfo = styled.div`
  display: flex;
  flex-direction: column; 
  margin-right: 5%;
  align-items: center;
  width:30%;
  padding: 3%;
  @media (max-width: 960px) {
    flex-direction: row; 
    justify-content: space-between;
    padding: 5% 3%;
  }
`;
const SlideImg = styled.img`
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  @media (max-width: 960px) {
  width: 4rem;
  height: 4rem;
}
 `;

const SlideTitle = styled(Typography)`
  text-transform: capitalize; 
  padding-top: 1rem;
  @media (max-width: 960px) {
    padding: 0px 10px;
  }
`;
const SlideText = styled(Typography)`
  width: 100%;
  padding: 3%;
`;

function ParentsWordsSlide(props) {
  return (
    <SlideContent>
      <SlideInfoContainer>
        <ParentInfo>
          <SlideImg src={props.slideInfo.image} alt={`${props.slideInfo.title} slide image`} />
          <SlideTitle>{props.slideInfo.title}</SlideTitle>
        </ParentInfo>
        <SlideText>"{props.slideInfo.description}"</SlideText>
      </SlideInfoContainer>
    </SlideContent>
  );
}

export default ParentsWordsSlide;