// test
// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import styled from "styled-components";

// or const { useQuill } = require('react-quilljs');

import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useQuill } from "react-quilljs";
import RegularButton from "../buttons/RegularButton/RegularButton";

import {
  // eslint-disable-next-line
  getAndReturnData,
  patchAndReturnResp,
} from "../../containers/HelperFunctions";

const TextEditorContainer = styled.div`
  width: 100%;
`;
const RegularButtonContainer = styled.div`
  width: 35%;
  margin: auto;
  padding: 20px 0px;
`;
function AdminRichTextEditor(props) {
  const { quill, quillRef } = useQuill();
  const [content, setContent] = useState();
  /* 
  // NLH outcommented below Quill test
  const oldContentFromAdmin = async () => {
    const data = await getAndReturnData(`api/v1/getRteContent`, handleError);
    if (!data || data.length === 0) {
      console.log("No data was coming from Quill");
      return;
    } else {
      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(data.data);
      }
    }
  };
  useEffect(() => {
    oldContentFromAdmin();
    // eslint-disable-next-line
  }, [quill]);
  */
  if (quill) {
    quill.on("text-change", () => {
      setContent(quill.root.innerHTML);
    });
  }

  // Come back: handle error
  const handleError = () => {
    console.log("message");
  };
  const handleClick = async (event) => {
    event.preventDefault();
    const requestBody = {
      rteContent: content,
    };
    await patchAndReturnResp("/api/v1/setRteContent", requestBody, handleError);
  };
  return (
    <form>
      <TextEditorContainer>
        <div ref={quillRef} />
      </TextEditorContainer>
      <RegularButtonContainer>
        <RegularButton title="Submit" onButtonClick={handleClick} />
      </RegularButtonContainer>
    </form>
  );
}

export default AdminRichTextEditor;
