import React from "react";
import styled from "styled-components";
import {
    Typography,
} from "@material-ui/core";

const ClassInfoContainer = styled.div`
  margin: 1rem 0 0 0;
  display: flex;
  justify-content: space-between;
  width:60%;
`;
const InfoColumn = styled.div`
  margin: 0.8rem 0 1.5rem 0;
  width: 40%;
`;
const Info = styled(Typography)`
  font-family: "Benton Sans Regular";
  font-size: 18px;
`;

function ClassInfo({ name, day, time, teacher, status }) {
    return (
        <ClassInfoContainer>
            <InfoColumn>
                <Info>{name}</Info>
                <Info>{day}</Info>
                <Info>{time}</Info>
            </InfoColumn>

            <InfoColumn>
                <Info>Lærer: {teacher}</Info>
                <Info>Status: {status}</Info>
            </InfoColumn>

        </ClassInfoContainer>
    );
}

export default ClassInfo;
