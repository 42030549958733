import moment from 'moment';
import { getLatestSimpleRegData, getUserId } from '../../../context/getData';
import { getAndReturnData, postAndReturnResp } from '../../HelperFunctions';

const sortEventsByDate = (events) => {
    return events.sort(
        (a, b) =>
            new Date(a.date + ' ' + a.start) - new Date(b.date + ' ' + b.start)
    );
};

export const getTimeString = (starttime, endtime, date) => {
    const startdateTimeString = date + ' ' + starttime;
    const enddateTimeString = date + ' ' + endtime;
    const start = moment(startdateTimeString).format('HH:mm');
    const end = moment(enddateTimeString).format('HH:mm');
    return `kl. ${start}-${end}`;
};

export const getIntromeetings = async (
    setIntroMeetings,
    handleError,
    setIsLoading,
    context
) => {
    setIsLoading(true);
    /* PWA service worker caches handles load optimization, no need to store in local storage
  // If there are meetings saved in local storage, load them from there
  const simpleRegData = getLatestSimpleRegData(context);
  if (simpleRegData && simpleRegData.listOfIntromeetings) {
    setIntroMeetings(simpleRegData.listOfIntromeetings);
    setIsLoading(false);
  } else {
  */

    // Or get meetings from server
    const todayString = moment(new Date()).format('YYYY-MM-DD');
    // End date is 6 month from today
    const enddateString = moment(new Date())
        .add(6, 'months')
        .format('YYYY-MM-DD');
    // Currently only intro meetings displayed not event 'parent-meeting' and 'extra-parent-meeting'
    const typeList = ['intro-meeting'];
    const data = await getAndReturnData(
        `/api/v1/event/public/${todayString}/${enddateString}/${typeList
            .map((typeName) => encodeURIComponent(typeName))
            .join(',')}`,
        handleError
    );
    if (data) {
        const introMeetings = data.eventList;
        setIntroMeetings(sortEventsByDate(introMeetings));
        setIsLoading(false);

        // Save list of intromeetings in storage
        const simpleRegData = getLatestSimpleRegData(context);
        simpleRegData.listOfIntromeetings = introMeetings;
        context.saveSimpleRegistrationData(simpleRegData);
    } else {
        handleError();
        setIsLoading(false);
    }
    //  }
};

//
export const sendOrder = async (selectedMeeting, props, handleError) => {
    const userId = getUserId();
    const requestBody = {
        introMeetings: [selectedMeeting.id],
        parentMeetings: []
    };
    // Send events order
    const response = await postAndReturnResp(
        `/api/v1/user/${userId}/order/events/intro-meeting`,
        requestBody,
        handleError
    );

    if (response && response.status === 200) {
        props.history.push('/tilmelding/intromoede-opsummering');
    } else {
        handleError();
    }
};

export const saveMeetingInStorage = (selectedIntroMeeting, option, context) => {
    const simpleRegistrationData = getLatestSimpleRegData(context)
        ? getLatestSimpleRegData(context)
        : {};
    simpleRegistrationData.whenToSelectMeeting = option;
    simpleRegistrationData.selectedIntroMeeting = selectedIntroMeeting;
    context.saveSimpleRegistrationData(simpleRegistrationData);
};
