import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";

//import { Typography } from "@material-ui/core";
import AboutContentContainer from "../../components/containers/about/AboutContentContainer";
import AboutImageContainer from "../../components/containers/about/AboutImageContainer";
import AboutHeadline from "../../components/headlines/AboutHeadline";

// CMS feature
import MessageList from "../../components/message-list";
import MessagePlaceholder from "../../components/message-list/MessagePlaceholder";
import { getMessage } from "../../components/message-list/MessageListHelper";

/*
const Paragraph = styled(Typography)`
  margin-bottom: 1rem;
`;
*/

const VisionImage = styled.img`
  width: 13rem;
  margin-left: auto;

  display: block;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin: 2rem auto;
  }
`;

const VisionContainer = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

function Vision({ getCurrentUrl }) {
  const { url } = useRouteMatch();

  useEffect(() => {
    // Return current url to parent - current menu gets differnt styling
    getCurrentUrl(url, "Vision");
  }, [getCurrentUrl, url]);

  const [messageList, setMessageList] = useState([]);

  return (
    <VisionContainer>
      <MessageList type="html" name="Vision" state="final" change={setMessageList}/>

      <AboutContentContainer>
        <AboutHeadline title={getMessage(messageList,"Vision",'title','Vision')} />
        <MessagePlaceholder list={messageList} name="Vision" value=""/>
      </AboutContentContainer>
      <AboutImageContainer>
        <VisionImage 
        src={getMessage(messageList,"Vision",'text','/images/about/vision-image.svg')} alt="vision" />
      </AboutImageContainer>
    </VisionContainer>
  );
}

export default Vision;
