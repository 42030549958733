import React from "react";
import styled from "styled-components";

const HeaderComponentContainerComp = styled.div`
@media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
  height: ${(props) => props.height};
  min-height: 25rem;
  background-image: linear-gradient(
      rgba(0, 0, 0, 1) 1%,
      rgba(250, 250, 250, 0) 30%
    ),
    url("${(props) => props.imageUrl}");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: block;


`;

function HeaderComponentContainer({ children, imageUrl, height }) {
  return (
    <HeaderComponentContainerComp height={height} imageUrl={imageUrl}>
      {children}
    </HeaderComponentContainerComp>
  );
}

export default HeaderComponentContainer;
