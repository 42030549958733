import React, { useContext, useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import { getUserFirstnameHooks } from '../../../context/getData';

import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MobileLogo from '../../../assets/MobileLogo.png';
import NavContainerMobile from '../../containers/header/NavContainerMobile';

import { Context } from '../../../context/Context';
import NavHeadline from '../../headlines/NavHeadline';
import Avatar from '../../../assets/avatar.png';

const MobileHeaderContainer = styled(Box)`
    width: 85%;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    @media (min-width: 960px) {
        display: none;
    }
`;

const MainContainer = styled(Box)`
    background: ${(props) => props.theme.palette.primary.main};
    width: 100%;
    position: fixed;
    z-index: 10;
`;

const Logo = styled.img`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 10rem;
        padding-left: 0.5rem;
    }
`;

const LoginContainer = styled(Box)`
    display: ${(props) =>
        props.context.state.features.enableLogin ? 'block' : 'none'};

    & a {
        text-decoration: none;
    }
`;

const MenuContainer = styled.div`
    & .bm-overlay {
        display: none;
    }
`;

const LogoutLink = styled.div`
    cursor: pointer;
`;

const AvatarImg = styled.img`
    width: 2.5rem;
`;

function NavHeaderMobile(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userFirstname, setUserFirstname] = useState('');

    const context = useContext(Context);

    const handleLogoutClick = () => {
        context.onLogout();
    };

    useEffect(() => {
        getUserFirstnameHooks(setIsAuthenticated, setUserFirstname);
    }, [context.state.currentUser]);

    return (
        <MainContainer>
            <MobileHeaderContainer
                display="flex"
                justifyContent="space-between"
            >
                <Box>
                    <Link to="/">
                        <Logo src={MobileLogo} alt="Logo" />
                    </Link>
                </Box>
                <MenuContainer>
                    <Menu
                        pageWrapId={'page-wrap'}
                        outerContainerId={'outer-container'}
                        right
                        width={'100%'}
                    >
                        <NavContainerMobile>
                            <LoginContainer
                                context={context}
                                // style={{display: (context.state.features.enableLogin?'block':'none')}}
                            >
                                {isAuthenticated ? (
                                    <Box mt="2rem" ml="1rem">
                                        <Box display="flex" mb="1rem">
                                            <Box>
                                                <AvatarImg src={Avatar} />
                                            </Box>
                                            <Box ml="1rem" mt=".5rem">
                                                <Typography variant="subtitle1">
                                                    {userFirstname}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box width="fit-content">
                                            <Link to="/min-side">
                                                <Typography variant="subtitle1">
                                                    Min side
                                                </Typography>
                                            </Link>
                                        </Box>

                                        <Box width="fit-content">
                                            <Link to="/rediger-profil">
                                                <Typography variant="subtitle1">
                                                    Rediger profil
                                                </Typography>
                                            </Link>
                                        </Box>

                                        <Box width="fit-content">
                                            <LogoutLink
                                                variant="subtitle1"
                                                onClick={() =>
                                                    handleLogoutClick()
                                                }
                                            >
                                                Log ud
                                            </LogoutLink>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Link
                                        className="frontpage-job"
                                        to={{
                                            pathname: '/login',
                                            state: { modal: true }
                                        }}
                                    >
                                        <NavHeadline title="Login" />
                                    </Link>
                                )}
                            </LoginContainer>
                        </NavContainerMobile>
                    </Menu>
                </MenuContainer>
            </MobileHeaderContainer>
        </MainContainer>
    );
}

export default NavHeaderMobile;
