import {
  getChildInRegFlow,
  getLatestChildInRegFlow,
  getLatestRegInformation,
} from "../../context/getData";

export const isBirthdateValid = () => {
  const childInRegFlow = getChildInRegFlow();
  if (childInRegFlow) {
    if (childInRegFlow.birthdate) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isCourseSelected = () => {
  const childInRegFlow = getChildInRegFlow();
  if (childInRegFlow) {
    if (childInRegFlow.priorities) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isProcessSelected = () => {
  const childInRegFlow = getChildInRegFlow();
  if (childInRegFlow) {
    if (childInRegFlow.priorities && childInRegFlow.rates) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isMeetingSelected = () => {
  const childInRegFlow = getChildInRegFlow();
  if (childInRegFlow) {
    if (childInRegFlow.selectedMeetings) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// export const isInfoFilled = (context) => {
//   const regInformation = getLatestRegInformation(context);
//   if (regInformation) {
//     if (Object.keys(regInformation).length > 0) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };

// Come back to this
export const isEmailFilled = (context) => {
  const regInformation = getLatestRegInformation(context);
  if (regInformation) {
    if (regInformation.email) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getParticipantId = (context) => {
  const childInRegFlow = getLatestChildInRegFlow(context);
  if (childInRegFlow && childInRegFlow.participantId) {
    return childInRegFlow.participantId;
  }
};

export const removeParticipantIdFromStorage = (context) => {
  const childInRegFlow = getLatestChildInRegFlow(context);
  if (getParticipantId(context)) {
    childInRegFlow.participantId = undefined;
    context.saveChildInRegFlow(childInRegFlow);
  }
};

