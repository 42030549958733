import React, { useEffect } from 'react';
import { getMessageList } from '../../context/getData';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// Pass callback to parent context setMessageList
function MessageList(props) {
    let { name, type, state, change } = props;
    if (!name) throw new Error('MessageList is missing name property');
    if (!type) type = 'html';
    if (!state) state = 'final';

    // ComponentDidMount
    useEffect(() => {
        const getContent = async () => {
            const json = await getMessageList(type, name, state, (err) => {
                console.log('ERROR: getMessageList:', err);
            });

            if (json && json.messageList) {
                // console.log('MessageList:',json.messageList);
                if (change) change(json.messageList);
            }
        };

        if (
            props.name === '/sessionen-udloebet' ||
            props.path === '/sessionen-udloebet'
        ) {
            setTimeout(() => {
                getContent();
            }, 3000);
        } else {
            getContent();
        }

        // eslint-disable-next-line
    }, []);

    return <>{/* MessageList */}</>;
}

export default MessageList;
