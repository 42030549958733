/*
    "options": [
        {
            "name": "Basistilmelding",
            "startdates": [
            {
                "date": "2023-08-06",
                "numberOfLessons": 17
            }
            ],
            "priceFixed": "",
            "priceOptions": [
            {
                "name": "employee",
                "text": "Ansat",
                "priceOfLesson": 0
            },
*/

export const getBasicProcessPrice = (childInRegFlow) => {
    // Check if course name exists
    const priceOptions =
        childInRegFlow.priorities[0].course.options[0].priceOptions;
    if (!priceOptions) {
        return undefined;
    }

    const basicPriceOption = priceOptions.find(
        (o) => o.name === 'new-customer'
    );

    if (!basicPriceOption || !basicPriceOption.priceOfLesson) {
        return undefined;
    }

    return basicPriceOption.priceOfLesson;
};

export const getDiscountProcessPrice = (basicProcessPrice) => {
    if (basicProcessPrice) {
        // TODO Read CustomerGroup.discountProcent instead of hardcoded 0.75
        // Some discounts are only valid for given period based on:
        // CustomerGroup.bookingStartDate and CustomerGroup.bookingEndDate
        return basicProcessPrice * 0.75;
    } else {
        return undefined;
    }
};
