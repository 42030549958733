import React, { useState } from "react";
import styled from "styled-components";
import LeftArrow from "../navigate-carousel/LeftArrow";
import RightArrow from "../navigate-carousel/RightArrow";
import SchoolQualitySlide from "./SchoolQualitySlide";
import CarouselBullet from "../navigate-carousel/CarouselBullet";
import { Fade } from "@material-ui/core";
import data from "./data";

const CarouselContainer = styled.div`
  margin: 5rem auto 10rem auto;
  align-items: center;
  max-height: 520px;
  height: 520px;
  @media (max-width: 960px) {
    display: none;
    margin: 5rem auto 20rem auto;
    max-height: 700px;
    height: 700px;
    position: relative;
  }
`;

const SlideWithArrows = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  @media (max-width: 960px) {
  }
`;

const SlideContentWithBullets = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  max-height: 520px;
  height: 520px;
  position: relative;
  @media (max-width: 960px) {
    position: static;
    display: block;
    height: 100%;
  }
`;

const BulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  @media (max-width: 960px) {
    position: static;
    justify-content: center;
    margin-top: 10px;
  }
`;

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [checked, setChecked] = useState(true);

  const slidesNumber = data.length;

  const handleArrowClick = (slideId) => {
    const newIndex = slideId;
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
      setCurrentSlide(newIndex);
    }, 200);
  };

  return (
    <CarouselContainer>
      <SlideWithArrows>
        <LeftArrow
          currentSlide={currentSlide}
          slidesNumber={slidesNumber}
          onClick={handleArrowClick}
        />
        <SlideContentWithBullets>
          {/* Use Fade animation  */}
          <Fade in={checked}>
            <div>
              <SchoolQualitySlide
                slideInfo={data[currentSlide]}
              ></SchoolQualitySlide>
            </div>
          </Fade>

          <BulletsContainer>
            {data.map((slide) => {
              return (
                <CarouselBullet
                  key={slide.id}
                  bulletId={slide.id}
                  currentSlide={currentSlide}
                  onClick={handleArrowClick}
                />
              );
            })}
          </BulletsContainer>
        </SlideContentWithBullets>
        <RightArrow
          currentSlide={currentSlide}
          slidesNumber={slidesNumber}
          onClick={handleArrowClick}
        />
      </SlideWithArrows>
    </CarouselContainer>
  );
}

export default Carousel;
