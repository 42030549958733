import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import { withRouter } from 'react-router-dom';
import { getDateString } from '../choose-meetings/ChooseMeetingsFunctions';

import {
    getAuthentication,
    getLatestSimpleRegData
} from '../../../context/getData';
import {
    getIntromeetings,
    getTimeString,
    saveMeetingInStorage,
    sendOrder
} from './ChooseIntroMeetingFunctions';
import { Box, FormControl, MenuItem, Typography } from '@material-ui/core';
import MeetingBar from '../../../components/bars/meeting-bar/MeetingBar';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';

import NavHeaderDesktop from '../../../components/headers/nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../../../components/headers/nav-header/NavHeaderMobile';
import Loader from '../../../components/loader/Loader';
import ProgressBar from '../../../components/progress-bars/ProgressBar';
import ReturnLinkReusable from '../../../components/return-link/ReturnLinkReusable';

import IntroMeetingDescription from '../choose-meetings/IntroMeetingDescription';
import SelectComponent from '../../../components/select/SelectComponent';
import RegistrationButton from '../../../components/buttons/RegistrationButton/RegistrationButton';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import NavHeaderMobileContainer from '../../../components/containers/header/NavHeaderMobileContainer';
import MessageList from '../../../components/message-list';

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const options = [
    { value: 'now', title: 'Vælg nu' },
    { value: 'later', title: 'Vælg senere' }
];

function ChooseIntroMeeting(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [introMeetings, setIntroMeetings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // {"id":202590,"title":"Intromøde","date":"14. oktober, 2023 ","time":"kl. 17:00-19:00"}
    const [selectedIntroMeeting, setSelectedIntroMeeting] = useState({});

    const [option, setOption] = useState('now');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const context = useContext(Context);

    const contentRef = useRef();

    const handleIntroMeetingSelect = (meetingObject) => {
        setSelectedIntroMeeting(meetingObject);
    };

    const handleError = (customMessage) => {
        setError(true);
        if (customMessage) {
            setErrorMessage(customMessage);
        } else {
            setErrorMessage('Kunne ikke indlæse møder. Prøv igen senere.');
        }
    };

    const handleOptionChange = (e) => {
        setOption(e.target.value);
        if (e.target.value === 'later') {
            setSelectedIntroMeeting({});
        }
    };

    const onSubmit = () => {
        if (isAuthenticated) {
            // They must select a meeting
            if (Object.keys(selectedIntroMeeting).length === 0) {
                handleError('Du skal vælge et møde.');
            } else {
                // Save meeting in localstorage - will be used in next page
                saveMeetingInStorage(selectedIntroMeeting, option, context);
                // Send meeting as order
                sendOrder(selectedIntroMeeting, props, handleError);
            }
        } else {
            // Save selected meeting and go to information page
            saveMeetingInStorage(selectedIntroMeeting, option, context);
            props.history.push('/tilmelding/intromoede-oplysninger');
        }
    };

    const recoverSelectedMeeting = () => {
        const simpleRegData = getLatestSimpleRegData(context);
        // console.log('recoverSelectedMeeting:',simpleRegData);
        if (
            simpleRegData &&
            simpleRegData.selectedIntroMeeting &&
            simpleRegData.whenToSelectMeeting
        ) {
            // Recover if it is in the storage
            setSelectedIntroMeeting(simpleRegData.selectedIntroMeeting);
            setOption(simpleRegData.whenToSelectMeeting);
        } else {
        }
    };

    // Get authentication
    useEffect(() => {
        getAuthentication(setIsAuthenticated);
    }, [context.state.currentUser]);

    // If user is logged in, then they need to select now
    useEffect(() => {
        if (isAuthenticated) {
            setOption('now');
        }
    }, [isAuthenticated]);

    // ComponentDidMount effect
    useEffect(() => {
        getIntromeetings(setIntroMeetings, handleError, setIsLoading, context);
        recoverSelectedMeeting();
        contentRef.current.scrollIntoView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <span ref={contentRef}></span>
            <NavHeaderDesktop />
            <NavHeaderMobileContainer>
                <NavHeaderMobile />
            </NavHeaderMobileContainer>

            <RegistrationContentContainer
                magin-top="2.5rem"
                mobilePaddingTop="1rem"
            >
                {isAuthenticated ? (
                    <React.Fragment>
                        <ProgressBar
                            currentStage="Vælg intromøde"
                            type="authIntromeeting"
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ProgressBar
                            currentStage="Vælg intromøde"
                            type="intromeeting"
                        />
                    </React.Fragment>
                )}

                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/tilmeldingstype"
                        destination="Tilmeldingstype"
                    />
                </Box>
                <IntroMeetingDescription
                    type="intromeeting"
                    isAuthenticated={isAuthenticated}
                />
                {!isAuthenticated && (
                    <FormControl variant="outlined">
                        <SelectComponent
                            onChange={(e) => handleOptionChange(e)}
                            name="option"
                            value={option}
                            option={option}
                            width="15rem"
                        >
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.title}
                                </MenuItem>
                            ))}
                        </SelectComponent>
                    </FormControl>
                )}
            </RegistrationContentContainer>
            {option === 'now' && (
                <React.Fragment>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            {introMeetings.length === 0 ? (
                                <RegistrationContentContainer>
                                    <DescriptionText>
                                        Der er ingen planlagte møder lige nu.
                                    </DescriptionText>
                                </RegistrationContentContainer>
                            ) : (
                                <React.Fragment>
                                    {introMeetings // Show first two intro meetings
                                        .filter((meeting, index) => index < 2)
                                        .map((meeting) => (
                                            <MeetingBar
                                                id={meeting.id}
                                                key={meeting.id}
                                                title="Intromøde"
                                                date={getDateString(
                                                    meeting.startdate
                                                )}
                                                time={getTimeString(
                                                    meeting.starttime,
                                                    meeting.endtime,
                                                    meeting.startdate
                                                )}
                                                // extraTextGreen="Afholdes uanset hvad"
                                                onSelect={
                                                    handleIntroMeetingSelect
                                                }
                                                height="12rem"
                                                selectedMeeting={
                                                    selectedIntroMeeting
                                                }
                                                type="introMeeting"
                                            />
                                        ))}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            <RegistrationContentContainer>
                {isAuthenticated ? (
                    <RegistrationButton
                        title="Reserver"
                        onButtonClick={onSubmit}
                    />
                ) : (
                    <RegistrationButton onButtonClick={onSubmit} />
                )}

                <Box width="fit-content">
                    <ReturnLinkReusable
                        url="/tilmelding/tilmeldingstype"
                        destination="Tilmeldingstype"
                    />
                </Box>
            </RegistrationContentContainer>
            {error && (
                <ErrorDialog
                    errorMessage={errorMessage}
                    close={() => {
                        setErrorMessage('');
                        setError(false);
                    }}
                />
            )}
        </div>
    );
}

export default withRouter(ChooseIntroMeeting);
