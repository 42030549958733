import React from "react";
import styled from "styled-components";

const VideoComp = styled.video``;

function VideoComponent({ src, type }) {
  return (
    <VideoComp controls>
      <source src={src} type={type} />
      Your browser is not supported.
    </VideoComp>
  );
}

export default VideoComponent;
