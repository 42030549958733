import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

import DlmvImg from "../../assets/logos/dlmv-logo.png";
import DlmImg from "../../assets/logos/logo.png";
import AftenSkoleImg from "../../assets/logos/den-lille-aftenskole-logo.png";

import DlmvImgMobile from "../../assets/logos/dlmv-logo-small.png";
import AftenSkoleImgMobile from "../../assets/logos/den-lille-aftenskole-logo-small.png";
import Privacy from "../privacy";
import { Context } from "../../context/Context";

const FooterComponent = styled.div`
  font-size: 80%;
  width: 85%;
  margin: 1rem auto 4rem auto;
  border-top: 1px solid ${(props) => props.theme.palette.primary.contrastText};

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 90%;
  }
`;

const FooterContentContainer = styled.div`
  padding-top: 3rem;
`;

const FooterBox = styled.div`
  color: #999999;
  /* width: 24%; */
  margin-right: 1rem;
  margin-bottom: 1%;
  min-width: 180px;
  min-height: 180px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    min-height: 0;
    margin-bottom: 2rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: block;
  }
`;

const FooterImage = styled.img`
  width: 50%;
  max-width: 6rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    width: 60%;
    min-width: 4rem;
  }
`;

const FooterTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-size: 0.8rem;
  margin-bottom: 1rem;
`;

const FooterText = styled(Typography)`
  font-size: 0.8rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-bottom: 0.5rem;
  }
`;

const FooterLink = styled.a`
  color: #999999;
`;

const MobileImageContainer = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 2rem auto;
  }
`;

const DesktopImageContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
  display: block;
`;

const FooterBreak = styled.div`
  display: block;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: none;
  }
`;

function Footer(props) {
  const context = useContext(Context);

  useEffect(() => {}, [context.state.isOpenPrivacy]);
  return (
    // <div className="App-footer">
    <FooterComponent>
      <FooterContentContainer margin="2rem">
        <FlexContainer>
          <DesktopImageContainer>
            <FooterBox>
              <Link to="/">
                <FooterImage src={DlmImg} alt="logo" />
              </Link>
            </FooterBox>
          </DesktopImageContainer>

          <MobileImageContainer>
            <Link to="/">
              <FooterImage src={DlmImg} alt="logo" />
            </Link>

            <FooterLink
              href="https://www.denlilleaftenskole.dk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FooterImage src={AftenSkoleImgMobile} alt="logo-aftenskole" />
            </FooterLink>

            <FooterLink
              href="https://dlmv.dk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FooterImage src={DlmvImgMobile} alt="logo-dlmv" />
            </FooterLink>
          </MobileImageContainer>
          <FooterBox>
            <FooterTitle variant="body2">Adresse</FooterTitle>
            <FooterText variant="body2">
              Leonardo
              <br />
              Bülowsvej 10
              <br />
              1870 Frederiksberg
            </FooterText>
            <FooterLink
              href="https://bulowsvej10.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book et lokale
            </FooterLink>
          </FooterBox>
          <FooterBox>
            <FooterTitle variant="body2">Kontakt</FooterTitle>
            <FooterText variant="body2">Telefon: 30 14 01 23</FooterText>
            <FooterText variant="body2">
              Mandag, onsdag og tordag
              <br />
              kl. 10-12
            </FooterText>
            <FooterText variant="body2">
              <FooterLink to="mailto:kontakt@denlillemusikskole.dk">
                kontakt@denlillemusikskole.dk
              </FooterLink>
            </FooterText>
          </FooterBox>

          <DesktopImageContainer>
            <FooterBox>
              <FooterLink
                href="https://www.denlilleaftenskole.dk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FooterImage src={AftenSkoleImg} alt="logo-aftenskole" />
              </FooterLink>
            </FooterBox>
          </DesktopImageContainer>
        </FlexContainer>
        <FlexContainer>
          <FooterBox>
            <FooterBreak>
              <FooterText variant="body2">&#160;</FooterText>
            </FooterBreak>
          </FooterBox>

          <FooterBox>
            <FooterTitle variant="body2">Udforsk</FooterTitle>
            <FooterText variant="body2">
              <Link to="/om-skolen/faq">FAQ</Link>
            </FooterText>
            {context.state.isOpenPrivacy ? <Privacy /> : <></>}
          </FooterBox>
          <FooterBox>
            <FooterTitle variant="body2">Følg os</FooterTitle>
            <FooterLink
              href="https://www.facebook.com/denlillemusikskole/?locale=da_DK"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </FooterLink>
          </FooterBox>
          <DesktopImageContainer>
            <FooterBox>
              <FooterLink
                href="https://dlmv.dk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FooterImage src={DlmvImg} alt="logo-dlmv" />
              </FooterLink>
            </FooterBox>
          </DesktopImageContainer>
        </FlexContainer>
        <div>&#169; 2023 <a href="https://leonardoudvikling.dk/" target="_blank" rel="noopener noreferrer">Leonardo udvikling</a></div>
      </FooterContentContainer>
    </FooterComponent>
  );
}

export default Footer;
