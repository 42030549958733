import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import LeftArrow from "../navigate-carousel/LeftArrow";
import RightArrow from "../navigate-carousel/RightArrow";
import ParentsWordsSlide from "./ParentsWordsSlide";
import CarouselBullet from "../navigate-carousel/CarouselBullet";
import { Fade } from "@material-ui/core";
// import data from "./data";
import RegularHeadline from "../../headlines/RegularHeadline";

// CMS feature
import { Context } from "../../../context/Context";
import MessageList from "../../../components/message-list";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@material-ui/core/IconButton';

const CarouselContainer = styled.div`
  // margin: 5rem auto 10rem auto;
  max-height: 500px;
  height: 500px;
  display: none;
  @media (max-width: 960px) {
    display: block;
    margin: 5rem auto 20rem auto;
    position: relative;
    // background-color: black;
  }
`;

const ArrowsWithBullets = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  @media (max-width: 960px) && (min-width: 350px) {
    bottom: -4rem;
  }
  @media (max-width: 350px) {
    bottom: -3rem;
  }
`;

const BulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: static;
  justify-content: center;
  @media (max-width: 960px) {
  }
`;

const HeadlineContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

// react-touch is compatible with latest React, just implement Swipeable here. Removed import { Swipeable } from "react-touch";
const Swipeable = (props) => {
  return (
    <div style={{
        overflowX: 'auto',
        overflowY: 'auto',      
        '&::WebkitScrollbar': {
          display: 'none',     
        },                   
        '&': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none'
        }
    }}>
      {props.children}
    </div>
  );
}

function Carousel() {
  const context = useContext(Context);
  const user = context.state.currentUser;
  const isSuperuser = (['superuser','admin'].indexOf(user.role)>=0);

  const [data, setData] = useState([]);
  const [messageList, setMessageList] = useState([]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [checked, setChecked] = useState(true);

  const slidesNumber = data.length;

  const handleArrowClick = (slideId) => {
    const newIndex = slideId;
    console.log(slideId);
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
      setCurrentSlide(newIndex);
    }, 200);
  };
  const handleSwipe = (direction) => {
    if (direction === "left") {
      currentSlide === slidesNumber - 1
        ? handleArrowClick(0)
        : handleArrowClick(currentSlide + 1);
    } else {
      currentSlide === 0
        ? handleArrowClick(slidesNumber - 1)
        : handleArrowClick(currentSlide - 1);
    }
  };

  // ComponentDidMount
  useEffect(() => {  
    let stories = messageList.map( (message,index) => {
        return {
          id: index+1,
          title: message.title,
          description: (''+message.html).trim(),
          image: message.text,
          messageId: message.id
        }
      }
    );
    // console.log('Stories:',stories);
    setData(stories);
    // eslint-disable-next-line
  }, [messageList.length]);
  
  const handleEdit = (messageId) => {
    window.open('/message/'+messageId+'/edit','_blank');
  }

  return (
    <CarouselContainer>
      <MessageList type="html" name="Parent stories%" state="final" change={setMessageList}/>
      <div style={{ display: (context.state.features.enableStories?'block':'none')}}>
        <HeadlineContainer>
          <RegularHeadline title="Hvad siger forældrene?" />
        </HeadlineContainer>
        <Swipeable
          onSwipeLeft={() => handleSwipe("left")}
          onSwipeRight={() => handleSwipe("right")}
        >
          <Fade in={checked}>
            <div>
              {data.length > 0 &&
                <>
                  { isSuperuser && 
                    <IconButton size="small" style={{ width: 30, height: 30, background: '#FF0000', margin: 4 }} component="span" onClick={(event) => handleEdit(data[currentSlide].messageId)}>
                      <EditIcon/>
                    </IconButton>
                  }  
                  <ParentsWordsSlide slideInfo={data[currentSlide]} ></ParentsWordsSlide>
                </>
              }
            </div>
          </Fade>
        </Swipeable>
        <ArrowsWithBullets>
          {/* Use Fade animation  */}
          <LeftArrow
            currentSlide={currentSlide}
            slidesNumber={slidesNumber}
            onClick={handleArrowClick}
          />
          <BulletsContainer>
            {data.map((slide) => {
              return (
                <CarouselBullet
                  key={slide.id}
                  bulletId={slide.id}
                  currentSlide={currentSlide}
                  onClick={handleArrowClick}
                />
              );
            })}
          </BulletsContainer>
          <RightArrow
            currentSlide={currentSlide}
            slidesNumber={slidesNumber}
            onClick={handleArrowClick}
          />
        </ArrowsWithBullets>
      </div>
    </CarouselContainer>
  );
}

export default Carousel;
