import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import MeetingBar from "../../../components/bars/meeting-bar/MeetingBar";
import RegistrationContentContainer from "../../../components/containers/registration/RegistrationContentContainer";
import RegularHeadline from "../../../components/headlines/RegularHeadline";
import Loader from "../../../components/loader/Loader";
import {
  getDateString,
  getTimeString,
} from "../choose-meetings/ChooseMeetingsFunctions";

const DescriptionText = styled(Typography)`
  margin-bottom: 1rem;
`;

function Workdays({
  workdays,
  selectedWorkdays,
  setSelectedWorkdays,
  isLoading,
}) {
  const handleWorkdaySelect = (workdayObject) => {
    const selectedWorkdaysCopy = [...selectedWorkdays];
    if (selectedWorkdays.find((workday) => workday.id === workdayObject.id)) {
      const filteredWorkdays = selectedWorkdaysCopy.filter(
        (workday) => workday.id !== workdayObject.id
      );
      setSelectedWorkdays(filteredWorkdays);
    } else {
      selectedWorkdaysCopy.push(workdayObject);
      setSelectedWorkdays(selectedWorkdaysCopy);
    }
  };

  return (
    <React.Fragment>
      <RegistrationContentContainer marginBottom="3rem" marginTop="2rem">
        <RegularHeadline
          title="Vælg mindst to arbejdsdage"
          component="h3"
          marginbottom="1rem"
        />
        <Typography variant="body2">
          Id reprehenderit consequat anim ex excepteur.
        </Typography>
      </RegistrationContentContainer>
      {workdays.length > 0 ? (
        <React.Fragment>
          {workdays.map((workday) => (
            <MeetingBar
              id={workday.id}
              key={workday.id}
              title={workday.title}
              date={getDateString(workday.date)}
              time={getTimeString(
                workday.date + " " + workday.start,
                workday.date + " " + workday.end
              )}
              onSelect={handleWorkdaySelect}
              height="12rem"
              selectedMeetings={selectedWorkdays}
              type="workday"
            />
          ))}
        </React.Fragment>
      ) : (
        <RegistrationContentContainer>
          {isLoading ? (
            <Loader />
          ) : (
            <DescriptionText variant="body2">
              Der er ingen arbejdsdage i øjeblikket. Kontakt os venlig.
            </DescriptionText>
          )}
        </RegistrationContentContainer>
      )}
    </React.Fragment>
  );
}

export default Workdays;
